export type ResolveLibrary<T> = (library?: T) => void;
export type ExecuteWithLibrary<T> = <Result>(cb: (library: T) => Result) => Promise<Result>;
export const executeWithDeferredLibrary = <T>(): [resolver: ResolveLibrary<T>, executor: ExecuteWithLibrary<T>] => {
  let resolveLibrary: ResolveLibrary<T> = () => {};
  const loadedLibraryPromise: Promise<T> = new Promise(resolve => {
    resolveLibrary = resolve as ResolveLibrary<T>;
  });
  const executeWithLibrary: ExecuteWithLibrary<T> = cb => {
    return loadedLibraryPromise.then(cb);
  };

  return [resolveLibrary, executeWithLibrary];
};
