export type ResolveLibrary<T> = (library?: T) => void;
export type ExecuteWithLibrary<T> = (cb: (library: T) => void) => void;
export const executeWithDeferredLibrary = <T>(): [ResolveLibrary<T>, ExecuteWithLibrary<T>] => {
  let resolveLibrary: ResolveLibrary<T> = () => {};
  const loadedLibraryPromise: Promise<T> = new Promise(resolve => {
    resolveLibrary = resolve as ResolveLibrary<T>;
  });
  const executeWithLibrary: ExecuteWithLibrary<T> = (func: (library: T) => void) => {
    loadedLibraryPromise.then(func);
  };

  return [resolveLibrary, executeWithLibrary];
};

export class HiddenLogger {
  private initialized = false;
  private logToConsole = false;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  log(message: any, ...optionalParams: any[]) {
    this.assertInitialized();

    if (this.logToConsole) {
      // eslint-disable-next-line no-console
      console.log(message, ...optionalParams);
    }
  }

  private assertInitialized() {
    if (this.initialized) {
      return;
    }

    this.addLogKeyToggle();
    this.logToConsole = this.shouldLogToConsole;
    this.initialized = true;
  }

  // This should be calculated only once since query params may be lost in routing
  private get shouldLogToConsole(): boolean {
    return !this.isProduction || this.isLoggingEnabledInQueryParams;
  }

  private get isProduction(): boolean {
    return process.env.NODE_ENV === 'production';
  }

  private get isLoggingEnabledInQueryParams(): boolean {
    const url = new URL(window.location.href);

    return url.searchParams.get('showlog') === 'true';
  }

  private addLogKeyToggle() {
    window.addEventListener('keydown', (e: KeyboardEvent) => {
      if (e.ctrlKey && e.code === 'KeyL') {
        this.logToConsole = !this.logToConsole;
      }
    });
  }
}

export const validateStr = <T extends string>(value: unknown, acceptableValues: T[], defaultVal: T): T => {
  if (typeof value !== 'string') {
    return defaultVal;
  }

  if (!(acceptableValues as string[]).includes(value)) {
    return defaultVal;
  }

  return value as T;
};
