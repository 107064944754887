import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { createContext } from '@shared/utils/createContext';
import { JoyLogoLoaderBase } from './JoyLogoLoaderBase';

type FullPageLoaderContextType = {
  stopTrackingKey: (key: string) => void;
  trackVisibilityForKey: (key: string, isLoading: boolean) => void;
};

const [Provider, useFullPageLoaderContext] = createContext<FullPageLoaderContextType>({
  name: 'FullPageLoader',
  strict: __BROWSER__,
  initialValue: {
    stopTrackingKey: () => {},
    trackVisibilityForKey: () => {}
  }
});

const _checkIfLoadingForAnyKey = (tracker: Record<string, boolean>) => Object.entries(tracker).some(([key, isEnabledForKey]) => isEnabledForKey);

/**
 * TODO:
 * Per Madhur:
 * can we log an aggregate telemetry event that captures all the loaders that were shown on a given screen. and when each of them started and ended.
 * that will help us track down which ui pieces are the long pole on complex pages. there should be just one event logged with all the loaders
 * (not one per loader) so that the telemetry itself doesnt flood the network
 */
export const FullPageLoaderProvider: React.FC = ({ children }) => {
  const [tracker, setTracker] = useState<Record<string, boolean>>({});

  const shouldAnimate = useMemo(() => {
    return _checkIfLoadingForAnyKey(tracker);
  }, [tracker]);

  const stopTrackingKey: FullPageLoaderContextType['stopTrackingKey'] = useCallback(
    key => {
      setTracker(tracker => {
        delete tracker[key];
        return { ...tracker };
      });
    },
    [setTracker]
  );

  const trackVisibilityForKey: FullPageLoaderContextType['trackVisibilityForKey'] = useCallback(
    (key, isLoading) => {
      setTracker(tracker => {
        return {
          ...tracker,
          [key]: isLoading
        };
      });
    },
    [setTracker]
  );

  const value = useMemo(() => {
    return { stopTrackingKey, trackVisibilityForKey };
  }, [stopTrackingKey, trackVisibilityForKey]);

  return (
    <Provider value={value}>
      {__BROWSER__ && <JoyLogoLoaderBase key="fullPageLoaderProvider" shouldAnimate={shouldAnimate} />}
      {children}
    </Provider>
  );
};

type UseFullPageLoaderContextArgs = Readonly<{
  key: string;
  initialIsLoading?: boolean;
}>;

export const useFullPageLoader = (args: UseFullPageLoaderContextArgs) => {
  const { initialIsLoading = false, key } = args;
  const { stopTrackingKey, trackVisibilityForKey } = useFullPageLoaderContext();
  const isMountedRef = useRef(false);

  const setIsLoading = useCallback(
    (isLoading: boolean) => {
      trackVisibilityForKey(key, isLoading);
    },
    [trackVisibilityForKey, key]
  );

  useEffect(() => {
    if (__BROWSER__ && !isMountedRef.current) {
      isMountedRef.current = true;
      if (initialIsLoading) {
        setIsLoading(initialIsLoading);
      }
    }
  }, [initialIsLoading, setIsLoading]);

  useEffect(() => {
    return () => {
      stopTrackingKey(key);
    };
  }, [stopTrackingKey, key]);

  return setIsLoading;
};

export { useFullPageLoaderContext };
