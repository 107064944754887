import { IconConfig } from '..';

export const dropdown: IconConfig = {
  name: 'dropdown',
  a11yLabel: 'Dropdown',
  viewBox: 24,
  fill: '#333333',
  body: `
    <path fill-rule="evenodd" clip-rule="evenodd" d="M7 10L12 15L17 10H7Z" />
  `
};
