function capitalizeWord(str: null | undefined): null | undefined;
function capitalizeWord(str: string): string;
function capitalizeWord(str: Maybe<string>): Maybe<string> {
  return str ? `${str.charAt(0).toUpperCase()}${str.slice(1).toLowerCase()}` : str;
}

export { capitalizeWord };

export const stripUrlProtocol = (url: string) => url.replace(/(^\w+:|^)\/\//, '');

export const generateSlugFromTitle = (title: string): string => {
  return title
    .toLowerCase()
    .trim()
    .replace(/[\s]+/g, '-')
    .replace(/[^\w\-]+/g, '')
    .replace(/--+/g, '-')
    .replace(/^-+|-+$/g, '');
};
