import { SegmentPageProps, SegmentTrackProps, SegmentGroupProps } from '../context';
import { deriveQueryStringHelpersFromSearchString } from '@shared/core/queryString';
import memoize from 'memoize-one';
import globalWindow from '@shared/core/globals';
import { getInstanceId, getBrowserId, getSessionId } from './getTrackerIds';
import { CREATE_WEDDING_APP_NAME } from '@apps/createWedding/constants';
import { CREATE_BABY_REGISTRY_APP_NAME } from '@apps/createBabyRegistry/constants';
import { EventType } from '@graphql/generated';
import { segment } from '@static/js/joy';

const PRODUCTION = 'production';

const parseLocationSearchString = memoize(deriveQueryStringHelpersFromSearchString);

let clientWidth = globalWindow.innerWidth ?? 0;
let clientHeight = globalWindow.innerHeight ?? 0;

const listenForResize = () => {
  if (typeof window !== 'undefined') {
    window.addEventListener('resize', () => {
      clientWidth = window.innerWidth;
      clientHeight = window.innerHeight;
    });
  }
};
listenForResize();

const getSharedContextProperties = () => {
  const { getValueString } = parseLocationSearchString(globalWindow.location?.search);

  return {
    ref: getValueString('ref'),
    browserId: getBrowserId(),
    sessionId: getSessionId(),
    instanceId: getInstanceId(),
    buildNumber: globalWindow.buildNumber,
    clientWidth,
    clientHeight
  };
};

const cleanData = <T extends Record<string | number, unknown>>(data: T): T => {
  return Object.keys(data).reduce((acc: T, curr: string | number) => {
    if (['number', 'boolean'].includes(typeof data[curr]) || data[curr] || curr === 'joyPlatformData') {
      return { ...acc, [curr]: data[curr] };
    } else {
      return acc;
    }
  }, {} as T);
};

const isAdminRoute = () => typeof window !== 'undefined' && /^\/.*\/edit(?:[\/?].*)?$/.test(window.location.pathname);
const isDomainRoute = () => typeof window !== 'undefined' && /\/domain[\/]?$/.test(window.location.pathname);
const isCreateWedding = () => typeof window !== 'undefined' && window.location.pathname.startsWith(`/${CREATE_WEDDING_APP_NAME}`);
const isCreateBabyRegistry = () => typeof window !== 'undefined' && window.location.pathname.startsWith(`/${CREATE_BABY_REGISTRY_APP_NAME}`);

const joyEventTypeForCreate = isCreateWedding() ? EventType.wedding : isCreateBabyRegistry() ? EventType.babyRegistry : undefined;

interface SegmentOptionOverrides {
  integrations: SegmentAnalytics.SegmentOpts['integrations'];
  referrer?: string;
}

const getSegmentOptions = (options: SegmentOptionOverrides) => {
  const { integrations, referrer } = options;

  const segmentOptions: SegmentAnalytics.SegmentOpts = {
    integrations
  };

  if (referrer) {
    segmentOptions.context = {
      page: { referrer }
    };
  }

  return segmentOptions;
};

export class SegmentService {
  private logToConsole: boolean = false;
  private groupPostgresEventId: Maybe<string>;
  private groupFirebaseEventId: Maybe<string>;
  private groupJoyEventType: Maybe<EventType>;
  private userEmail: Maybe<string>;

  constructor() {
    this.listenForDebug();
  }

  public sendEvent = (data: SegmentTrackProps) => {
    const action = data.action;
    const { pageOverride, categoryOverride, ...extraInfo } = data.extraInfo || {};
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const event: any = {
      analyticsId: data.analyticsId,
      category: categoryOverride || data.category,
      label: data.label,
      email: this.userEmail,
      ...extraInfo,
      pagePrefix: data.pagePrefix,
      page: pageOverride || data.page,
      context: data.context
    };

    event.context = event.context || {};
    // Must add context properties to `event.context` instead of directly to `context` because
    // `context` fields are reserved/cleaned by segment.
    // https://segment.com/docs/connections/spec/common/#context
    event.context = this.getMergedSharedContext(event.context);

    if (!event.eventId) {
      // automatically add `eventId` to property bag if not defined

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      event.eventId = (data.context as any)?.eventId;
    }

    if (!event.context?.eventId && event.eventId) {
      event.context.eventId = event.eventId;
    }

    const segmentOptions = getSegmentOptions({
      integrations: { Intercom: isDomainRoute() || isCreateWedding() },
      referrer: data.referrerOverride
    });

    const callback = data.callback;

    const cleanedEvent = cleanData(event);
    segment.sendTrackTelemetry(action, callback, cleanedEvent, segmentOptions);
    if (process.env.NODE_ENV !== PRODUCTION || this.logToConsole) {
      if (typeof window !== 'undefined') {
        // eslint-disable-next-line no-console
        console.log('Analytics Track ', { action, data: cleanedEvent, segmentOptions });
      }
    }
  };

  public sendPage = (data: SegmentPageProps) => {
    const page = [data.pagePrefix, data.category, data.page].map(e => e.toLowerCase()).join('.');
    const { categoryOverride, ...extraPageInfo } = data.extraPageInfo || {};
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const event: any = {
      analyticsId: data.analyticsId,
      action: data.action,
      category: categoryOverride || data.category,
      timeSpentInSeconds: data.timeSpentInSeconds,
      extraPageInfo,
      referrer: data.referrerOverride,
      context: data.context
    };
    const cleanedEvent = cleanData(event);

    cleanedEvent.context = event.context || {};
    // Must add context properties to `event.context` instead of directly to `context` because
    // `context` fields are reserved/cleaned by segment.
    // https://segment.com/docs/connections/spec/common/#context
    cleanedEvent.context = this.getMergedSharedContext(cleanedEvent.context);

    const segmentOptions = getSegmentOptions({
      integrations: {
        Intercom: false,
        Amplitude: cleanedEvent.action === 'enter'
      }
    });

    segment.sendPageTelemetry(page, cleanedEvent, segmentOptions);
    if (process.env.NODE_ENV !== PRODUCTION || this.logToConsole) {
      if (typeof window !== 'undefined') {
        // eslint-disable-next-line no-console
        console.log('Analytics Page ', { page, data: cleanedEvent, segmentOptions });
      }
    }
  };

  public identify = (data: Record<string | number, unknown>) => {
    // it's important that the userId sent to segment comes from auth0
    // b/c that is the id we use for CX, user segments and communications
    const auth0Id = data.auth0Id;
    const extraData = Object.keys(data).reduce((acc, curr) => {
      if (curr === 'userId') {
        return acc;
      } else {
        return { ...acc, [curr]: data[curr] };
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    }, {} as Record<string, any>);

    extraData.context = { ...(extraData.context || {}), ...getSharedContextProperties() };

    if (auth0Id && typeof auth0Id === 'string') {
      segment.sendIdentifyTelemetry(auth0Id, extraData, { integrations: { Intercom: isDomainRoute() || isCreateWedding() || isAdminRoute() } });
    }
    if (process.env.NODE_ENV !== PRODUCTION || this.logToConsole) {
      if (typeof window !== 'undefined') {
        // eslint-disable-next-line no-console
        console.log('Analytics Identify ', { userId: auth0Id, data: extraData });
      }
    }

    if (typeof data.email === 'string') {
      this.userEmail = data.email;
    }
  };

  public group = (args: SegmentGroupProps): void => {
    this.groupFirebaseEventId = args.groupId;
    this.groupJoyEventType = args.traits?.eventType;
    this.groupPostgresEventId = args.traits?.postgresEventId;
  };

  public anonymousId = (): string => {
    return segment.getAnonymousId();
  };

  private listenForDebug = () => {
    if (typeof window !== 'undefined') {
      window.addEventListener('keydown', (e: KeyboardEvent) => {
        if (e.ctrlKey && e.code === 'KeyA') {
          this.logToConsole = !this.logToConsole;
        }
      });
    }
  };

  private getMergedSharedContext = (context: Record<string, unknown>): Record<string, unknown> => {
    return {
      postgresEventId: this.groupPostgresEventId ?? undefined,
      firebaseEventId: this.groupFirebaseEventId ?? undefined,
      joyEventType: this.groupJoyEventType ?? joyEventTypeForCreate,
      ...context,
      ...getSharedContextProperties()
    };
  };
}
