import React from 'react';

export interface IntercomContextProps {
  hide: () => void;
  show: () => void;
  boot: () => Promise<void>;
  shutdown: () => void;

  /**
   * Show the default launcher icon
   */
  showLauncher: () => void;

  /**
   * Hide the default launcher icon
   */
  hideLauncher: () => void;
  startSurvey: (id: string) => void;
}

export const IntercomContext = React.createContext<IntercomContextProps>({
  hide: () => {
    throw Error('No Intercom Provider');
  },
  show: () => {
    throw Error('No Intercom Provider');
  },
  boot: () => {
    throw Error('No Intercom Provider');
  },
  shutdown: () => {
    throw Error('No Intercom Provider');
  },
  hideLauncher: () => {
    throw Error('No Intercom Provider');
  },
  showLauncher: () => {
    throw Error('No Intercom Provider');
  },
  startSurvey: () => {
    throw Error('No Intercom Provider');
  }
});
