import { IconConfig } from '..';

/* tslint:disable:max-line-length */
export const config: IconConfig = {
  name: 'config',
  a11yLabel: 'Config',
  viewBox: 24,
  fill: '#333333',
  body: `
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.86609 11.4566H3.18116C2.88116 11.4566 2.63768 11.7001 2.63768 12.0001C2.63768 12.3001 2.88116 12.5436 3.18116 12.5436H4.86609C5.11855 13.7821 6.21681 14.7175 7.52898 14.7175C8.84116 14.7175 9.93942 13.7821 10.1919 12.5436L21.6594 12.5436C21.9594 12.5436 22.2029 12.3001 22.2029 12.0001C22.2029 11.7001 21.9594 11.4566 21.6594 11.4566L10.1919 11.4566C9.93942 10.2181 8.84116 9.28271 7.52898 9.28271C6.21681 9.28271 5.11855 10.2181 4.86609 11.4566ZM9.15942 12.0001C9.15942 12.8992 8.42811 13.6305 7.52898 13.6305C6.62985 13.6305 5.89855 12.8992 5.89855 12.0001C5.89855 11.101 6.62985 10.3697 7.52898 10.3697C8.42811 10.3697 9.15942 11.101 9.15942 12.0001Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.1919 4.9349L3.18116 4.9349C2.88116 4.9349 2.63768 5.17838 2.63768 5.47838C2.63768 5.77838 2.88116 6.02186 3.18116 6.02186L15.1919 6.02186C15.4446 7.26041 16.5429 8.19577 17.8551 8.19577C19.1672 8.19577 20.2655 7.26041 20.5183 6.02186H21.6594C21.9594 6.02186 22.2029 5.77838 22.2029 5.47838C22.2029 5.17838 21.9594 4.9349 21.6594 4.9349H20.5183C20.2655 3.69635 19.1672 2.76099 17.8551 2.76099C16.5429 2.76099 15.4446 3.69635 15.1919 4.9349ZM19.4855 5.47838C19.4855 6.37751 18.7542 7.10881 17.8551 7.10881C16.9559 7.10881 16.2246 6.37751 16.2246 5.47838C16.2246 4.57925 16.9559 3.84794 17.8551 3.84794C18.7542 3.84794 19.4855 4.57925 19.4855 5.47838Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.3878 17.9784L3.18116 17.9784C2.88116 17.9784 2.63768 18.2218 2.63768 18.5218C2.63768 18.8218 2.88116 19.0653 3.18116 19.0653L11.3878 19.0653C11.6403 20.3039 12.7385 21.2392 14.0507 21.2392C15.3629 21.2392 16.4612 20.3039 16.7136 19.0653H21.6594C21.9594 19.0653 22.2029 18.8218 22.2029 18.5218C22.2029 18.2218 21.9594 17.9784 21.6594 17.9784H16.7136C16.4612 16.7398 15.3629 15.8044 14.0507 15.8044C12.7385 15.8044 11.6403 16.7398 11.3878 17.9784ZM15.6812 18.5218C15.6812 19.421 14.9499 20.1523 14.0507 20.1523C13.1516 20.1523 12.4203 19.421 12.4203 18.5218C12.4203 17.6227 13.1516 16.8914 14.0507 16.8914C14.9499 16.8914 15.6812 17.6227 15.6812 18.5218Z"
    />  
  `
};
