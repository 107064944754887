import React from 'react';
import { IconSource, IconV1, IconV1Props } from '../components/IconV1';
import { Spinner } from '../components/Spinner';
import { BUTTON_SPINNER } from './classNames';
import { styled } from '@shared/joykit/packages/core/common/styledComponents';
import { BaseComponentProps } from './props';
import { ResponsiveValue } from './styledSystem/types';

export type ButtonSize = 'small' | 'medium';
export type Intent = 'primary' | 'secondary';
export type ButtonVariant = 'success' | 'danger';

export type AbstractButtonProps = {
  /** Content to displayed */
  children?: React.ReactNode;
  className?: string;

  /** Whether or not this button can be interacted with */
  disabled?: boolean;

  /** Whether or not it should expand to the width of its container */
  fill?: ResponsiveValue<boolean>;

  /**
   * Name of JoyKit icon or custom icon to be displayed on the left.
   *
   * Can be used in lieu of children, at
   */
  icon?: IconV1Props['source'];

  /** Name of JoyKit icon or custom icon to be displayed on the right.  */
  iconRight?: IconSource;

  /**
   * To show different levels of emphasis
   */
  intent?: Intent;

  // TODO:
  // inverse?: boolean;

  /** Replaces button text with spinner while a background action is being performed */
  loading?: boolean;

  size?: ButtonSize;

  /**
   * Visual intent color to apply
   */
  variant?: ButtonVariant;
};

const Content = styled.span`
  flex: 0 1 auto;
`;

const StyledSpinner = styled(Spinner)`
  position: absolute;
`;

interface CommonButtonProps<T> {
  onClick?: React.MouseEventHandler<T>;
  disabled?: boolean;
  tabIndex?: number;
}

/**
 * -----------------------------------
 * DEPRECATED -- Only used by legacy class button components.
 * ----------------------------------
 *
 * @deprecated
 */
export abstract class AbstractButton<P extends Omit<React.HTMLAttributes<any>, 'color'> & BaseComponentProps> extends React.PureComponent<AbstractButtonProps & P> {
  /**
   * Override native button attributes depending upon state
   */
  protected getCommonButtonProps = (): CommonButtonProps<any> => {
    const { tabIndex } = this.props;
    const disabled = this.props.disabled || this.props.loading;

    return {
      onClick: disabled ? undefined : this.props.onClick,
      disabled,
      tabIndex: disabled ? -1 : tabIndex
    };
  };

  // ==============================================================
  // Render
  // ==============================================================

  public abstract render(): React.ReactNode;

  protected renderChildren = (): React.ReactNode => {
    const { icon, iconRight, loading } = this.props;

    return (
      <>
        {loading && <StyledSpinner className={BUTTON_SPINNER} />}
        <IconV1 source={icon} />
        {this.renderContent()}
        <IconV1 source={iconRight} />
      </>
    );
  };

  private renderContent = (): JSX.Element | null => {
    const { children } = this.props;
    if (!children) {
      return null;
    }

    return <Content>{children}</Content>;
  };

  // ==============================================================
  // Helpers
  // ==============================================================
}
