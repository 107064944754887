import { ApolloLink, HttpLink, Observable } from '@apollo/client';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import fetchMethod from 'isomorphic-fetch';
import queryString from '../queryString';

const consoleLog = (msg: string, arr?: ReadonlyArray<string>) => {
  // eslint-disable-next-line no-console
  console.log(`BATCHGQL:`, msg, arr?.join(', '));
};

const sleep = (ms: number) => {
  return new Promise(resolve => global.setTimeout(resolve, ms));
};

const fetchWithPerformanceMarker = (() => {
  return function () {
    // eslint-disable-next-line prefer-spread, prefer-rest-params, @typescript-eslint/no-explicit-any
    const promise = fetchMethod.apply(null, arguments as any);
    promise.finally(() => {
      // eslint-disable-next-line compat/compat
      performance.mark('gql');
    });
    return promise;
  };
})();

type OperationEntry = Readonly<{
  name: string;
  duration: number;
  start: number;
  end: number;
}>;

const pushOperation = (arr: Array<OperationEntry>, name: string, start: number, end: number) => {
  arr.push({
    name: name,
    // keep duration at the beginning of this object so it shows up in the chrome debugger tools
    // before other stuff like start and end
    duration: end - start,
    start,
    end
  });
};

const getOperationNames = (arr: ReadonlyArray<OperationEntry>) => {
  return arr.map(op => op.name);
};

const runAnalysis = (description: string, operations: ReadonlyArray<OperationEntry>) => {
  const operationsList = operations.slice();
  const groups: Array<Array<OperationEntry>> = [];

  while (operationsList.length) {
    const currentOp = operationsList.shift() as OperationEntry;
    const matchingGroup = groups.find(group => {
      return group.some(op => currentOp.start > op.start) && group.every(op => currentOp.start < op.end);
    });

    if (matchingGroup) {
      matchingGroup.push(currentOp);
    } else {
      groups.push([currentOp]);
    }
  }

  const ret = {
    groups: [] as Array<Array<OperationEntry>>,
    singles: [] as Array<OperationEntry>
  };

  groups.forEach(group => {
    if (group.length > 1) {
      consoleLog(description, getOperationNames(group));
      ret.groups.push(group);
    } else {
      ret.singles.push(group[0]);
    }
  });

  return ret;
};

export const getTerminatingLink = (graphQLUrl: string, originalGetTerminatingLink: (url: string, fetch: typeof fetchMethod) => ApolloLink) => {
  const rawValue = queryString.getValue('feature.batchgql') || '';
  const batchModeFromQueryString = typeof rawValue === 'string' ? rawValue.toLowerCase() : 'test';

  const fastBatchLink = new BatchHttpLink({
    batchInterval: batchModeFromQueryString !== 'analyze' ? 200 : 5000,
    credentials: 'include',
    uri: graphQLUrl,
    fetch: fetchWithPerformanceMarker
  });

  const httpLink = new HttpLink({
    credentials: 'include',
    uri: graphQLUrl,
    fetch: fetchWithPerformanceMarker
  });

  let analyzeMethod = () => {
    // eslint-disable-next-line compat/compat
    const lastGqlMarker = performance.getEntriesByName('gql').slice(-1)[0].startTime;
    consoleLog(`Last GraphQL method returned at ${lastGqlMarker}`);
  };

  let finalLink: ApolloLink;
  switch (batchModeFromQueryString) {
    case 'fast':
      finalLink = fastBatchLink;
      break;

    case 'on':
      // enable regular batching but also capture analytics about fetch calls
      finalLink = originalGetTerminatingLink(graphQLUrl, fetchWithPerformanceMarker);
      break;

    case 'test':
      {
        const operationsToBatch = Array.isArray(rawValue) ? (rawValue as ReadonlyArray<string>) : [];
        consoleLog(`Wil use batching for the following operations`, operationsToBatch);

        finalLink = new ApolloLink(operation => {
          let link: ApolloLink;
          if (operationsToBatch.includes(operation.operationName)) {
            consoleLog(`Using fast batching based on query string for operation ${operation.operationName}`);
            link = fastBatchLink;
          } else {
            consoleLog(`Defaulting to no batching for operation ${operation.operationName}`);
            link = httpLink;
          }

          return link.request(operation) || Observable.of();
        });
      }
      break;

    case 'analyze':
      {
        const realOperations: Array<OperationEntry> = [];
        const delayedOperations: Array<OperationEntry> = [];

        analyzeMethod = () => {
          const batchable = runAnalysis('The following operations are candidates for batching', realOperations);
          const batchableButSlow = runAnalysis('The following operations could be batched if some of them were faster. Speed those ones up on the server', delayedOperations);

          if (batchableButSlow.singles.length) {
            consoleLog(
              'The following operations cannot be batched at all. The UI is effectively blocked on them. See if code can be refactored to make them non blocking',
              getOperationNames(batchableButSlow.singles)
            );
          }

          return {
            batchable: batchable.groups,
            batchableButSlow: batchableButSlow.groups,
            blocking: batchableButSlow.singles
          };
        };

        const getSlowFetchMethod = (operationName: string) => {
          return function () {
            const start = Date.now();
            // eslint-disable-next-line prefer-spread, prefer-rest-params, @typescript-eslint/no-explicit-any
            const promise = fetchMethod.apply(null, arguments as any);
            let end: number;

            promise.finally(() => {
              end = Date.now();
              pushOperation(realOperations, operationName, start, end);
            });

            return sleep(5000).then(() => {
              pushOperation(delayedOperations, operationName, start, end);
              return promise;
            });
          };
        };

        finalLink = new ApolloLink(operation => {
          const link = new HttpLink({
            credentials: 'include',
            uri: graphQLUrl,
            fetch: getSlowFetchMethod(operation.operationName)
          });

          return link.request(operation) || Observable.of();
        });
      }
      break;

    case 'off':
      finalLink = httpLink;
      break;

    default:
      consoleLog(`Unknown query string specified ${batchModeFromQueryString}. Turning off batching.`);
      finalLink = httpLink;
      break;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (global as any).batchGQL = {
    analyze: analyzeMethod
  };

  return finalLink;
};
