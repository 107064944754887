import { useEffect, useRef } from 'react';

export function useComponentWillMount(callBeforeMount: () => void) {
  const willMount = useRef(true);

  if (willMount.current) {
    callBeforeMount();
  }

  useEffect(() => {
    willMount.current = false;
  }, []);
}
