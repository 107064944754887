import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { getDateFormatString } from './utils';

type LocaleContextType = {
  language: string;
  dateFormat: string;
  dateInputMask: string;
};

export const LocaleContext = React.createContext<LocaleContextType | undefined>(undefined);

export const LocaleProvider: React.FC = ({ children }) => {
  const { i18n } = useTranslation();

  const value = React.useMemo<LocaleContextType>(() => {
    return {
      language: i18n.language,
      ...getDateFormatString(i18n.language)
    };
  }, [i18n.language]);

  return <LocaleContext.Provider value={value}>{children}</LocaleContext.Provider>;
};

export const useLocaleContext = () => {
  const context = React.useContext(LocaleContext);
  if (!context) {
    throw new Error('useLocaleContext() can only be used within a LocaleProvider.');
  }
  return context;
};
