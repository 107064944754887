import * as IconNames from './iconDefinitions/iconNames';

export type IconViewBox = 16 | 24 | 40 | 48;

export type ComposeableIcon = 'iconBase';

/**
 * JoyKit provided icons
 */
export type BundledIcon = typeof IconNames[keyof typeof IconNames] | ComposeableIcon;

export interface IconConfig {
  name: BundledIcon;

  /**
   * Descriptive text to be read to screenreaders
   */
  a11yLabel: string;

  /**
   * Base fill - to be applied to the SVG directly
   */
  fill: string;

  /**
   * Size of the icon.
   *
   * JoyKit contains 24px and 40px icons.
   */
  viewBox: IconViewBox;

  /**
   * The sub-paths to be drawn
   */
  body: string;
}

import { JOYKIT_ICONS, IconSVG24 } from './iconDefinitions';

const generateViewBoxStringFromValue = (viewBox: IconViewBox) => `0 0 ${viewBox} ${viewBox}`;

export { JOYKIT_ICONS, generateViewBoxStringFromValue, IconSVG24 };
