import React, { useMemo, useEffect } from 'react';
import { useImmer } from 'use-immer';
import * as H from 'history';
import { createContext } from '@shared/utils/createContext';
// Importing directly from 'react-router-dom' since `src/shared/core/router.helper/reactRouter.tsx` consumes
// the FeatureFlagsProvider context

// eslint-disable-next-line no-restricted-imports
import { useHistory, useLocation } from 'react-router-dom';
import {
  FeatureFlagsProviderProps,
  FeatureFlagsState,
  FeatureFlagsContextType,
  FeatureFlagKey,
  BooleanStringBasedFeatureKeys,
  StringBasedFeatureKeys,
  RemoteFeatureFlagKeys,
  RemoteFeatureFlagOptions
} from './featureFlags.types';
import { deriveFeatureContextFromState, updateStateDraftFromLocation, formatFeatureFlagsToUrl } from './featureFlags.utils';
import { FEATURE_FLAG_VALUES } from './featureFlags.constants';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';
import { RemoteFeatureFlag, RemoteFeatureFlagsProvider, useRemoteFeatureFlag } from './remoteFeatureFlags';

const [Provider, useFeatureFlagsContext, InternalFeatureFlagsContext] = createContext<FeatureFlagsContextType>({ name: 'FeatureFlagQueryStrings' });

const FeatureFlagsProvider = ({ children, config = FEATURE_FLAG_VALUES }: FeatureFlagsProviderProps) => {
  const location = useLocation();
  const history = useHistory();

  const [state, setState] = useImmer<FeatureFlagsState>(() => {
    const stateDraft = Object.entries(config).reduce((acc, [key]) => {
      // @ts-ignore
      acc[key] = undefined;
      return acc;
    }, {} as FeatureFlagsState);

    updateStateDraftFromLocation(config, stateDraft, location);
    return stateDraft;
  });

  const handleOnHistoryChange = useEventCallback<H.LocationListener>((location, action) => {
    setState(draft => {
      updateStateDraftFromLocation(config, draft, location);
    });
  });

  useEffect(() => {
    const unsubscribe = history.listen(handleOnHistoryChange);
    return () => {
      unsubscribe();
    };
  }, [history, handleOnHistoryChange]);

  const value = useMemo<FeatureFlagsContextType>(() => {
    return {
      features: deriveFeatureContextFromState(config, state),
      formatToUrl: url => formatFeatureFlagsToUrl(config, state, url)
    } as FeatureFlagsContextType;
  }, [state, config]);

  return (
    <Provider value={value}>
      <RemoteFeatureFlagsProvider>{children}</RemoteFeatureFlagsProvider>
    </Provider>
  );
};

function useFeatureValue<Key extends RemoteFeatureFlagKeys>(featureName: Key, options?: RemoteFeatureFlagOptions): RemoteFeatureFlag<Key>;
function useFeatureValue<Key extends StringBasedFeatureKeys>(featureName: Key): string | undefined;
function useFeatureValue<Key extends BooleanStringBasedFeatureKeys>(featureName: Key): boolean;
function useFeatureValue<Key extends FeatureFlagKey>(featureName: Key, options?: RemoteFeatureFlagOptions) {
  const { features } = useFeatureFlagsContext();
  const feature = features[featureName];

  const isRemoteFeatureFlag = feature.type === 'remoteconfig';
  const remoteFeatureFlagValue = useRemoteFeatureFlag(isRemoteFeatureFlag ? (featureName as RemoteFeatureFlagKeys) : undefined, options);
  if (isRemoteFeatureFlag) {
    return remoteFeatureFlagValue;
  }

  const stateOrDefaultValue = feature.value ?? feature.defaultValue;

  // always return false for boolean feature flags if state/default value is undefined
  return feature.type === 'boolean' ? stateOrDefaultValue ?? false : stateOrDefaultValue;
}

export { useFeatureValue, FeatureFlagsProvider, useFeatureFlagsContext, InternalFeatureFlagsContext };
