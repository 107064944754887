import { IconConfig } from '..';

/* tslint:disable:max-line-length */
export const lock: IconConfig = {
  name: 'lock',
  a11yLabel: 'Lock',
  fill: '#333333',
  viewBox: 24,
  body: `
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.02667 16.5599C4.02667 20.6666 7.36001 23.9999 11.4667 23.9999C15.5733 23.9999 18.9067 20.6399 18.9067 16.5333C18.9067 12.4266 15.5733 9.09326 11.4667 9.09326C7.36001 9.09326 4.02667 12.4533 4.02667 16.5599ZM5.36001 16.5333C5.36001 13.1733 8.08001 10.4266 11.4667 10.4266C14.8533 10.4266 17.5733 13.1999 17.5733 16.5599C17.5733 19.9199 14.8533 22.6666 11.4667 22.6666C8.08001 22.6666 5.36001 19.8933 5.36001 16.5333Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.56 12.5867C16.1867 12.5867 15.8933 12.2933 15.8933 11.92V5.76C15.8933 3.30667 13.8933 1.33333 11.4667 1.33333C9.04 1.33333 7.04 3.30667 7.04 5.76V11.9467C7.04 12.32 6.74667 12.6133 6.37333 12.6133C6 12.6133 5.70667 12.32 5.70667 11.9467V5.76C5.70667 2.58667 8.29333 0 11.4667 0C14.64 0 17.2267 2.58667 17.2267 5.76V11.9467C17.2267 12.2933 16.9333 12.5867 16.56 12.5867Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.5 18.8182C12.7803 18.8182 13.8182 17.7804 13.8182 16.5001C13.8182 15.2198 12.7803 14.1819 11.5 14.1819C10.2197 14.1819 9.18182 15.2198 9.18182 16.5001C9.18182 17.7804 10.2197 18.8182 11.5 18.8182Z"
    />
  `
};
