import { IconConfig } from '..';

/* tslint:disable:max-line-length */
export const eye: IconConfig = {
  name: 'eye',
  a11yLabel: 'Eye',
  viewBox: 24,
  fill: '#333333',
  body: `
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 18.411C8.35057 18.411 4.87828 16.5178 1.68086 12.7835C1.15057 12.1641 1.15057 11.2647 1.68086 10.6453C4.87828 6.91182 8.35057 5.01782 12 5.01782C15.6494 5.01782 19.1217 6.91182 22.3191 10.6453C22.8494 11.2647 22.8494 12.1641 22.318 12.7835C19.1217 16.5178 15.6494 18.411 12 18.411ZM2.69743 11.517C2.60057 11.6295 2.60057 11.7993 2.69743 11.913C5.62943 15.3361 8.76 17.0715 12 17.0715C15.2411 17.0715 18.3706 15.3361 21.3026 11.9118C21.3994 11.7993 21.3994 11.6295 21.3034 11.517C18.3706 8.09268 15.2411 6.35725 12 6.35725C8.76 6.35725 5.62943 8.09268 2.69743 11.517Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 15.732C9.78459 15.732 7.9823 13.9297 7.9823 11.7142C7.9823 9.49882 9.78459 7.69653 12 7.69653C14.2154 7.69653 16.0177 9.49882 16.0177 11.7142C16.0177 13.9297 14.2154 15.732 12 15.732Z"
    />
  `
};
