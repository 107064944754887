import { useForceUpdate } from '@withjoy/joykit/hooks';
import globalWindow from '@shared/core/globals';
import { createContext } from '@shared/utils/createContext';
import { useIsomorphicLayoutEffect } from '@shared/utils/hooks/useIsomorphicLayoutEffect';
import React, { useMemo, useRef } from 'react';
import { createPortal } from 'react-dom';

export interface PortalProps
  extends Readonly<{
    appendToParentPortal?: boolean;
    containerRef?: React.RefObject<HTMLElement | null>;
    children: React.ReactNode;
  }> {}

const PORTAL_CLASS_NAME = 'joykit-portal';

const [PortalProvider, usePortalContext] = createContext<HTMLElement | null>({ name: 'Portal', strict: false });

const DocumentPortal: React.FC<{ appendToParentPortal?: boolean }> = ({ appendToParentPortal, children }) => {
  const tempNodeRef = useRef<HTMLElement>(null);
  const portalContainerRef = useRef<HTMLDivElement | null>(null);

  const tick = useForceUpdate();
  const parentPortalEl = usePortalContext();

  useIsomorphicLayoutEffect(() => {
    // Temp node needs to be attached in order for us to get a stable document reference
    if (!tempNodeRef.current) {
      return;
    }

    const ownerDoc = tempNodeRef.current.ownerDocument;
    const mountNode = (appendToParentPortal ? parentPortalEl : ownerDoc.body) ?? ownerDoc.body;

    if (!mountNode) {
      return;
    }

    portalContainerRef.current = ownerDoc.createElement('div');
    portalContainerRef.current.className = PORTAL_CLASS_NAME;

    mountNode.appendChild(portalContainerRef.current);
    tick();

    const portal = portalContainerRef.current;
    return () => {
      if (mountNode.contains(portal)) {
        mountNode.removeChild(portal);
      }
    };
  }, [tick, parentPortalEl, appendToParentPortal]);

  return portalContainerRef.current ? (
    createPortal(<PortalProvider value={portalContainerRef.current}>{children}</PortalProvider>, portalContainerRef.current)
  ) : (
    <span ref={tempNodeRef} />
  );
};

const ContainerPortal: React.FC<{ containerRef: React.RefObject<HTMLElement | null>; appendToParentPortal?: boolean }> = props => {
  const { appendToParentPortal, children, containerRef } = props;

  const containerEl = containerRef.current;
  const mountNode = containerEl ?? globalWindow.document?.body;

  const portalContainer = useMemo(() => {
    const portalEl = containerEl?.ownerDocument.createElement('div');
    if (portalEl) {
      portalEl.className = PORTAL_CLASS_NAME;
    }
    return portalEl;
  }, [containerEl]);

  const tick = useForceUpdate();

  useIsomorphicLayoutEffect(() => {
    if (!portalContainer || !mountNode) {
      return;
    }

    // Must insert the portal container in the DOM tree so that the Portal's children can be measured/accessible via the DOM.
    mountNode.appendChild(portalContainer);

    tick();
    return () => {
      mountNode.removeChild(portalContainer);
    };
  }, [portalContainer, mountNode, tick]);

  return mountNode && portalContainer ? createPortal(<PortalProvider value={appendToParentPortal ? portalContainer : null}>{children}</PortalProvider>, portalContainer) : null;
};

export const Portal: React.FC<PortalProps> = (props: PortalProps) => {
  const { containerRef, ...restProps } = props;

  return containerRef ? <ContainerPortal containerRef={containerRef} {...restProps} /> : <DocumentPortal {...restProps} />;
};

Portal.defaultProps = {
  appendToParentPortal: true
};
