import React, { useMemo, useRef } from 'react';
import { isMobileOnly, isTablet } from 'mobile-device-detect';

import { createContext } from '../createContext';
import { withWindow } from '../withWindow';

export type MediaScreen = 'mobile' | 'tablet' | 'desktop';

interface DefaultMediaMatchContext {
  defaultScreen: MediaScreen;
}

const [Provider, useDefaultMediaMatchContext] = createContext<DefaultMediaMatchContext>({ name: 'DefaultMediaMatch', strict: false });

export const DefaultMediaMatchProvider: React.FC<Partial<DefaultMediaMatchContext>> = ({ children, defaultScreen }) => {
  const mobile = useMemo(() => {
    if (isMobileOnly) {
      return 'mobile';
    }

    return withWindow<'mobile' | false>(global => {
      const windowWidth = global.innerWidth;
      return windowWidth <= 1024 ? 'mobile' : false;
    }, false);
  }, []);

  const tablet = useMemo(() => {
    if (isTablet) {
      return 'tablet';
    }

    return withWindow<'tablet' | false>(global => {
      const windowWidth = global.innerWidth;

      return windowWidth > 1024 && windowWidth < 1280 ? 'tablet' : false;
    }, false);
  }, []);

  const defaultScreenRef = useRef<MediaScreen>(defaultScreen || mobile || tablet || 'desktop');

  const ctx = useMemo(() => ({ defaultScreen: defaultScreenRef.current }), [defaultScreenRef]);
  return <Provider value={ctx}>{children}</Provider>;
};

DefaultMediaMatchProvider.displayName = 'DefaultMediaMatchProvider';

export { useDefaultMediaMatchContext };
