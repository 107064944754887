import { useImmerReducer } from 'use-immer';
import type { UserFragment, ActiveEventUserFragment, GetMeQuery } from '@graphql/generated';
import { Action, ReducerActions } from './AuthProvider.actions';
import { isValidActiveEventUser } from './AuthProvider.utils';

// TODO: Update when webpack config is updated to handle const enums
const RequestStatus = {
  idle: 'idle',
  loading: 'loading',
  success: 'success',
  failure: 'failure'
} as const;

type RequestStatus = typeof RequestStatus[keyof typeof RequestStatus];

export type EventRegistriesSummaryFragment = NonNullable<NonNullable<GetMeQuery['me']>['eventMemberships']>;

export type EventMembership = NonNullable<GetMeQuery['me']>['eventMemberships'][number];

export type UserProfile = {
  activeEventUser: ActiveEventUserFragment | null;
  eventMemberships: Array<EventMembership>;
  profile: UserFragment | null;
  requestStatus: RequestStatus;
};

export type AuthProviderState = {
  hasInitializedOnce: boolean;
  user: UserProfile;
};

const initialState: AuthProviderState = {
  hasInitializedOnce: false,
  user: {
    requestStatus: RequestStatus.idle,
    profile: null,
    activeEventUser: null,
    eventMemberships: []
  }
};

export const useAuthProviderReducer = () => {
  return useImmerReducer<AuthProviderState, ReducerActions>((draft, action) => {
    switch (action.type) {
      case Action.HANDLE_MISSING_TOKEN: {
        draft.hasInitializedOnce = true;
        draft.user = {
          activeEventUser: null,
          eventMemberships: [],
          profile: null,
          requestStatus: RequestStatus.idle
        };
        break;
      }

      case Action.IDENTIFY_USER_INITIATE: {
        draft.user.requestStatus = RequestStatus.loading;
        break;
      }
      case Action.IDENTIFY_USER: {
        // With the current query `errorPolicy` set to 'none', data is discarded if there were any errors.
        const { data, error } = action.payload;
        draft.hasInitializedOnce = true;
        const userDraft = draft.user;
        userDraft.requestStatus = error ? RequestStatus.failure : RequestStatus.success;

        // Conditional changes
        if (data?.me) {
          const { activeEventUserForEvent, eventMemberships, ...profile } = data.me;
          const userDraft = draft.user;
          userDraft.profile = profile;
          userDraft.activeEventUser = (isValidActiveEventUser(profile, activeEventUserForEvent) && activeEventUserForEvent) || null;
          userDraft.eventMemberships = eventMemberships;
        } else {
          // If data does not exist, clear out user/event user data.
          userDraft.activeEventUser = null;
          userDraft.eventMemberships = [];
          userDraft.profile = null;
        }
        break;
      }
      default:
        break;
    }
  }, initialState);
};
