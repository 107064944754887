import { pxToRem } from './typography';

export const spaceScalePx: ReadonlyArray<number> = [
  0, //   -> 0  -> 0rem
  4, //   -> 1  -> 0.25rem
  6, //   -> 2  -> 0.375rem
  8, //   -> 3  -> 0.5rem
  12, //  -> 4  -> 0.75rem
  16, //  -> 5  -> 1rem
  24, //  -> 6  -> 1.5rem
  32, //  -> 7  -> 2rem
  40, //  -> 8  -> 2.5rem
  48, //  -> 9  -> 3rem
  64 //   -> 10 -> 4rem
];

export const spaceScale = spaceScalePx.map(pxToRem);
