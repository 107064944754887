import { v1 as uuid } from 'uuid';
import { ActionType } from '../analytics';

type ConsoleLevel = 'trace' | 'log' | 'info' | 'warn' | 'error';

export const getActionTypeLogger = (logger: Logger, actionType?: ActionType) => {
  switch (actionType) {
    case 'error':
      // bind the logger for instances
      // when logger is external using internal methods
      return logger.error.bind(logger);
    default:
      return logger.info.bind(logger);
  }
};

export class Logger {
  private readonly requestId: string;
  private readonly appName: string;

  constructor(appName?: string) {
    this.requestId = uuid();
    this.appName = appName || (process.env.APP as string);
  }

  public readonly trace = (msg: Record<string, unknown>) => this.toLoggentries('log', 10, msg);
  public readonly log = (msg: Record<string, unknown>) => this.toLoggentries('log', 20, msg);
  public readonly info = (msg: Record<string, unknown>) => this.toLoggentries('info', 30, msg);
  public readonly warn = (msg: Record<string, unknown>) => this.toLoggentries('warn', 40, msg);
  public readonly error = (msg: Record<string, unknown>) => this.toLoggentries('error', 50, msg);

  private readonly toLoggentries = (consoleLevel: ConsoleLevel, level: number, msg: Record<string, unknown>) => {
    const data = { level, requestId: this.requestId, app: this.appName, ...msg };
    // eslint-disable-next-line no-console
    console[consoleLevel]?.(data);
  };
}
