export interface NumberFormatOptions extends Intl.NumberFormatOptions {
  as?: 'number' | 'currency' | 'percent';
  precision?: number;
}

export type CurrencyFormat = 'auto' | 'short' | 'explicit' | 'none';
export interface CurrencyFormatOptions extends NumberFormatOptions {
  form?: CurrencyFormat;
}

export class MissingCurrencyCodeError extends Error {
  constructor(additionalMessage = '') {
    const baseErrorMessage = 'No currency code provided.';
    super(additionalMessage === '' ? baseErrorMessage : `${baseErrorMessage} ${additionalMessage}`);
  }
}
export class MissingCountryError extends Error {
  constructor(additionalMessage = '') {
    const baseErrorMessage = 'No country code provided.';
    super(additionalMessage === '' ? baseErrorMessage : `${baseErrorMessage} ${additionalMessage}`);
  }
}

export type CurrencyError = MissingCurrencyCodeError | MissingCountryError;

export interface CurrencyArgs {
  locale: string;
  defaultCurrency?: string;
}

export type CurrencyFormatterArgs = {
  priceFloatingPointDecimalString: string;
  priceCurrencyCode?: string;
  formatForm: CurrencyFormat;
  formatPrecision?: number;
  canShowFreeLabel?: boolean;
  shouldRoundPriceUp?: boolean;
  shouldCalculateCharmPrice?: boolean;
};
