import React, { useContext } from 'react';
import { History } from 'history';
import { UserProfile } from './AuthProvider.reducer';
import { LogoutEventSessionMutationVariables } from '@graphql/generated';
import { LoginManager } from '@shared/core';

export type AuthContextValue = {
  isLoggedIn: boolean;
  hasInitializedOnce: boolean;
  currentUser: UserProfile;
  willRefetch: boolean;
  loginManager: LoginManager;
  provideAuthentication: (options: ProvideAuthenticationOptions) => Promise<void>;
  provideLogout: (variables: LogoutEventSessionMutationVariables, redirectUrl?: string) => Promise<void>;
  isCurrentUserSettled: boolean;
  identifyCurrentUser: () => void;
  authorize: (returnTo: string, params: Record<string, string | boolean | number>) => void;
};

export type ProvideAuthenticationOptions = {
  loginVariables: LogoutEventSessionMutationVariables;
  history: History<History.PoorMansUnknown>;
};

export const AuthContext = React.createContext<AuthContextValue | undefined>(undefined);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider');
  }

  return context;
};
