import { Flex, Box } from '@withjoy/joykit';
import React from 'react';
import { StyledTitle, StyledSubtitle, StyledBox, StyledLink } from './ErrorSplash.styles';
import { ReactComponent as ErrorCake } from '@assets/icons/error-cake.svg';
import { useErrorTranslations } from '@shared/translations/Error.i18n';

interface CustomError {
  title: string;
  subtitle: string;
  linkText?: string;
}

export const ErrorSplash: React.FC<{ customError?: CustomError; handleAction?: () => void }> = props => {
  const { getErrorTranslations } = useErrorTranslations();
  const { title: defaultTtile, subtitle: defaultSubtitle } = getErrorTranslations();
  const { customError, handleAction } = props;

  const title = (customError && customError.title) ?? defaultTtile;
  const subtitle = (customError && customError.subtitle) ?? defaultSubtitle;

  return (
    <StyledBox width={customError ? '300px' : undefined}>
      <Flex flexDirection="column" alignItems="center">
        <Box marginBottom={40}>
          <ErrorCake />
        </Box>
        <StyledTitle typographyVariant={'hed4'} marginBottom={24}>
          {title}
        </StyledTitle>
        <StyledSubtitle typographyVariant={'body4'}>{subtitle}</StyledSubtitle>
        {customError && handleAction && (
          <StyledLink onClick={handleAction} marginTop={24} underline="none">
            {customError.linkText}
          </StyledLink>
        )}
      </Flex>
    </StyledBox>
  );
};
