import * as React from 'react';
import { setRef } from './setRef';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useCombinedRefs = <T extends any>(...refs: Array<React.Ref<T>>) => {
  const targetRef = React.useRef<T>(null);

  React.useEffect(() => {
    refs.forEach(ref => {
      setRef(ref, targetRef.current);
    });
  });

  return targetRef;
};

/**
 * Create a new ref collection function whenever the `ref` props change.
 */
export const useForkRef = <T>(...refs: Array<React.Ref<T>>): React.RefCallback<T> => {
  const refList = React.useRef(refs);

  React.useEffect(() => {
    refList.current = refs;
  });

  return React.useMemo(() => {
    return (refValue: T) => {
      refList.current.forEach(ref => {
        setRef(ref, refValue);
      });
    };
  }, [refList]);
};
