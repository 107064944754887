import type { MinimalAnatomy, PartsStyleObject } from '.';
import { createContext } from '@shared/utils/createContext';
import { capitalizeWord } from '@shared/utils/strings';

export function createMultiPartStylesContext<T extends MinimalAnatomy = MinimalAnatomy>(anat: T) {
  const componentName = capitalizeWord(anat.name);
  const [StylesProvider, useStyles] = createContext<PartsStyleObject<T>>({
    name: 'StylesContext',
    errorMessage: `use${componentName}Styles: 'styles' is undefined. Seems you forgot to wrap the components in '<${componentName}StylesProvider />'`
  });

  return [StylesProvider, useStyles] as const;
}
