import { addError } from '@shared/utils/logger';
import { withWindow } from '@shared/utils/withWindow';
import React, { useEffect, useRef } from 'react';
import { useFeatureValue } from '../featureFlags';

type App = 'core' | 'registry' | 'print';

export const shouldReloadOnAppBoundary = (previousPath?: string, currentPath?: string): boolean => {
  let app = '';
  let previousApp = '';
  if (previousPath === undefined || currentPath === undefined) {
    return false;
  }
  try {
    app = getAppForPath(previousPath);
    previousApp = getAppForPath(currentPath);
  } catch (e) {
    addError(e, {
      previousPath,
      currentPath
    });
    // just let the app continue to render in the same build
    return false;
  }
  if (app !== previousApp) {
    return true;
  }
  return false;
};

const useAppBoundaryObserver = () => {
  const ref = useRef<string>();
  const isAppBoundaryEnabled = useFeatureValue('joyWebAppBoundaryEnabled').value === 'on';

  useEffect(() => {
    if (!isAppBoundaryEnabled) {
      return;
    }
    withWindow(
      global => {
        const checkAppBoundary = () => {
          if (shouldReloadOnAppBoundary(ref.current, global.location.pathname)) {
            global.location.reload();
          } else {
            ref.current = global.location.pathname;
          }
        };
        ref.current = global.location.pathname;
        global.addEventListener('popstate', checkAppBoundary);
        return () => {
          global.removeEventListener('popstate', checkAppBoundary);
        };
      },
      () => {}
    );
  }, [isAppBoundaryEnabled]);
};

export const AppBoundaryObserver = ({ children }: { children: React.ReactNode }) => {
  useAppBoundaryObserver();
  return <>{children}</>;
};

const APP_BOUNDARIES: Array<[App, RegExp]> = [
  // ['core', /^\/[a-zA-Z0-9-]+\/edit/],
  // ['registry', /^\/[a-zA-Z0-9-]+\/edit\/registry/],
  // ['registry', /^\/[a-zA-Z0-9-]+\/registry/],
  // ['registry', ]
  // ['print', /^\/[a-zA-Z0-9-]+\/edit\/card/]
  // # ~~~~~~~~~~~CORE~~~~~~~~~~~~~~~~
  //   ~*^/[^/]+/edit/accommodations(/.*)?$ CORE;
  ['core', /^\/[^/]+\/edit\/accommodations/],
  //   ~*^/[^/]+/edit/checklist(/.*)?$ CORE;
  ['core', /^\/[^/]+\/edit\/checklist/],
  //   ~*^/[^/]+/edit/dashboard(/.*)?$ CORE;
  ['core', /^\/[^/]+\/edit\/dashboard/],
  //   ~*^/[^/]+/edit/design(/.*)?$ CORE;
  ['core', /^\/[^/]+\/edit\/design/],
  //   ~*^/[^/]+/edit/domain(/.*)?$ CORE;
  ['core', /^\/[^/]+\/edit\/domain/],
  //   ~*^/[^/]+/edit/faq(/.*)?$ CORE;
  ['core', /^\/[^/]+\/edit\/faq/],
  //   ~*^/[^/]+/edit/newservicecenter(/.*)?$ CORE;
  ['core', /^\/[^/]+\/edit\/newservicecenter/],
  //   ~*^/[^/]+/edit/photos(/.*)?$ CORE;
  ['core', /^\/[^/]+\/edit\/photos/],
  //   ~*^/[^/]+/edit/schedule(/.*)?$ CORE;
  ['core', /^\/[^/]+\/edit\/schedule/],
  //   ~*^/[^/]+/edit/settings(/.*)?$ CORE;
  ['core', /^\/[^/]+\/edit\/settings/],
  //   ~*^/[^/]+/edit/showcase(/.*)?$ CORE;
  ['core', /^\/[^/]+\/edit\/showcase/],
  //   ~*^/[^/]+/edit/story(/.*)?$ CORE;
  ['core', /^\/[^/]+\/edit\/story/],
  //   ~*^/[^/]+/edit/travel(/.*)?$ CORE;
  ['core', /^\/[^/]+\/edit\/travel/],
  //   ~*^/[^/]+/edit/video(/.*)?$ CORE;
  ['core', /^\/[^/]+\/edit\/video/],
  //   ~*^/[^/]+/edit/vip(/.*)?$ CORE;
  ['core', /^\/[^/]+\/edit\/vip/],
  //   ~*^/[^/]+/edit/website(/.*)?$ CORE;
  ['core', /^\/[^/]+\/edit\/website/],

  //   # ~~~~~~~~~~~PRINT~~~~~~~~~~~~~~~~
  //   ~*^/card(/.*)?$ PRINT;
  ['print', /^\/card(s)?/],
  //   ~*^/contact(/.*)?$ PRINT;
  ['print', /^\/contact/],
  //   ~*^/ecard(/.*)?$ PRINT;
  ['print', /^\/ecard/],
  //   ~*^/servicecenter/messages(/.*)?$ PRINT;
  ['print', /^\/servicecenter\/messages/],
  //   ~*^/servicecenter/print(/.*)?$ PRINT;
  ['print', /^\/servicecenter\/print/],
  //   ~*^/[^/]+/edit/card(/.*)?$ PRINT;
  ['print', /^\/[^/]+\/edit\/card(s)?/],
  //   ~*^/[^/]+/edit/contact(/.*)?$ PRINT;
  ['print', /^\/[^/]+\/edit\/contact/],
  //   ~*^/[^/]+/edit/ecard(/.*)?$ PRINT;
  ['print', /^\/[^/]+\/edit\/ecard/],
  //   ~*^/[^/]+/edit/email(/.*)?$ PRINT;
  ['print', /^\/[^/]+\/edit\/email/],
  //   ~*^/[^/]+/edit/savethedate(/.*)?$ PRINT;
  ['print', /^\/[^/]+\/edit\/savethedate/],
  //   ~*^/[^/]+/edit/stationery(/.*)?$ PRINT;
  ['print', /^\/[^/]+\/edit\/stationery/],

  //   # ~~~~~~~~~~~REGISTRY~~~~~~~~~~~~~~~~
  //   ~*^/return-policy(/.*)?$ REGISTRY;
  ['registry', /^\/return-policy/],
  //   ~*^/shipping-policy(/.*)?$ REGISTRY;
  ['registry', /^\/shipping-policy/],
  //   ~*^/shop(/.*)?$ REGISTRY;
  ['registry', /^\/shop/],
  //   ~*^/[^/]+/edit/registry(/.*)?$ REGISTRY;
  ['registry', /^\/[^/]+\/edit\/registry/],
  //   ~*^/[^/]+/registry(/.*)?$ REGISTRY;
  ['registry', /^\/[^/]+\/registry/]
];

const getAppForPath = (path: string): App => {
  const [app, _] = APP_BOUNDARIES.find(([app, regex]) => regex.test(path)) ?? [];
  if (app) {
    return app;
  }
  return 'core';
};
