import { useTranslation } from '@shared/core/i18n';

export const useErrorTranslations = () => {
  const { t2 } = useTranslation('errors');

  const getErrorTranslations = () => {
    return t2('errorSplash');
  };

  return {
    getErrorTranslations
  };
};
