import React from 'react';

import { Route } from '@react-router';
import { ClarityService } from '../microsoftClarity';

/**
 * Conditionally include external service scripts based on route
 */
export const RouteBasedServices = () => {
  return (
    <>
      {/* Admin registry + shop (admin + public) + admin room blocks */}
      <Route
        path={[
          '/:eventHandle/edit/registry',
          '/shop',
          '/:eventHandle/edit/planning/hotelblock',
          '/createwedding',
          '/createbabyregistry',
          '/:eventHandle/travel',
          '/:eventHandle/accommodations'
        ]}
        render={() => {
          return <ClarityService />;
        }}
      ></Route>
    </>
  );
};
