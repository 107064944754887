import { isEmpty } from 'lodash-es';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isUndefined = (value: any): value is undefined => {
  return typeof value === 'undefined' || value === undefined;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isArray = <T>(value: any): value is Array<T> => {
  return Array.isArray(value);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isString = (value: any): value is string => typeof value === 'string';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isObject = (value: unknown): value is Record<string, any> => {
  return value !== null && typeof value === 'object' && !isArray(value);
};

export const isEmptyObject = (value: unknown) => isObject(value) && isEmpty(value);

export const isEmptyArray = (value: unknown) => isArray(value) && isEmpty(value);

export const isFunction = (maybeFn: unknown): maybeFn is Function => typeof maybeFn === 'function';
