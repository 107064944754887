import { SyntheticEvent } from 'react';
import { isFunction } from './assertions';

export const callAllHandlers = <Evt extends SyntheticEvent>(...fns: Array<((event: Evt) => void) | undefined>) => {
  return (evt: Evt) => {
    fns.some(fn => {
      fn?.(evt);
      return evt?.defaultPrevented;
    });
  };
};

export const maybeRender = <T>(condition: unknown, content: T) => (Boolean(condition) ? content : null);

export const maybeUseValue = <T, V extends unknown>(condition: boolean, propsToPass: T, defaultValue?: V) => (condition ? propsToPass : defaultValue);

export const runIfFn = <T, U>(valueOrFn: T | ((...fnArgs: U[]) => T), ...args: U[]): T => {
  return isFunction(valueOrFn) ? valueOrFn?.(...args) : valueOrFn;
};
