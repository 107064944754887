import { IconConfig } from '..';

export const more: IconConfig = {
  name: 'more',
  a11yLabel: 'More',
  fill: '#333333',
  viewBox: 24,
  body: `
    <circle cx="6" cy="12" r="2" />
    <circle cx="12" cy="12" r="2" />
    <circle cx="18" cy="12" r="2" />  
  `
};
