import React, { useCallback, useMemo } from 'react';
import { toast as hotToast } from 'react-hot-toast';
import { UseToastOptions, UseToastReturn } from './Toast.types';
import { StyledMessage } from './Toast.styles';

export const useToast = (): UseToastReturn => {
  const toast = useCallback((message: string, options?: UseToastOptions) => {
    return hotToast(<StyledMessage data-testid="toast-message">{message}</StyledMessage>, options);
  }, []);

  const dismiss = useCallback((id: string) => {
    hotToast.dismiss(id);
  }, []);

  const removeAll = useCallback(() => {
    hotToast.dismiss();
  }, []);

  return useMemo(() => ({ toast, dismiss, removeAll }), [toast, dismiss, removeAll]);
};
