import globalWindow from '@shared/core/globals';

export const newrelic = globalWindow.newrelic;
const consoleLoggingEnabled = typeof window !== 'undefined' && new URLSearchParams(window.location.search.toLowerCase()).has('consolelogging');

const isError = (err: unknown): err is Error => err instanceof Error;

function logToConsoleIfEnabled(method: 'log' | 'error', ...restParams: unknown[]) {
  if (method === 'error' || consoleLoggingEnabled) {
    // eslint-disable-next-line no-console
    console[method]('[Logger]', ...restParams);
  }
}

export function addAction(actionName: string, value?: Record<string, unknown>): void {
  const valueWithPrefixes: Record<string, unknown> = {};

  for (const key in value) {
    valueWithPrefixes[`${actionName}_${key}`] = value[key];
  }

  // log to console without property prefixes to be easier to consume
  logToConsoleIfEnabled('log', actionName, value);
  newrelic?.addPageAction(actionName, valueWithPrefixes);
}

export function addError(error: unknown, context?: object) {
  let errorValue;

  if (isError(error)) {
    errorValue = error;
  } else if (typeof error === 'string') {
    errorValue = new Error(error);
  } else {
    errorValue = new Error('unknown');
  }

  logToConsoleIfEnabled('error', errorValue, context);
  newrelic?.noticeError(errorValue, context);
}
