export { ApolloProvider, ApolloConsumer, getApolloContext, resetApolloContext } from '@apollo/client';
import {
  LazyQueryHookOptions as LazyQueryHookOptionsApollo,
  QueryHookOptions as QueryHookOptionsApollo,
  useQuery as useQueryApollo,
  OperationVariables,
  useLazyQuery as useLazyQueryApollo,
  useMutation,
  MutationHookOptions
} from '@apollo/client';
export { useMutation };
export type { MutationHookOptions };
import { DocumentNode } from 'graphql';

export type BatchModeValue = 'fast' | 'off' | 'slow';

type BatchModeSettingHookOption = {
  /**
   * Fast batching is the best mode and is prefered.
   * Slow batching is for scenarios like long-polling,
   * where we are ok with some delay in the response,
   * but we still want to amortize multiple polls.
   * Off is for scenarios where we know batching
   * makes things worse because of the way the
   * client code is written. It should be used very
   * sparingly.
   * Separately batchMode also supports the value of
   * undefined where we haven't yet evaluated the code
   * to know if batching will help.
   */
  readonly batchMode: BatchModeValue | undefined;
};

export interface QueryHookOptions<TData, TVariables> extends QueryHookOptionsApollo<TData, TVariables>, BatchModeSettingHookOption {}

export interface LazyQueryHookOptions<TData, TVariables> extends LazyQueryHookOptionsApollo<TData, TVariables>, BatchModeSettingHookOption {}

const monkeyPatchOptions: <TData = unknown, TVariables = OperationVariables>(options?: BatchModeSettingHookOption & { variables?: unknown }) => void = options => {
  if (options) {
    if (options.batchMode) {
      if (!options.variables) {
        options.variables = {
          batchMode: options.batchMode
        };
      } else {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (options.variables as any).batchMode = options.batchMode;
      }
    }
  }
};

export function useQuery<TData = unknown, TVariables = OperationVariables>(query: DocumentNode, options?: QueryHookOptions<TData, TVariables>) {
  monkeyPatchOptions(options);
  return useQueryApollo(query, options);
}

export function useLazyQuery<TData = unknown, TVariables = OperationVariables>(query: DocumentNode, options?: LazyQueryHookOptions<TData, TVariables>) {
  monkeyPatchOptions(options);
  return useLazyQueryApollo(query, options);
}
