import React from 'react';
import { getNodeEnv } from '@shared/utils/getEnvironmentVariables';
import { EventType } from '@graphql/generated';

export type PagePrefix = 'admin' | 'guest' | 'vendor' | 'rsvp' | 'marketing' | 'test' | 'build' | 'manage' | 'system' | 'admin.registry' | 'iframe' | 'account' | 'newmarketing';
export type ActionType = 'click' | 'select' | 'error';
type PageAction = 'enter' | 'exit';

export interface PageProps
  extends Readonly<{
    action: PageAction;
    pagePrefix: PagePrefix;
    category: string;
    page: string;
    url?: string;
    timeSpentInSeconds?: number;
    time?: Readonly<{
      startTime: number;
      endTime: number;
    }>;
    extraPageInfo?: ReadonlyRecord<string, unknown>;
    referrerOverride?: string;
    context?: unknown;
  }> {}

export interface SegmentPageProps
  extends PageProps,
    Readonly<{
      analyticsId: string;
    }> {}

export interface TrackItemProps {
  id?: string | number;
  price?: string | number;
  quantity?: string | number;
}

export interface TrackProps
  extends Readonly<{
    category: string;
    pagePrefix?: string;
    page?: string;
    action: string;
    actionType?: ActionType;
    label?: string;
    extraInfo?: ReadonlyRecord<string, unknown>;
    referrerOverride?: string;
    context?: unknown;
    callback?: () => void;
  }> {}

export interface SegmentGroupTraitsProps {
  postgresEventId: Maybe<string>;
  eventType: Maybe<EventType>;
}
export interface SegmentGroupProps {
  /**
   * A unique identifier for the group.
   *
   * eg. the group could be the event id
   */
  groupId: string | null;
  traits?: SegmentGroupTraitsProps;
}

export interface SegmentTrackProps
  extends TrackProps,
    Readonly<{
      analyticsId: string;
    }> {}

export interface IdentifyProp
  extends Readonly<{
    [prop: string]: unknown;
  }> {}

export interface SegmentIdentifyProp
  extends IdentifyProp,
    Readonly<{
      analyticsId: string;
    }> {}

interface AnalyticsContext
  extends Readonly<{
    page: (value: PageProps) => void;
    track: (value: TrackProps) => void;
    group: (value: SegmentGroupProps) => void;
    identify: (value: IdentifyProp) => void;
    identifiedUser?: IdentifyProp;
    anonymousId: string;
  }> {}

const defaultAction = (action: string) => {
  if (!getNodeEnv().isTest) {
    throw Error(`No Provider for ${action}`);
  }
};

const page = (value: unknown) => defaultAction('page');

const track = (value: unknown) => defaultAction('track');

const identify = (value: unknown) => defaultAction('identify');

const group = (value: unknown) => defaultAction('group');

export const AnalyticsContext = React.createContext<AnalyticsContext>({
  page,
  track,
  identify,
  group,
  anonymousId: ''
});
