import { IconConfig } from '..';

/* tslint:disable:max-line-length */
export const close: IconConfig = {
  name: 'close',
  a11yLabel: 'Close',
  fill: '#333333',
  viewBox: 24,
  body: `
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.7913 4.2087C19.513 3.93043 19.0725 3.93043 18.8174 4.2087L12 11.0261L5.18261 4.2087C4.90435 3.93043 4.46377 3.93043 4.2087 4.2087C3.93043 4.48696 3.93043 4.92754 4.2087 5.18261L11.0261 12L4.2087 18.8174C3.93043 19.0957 3.93043 19.5362 4.2087 19.7913C4.34783 19.9304 4.53333 20 4.69565 20C4.85797 20 5.04348 19.9304 5.18261 19.7913L12 12.9739L18.8174 19.7913C18.9565 19.9304 19.142 20 19.3043 20C19.4667 20 19.6522 19.9304 19.7913 19.7913C20.0696 19.513 20.0696 19.0725 19.7913 18.8174L12.9739 12L19.7913 5.18261C20.0696 4.90435 20.0696 4.48696 19.7913 4.2087Z"
    />  
  `
};
