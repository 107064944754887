import React from 'react';
import { styled, ThemeStyledProps, css } from '@shared/joykit/packages/core/common/styledComponents';
import { AbstractButton, AbstractButtonProps } from '../../common/AbstractButton';
import { getStyledBaseComponentProps } from '../../common/styledSystem/styledFunctions';
import { getButtonnVariantStyle } from '../../common/styles';
import { animationTransition } from '../../utils';
import { Box } from '../Box';

type InternalLinkProps = {
  component?: 'a' | 'button' | React.ComponentType<any>;
  color?: string;

  /**
   * Controls when the link should have an underline, if the component is an anchor.
   */
  underline?: 'none' | 'hover' | 'always';
  [x: string]: any;
};
export interface LinkV1Props extends AbstractButtonProps, InternalLinkProps, React.HTMLAttributes<HTMLLinkElement> {}

const buttonStyle = () => css`
  background: transparent;
  border: 0;
  box-shadow: none;
  padding: 0;
  cursor: pointer;
`;

const linkStyle = () => css<LinkV1Props>`
  ${props => ({
    textDecoration: props.underline === 'hover' || props.underline === 'none' ? 'none' : 'underline',
    cursor: 'pointer',
    ':hover:not(:disabled)': {
      textDecoration: props.underline === 'none' ? 'none' : undefined,
      color: '#645BB7'
    }
  })}
`;

const getComponentStyle = (props: ThemeStyledProps<LinkV1Props>) => {
  const styleConfig = getButtonnVariantStyle(props.theme, props.variant);
  let componentStyle;
  switch (props.component) {
    case 'button':
      componentStyle = buttonStyle();
      break;
    case 'a':
    default:
      componentStyle = linkStyle();
      break;
  }

  return css`
    transition: ${animationTransition('color')};
    color: ${styleConfig.fill};
    :hover:enabled {
      color: ${styleConfig.hover};
    }
    :active:enabled {
      color: ${styleConfig.active};
    }
    ${componentStyle}
  `;
};

const StyledLink = styled(Box).attrs<LinkV1Props>(props => ({
  typeVariant: 'body1',
  as: props.as || props.component
}))`
  ${getComponentStyle}
  ${getStyledBaseComponentProps}
`;

export class LinkV1 extends AbstractButton<LinkV1Props> {
  static defaultProps = {
    component: 'a',
    underline: 'always'
  };

  render(): React.ReactNode {
    const { component, className, variant, underline, ...rest } = this.props as LinkV1Props;
    return (
      <StyledLink
        className={className}
        component={component}
        type={component === 'button' ? 'button' : undefined}
        variant={variant}
        underline={underline}
        {...rest}
        {...this.getCommonButtonProps()}
      >
        {this.renderChildren()}
      </StyledLink>
    );
  }
}
