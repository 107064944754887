import deepmergeLib from 'deepmerge';

type ArrayMergePreset = 'source' | 'target';
type ArrayMergeHandler = NonMaybe<deepmergeLib.Options['arrayMerge']>;
export type DeepMergeOptions = Readonly<{
  /**
   * @default 'source'
   */
  arrayMerge?: ArrayMergePreset | ArrayMergeHandler;
}>;

const arrayMergePresetFns: ReadonlyRecord<ArrayMergePreset, ArrayMergeHandler> = {
  source: (target, source) => source,
  target: (target, source) => target
};

const _defaultArrayMerge = (preset: ArrayMergePreset, target: unknown[], source: unknown[], opts?: deepmergeLib.Options) => {
  const handler = arrayMergePresetFns[preset] || arrayMergePresetFns.source;
  return handler(target, source, opts);
};

const _shouldUsePreset = (arrayMerge: NonMaybe<DeepMergeOptions['arrayMerge']>): arrayMerge is ArrayMergePreset => {
  return arrayMerge === 'source' || arrayMerge === 'target';
};

export const deepmerge = <T1, T2>(x: T1, y: T2, opts: DeepMergeOptions = {}) => {
  const { arrayMerge = 'source' } = opts;
  return deepmergeLib<T1, T2>(x, y, {
    arrayMerge: (...args) => (_shouldUsePreset(arrayMerge) ? _defaultArrayMerge(arrayMerge, ...args) : arrayMerge(...args))
  });
};
