import { currencySymbols, DEFAULT_CURRENCY_SYMBOL } from './constants';

const numberFormatCacheKey = (locales?: string | string[], options: Intl.NumberFormatOptions = {}) => {
  const localeKey = Array.isArray(locales) ? locales.sort().join('-') : locales;

  return `${localeKey}-${JSON.stringify(options)}`;
};

const numberFormats = new Map<string, Intl.NumberFormat>();

export const memoizedNumberFormatter = (locales?: string | string[], options?: Intl.NumberFormatOptions) => {
  const key = numberFormatCacheKey(locales, options);
  if (numberFormats.has(key)) {
    return numberFormats.get(key)!;
  }
  const i = new Intl.NumberFormat(locales, options);
  numberFormats.set(key, i);
  return i;
};

export function getCurrencySymbol(locale: string, options: Intl.NumberFormatOptions) {
  const delimiters = ',.';
  const directionControlCharacters = /[\u200E\u200F]/;
  const numReg = new RegExp(`0[${delimiters}]*0*`);

  const currencyStringRaw = formatCurrency(0, locale, options);
  const currencyString = currencyStringRaw.replace(directionControlCharacters, '');
  const matchResult = numReg.exec(currencyString);
  if (!matchResult) {
    throw new Error(`Number input in locale ${locale} is currently not supported.`);
  }
  const formattedAmount = matchResult[0];
  const [currencyPrefix, currencySuffix] = currencyString.split(formattedAmount);
  const elements = {
    symbol: currencyPrefix || currencySuffix,
    prefixed: currencyPrefix !== ''
  };

  return elements;
}

function formatCurrency(amount: number, locale: string, options: Intl.NumberFormatOptions) {
  return memoizedNumberFormatter(locale, {
    style: 'currency',
    ...options
  }).format(amount);
}

export function getCurrencySymbolFromCurrencyCode(currencyCode?: string): string {
  const code = currencyCode?.toUpperCase() as keyof typeof currencySymbols;

  if (!code || !Object.prototype.hasOwnProperty.call(currencySymbols, code)) {
    return DEFAULT_CURRENCY_SYMBOL;
  }

  return currencySymbols[code];
}

export function getCharmPriceForQuantity(floatingPointDecimalString: string, quantity: number, numberPrice: number) {
  return floatingPointDecimalString?.endsWith('.00') ? (numberPrice - 0.01) * quantity : numberPrice * quantity;
}
