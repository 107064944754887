import { isInIframe } from '@shared/utils/isInIframe';
import { getConfig } from '@config';
import { getLocalStorage } from '@shared/core/storage';
import { CREATE_WEDDING_APP_NAME } from '@apps/createWedding/constants';
import globalWindow from '@shared/core/globals';
import { v4 as uuidv4 } from 'uuid';

export type SegmentOptions = Record<string, boolean>;

const config = getConfig();
const SAFE_IFRAME_NAMES = ['joy-addgift-iframe', 'joy-popupblocked-iframe'];
const SAFE_CONTEXT_NAMES = ['admindashboard'];
const SEGMENT_ANONYMOUS_ID_KEY = 'ajs_anonymous_id';
const JOY_ANONYMOUS_ID_KEY = 'joy_anonymous_id';

/**
 * This function manages joy-web's own anonymous id, so that it's stable and always available on app load.
 *
 * The anonymous id is synchronized with the segment SDK and is backwards compatible with existing segment anonymous ids.
 */
function getOrGenerateAnonymousId(): string {
  const localStorage = getLocalStorage();
  let anonymousId = localStorage.getItem(JOY_ANONYMOUS_ID_KEY);

  if (!anonymousId) {
    const segmentAnonymousId = (localStorage.getItem(SEGMENT_ANONYMOUS_ID_KEY) || '').replaceAll('"', '');

    if (segmentAnonymousId && segmentAnonymousId.length === 36) {
      // Use segment anonymous id if it roughly looks like a valid uuid (36 chars)
      anonymousId = segmentAnonymousId;
    } else {
      anonymousId = uuidv4();
    }

    localStorage.setItem(JOY_ANONYMOUS_ID_KEY, anonymousId);
  }

  return anonymousId;
}

const _ensureAnalyticsIsDefined = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const analytics = (globalWindow.analytics = (globalWindow as any).analytics || []);
  // If the real analytics.js is already on the page return.
  if (analytics.initialize) {
    return;
  }
  // If the snippet was invoked already show an error.
  if (analytics.invoked) {
    return;
  }

  // Invoked flag, to make sure the snippet
  // is never invoked twice.
  analytics.invoked = true;
  // A list of the methods in Analytics.js to stub.
  analytics.methods = [
    'trackSubmit',
    'trackClick',
    'trackLink',
    'trackForm',
    'pageview',
    'identify',
    'reset',
    'group',
    'track',
    'ready',
    'alias',
    'debug',
    'page',
    'once',
    'off',
    'on',
    'addSourceMiddleware',
    'addIntegrationMiddleware',
    'setAnonymousId',
    'addDestinationMiddleware'
  ];
  // Define a factory to create stubs. These are placeholders
  // for methods in Analytics.js so that you never have to wait
  // for it to load to actually record data. The `method` is
  // stored as the first argument, so we can replay the data.
  analytics.factory = function (method: unknown) {
    return function () {
      // eslint-disable-next-line prefer-rest-params
      const args = Array.prototype.slice.call(arguments);
      args.unshift(method);
      analytics.push(args);
      return analytics;
    };
  };
  // For each of our methods, generate a queueing stub.
  for (let t = 0; t < analytics.methods.length; t++) {
    const e = analytics.methods[t];
    analytics[e] = analytics.factory(e);
  }
  analytics.load = (key: string, options: unknown) => {
    /**
     * Allow segment if
     * 1- We are not inside an iframe
     * 2- We are inside a specific iframe.
     */
    const checkAllowSegment = () => {
      const locationURL = new URL(globalWindow.location?.href || '');
      const getContext = locationURL.searchParams.get('ctx');

      if (!isInIframe()) {
        return true;
      } else if (globalWindow.name && SAFE_IFRAME_NAMES.includes(globalWindow.name)) {
        return true;
      } else if (getContext && SAFE_CONTEXT_NAMES.includes(getContext)) {
        return true;
      } else {
        return false;
      }
    };

    if (!checkAllowSegment()) {
      // The preview should not load Segment at all.
      // Resolving early only means that we won't send a request for the Segment library
      // as well as any of the integrations - this will drastically reduce load times.
      //
      // We've already defined the analytics object on the window, so resolving now will not
      // cause any services to fail when invoking an analytics method.
      return;
    }
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = `${config.segmentCdnUrl}/analytics.js/v1/` + key + '/analytics.min.js';
    const firstScript = document.getElementsByTagName('script')[0];
    firstScript.parentNode!.insertBefore(script, firstScript);
    // Remove so we don't auto track a pagscript.
    // analytics.page();

    analytics._loadOptions = options;
  };

  analytics._writeKey = config.segmentKey;
  analytics.SNIPPET_VERSION = '4.13.2';

  // Control the analytics anonymous id to ensure we always have access to it on app load
  analytics.setAnonymousId(getOrGenerateAnonymousId());
};

const _withAnalytics = (cb: (analytics: SegmentAnalytics.AnalyticsJS) => void) => {
  if (!globalWindow.analytics) {
    _ensureAnalyticsIsDefined();
  }
  cb(globalWindow.analytics!);
};

export const initSegment = () => {
  const localStorage = getLocalStorage();
  if (!localStorage.getItem('segmentPlatform')) {
    localStorage.setItem('segmentPlatform', 'web');
  }

  _withAnalytics(analytics => {
    const pathParts = window.location.pathname.split('/') || [];
    const isAdminRoute = /^\/.*\/edit(?:[\/?].*)?$/.test(window.location.pathname);
    const isCreateWedding = window.location.pathname.startsWith(`/${CREATE_WEDDING_APP_NAME}`);

    const isCardRoute = pathParts[3] === 'card';
    const loadOptions = {
      integrations: {
        Chameleon: isAdminRoute && isCardRoute,
        Intercom: isAdminRoute || isCreateWedding,
        'Segment.io': { apiHost: `${config.segmentApiHost}/v1` }
      }
    };

    analytics.load(config.segmentKey, loadOptions);
  });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const track = (event: string, callback?: () => void, properties?: Object, options?: SegmentAnalytics.SegmentOpts) => {
  _withAnalytics(analytics => {
    analytics.track?.(event, properties, options);
    if (callback) setTimeout(callback, 250);
  });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const identify: SegmentAnalytics.AnalyticsJS['identify'] = (...args: any[]) => {
  _withAnalytics(analytics => {
    analytics.identify?.(...args);
  });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const page: SegmentAnalytics.AnalyticsJS['page'] = (...args: any[]) => {
  _withAnalytics(analytics => {
    analytics.page?.(...args);
  });
};

const group = (groupId: string, traits?: Object, options?: SegmentAnalytics.SegmentOpts, callback?: () => void) => {
  _withAnalytics(analytics => {
    analytics.group?.(groupId, traits, options, callback);
  });
};

export const segment = {
  sendTrackTelemetry: track,
  sendIdentifyTelemetry: identify,
  sendPageTelemetry: page,
  sendGroupTelemetry: group,
  getAnonymousId: getOrGenerateAnonymousId
};
