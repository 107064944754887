import React from 'react';
import { lazy } from '@loadable/component';
import { useFeatureValue } from '@shared/core/featureFlags';

const PseudoLocalization = lazy(
  () =>
    import(
      /* webpackChunkName: "feature/pseudolocalization" */
      `@shared/core/i18n/pseudoLocalization/PseudoLocalization`
    )
);

export const QueryStringFeatures = () => {
  const pseudoLocalization = useFeatureValue('pseudoLocalization');
  return (
    <>
      {
        // When pseudoloc feature value is `all`, the observer will pseudo localize
        // every TEXT node in the document
        (pseudoLocalization || pseudoLocalization === 'all') && <PseudoLocalization />
      }
    </>
  );
};
