import React, { useRef } from 'react';
import { isValidElementType } from 'react-is';

import { useCombinedRefs } from '@shared/utils/hooks/useCombinedRefs';
import { getOverrides } from '@shared/utils/overrides';
import { createDisplayName } from '@withjoy/joykit/utils';
import { StyledStartIcon, StyledEndIcon, StyledContainer, StyledSpinner, buttonSizeToIconSize, buttonStyles } from './Button.styles';
import { ButtonV2Props, ButtonSize, Intent, ButtonVariant, ButtonShape, SharedButtonProps, ButtonDefaultProps } from './Button.types';
import { IconV2Props } from '@withjoy/joykit/components/IconV2';
import { forwardStyledComponentRef } from '@shared/utils/forwardRef';
import { useStyleConfig } from '../../common/utils/styleConfig';

const isIconElement = (input: unknown): input is React.ElementType<IconV2Props> => isValidElementType(input);

const ButtonIcon: React.FC<{ icon: React.ReactNode } & SharedButtonProps> = ({ icon: Icon, $isLoading, $isDisabled, $size, ...props }) => {
  return (isIconElement(Icon) ? <Icon focusable={false} size={buttonSizeToIconSize[$size]} {...props} /> : Icon) as React.ReactElement;
};

export const defaultProps: ButtonDefaultProps = {
  intent: 'primary' as Intent,
  variant: 'solid' as ButtonVariant,
  size: 'lg' as ButtonSize,
  shape: 'square' as ButtonShape
};

const ButtonV2 = forwardStyledComponentRef<'button', ButtonV2Props>((props, ref) => {
  const { children, disabled, endIcon, intent, loading, overrides, shape, size, startIcon, tabIndex, variant, ...restProps } = props as ButtonV2Props & ButtonDefaultProps;

  const sharedProps: SharedButtonProps = {
    $isDisabled: !!disabled,
    $isLoading: !!loading,
    $size: size,
    $shape: shape,
    $intent: intent,
    $variant: variant
  };

  const [Container, containerProps] = getOverrides(overrides?.Root, StyledContainer);

  const styles = useStyleConfig(buttonStyles, props as ButtonV2Props & ButtonDefaultProps);

  const buttonRef = useRef<HTMLButtonElement>(null);
  const combinedRef = useCombinedRefs(ref, buttonRef);

  /////////////////////////////////
  // Render

  const isRenderingAButton = !restProps.as || restProps.as === 'button';
  const isInDisabledState = disabled || loading;

  const buttonProps: React.ButtonHTMLAttributes<HTMLButtonElement> = {
    'aria-disabled': isRenderingAButton ? undefined : isInDisabledState,
    disabled: isRenderingAButton ? isInDisabledState : undefined,
    role: isRenderingAButton ? undefined : 'button',
    tabIndex: isInDisabledState ? -1 : tabIndex,
    type: 'button'
  };

  return (
    <Container as="button" ref={combinedRef} __css={styles.root} {...buttonProps} {...restProps} {...containerProps}>
      {loading ? (
        <StyledSpinner as="span" aria-label="Loading" __css={styles.spinner} />
      ) : (
        <>
          {startIcon && (
            <StyledStartIcon as="span" $noMarginX={!children && !endIcon}>
              <ButtonIcon icon={startIcon} {...sharedProps} />
            </StyledStartIcon>
          )}
          {children}
          {endIcon && (
            <StyledEndIcon as="span" $noMarginX={!children && !startIcon}>
              <ButtonIcon icon={endIcon} {...sharedProps} />
            </StyledEndIcon>
          )}
        </>
      )}
    </Container>
  );
});

ButtonV2.displayName = createDisplayName('ButtonV2');
ButtonV2.defaultProps = defaultProps;

export { ButtonV2 };
