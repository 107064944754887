const globalWindow: Partial<Window> = typeof window !== 'undefined' ? window : ({} as Window);

const buildUrl = (protocol: string, host: string, port: string): string => {
  return `${protocol}://${host}:${port}`;
};

type JoyEnvironment = 'local' | 'development' | 'stage' | 'production';

export interface ConfigProps {
  joyEnvironment?: JoyEnvironment;
  serverUri?: string;
  graphQLUrl?: string;
  clientUri?: string;
  parentClientUri?: string;
  auth0ClientId?: string;
  logentries?: string;
  vendorStoreUri?: string;
  port?: string;
  appName?: string;
  registryLogoServiceUri?: string;
  redirectServiceUri?: string;
  environment?: string;
  previewTargetOrigin?: string;
  s3PublicAssetsUrl?: string;
  s3PublicAssetsThemeBucket?: string;
  intercomAppId?: string;
  smartyApiKey?: string;
  charmAllowedTrafficPercentage?: string;
  legacyCeremonyUri?: string;
  brightDataCatalogSerpApiUsername?: string;
  brightDataCatalogSerpApiPassword?: string;
  zyteApiKey?: string;
}

export interface ConfigReturn {
  joyEnvironment: JoyEnvironment;
  serverUri: string;
  graphQLUrl: string;
  clientUri: string;
  parentClientUri: string;
  auth0ClientId: string;
  logentries: string;
  appName: string;
  vendorStoreUri: string;
  port: string;
  registryLogoServiceUri: string;
  redirectServiceUri: string;
  environment: string;
  previewTargetOrigin: string;
  s3PublicAssetsUrl: string;
  s3PublicAssetsThemeBucket: string;
  intercomAppId?: string;
  smartyApiKey?: string;
  charmAllowedTrafficPercentage: string;
  legacyCeremonyUri: string;
  brightDataCatalogSerpApiUsername: string;
  brightDataCatalogSerpApiPassword: string;
  zyteApiKey: string;
}

const extractWindowDefaults = (jEnv: Record<string, string>): ConfigProps => {
  let graphQLUri: string | undefined;
  if (jEnv.JOY_CONFIG_CLOUDFRONT_HOSTNAME) {
    // custom Cloudfront'ed URI
    //   @see other uses within this repo
    graphQLUri = buildUrl(jEnv.JOY_CONFIG_CLOUDFRONT_PROTOCOL, jEnv.JOY_CONFIG_CLOUDFRONT_HOSTNAME, jEnv.JOY_CONFIG_CLOUDFRONT_PORT);
  } else if (jEnv.JOY_CONFIG_WEBSITE_HOSTNAME) {
    // standard Cloudfront'ed URI
    graphQLUri = buildUrl(jEnv.JOY_CONFIG_WEBSITE_PROTOCOL, jEnv.JOY_CONFIG_WEBSITE_HOSTNAME, jEnv.JOY_CONFIG_WEBSITE_PORT);
  }

  const isNewUrlChangesEnabled = (): boolean => {
    const newUrlEnabled = jEnv?.JOY_NEW_URL_CHANGES_ENABLED;
    return newUrlEnabled === 'true';
  };

  const baseRegistryUri =
    jEnv.JOY_CONFIG_REGISTRIES_SERVICE_HOSTNAME &&
    buildUrl(jEnv.JOY_CONFIG_REGISTRIES_SERVICE_PROTOCOL, jEnv.JOY_CONFIG_REGISTRIES_SERVICE_HOSTNAME, jEnv.JOY_CONFIG_REGISTRIES_SERVICE_PORT);
  const baseLegacyUri = jEnv.JOY_CONFIG_SERVICE_HOSTNAME && buildUrl(jEnv.JOY_CONFIG_SERVICE_PROTOCOL, jEnv.JOY_CONFIG_SERVICE_HOSTNAME, jEnv.JOY_CONFIG_SERVICE_PORT);
  const baseRedirectServiceUri = jEnv.JOY_CONFIG_REDIRECT_HOSTNAME && buildUrl(jEnv.JOY_CONFIG_REDIRECT_PROTOCOL, jEnv.JOY_CONFIG_REDIRECT_HOSTNAME, jEnv.JOY_CONFIG_REDIRECT_PORT);
  const baseServiceUri =
    jEnv.JOY_CONFIG_BLISS_GATEWAY_HOSTNAME && buildUrl(jEnv.JOY_CONFIG_BLISS_GATEWAY_PROTOCOL, jEnv.JOY_CONFIG_BLISS_GATEWAY_HOSTNAME, jEnv.JOY_CONFIG_BLISS_GATEWAY_PORT);

  // TODO: PLAT-925 Remove after a few weeks of successful production deployment.
  const registryLogoServiceUri = isNewUrlChangesEnabled() ? `${baseRegistryUri}/services/registries` : baseRegistryUri;
  const legacyCeremonyUri = isNewUrlChangesEnabled() ? `${baseLegacyUri}/services/api` : baseLegacyUri;
  const redirectServiceUri = isNewUrlChangesEnabled() ? `${baseRedirectServiceUri}/services/redirect` : baseRedirectServiceUri;
  const serverUri = isNewUrlChangesEnabled() ? `${baseServiceUri}/services` : baseServiceUri;
  return {
    joyEnvironment: jEnv.NODE_ENV as JoyEnvironment,
    serverUri: serverUri,
    graphQLUrl: graphQLUri && `${graphQLUri}/graphql`,
    clientUri: jEnv.JOY_CONFIG_WEBSITE_HOSTNAME && buildUrl(jEnv.JOY_CONFIG_WEBSITE_PROTOCOL, jEnv.JOY_CONFIG_WEBSITE_HOSTNAME, jEnv.JOY_CONFIG_WEBSITE_PORT),
    parentClientUri: jEnv.JOY_CONFIG_WEBSITE_HOSTNAME && buildUrl(jEnv.JOY_CONFIG_WEBSITE_PROTOCOL, jEnv.JOY_CONFIG_WEBSITE_HOSTNAME, jEnv.JOY_CONFIG_WEBSITE_PORT),
    auth0ClientId: jEnv.JOY_CONFIG_AUTH0_CLIENTID,
    logentries: jEnv.JOY_CONFIG_LE_TOKEN_BROWSER,
    redirectServiceUri: redirectServiceUri,
    registryLogoServiceUri: registryLogoServiceUri,
    intercomAppId: jEnv.JOY_CONFIG_INTERCOM_APP_ID,
    smartyApiKey: jEnv.SMARTY_API_KEY,
    legacyCeremonyUri: legacyCeremonyUri
  };
};

export const config: ConfigReturn = {} as ConfigReturn;

export const load = (userOverrides: ConfigProps = {}) => {
  const envDefaults: ConfigProps = {
    joyEnvironment: process.env.JOY_ENVIRONMENT as JoyEnvironment,
    serverUri: process.env.SERVER_URI as string,
    graphQLUrl: process.env.GRAPHQL_URL as string,
    clientUri: process.env.CLIENT_URI as string,
    parentClientUri: process.env.PARENT_CLIENT_URI as string,
    auth0ClientId: process.env.AUTH0_CLIENT_ID as string,
    logentries: process.env.LOGENTRIES_KEY as string,
    appName: process.env.APP as string,
    vendorStoreUri: process.env.VENDOR_STORE_URI as string,
    port: process.env.PORT as string,
    registryLogoServiceUri: process.env.LOGO_SERVICE_URI as string,
    redirectServiceUri: process.env.REDIRECT_SERVICE_URI as string,
    environment: process.env.ENVIRONMENT as string,
    previewTargetOrigin: process.env.PREVIEW_TARGET_ORIGIN as string,
    s3PublicAssetsUrl: process.env.S3_PUBLIC_ASSETS_URL as string,
    s3PublicAssetsThemeBucket: process.env.S3_PUBLIC_ASSETS_THEMES_BUCKET as string,
    intercomAppId: process.env.INTERCOM_APP_ID as string,
    smartyApiKey: process.env.SMARTY_API_KEY as string,
    charmAllowedTrafficPercentage: process.env.CHARM_TRAFFIC_PERCENTAGE as string,
    legacyCeremonyUri: process.env.LEGACY_CEREMONY_API as string,
    brightDataCatalogSerpApiUsername: process.env.BRIGHTDATA_CATALOG_SERP_API_USERNAME as string,
    brightDataCatalogSerpApiPassword: process.env.BRIGHTDATA_CATALOG_SERP_API_PASSWORD as string,
    zyteApiKey: process.env.ZYTE_API_KEY as string
  };

  const windowDefaults = globalWindow.joyEnv ? (extractWindowDefaults(globalWindow.joyEnv) as ConfigProps) : {};

  const baselineDefaults = {
    // serverUri: 'http://localhost:9000',
    // graphQLUrl: 'http://localhost:9000/graphql',
    // clientUri: 'http://localhost:3000',
    // parentClientUri: 'https://dev.withjoy.com'
  };

  const getConfigKey = (key: keyof ConfigProps) =>
    envDefaults[key] || userOverrides[key] || windowDefaults[key] || envDefaults[key] || baselineDefaults[key as keyof typeof baselineDefaults];

  const _config: ConfigReturn = {
    joyEnvironment: getConfigKey('joyEnvironment') as JoyEnvironment,
    serverUri: getConfigKey('serverUri'),
    graphQLUrl: getConfigKey('graphQLUrl'),
    clientUri: getConfigKey('clientUri'),
    parentClientUri: getConfigKey('parentClientUri'),
    auth0ClientId: getConfigKey('auth0ClientId'),
    logentries: getConfigKey('logentries'),
    appName: getConfigKey('appName'),
    vendorStoreUri: getConfigKey('vendorStoreUri'),
    port: getConfigKey('port'),
    registryLogoServiceUri: getConfigKey('registryLogoServiceUri'),
    redirectServiceUri: getConfigKey('redirectServiceUri'),
    environment: getConfigKey('environment'),
    previewTargetOrigin: getConfigKey('previewTargetOrigin'),
    s3PublicAssetsUrl: getConfigKey('s3PublicAssetsUrl'),
    s3PublicAssetsThemeBucket: getConfigKey('s3PublicAssetsThemeBucket'),
    intercomAppId: getConfigKey('intercomAppId'),
    smartyApiKey: getConfigKey('smartyApiKey'),
    charmAllowedTrafficPercentage: getConfigKey('charmAllowedTrafficPercentage'),
    legacyCeremonyUri: getConfigKey('legacyCeremonyUri'),
    brightDataCatalogSerpApiUsername: getConfigKey('brightDataCatalogSerpApiUsername'),
    brightDataCatalogSerpApiPassword: getConfigKey('brightDataCatalogSerpApiPassword'),
    zyteApiKey: getConfigKey('zyteApiKey')
  };

  // Apply config
  Object.assign(config, _config);
};

export const reload = () => {
  load();
};

// We are self-loading
load();
