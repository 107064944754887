import { IconConfig } from '..';

/* tslint:disable:max-line-length */

export const email: IconConfig = {
  name: 'email',
  a11yLabel: 'Email',
  viewBox: 24,
  fill: '#333333',
  body: `
  <mask id="path-1-inside-1" fill="white">
  <path d="M1 5.65517C1 4.74105 1.70355 4 2.57143 4H21.4286C21.9248 4 22.3674 4.24229 22.6553 4.62069C22.871 4.90405 23 5.26375 23 5.65517V18.3448C23 18.7363 22.871 19.0959 22.6553 19.3793C22.3674 19.7577 21.9248 20 21.4286 20H2.57143C2.16896 20 1.80183 19.8406 1.52381 19.5785C1.20232 19.2755 1 18.835 1 18.3448V5.65517Z"/>
  </mask>
  <path d="M14.7886 11.7931L14.4517 11.4236L14.7886 11.7931ZM11.0367 13.3336L11.3744 12.9649L11.0367 13.3336ZM13.0963 13.336L12.7594 12.9666L13.0963 13.336ZM1.1861 4.98941L9.01705 12.1618L9.69247 11.4244L1.86152 4.25197L1.1861 4.98941ZM9.01705 12.1618L10.699 13.7023L11.3744 12.9649L9.69247 11.4244L9.01705 12.1618ZM9.00224 11.4385L1.17129 19.224L1.87633 19.9331L9.70728 12.1477L9.00224 11.4385ZM13.4332 13.7055L15.1255 12.1626L14.4517 11.4236L12.7594 12.9666L13.4332 13.7055ZM15.1255 12.1626L22.9922 4.99017L22.3185 4.25121L14.4517 11.4236L15.1255 12.1626ZM14.4415 12.153L22.3083 19.7392L23.0024 19.0194L15.1357 11.4332L14.4415 12.153ZM10.699 13.7023C11.48 14.4177 12.6505 14.4191 13.4332 13.7055L12.7594 12.9666C12.3592 13.3314 11.7739 13.3308 11.3744 12.9649L10.699 13.7023ZM22.6553 4.62069L21.8596 5.22631L22.6553 4.62069ZM1.52381 19.5785L0.837849 20.3062H0.837849L1.52381 19.5785ZM22.6553 19.3793L23.4511 19.9849L22.6553 19.3793ZM2.57143 5H21.4286V3H2.57143V5ZM22 5.65517V18.3448H24V5.65517H22ZM21.4286 19H2.57143V21H21.4286V19ZM2 18.3448V5.65517H0V18.3448H2ZM2.57143 3C1.10298 3 0 4.23832 0 5.65517H2C2 5.24377 2.30413 5 2.57143 5V3ZM21.4286 5C21.5877 5 21.7451 5.07592 21.8596 5.22631L23.4511 4.01507C22.9896 3.40866 22.262 3 21.4286 3V5ZM21.8596 5.22631C21.9447 5.33809 22 5.48566 22 5.65517H24C24 5.04183 23.7973 4.47002 23.4511 4.01507L21.8596 5.22631ZM2.57143 19C2.44038 19 2.31452 18.9496 2.20977 18.8509L0.837849 20.3062C1.28913 20.7316 1.89753 21 2.57143 21V19ZM2.20977 18.8509C2.08679 18.735 2 18.557 2 18.3448H0C0 19.1131 0.31785 19.816 0.837849 20.3062L2.20977 18.8509ZM22 18.3448C22 18.5143 21.9447 18.6619 21.8596 18.7737L23.4511 19.9849C23.7973 19.53 24 18.9582 24 18.3448H22ZM21.8596 18.7737C21.7451 18.9241 21.5877 19 21.4286 19V21C22.262 21 22.9896 20.5913 23.4511 19.9849L21.8596 18.7737Z" mask="url(#path-1-inside-1)"/>
  `
};
