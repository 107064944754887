import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { LoginManager, useIdentify } from '@shared/core';
import { AuthContext, AuthContextValue, ProvideAuthenticationOptions } from './AuthProvider.context';
import { useGetMeLazyQuery, useLogoutEventSessionMutation, LogoutEventSessionMutationVariables, useLoginEventSessionMutation, EventType } from '@graphql/generated';
import { useEventNameMatch } from './AuthProvider.utils';
import { useAuthProviderReducer } from './AuthProvider.reducer';
import { identifyUserAction, handleMissingTokenAction, initiateIdentifyUserAction } from './AuthProvider.actions';
interface Props {
  loginManager: LoginManager;
}

export const AuthProvider: React.FC<Props> = ({ children, loginManager }) => {
  const eventNameMatch = useEventNameMatch();
  const [{ hasInitializedOnce, user }, dispatch] = useAuthProviderReducer();
  const { identify } = useIdentify();
  const [getMeQuery, {}] = useGetMeLazyQuery({
    // This query is blocking and runs before any other network code.
    // so there is no point batching it (yet).
    // We should fix the code so it plays better in parallel.
    batchMode: 'off',
    // 1. Never return data from cache
    // 2. Never write this data to the cache
    // Note: This query is invoked once at mount and again when the token is being refreshed
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      // if authService.shouldRefreshMfaStatus() is true, that means `me` was also executed with refreshMfa = true
      if (loginManager.authService.shouldRefreshMfaStatus()) {
        loginManager.authService.handleRefreshMfaStatusDone();
      }
      if (data.me) {
        identify({
          auth0Id: data.me.activeAlias?.auth0Id,
          email: data.me.email,
          hasMFAEnabled: Boolean(data.me.activeAlias?.mfaEnabled)
        });
      }
      // this is called on every render
      dispatch(identifyUserAction({ data }));
    },
    onError: error => {
      // if authService.shouldRefreshMfaStatus() is true, we should clear this out anyway if an error is returned
      // the most common error so far is if user has multi-aliases will not return any data since the user is trying tp update the user
      if (loginManager.authService.shouldRefreshMfaStatus()) {
        loginManager.authService.handleRefreshMfaStatusDone();
      }
      dispatch(identifyUserAction({ error }));
    }
  });

  const identifyCurrentUser = useCallback(() => {
    const { authService } = loginManager;

    getMeQuery({
      variables: {
        refreshMfa: authService.shouldRefreshMfaStatus(),
        eventName: eventNameMatch.eventName,
        withActiveEventUser: eventNameMatch.isEventRoute && !!eventNameMatch.eventName,
        filterEventTypes: [EventType.wedding, EventType.babyRegistry]
      }
    });
  }, [eventNameMatch.eventName, eventNameMatch.isEventRoute, getMeQuery, loginManager]);

  const providedAuthenticationCodesRef = useRef(new Set<string>());
  // both of these Mutations resolve `null`
  const [loginEventSession] = useLoginEventSessionMutation({ ignoreResults: true });
  const [logoutEventSession] = useLogoutEventSessionMutation({ ignoreResults: true });

  const processToken = useCallback(
    (token: string | null) => {
      if (token) {
        // Only identify if token differs from the current one.
        dispatch(initiateIdentifyUserAction);
        // If token exists, request user identity and event ACLs
        // Executing the lazy query will update the QueryResult value in the result tuple.
        identifyCurrentUser();
      } else {
        // If token does not exist, user is not logged in.
        dispatch(handleMissingTokenAction);
      }
    },
    [dispatch, identifyCurrentUser]
  );

  useEffect(() => {
    // Initiate ACL request flow
    loginManager.getTokenAsync().then(processToken);

    const accessTokenSub = loginManager.accessTokenObservable().subscribe({
      next: token => {
        // Triggered when the observable pushes a new token - typically when it is refreshed.
        // The observed behavior is that this observeable will only update so long as the webpage is not in a background tab.
        processToken(token?.id_token ?? null);
      }
    });

    return () => {
      accessTokenSub?.unsubscribe();
    };
  }, [identifyCurrentUser, loginManager, processToken]);

  const authContextValue: AuthContextValue = useMemo(() => {
    // an end-to-end Auth operation require a blend of Auth0 + Gateway trickery
    //   both take an optional { eventId } to login / logout to a specific Event
    //   eg. useful in the case of a Person / Guest / Invitee
    //   vs. an Auth0 User, who would logout from *all* Events
    async function provideAuthentication({ loginVariables, history }: ProvideAuthenticationOptions): Promise<void> {
      const { authService } = loginManager;
      const authResult = authService.deriveAuthorizeResult(history);

      // we should only process a given Authentication code *once*
      //   across the lifespan of this SPA.
      const { code } = authResult;
      const codes = providedAuthenticationCodesRef.current;
      if (codes.has(code)) {
        return;
      }
      codes.add(code);

      let success = false;
      try {
        success = await authService.consumeAuthenticationToken(authResult);
        if (success) {
          // notify the Gateway, *after* Auth handling, but *before* any redirection
          await loginEventSession({ variables: loginVariables });
        }
      } catch (err) {
        console.error('could not provide Authentication');
        console.error(err);
        success = false;
      }

      authService.redirectAfterAuthentication(authResult, history, success);
    }

    function authorize(returnTo = '/', params?: Record<string, string | boolean | number>) {
      return loginManager.authService.authorize(returnTo, params);
    }

    // logout is *much easier*
    // WARNING: you need to reload the page for apollo's me query to respect the new token
    // it will fire onCompleted without a new request with the previously returned value (signed-in user)
    async function provideLogout(variables: LogoutEventSessionMutationVariables, returnUrl?: string): Promise<void> {
      const { authService } = loginManager;
      try {
        // notify the Gateway, *after* the logout
        await logoutEventSession({ variables });
      } catch (err) {
        console.error('could not provide Logout');
        console.error(err);
      } finally {
        // always redirect to auth0 logout route
        authService.signout(returnUrl);
      }
    }

    const isCurrentUserSettled = ['success', 'idle'].includes(user.requestStatus) && hasInitializedOnce;

    return {
      hasInitializedOnce,
      isLoggedIn: !!user.profile,
      currentUser: user,
      willRefetch: eventNameMatch.shouldRevalidate,
      loginManager: loginManager,
      provideAuthentication,
      provideLogout,
      isCurrentUserSettled,
      identifyCurrentUser,
      authorize
    };
  }, [user, hasInitializedOnce, eventNameMatch.shouldRevalidate, loginManager, identifyCurrentUser, loginEventSession, logoutEventSession]);

  return <AuthContext.Provider value={authContextValue}>{children}</AuthContext.Provider>;
};
