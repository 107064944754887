import React from 'react';
import { ButtonV2, ButtonV2Props } from '@withjoy/joykit/components/ButtonV2';
import { Close } from '@withjoy/joykit/icons';
import { Override, useOverrides } from '@shared/utils/overrides';
import { IconV1Props } from '../IconV1';
import { forwardStyledComponentRef } from '@shared/utils/forwardRef';

export interface CloseButtonProps
  extends Readonly<
    Merge<
      ButtonV2Props,
      {
        overrides?: {
          Root?: Override<ButtonV2Props>;
          Icon?: Override<IconV1Props>;
        };
      }
    >
  > {
  iconSize?: number;
}

/**
 * ButtonV2 with close icon.
 *
 * Intended to be used in toasts, dialogs, drawers.
 */
export const CloseButton = forwardStyledComponentRef<'button', CloseButtonProps>((props, ref) => {
  const { children, overrides = {}, ...restProps } = props;
  const {
    Root: [Root, rootProps],
    Icon: [CloseIcon, iconProps]
  } = useOverrides({ Root: ButtonV2, Icon: Close }, overrides);
  return (
    <Root ref={ref} intent="neutral" variant="ghost" {...restProps} {...rootProps}>
      {children || <CloseIcon size={props.iconSize || 24} {...iconProps} />}
    </Root>
  );
});
