function safeUrlToRedirect(url: string) {
  const global = window;
  if (url.indexOf(global.location.origin) === 0) {
    // same domain as /account-callback page e.g. withjoy.com
    return true;
  }
  return false;
}

export default safeUrlToRedirect;
