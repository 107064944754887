import React from 'react';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import { JoyKitThemeProvider, ToastContainer, createGlobalStyle } from '@withjoy/joykit';
import { AnalyticsProvider, SegmentService, Logger, CoreConfigProvider, LoginManager } from '@shared/core';
import { AuthProvider } from '@shared/components/AuthProvider';
import { FilestackProvider } from '@shared/utils/filestack';
import { ConfigReturn } from '../../static/js/env.config';
import { ApolloClient } from '@apollo/client';
import { LocaleProvider } from '@shared/core/i18n/LocaleContext';
import { FeatureFlagsProvider } from '@shared/core/featureFlags';
import { IntercomProvider, intercomService } from '@shared/core/intercom';
import { HelmetProvider } from 'react-helmet-async';
import { DefaultMediaMatchProvider, MediaScreen } from '@shared/utils/media/DefaultMediaMatchProvider';
import { CurrencyProvider } from '@shared/utils/currency';
import { WrappedApolloProvider } from '@shared/core/apollo/WrappedApolloProvider';
import { MfaProvider } from '@shared/components/MfaController/MfaControllerProvider';
import { VimeoPlayerProvider } from '@apps/registry/common/components/Catalog/utils/vimeoPlayer';
import { RouteBasedServices } from '@shared/core/RouteBasedServices';
import { WafCaptcha } from '@shared/components/WafCaptcha';
import { EventInfoProvider } from '@shared/utils/eventInfo';
import { AppBoundaryObserver } from '@shared/core/router.helper/appBoundary';
import { ApolloFeatureFlag } from '@shared/core/graphql';

interface SetupProps
  extends Readonly<{
    logger: Logger;
    segment: SegmentService;
    apolloClient: ApolloClient<NormalizedCacheObject>;
    loginManager: LoginManager;
    config: ConfigReturn;
    helmetContext?: Object;
    defaultScreen?: MediaScreen;
  }> {}

const GlobalStyle = createGlobalStyle`
  :root {
    --full-screen-height: calc(100vh - (100vh - 100%));
  }
  #root {
    /**
    100vh units does not play well on mobile web - chrome and safari collape the address bar and optional tab bar at the top, and the
    tool bar at the bottom.

    "When the address bar is visible, the bottom of the screen gets cut off since mobile browsers incorrectly set 100vh to be the
    height of the screen without the address bar showing. In the image above, the button which should be at the bottom of the screen
    is instead hidden. Worse, when a user first goes to a website on mobile the address bar will be visible at the top, so the broken
    experience is the default experience."

    Solution: Use the css property "fill-available" for browsers that support it, and default to 100vh.

    References:
      - https://chanind.github.io/javascript/2019/09/28/avoid-100vh-on-mobile-web.html
      - https://bugs.chromium.org/p/chromium/issues/detail?id=844848#c4
      - https://gist.github.com/claus/622a938d21d80f367251dc2eaaa1b2a9
      - https://caniuse.com/#search=fill-available
    */
    min-height: var(--full-screen-height);
    min-height: -webkit-fill-available;
    display: flex;
    flex-direction: column;
    > *:first-child {
      flex: 1;
    }
  }

  body.overlay-open {
    overflow: hidden;
    padding-right: 15px;
  }

  *,::after,::before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
`;

export const Setup: React.FC<SetupProps> = ({ defaultScreen, loginManager, segment, apolloClient, config, helmetContext = {}, children }) => {
  return (
    <DefaultMediaMatchProvider defaultScreen={defaultScreen}>
      <IntercomProvider intercom={intercomService}>
        <LocaleProvider>
          <CurrencyProvider>
            <WrappedApolloProvider client={apolloClient}>
              <AnalyticsProvider segment={segment}>
                <AuthProvider loginManager={loginManager}>
                  <EventInfoProvider>
                    <FeatureFlagsProvider>
                      <ApolloFeatureFlag>
                        <AppBoundaryObserver>
                          <MfaProvider>
                            <JoyKitThemeProvider>
                              <CoreConfigProvider {...config}>
                                <HelmetProvider context={helmetContext}>
                                  <FilestackProvider>
                                    <VimeoPlayerProvider>
                                      <ToastContainer />
                                      <WafCaptcha />
                                      <>
                                        <RouteBasedServices />
                                        <GlobalStyle />
                                        {children}
                                      </>
                                    </VimeoPlayerProvider>
                                  </FilestackProvider>
                                </HelmetProvider>
                              </CoreConfigProvider>
                            </JoyKitThemeProvider>
                          </MfaProvider>
                        </AppBoundaryObserver>
                      </ApolloFeatureFlag>
                    </FeatureFlagsProvider>
                  </EventInfoProvider>
                </AuthProvider>
              </AnalyticsProvider>
            </WrappedApolloProvider>
          </CurrencyProvider>
        </LocaleProvider>
      </IntercomProvider>
    </DefaultMediaMatchProvider>
  );
};
