import { IconConfig } from '..';

/* tslint:disable:max-line-length */
export const friends: IconConfig = {
  name: 'friends',
  a11yLabel: 'Friends',
  viewBox: 24,
  fill: '#333333',
  body: `
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.7497 20H1.00001V17.8163C0.998814 17.3567 1.19294 16.9215 1.52909 16.6284C2.86654 15.4968 4.31564 14.5192 5.85227 13.7148C5.89872 13.6917 5.9276 13.6429 5.9276 13.5878V12.1165C5.71829 11.7451 5.57656 11.3356 5.50779 10.9104C5.3473 10.8957 5.1368 10.6598 4.90903 9.81639C4.61576 8.73018 4.89146 8.51368 5.15675 8.51619C5.01592 7.88329 4.65655 5.67738 6.21968 4.67532C6.0324 4.50794 5.92313 4.26329 5.92313 4.00425C5.92313 4.00425 7.70957 4.21574 8.56676 4.0299C9.42396 3.84438 10.3532 4.55549 10.5033 5.23876C10.5033 5.23876 12.2513 5.23876 11.573 8.52369C11.8452 8.51149 12.1429 8.70921 11.8431 9.8239C11.6198 10.6683 11.3965 10.9054 11.244 10.9176C11.1753 11.3428 11.0335 11.7523 10.8242 12.1237V13.5953C10.8242 13.6488 10.8531 13.6992 10.8999 13.7223C12.4362 14.5251 13.8853 15.4993 15.2227 16.6284C15.5589 16.9215 15.753 17.3567 15.7521 17.8163L15.7497 20ZM15.1042 13.9679V14.8405C15.1042 14.9346 15.0786 15.0263 15.0334 15.1058C15.3076 15.3194 15.5845 15.5444 15.859 15.7815C16.4301 16.2815 16.7579 17.0232 16.7522 17.8041V19.9768H23V17.9239C22.9467 17.347 22.607 16.8411 22.1068 16.5952C21.6882 16.3791 21.2508 16.2054 20.8009 16.0772C20.7765 16.0625 19.3875 15.6814 18.7283 15.3122L18.67 15.2841C18.5306 15.1887 18.4467 15.0263 18.4467 14.8527V13.9373C18.4467 13.9373 20.3564 13.9986 21.2603 13.0269C21.2603 13.0269 19.9425 12.6346 20.1798 10.1123C20.4171 7.58983 19.8853 5.37673 17.9468 5.56506C17.9468 5.56506 17.1116 4.50919 15.4484 5.17275C14.8762 5.40739 13.3446 5.97803 13.4235 9.47069C13.5015 12.9646 12.2418 12.9903 12.2418 12.9903C12.2418 12.9903 12.8932 13.9886 15.1042 13.9679Z"
    />  
  `
};
