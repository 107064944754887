import { Theme } from './themes';
import { ButtonVariant } from '../common/AbstractButton';

// Buttons

export const getButtonnVariantStyle = (
  theme: Theme,
  variant?: ButtonVariant
): {
  fill: string;
  text: string;
  hover: string;
  active: string;
} => {
  switch (variant) {
    case 'success':
      return {
        fill: theme.colors.buttonProductiveFill,
        text: theme.colors.buttonProductiveText,
        hover: theme.colors.buttonProductiveHover,
        active: theme.colors.buttonProductiveActive
      };
    case 'danger':
      return {
        fill: theme.colors.buttonDestructiveFill,
        text: theme.colors.buttonDestructiveText,
        hover: theme.colors.buttonDestructiveHover,
        active: theme.colors.buttonDestructiveActive
      };

    default: {
      return {
        fill: theme.colors.buttonPrimaryFill,
        text: theme.colors.buttonPrimaryText,
        hover: theme.colors.buttonPrimaryHover,
        active: theme.colors.buttonPrimaryActive
      };
    }
  }
};
