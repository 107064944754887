import React from 'react';
import { addError } from '@shared/utils/logger';

export interface ErrorBoundaryProps {
  logKey?: string; // Action Datadog error name
}

/**
 * Error boundaries do not catch errors for:
 * - Event handlers {@link https://reactjs.org/docs/error-boundaries.html#how-about-event-handlers}
 * - Asynchronous code (e.g. setTimeout or requestAnimationFrame callbacks)
 * - Server side rendering
 * - Errors thrown in the error boundary itself (rather than its children)
 */
export class ErrorBoundary extends React.Component<ErrorBoundaryProps> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: unknown, errorInfo: unknown) {
    // You can also log the error to an error reporting service
    console.error(`Caught an error.`, error, errorInfo);
    if (this.props.logKey) {
      addError(this.props.logKey, {
        error,
        errorInfo
      });
    }
  }

  render() {
    return this.props.children;
  }
}
