import React, { useEffect } from 'react';
import { ErrorSplash } from '@shared/components';
import { useTranslation } from '@shared/core';
import { withWindow } from '@shared/utils/withWindow';
import { useEcardGuestTelemetry } from '@apps/ecard/guest/EcardGuest.telemetry';

export const Error: React.FC = () => {
  const { t } = useTranslation('emailsAndEcards');
  const tEcardGuestError = t('ecardGuest', 'error');
  const telemetry = useEcardGuestTelemetry();

  useEffect(() => {
    withWindow(() => {
      telemetry.onErrorPageShown({ pageUrl: window.location.href });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleNavigateToFindCouple = () => {
    withWindow(global => {
      global.location.href = '/find';
    });
  };

  return (
    <ErrorSplash
      customError={{
        title: tEcardGuestError.title(),
        subtitle: tEcardGuestError.subtitle(),
        linkText: tEcardGuestError.linkText()
      }}
      handleAction={handleNavigateToFindCouple}
    />
  );
};
