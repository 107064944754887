import { IconConfig } from '..';

/* tslint:disable:max-line-length */

export const chevronRight: IconConfig = {
  name: 'chevronRight',
  a11yLabel: 'Go forward',
  viewBox: 16,
  fill: '#333333',
  body: `
  <path fill-rule="evenodd" clip-rule="evenodd" d="M12 8L5.89091 14L5 13.215L10.3455 8L5 2.78505L5.89091 2L12 8Z" />
  
  `
};
