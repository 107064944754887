import { ComponentColorTokens, PaletteColorTokens } from '@withjoy/joykit/theme';
// NOTE: src/apps/guest/packages/joykit/common/theme/shared/colors/paletteTokens.ts
export const getComponentColorTokens = (paletteTokens: PaletteColorTokens): ComponentColorTokens => {
  return {
    backdropFill: 'rgba(0,0,0,.4)',

    // Fill
    fillDark: paletteTokens.primary8, // rgba(80, 32, 128, 1)
    fillDarkHover: paletteTokens.primary7, // rgba(95, 38, 155, 1)
    fillDarkActive: paletteTokens.primary9, // rgba(68, 0, 102, 1)

    fillLight: paletteTokens.white, // #FFFFFF
    fillLightActive: paletteTokens.mono2, // #F7F7F7
    fillLightHover: paletteTokens.mono1, // #FBFBFB
    // Type
    /* Type (Dark) */
    typePrimaryDark: paletteTokens.mono14, // #333333 // Headline
    typeSecondaryDark: paletteTokens.mono12, // #595959 // Subheads, Descriptions, Body text
    typeTertiaryDark: paletteTokens.mono10, // #767676 // Field Titles
    typeDisabledDark: paletteTokens.mono8, // #949494 // Disabled, Placeholder, Field filler

    /* Type Light */
    typePrimaryLight: paletteTokens.white, // #FFFFFF // Headline
    typeSecondaryLight: paletteTokens.mono4, // #E0E0E0 // Subheads, Descriptions, Body text
    typeTertiaryLight: paletteTokens.mono5, // #D9D9D9 // Field Titles
    typeDisabledLight: paletteTokens.mono8, // #949494 // Disabled, Placeholder, Field filler

    // Buttons
    buttonDisabledText: paletteTokens.mono6, // #BEBEBE
    buttonDisabledFill: paletteTokens.mono4, // #E0E0E0

    buttonText: paletteTokens.mono14, // #333333
    buttonFill: paletteTokens.mono6, // #BEBEBE
    buttonHover: paletteTokens.mono1, // #d9d9d9
    buttonActive: paletteTokens.mono13, // #a6a6a6

    buttonPrimaryText: paletteTokens.white, // #FFFFFF
    buttonPrimaryFill: paletteTokens.primary8, // rgba(113, 57, 191, 1)
    buttonPrimaryFillLight: paletteTokens.white, // #FFFFFF
    buttonPrimaryFillDark: paletteTokens.primary8, // rgba(80, 32, 128, 1)
    buttonPrimaryHover: paletteTokens.primary7,
    buttonPrimaryActive: paletteTokens.primary9,
    buttonOutlinePrimaryText: paletteTokens.primary7,
    buttonOutlinePrimaryTextHover: paletteTokens.primary6,
    buttonOutlinePrimaryTextPress: paletteTokens.primary8,
    buttonOutlinePrimaryFillHover: 'rgba(113, 57, 191, 0.1)',
    buttonOutlinePrimaryFillPress: 'rgba(80, 32, 128, 0.2)',

    buttonProductiveText: paletteTokens.white, // #FFFFFF
    buttonProductiveFill: paletteTokens.positive7, // rgba(23, 111, 105, 1)
    buttonProductiveHover: paletteTokens.positive6, // rgba(24, 146, 137, 1)
    buttonProductiveActive: paletteTokens.positive8, // rgba(6, 64, 57, 1)
    buttonOutlineProductiveText: paletteTokens.positive7,
    buttonOutlineProductiveTextHover: paletteTokens.positive6,
    buttonOutlineProductiveTextPress: paletteTokens.positive8,
    buttonOutlineProductiveFillHover: 'rgba(24, 146, 137, 0.1)',
    buttonOutlineProductiveFillPress: 'rgba(6, 64, 57, 0.2)',

    buttonDestructiveText: paletteTokens.white, // #FFFFFF
    buttonDestructiveFill: paletteTokens.negative6,
    buttonDestructiveHover: 'rgba(224, 0, 36, 1)', // rgba(215, 66, 90, 1) // figma: rgba(224, 0, 36, 1)
    buttonDestructiveActive: paletteTokens.negative7, // rgba(153, 0, 25, 1)
    buttonOutlineDestructiveText: paletteTokens.negative6,
    buttonOutlineDestructiveTextHover: 'rgba(224, 0, 36, 1)',
    buttonOutlineDestructiveTextPress: paletteTokens.negative7,
    buttonOutlineDestructiveFillHover: 'rgba(224, 0, 36, 0.1)',
    buttonOutlineDestructiveFillPress: 'rgba(153, 0, 25, 0.2)',

    buttonNeutralText: paletteTokens.white,
    buttonNeutralFill: paletteTokens.mono14,
    buttonNeutralHover: paletteTokens.mono12,
    buttonNeutralActive: paletteTokens.mono13,
    buttonOutlineNeutralText: paletteTokens.mono14,
    buttonOutlineNeutralTextHover: paletteTokens.mono12,
    buttonOutlineNeutralTextPress: paletteTokens.mono13,
    buttonOutlineNeutralFillHover: 'rgba(77, 77, 77, 0.1)',
    buttonOutlineNeutralFillPress: 'rgba(89, 89, 89, 0.2)',

    linkText: paletteTokens.primary7, // rgba(95, 38, 155, 1)
    linkVisited: paletteTokens.primary9, // rgba(68, 0, 102, 1)
    linkHover: paletteTokens.primary6, // rgba(113, 57, 191, 1)
    linkActive: paletteTokens.primary8, // rgba(80, 32, 128, 1)

    inputFill: paletteTokens.white, // #FFFFFF
    inputFillError: paletteTokens.white, // #FFFFFF
    inputFillDisabled: paletteTokens.mono2, // #F7F7F7
    inputText: paletteTokens.mono14, // #333333
    inputTextError: paletteTokens.mono14, // #333333
    inputTextDisabled: paletteTokens.mono10, // #767676
    inputBorder: paletteTokens.mono5, // #D9D9D9
    inputBorderError: paletteTokens.negative6,
    inputBorderDisabled: paletteTokens.mono5, // #D9D9D9
    inputBorderFocused: paletteTokens.mono14, // #333333
    inputPlaceholder: paletteTokens.mono11, // #666666
    inputPlaceholderDisabled: paletteTokens.mono10, // #767676

    // Stroke
    stroke: paletteTokens.primary7, // rgba(95, 38, 155, 1)
    strokeActive: paletteTokens.primary8, // rgba(80, 32, 128, 1)
    strokeHover: paletteTokens.primary6, // rgba(113, 57, 191, 1)

    // Menu
    menuFill: paletteTokens.white, // #FFFFFF
    menuText: paletteTokens.mono14, // #333333

    menuItemFillHighlighted: paletteTokens.primary1, // rgba(250, 247, 255, 1)
    menuItemFillActive: paletteTokens.primary2, // rgba(238, 235, 255, 1)
    menuItemFillDisabled: paletteTokens.mono3, // #EBEBEB
    menuItemTextHighlighted: paletteTokens.primary6, // rgba(113, 57, 191, 1)
    menuItemTextActive: paletteTokens.primary7, // rgba(95, 38, 155, 1)
    menuItemTextDisabled: paletteTokens.mono13, // #4D4D4D

    // DatePicker
    datepickerDayHighlighted: paletteTokens.mono12, // #595959
    datepickerDayHighlightedHover: paletteTokens.mono10, // #767676
    datepickerDaySelected: paletteTokens.mono14, // #333333

    // Scrollbar
    scrollbarHandle: '#9D9D9D',

    // misc
    currentColor: 'currentColor'
  };
};
