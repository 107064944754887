import React, { useEffect, useRef, useCallback } from 'react';
import { useImmer } from 'use-immer';
import { createDisplayName } from '@withjoy/joykit/utils';
import { StyledContainer } from './Text.styles';
import { useCombinedRefs } from '@shared/utils/hooks/useCombinedRefs';
import { As, forwardStyledComponentRef } from '@shared/utils/forwardRef';
import { BoxProps } from '@withjoy/joykit';

export type TextV2Props<Component extends As = 'p'> = Merge<
  Omit<BoxProps<Component>, 'as' | 'css'>,
  {
    /**
     * Override the default HTML element type
     * @default div
     */
    tagName?: React.ElementType;

    children?: React.ReactNode;

    // /**
    //  * Apply the theme typography styles
    //  * @default 'bodySM'
    //  */
    // variant?: ResponsiveValue<TypographyVariant>;

    /**
     * Whether to render an ellipsis when the text exceeds the width of the container
     *
     * Note: This will only apply to block containers. Span will not work.
     * https://www.w3.org/TR/css-overflow-3/#text-overflow
     *
     * @default false
     */
    truncate?: boolean;
  }
>;

/**
 * TODO:
 * - Create variant -> component mapping
 */

export const TextV2 = forwardStyledComponentRef<'p', TextV2Props>(({ as, tagName = 'p', truncate = false, children, typographyVariant, ...restProps }, ref) => {
  const [state, setState] = useImmer<{ textContent: string; isOverflowing: boolean }>(() => ({ isOverflowing: false, textContent: '' }));
  const containerRef = useRef<HTMLParagraphElement>(null);
  const multiRef = useCombinedRefs(ref, containerRef);

  const handleUpdate = useCallback(() => {
    // Immer will only trigger re-render if state has actually changed
    setState(draft => {
      // Callback is asynchronous, perform ref existence check at call
      if (multiRef.current) {
        // With white-space="no-wrap", text will render on one line regardless of container size -
        // this will increase the original scroll width (distance).
        draft.isOverflowing = truncate && multiRef.current.scrollWidth > multiRef.current.clientWidth;
        draft.textContent = multiRef.current.textContent || '';
      }
    });
    // `truncate` is the only unstable dep
  }, [truncate, multiRef, setState]);

  useEffect(() => {
    // Respond to every re-render
    handleUpdate();
  });

  return (
    <StyledContainer
      ref={multiRef}
      title={state.isOverflowing && state.textContent ? state.textContent : undefined}
      truncate={truncate}
      as={as || tagName}
      typographyVariant={typographyVariant || 'body2'}
      {...restProps}
    >
      {children}
    </StyledContainer>
  );
});

TextV2.displayName = createDisplayName('Text');

// typographyVariant
