import { useMemo } from 'react';
import { getKeys } from '@shared/utils/object';
import { isValidElementType } from 'react-is';
import { deepmerge } from './deepmerge';
// eslint-disable-next-line
import { StyledComponent } from 'styled-components';
import { Theme } from '@withjoy/joykit';

type OverrideWithComponent<C> = {
  readonly component?: C;
  // Unable to infer a property based on a sibling property at runtime.
  // Solution is to set as `{}` and assume the dev passes in the appropriate props.
  readonly props?: {};
};

export type Override<Props, C extends React.ComponentType = React.ComponentType> =
  | OverrideWithComponent<C>
  | {
      readonly props?: Partial<Props> & { 'data-testid'?: string };
    };

type ComponentMap = Record<string, React.ElementType | StyledComponent<React.ComponentType<unknown>, Theme>>;
export type DeriveOverrides<T extends ComponentMap> = { readonly [K in keyof T]?: Override<React.ComponentProps<T[K]>> };

type ComponentOverrides<CMap extends ComponentMap> = { [K in keyof CMap]?: Override<React.ComponentProps<CMap[K]>> | undefined };
export type GetOverridesReturn<C, Props> = readonly [Component: C, props: Props];
// Since this is a mapped type, use readonly modifier instead of Readonly type to simplify the resultant type.
export type UseOverridesReturn<CMap extends ComponentMap> = {
  readonly [K in keyof CMap]: GetOverridesReturn<CMap[K], Partial<React.ComponentPropsWithoutRef<CMap[K]>>>;
};

// `override` is a reserved word by typescript
// https://github.com/microsoft/TypeScript/issues/44466
// https://github.com/prettier/prettier/issues/11018
// https://www.typescriptlang.org/play?ts=4.3.5#code/MYewdgzgLgBCBuBTATsglgE0TAvDA3gL4DcAUKJLAEa4wAUCK6WMAhhG2AJ4CUZQA
const _getOverrideComponent = <Props>(overrideArg: Override<Props> | undefined) => {
  let overrideComponent = undefined;
  if (overrideArg && isValidElementType((overrideArg as OverrideWithComponent<unknown>).component)) {
    overrideComponent = (overrideArg as OverrideWithComponent<unknown>).component;
  }
  return overrideComponent;
};

const _getOverrideProps = <Props>(override?: Override<Props>) => {
  return override?.props || ({} as Props);
};

const _mergeOverrideConfig = <Props extends {}>(target: Override<Props> = {}, source: Override<Props> = {}): Override<Props> => {
  const merged = { ...target, ...source };
  if (target.props && source.props) {
    merged.props = deepmerge(target.props, source.props);
  }
  return merged;
};

export const getOverrides = <Props extends {}, C extends React.ElementType>(override: Override<Props> | undefined, defaultComponent: C): GetOverridesReturn<C, Partial<Props>> => {
  // For now, only support component prop overrides. Support full component overrides in the future.
  const Component = (_getOverrideComponent(override) || defaultComponent) as C;
  const overrideProps = _getOverrideProps(override) as Props;
  return [Component, overrideProps];
};

export const mergeOverrides = <CMap extends ComponentMap, Overrides extends ComponentOverrides<CMap>>(target: Overrides, source: Overrides = {} as Overrides): Overrides => {
  const keysToResolve = getKeys({ ...target, ...source });
  return keysToResolve.reduce((acc, key) => {
    acc[key] = _mergeOverrideConfig(target[key], source[key]) as Overrides[typeof key];
    return acc;
  }, {} as UnsealedReadonly<Overrides>);
};

export const useOverrides = <CMap extends ComponentMap>(defaults: CMap, overrides: ComponentOverrides<CMap>): UseOverridesReturn<CMap> => {
  return useMemo(() => {
    return getKeys(defaults).reduce((acc, key) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      acc[key] = getOverrides<any, any>(overrides[key], defaults[key]);
      return acc;
    }, {} as UnsealedReadonly<UseOverridesReturn<CMap>>);
  }, [defaults, overrides]);
};
