import { useEffect } from 'react';

import { useInternalEventInfoContext } from './internalEventInfoContext';

/**
 * Sets the eventHandle to load event info for.
 *
 * Event info can be accessed via the `useEventInfo` hook.
 */
export function useSetEventHandle(eventHandle: string | undefined): void {
  const internalEventInfo = useInternalEventInfoContext();
  const setEventHandle = internalEventInfo?.setEventHandle;

  useEffect(() => {
    setEventHandle?.(eventHandle);

    return () => {
      setEventHandle?.(undefined);
    };
  }, [eventHandle, setEventHandle]);
}
