import {
  ElectronicCardInput,
  ElectronicCardEmailDraftInput,
  PrivacyModeType,
  ElectronicCardCardLinkOptionFragment,
  SaveTheDateEventByIdDataFragment,
  SaveTheDateGuestEventByIdDataFragment,
  SaveTheDateElectronicCardFragment
} from '@graphql/generated';
export interface HandleSendEmailArgs {
  selectedPeopleIds?: string[];
  testEmail?: string;
  testName?: string;
  onCompleted?: () => void;
  onError?: () => void;
}

export type SaveTheDateHandlers = {
  toggleIsNavBarOpen: () => void;
  toggleIsAdjustingPosition: () => void;
  toggleEditPanel: () => void;
  sendEmail: (payload: HandleSendEmailArgs) => void;
  saveEcard: (ecardInput: ECardInputNoEventId, photo?: { id: string; url: string; assetId: string; width: number; height: number }) => Promise<string | undefined>;
  saveEmail: (emailInput: ElectronicCardEmailDraftInput) => void;
  copyShareableLink: () => Promise<void>;
};

export type ECardInputNoEventId = Omit<ElectronicCardInput, 'eventId'>;
export type ECardDraft = Omit<SaveTheDateElectronicCardFragment, 'eventId'>;

export interface SaveTheDateDesignInputFields
  extends Readonly<{
    coupleNameFormat: string;
    locationStringFormat: string;
    dateFormat: string;
    inviteToFollow: string;
    additionalLink1?: string;
    additionalLink2?: string;
    additionalMessage: string;
    displayEventLink: boolean;
    themeSelected: string;
    photoId?: string;
    photoAssetId?: string;
    photoUrl?: string;
    photoWidth?: number;
    photoHeight?: number;
    photoX: number | null;
    photoY: number | null;
    photoScale: number;
  }> {}

export interface SaveTheDateEmailInputFields
  extends Readonly<{
    subject: string;
    replyToEmail: string;
    headline: string;
    salutation: Maybe<string>;
    includeGuestNames: boolean;
    message: Maybe<string>;
    testName?: Maybe<string>;
    testEmail?: Maybe<string>;
    imageUrl?: Maybe<string>;
    subheadline: Maybe<string>;
    includeEventDate: boolean;
    includeAddToCalendarLink: boolean;
    buttonText: Maybe<string>;
  }> {}

export enum SaveTheDateEditorType {
  EMAIL = 'email',
  DESIGN = 'design',
  CONTENT = 'content'
}

export type LinkLabel = 'date' | 'location' | 'url' | 'name';

export enum LocationFormat {
  CITY_STATE_COUNTRY = 'CITY, STATE, COUNTRY',
  CITY_STATE = 'CITY, STATE',
  CITY_COUNTRY = 'CITY, COUNTRY'
}

export enum NameFormat {
  FIRST = 'FIRST_NAME',
  FULL = 'FULL_NAME'
}

export interface SaveTheDateExtraData
  extends Readonly<{
    hasInitializedOnce: boolean;
    eventId: string;
    eventHandle: string;
    eventDisplayName: string;
    eventById?: SaveTheDateEventByIdDataFragment | SaveTheDateGuestEventByIdDataFragment | null;
    eventPassword: Maybe<string>;
    privacyMode?: PrivacyModeType;
    guidId: string;
    website?: string;
    domainName?: string;
    date: string;
    location: string;
    accentId: string;
    linkOptions?: ElectronicCardCardLinkOptionFragment[];
  }> {}
