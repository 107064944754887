import { styled } from '@withjoy/joykit';
import { Box } from '../Box';
import { IconSize, IconSizeScale, IconSizeGrid } from './Icon.types';

export const iconScaleToSize: Record<IconSizeScale, IconSizeGrid> = {
  sm: 16,
  md: 24,
  lg: 32,
  xl: 48,
  xxl: 64
};

export const StyledSvg = styled(Box)<{ iconSize: IconSize | IconSizeScale | number }>`
  flex-shrink: 0;
  backface-visibility: hidden;
  display: inline-block;
  vertical-align: middle;
  ${({ iconSize }) => {
    const size = `${typeof iconSize === 'number' ? iconSize : iconScaleToSize[iconSize]}px`;
    return {
      height: size,
      width: size
    };
  }}
`;
