const NAMESPACE = '[JoyKit]';

export const COLUMNS_WARN_INVALID_CHILDREN = `${NAMESPACE} <Columns /> children must be <Columns.Column />.`;
export const BUTTON_GROUP_WARN_INVALID_CHILDREN = `${NAMESPACE} <ButtonGroup /> children must be <ButtonGroup.Button />.`;
export const TABS_WARN_CONTROLLED_REQUIREMENTS = `${NAMESPACE} <Tabs /> requires an onChange handler if 'selectedTabId' is provided.`;

// Nav
export const NAV_NAVITEM_WARN_MISSING_HREF = `${NAMESPACE} <NavItem /> is missing a href prop.`;

// Card
export const CARD_WARN_INTERACTIVE_MISSING_A11YLABEL = `${NAMESPACE} <Card /> requires an 'a11yLabel' if 'onClick()' is provided.`;

// Message Card
export const MESSAGE_CARD_WARN_MISSING_ON_CLOSE = `${NAMESPACE} <MessageCard /> requires an 'onClick' handler if 'duration' or 'closeIcon' is provided.`;

// Overlay
export const OVERLAY_PROVIDER_WARN_KEY_DOES_NOT_EXIST = (key: string) => `${NAMESPACE} Attempting to show Overlay with key: ${key} but key does not exist. This is a no-op.`;

export const OVERLAY_PROVIDER_WARN_REGISTER_CONTAINS_KEY = (key: string) => {
  return `${NAMESPACE} Attempting to register an existing Overlay key: ${key}. Overlay props will be merged.`;
};

export const OVERLAY_PROVIDER_WARN_UNREGISTER_INVALID_KEY = (key: string) => {
  return `${NAMESPACE} Attempting to unregister an invalid Overlay key: ${key}. This is a no-op.`;
};

export const CAROUSEL_WARN_MIXED_USAGE = `${NAMESPACE} <Carousel /> is mixing controlled and uncontrolled usage. Provide either 'activeSlideIndex' or 'defaultActiveSlideIndex' but not both.`;
export const CAROUSEL_WARN_CONTROLLED_USAGE_MISSING_HANDLER = `${NAMESPACE} <Carousel /> requires an onChange handler if activeSlideIndex is provided.`;
export const CAROUSEL_WARN_MISSING_SLIDE_TITLE = `${NAMESPACE} <Carousel /> requires a title for each slide for proper accessibility.`;

export const POPOVER_REQUIRES_TARGET = `${NAMESPACE} <Popover /> requires a target - it can be provided as the first child element.`;

export const SELECT_CONTROLLED_INPUT_REQUIRES_ON_CHANGE = `${NAMESPACE} <Select /> requires an onInputChange handler when a searchQuery is provided in order to have a controlled input.`;
export const SELECT_CONTROLLED_VALUE_REQUIRES_ON_CHANGE = `${NAMESPACE} <Select /> requires an onChange handler when "value" is provided.`;

// Focusbox
export const FOCUS_BOX_WARN_MIXED_USAGE = `${NAMESPACE} <Focusbox/> is mixing controleld and uncontrolled usage. Provide either 'focused' or 'defaultIsFocused' but not both.`;
