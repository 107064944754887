import React, { useEffect, useState } from 'react';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
class ModalManager<T = any> {
  private modals: Array<React.RefObject<T>>;
  private onUpdateCallbacks: Set<() => void>;

  constructor() {
    this.modals = [];
    this.onUpdateCallbacks = new Set();
  }

  add(modal: React.RefObject<T>) {
    this.modals.push(modal);
    this.notifyOnUpdate();
  }

  remove(modal: React.RefObject<T>) {
    this.modals = this.modals.filter(storedModal => storedModal !== modal);
    this.notifyOnUpdate();
  }

  isTopModal(modal: React.RefObject<T>) {
    return this.modals.length > 0 && this.modals[this.modals.length - 1] === modal;
  }

  hasActiveModals() {
    return !!this.modals.length;
  }

  onModalUpdate(callback: () => void): () => void {
    this.onUpdateCallbacks.add(callback);

    return () => {
      this.onUpdateCallbacks.delete(callback);
    };
  }

  private notifyOnUpdate() {
    this.onUpdateCallbacks.forEach(fn => fn());
  }
}

/**
 * Global modal manager
 */
export const modalManager = new ModalManager();

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useModalManager = (modalRef: React.RefObject<any>, isOpen: boolean) => {
  useEffect(() => {
    if (isOpen) {
      modalManager.add(modalRef);
    }

    return () => {
      modalManager.remove(modalRef);
    };
  }, [isOpen, modalRef]);
};

export const useHasActiveModals = (): boolean => {
  const [hasActiveModals, setHasActiveModals] = useState(modalManager.hasActiveModals());

  useEffect(() => {
    const removeOnModalUpdateCallback = modalManager.onModalUpdate(() => {
      setHasActiveModals(modalManager.hasActiveModals());
    });

    return removeOnModalUpdateCallback;
  }, []);

  return hasActiveModals;
};
