import { IconConfig } from '..';

/* tslint:disable:max-line-length */
export const label: IconConfig = {
  name: 'label',
  a11yLabel: 'Label',
  viewBox: 24,
  fill: '#5BA4E5',
  body: `
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M23.8691 6.57298L23.6406 3.45708C23.5238 1.8678 22.1337 0.477749 20.5441 0.360885L17.4574 0.132437L12.9198 0.120117C12.0289 0.120117 10.8835 0.594613 10.2537 1.22293L0.916219 10.5604C-0.145413 11.6221 -0.145413 13.349 0.916219 14.4092L9.59091 23.0839C10.1038 23.5982 10.7874 23.8801 11.5146 23.8801C12.2422 23.8801 12.9254 23.5982 13.4397 23.0839L22.7758 13.7464C23.3974 13.1248 23.8814 11.9519 23.88 11.0747L23.8691 6.57298ZM21.8602 12.8305L12.5224 22.1683C12.2545 22.4362 11.8969 22.5833 11.5146 22.5833C11.1323 22.5833 10.7736 22.4362 10.5068 22.1683L1.83177 13.4933C1.5639 13.2265 1.41676 12.8678 1.41676 12.4855C1.41676 12.1033 1.5639 11.7442 1.83177 11.4777L11.1696 2.13989C11.5477 1.76185 12.3809 1.41689 12.9184 1.41689V1.19548L12.9212 1.41548L17.3831 1.42639L20.4466 1.65202C21.4065 1.72348 22.2752 2.59221 22.3456 3.55212L22.5709 6.59762L22.5818 11.0775C22.5846 11.615 22.2397 12.451 21.8602 12.8305Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.4846 2.86987C17.7777 2.86987 17.1149 3.14514 16.6158 3.64427C16.1152 4.14341 15.8403 4.80728 15.8403 5.5141C15.8403 6.22091 16.1152 6.88514 16.6144 7.38427C17.1135 7.88341 17.7777 8.15832 18.4831 8.15832C19.19 8.15832 19.8538 7.88341 20.3544 7.38286C21.3857 6.3515 21.3843 4.67423 20.353 3.64427C19.8552 3.14514 19.1914 2.86987 18.4846 2.86987ZM19.4402 6.46696C19.1843 6.72146 18.8461 6.86155 18.486 6.86155C18.1255 6.86155 17.7872 6.72146 17.5331 6.46696C17.2786 6.21282 17.1385 5.87455 17.1385 5.51269C17.1385 5.15259 17.2786 4.81432 17.5331 4.55842C17.7872 4.30427 18.1241 4.16383 18.4846 4.16383C18.8447 4.16383 19.1843 4.30427 19.4388 4.55842C19.964 5.08642 19.964 5.94178 19.4402 6.46696Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.5326 8.59822C11.5326 8.42504 11.465 8.26276 11.3428 8.14027C11.0982 7.89563 10.6705 7.89563 10.4269 8.14027L5.23633 13.3309C5.11418 13.4534 5.0466 13.6156 5.0466 13.7888C5.0466 13.962 5.11418 14.1243 5.23633 14.2468C5.35882 14.3689 5.5211 14.4365 5.69428 14.4365C5.86746 14.4365 6.02974 14.3689 6.15223 14.2468L11.3428 9.05617C11.465 8.93368 11.5326 8.7714 11.5326 8.59822Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.6614 11.9862C12.6614 11.813 12.5942 11.6507 12.4717 11.5282C12.227 11.2836 11.8008 11.2836 11.5558 11.5282L7.49405 15.5899C7.24096 15.843 7.24096 16.2542 7.49405 16.5058C7.61654 16.628 7.77881 16.6956 7.952 16.6956C8.12518 16.6956 8.28745 16.628 8.41136 16.5058L12.4717 12.4441C12.5942 12.3216 12.6614 12.1593 12.6614 11.9862Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.9424 12.6572L9.75317 17.8478C9.50008 18.0995 9.50008 18.512 9.75317 18.7637C9.87567 18.8858 10.0379 18.9534 10.2111 18.9534C10.3843 18.9534 10.5466 18.8858 10.6691 18.7637L15.8597 13.5745C16.1128 13.3214 16.1128 12.9103 15.8597 12.6586C15.615 12.4125 15.1873 12.4125 14.9424 12.6572Z"
    />
  `
};
