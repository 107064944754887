import type { InitOptions } from 'i18next';

/** 'en-us' */
export const DEFAULT_LANGUAGE = 'en';

export const getInitOptions = (overrides: Readonly<Partial<InitOptions>> = {}): InitOptions => ({
  // Default: 'all'
  // For now, we will only provide language and not region specific locale
  load: 'languageOnly',

  // Localization work will begin with charm (the guest site). We can avoid preloading
  // translations in prod for the rest of the app.
  // Default: false
  // preload: isDevelopment && ['en-US'],
  preload: [DEFAULT_LANGUAGE],
  // Default: 'dev'
  fallbackLng: DEFAULT_LANGUAGE,
  supportedLngs: [DEFAULT_LANGUAGE],

  returnEmptyString: false,
  // language will be lowercased EN --> en while leaving full locales like en-US
  cleanCode: true,
  // locale will be fully lowercased eg. en-US --> en-us

  interpolation: {
    escapeValue: false // not needed for react as it escapes by default
  },

  // This option is necessary to tell i18next to try loading missing resources via
  // i18next-xhr-backend, otherwise no calls will be made if resources are defined.
  partialBundledLanguages: true,

  react: {
    useSuspense: true,
    wait: !process?.release
  },
  postProcess: [`reactPostprocessor`],
  ...overrides
});

type ParsedFormat = {
  dateFormat: string[];
  dateInputMask: string[];
};

export const getDateFormatString = (locale: string) => {
  const formatObj = new Intl.DateTimeFormat(locale).formatToParts(new Date());
  const parsed = formatObj.reduce(
    (acc, obj) => {
      switch (obj.type) {
        case 'day':
          acc.dateFormat.push('dd');
          acc.dateInputMask.push('99');
          break;
        case 'month':
          acc.dateFormat.push('MM');
          acc.dateInputMask.push('99');
          break;
        case 'year':
          acc.dateFormat.push('yyyy');
          acc.dateInputMask.push('9999');
          break;
      }
      return acc;
    },
    { dateFormat: [], dateInputMask: [] } as ParsedFormat
  );
  return {
    dateFormat: parsed.dateFormat.join('/'),
    dateInputMask: parsed.dateInputMask.join('/')
  };
};
