import { ApolloLink, DocumentNode, HttpLink } from '@apollo/client';
import { CreateEventDocument } from '@graphql/generated';
import { requestCaptcha } from '@shared/components/WafCaptcha';
import { addAction } from '@shared/utils/logger';
import { withWindow } from '@shared/utils/withWindow';

const captchaEnforcedOperations: DocumentNode[] = [CreateEventDocument];

const forceEnableCaptcha = withWindow(global => new URLSearchParams(global.location.search.toLowerCase()).has('forcecaptcha'), false);
const captchaEnforcedOperationsSet: Set<string> = new Set(captchaEnforcedOperations.map(getDocumentNodeIdentifier));
const OPERATION_NAME_HEADER_KEY = 'joy-gql-operation-name';

export function createEnforceWafCaptchaLink(url: string) {
  const httpLinkWithWafFetch = withWindow<HttpLink | null>(global => {
    const customFetch: typeof global.fetch = async (requestInfo, init) => {
      const awsWafFetch = global.AwsWafIntegration?.fetch;
      const { operationName, headersWithoutOperationName } = extractOperationNameFromRequest(init);
      if (init?.headers && headersWithoutOperationName) {
        init.headers = headersWithoutOperationName;
      }

      if (!awsWafFetch) {
        wafCaptchaLinkLogger('wafCaptchaNotSupported', operationName);
        return global.fetch(requestInfo, init);
      }

      wafCaptchaLinkLogger('makingWafCaptchaRequest', operationName);
      const result = await awsWafFetch(requestInfo, init);

      // AWS WAF returns 405 if captcha is required
      // https://docs.aws.amazon.com/waf/latest/developerguide/waf-js-captcha-api-conditional.html
      if (result.status === 405 && result.headers.get('X-Amzn-Waf-Action') === 'captcha') {
        wafCaptchaLinkLogger('wafCaptchaRequired', operationName);
        try {
          await requestCaptcha();
        } catch (e) {
          wafCaptchaLinkLogger('wafCaptchaFailed', operationName, {
            error: e
          });
          throw new Error('WafCaptchaRequired: Captcha is required to access this resource.');
        }

        wafCaptchaLinkLogger('wafCaptchaCompleted', operationName);

        // If captcha is successfully completed, local captcha state is automatically updated, so retry the request
        return awsWafFetch(requestInfo, init);
      }

      return result;
    };

    return new HttpLink({
      uri: url,
      credentials: 'include',
      headers: forceEnableCaptcha
        ? {
            'joy-captcha-force-enable': '1'
          }
        : {},
      fetch: customFetch
    });
  }, null);

  return new ApolloLink((operation, forward) => {
    if (httpLinkWithWafFetch && captchaEnforcedOperationsSet.has(getDocumentNodeIdentifier(operation.query))) {
      // Add operation name to headers, so it's available to our custom fetch function
      operation.setContext({
        headers: {
          ...(operation.getContext().headers || {}),
          [OPERATION_NAME_HEADER_KEY]: operation.operationName
        }
      });
      return httpLinkWithWafFetch.request(operation);
    }

    return forward(operation);
  });
}

let counter = 0;
function getDocumentNodeIdentifier(document: DocumentNode): string {
  const loc = document.loc;
  let identifier = document.kind;

  if (loc && loc.source) {
    identifier += `|${loc.start}|${loc.end}|${loc.source.body}|${loc.source.name}`;
  } else {
    identifier += `|UnknownDocumentNode${counter++}`;
  }

  return identifier;
}

function wafCaptchaLinkLogger(message: string, operationName = 'unknown', details?: Record<string, unknown>) {
  addAction('WafCaptchaLink', {
    ...details,
    message,
    operationName
  });
}

function extractOperationNameFromRequest(requestInit?: RequestInit): { operationName: string; headersWithoutOperationName?: Headers } {
  let operationName: string | undefined | null;
  let headersWithoutOperationName: Headers | undefined;

  if (requestInit?.headers) {
    // TODO: our .browserslistrc is out of date, which is why we need to disable compat even though `Headers` is available
    // on all major browsers for several years. Remove below eslint rule once we update .browserslistrc.
    // eslint-disable-next-line compat/compat
    const headers = new Headers(requestInit.headers);
    operationName = headers.get(OPERATION_NAME_HEADER_KEY);

    // Remove the operation name header, so it's not sent to server
    headers.delete(OPERATION_NAME_HEADER_KEY);
    headersWithoutOperationName = headers;
  }

  return {
    operationName: operationName || 'Unknown',
    headersWithoutOperationName
  };
}
