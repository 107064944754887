import { EventInfo } from './EventInfo.types';
import { useInternalEventInfoContext } from './internalEventInfoContext';

/**
 * Returns information about the currently loaded event.
 */
export function useEventInfo(): EventInfo {
  const internalEventInfo = useInternalEventInfoContext();
  return internalEventInfo?.eventInfo ?? { loading: false };
}
