import globalWindow from '@shared/core/globals';

export const initIntercom = (appId: string) => {
  return new Promise((resolve, reject) => {
    const w = globalWindow;
    const ic = w.Intercom;
    if (typeof ic === 'function') {
      //@ts-ignore
      ic('reattach_activator');
      ic('update', { app_id: appId });
    } else {
      const d = document;
      const i = function () {
        //@ts-ignore
        /* eslint-disable */
        i.c(arguments);
      };
      //@ts-ignore
      i.q = [];
      //@ts-ignore
      i.c = function (args) {
        //@ts-ignore
        i.q.push(args);
      };
      //@ts-ignore
      w.Intercom = i;
      const l = function () {
        const s = d.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.src = 'https://widget.intercom.io/widget/' + appId;
        const x = d.getElementsByTagName('script')[0];
        // @ts-ignore
        x.parentNode.insertBefore(s, x);
        s.onload = () => {
          resolve('loaded intercom');
        };
      };
      if (document.readyState === 'complete') {
        l();
        //@ts-ignore
      } else if (w.attachEvent) {
        //@ts-ignore
        w.attachEvent('onload', l);
      } else {
        if (w.addEventListener) {
          w.addEventListener('load', l, false);
        }
      }
    }
  });
};
