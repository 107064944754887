import {
  // Props

  // Util
  get,
  compose,
  style,
  system,
  Config,

  // Style Functions
  borders,
  position,
  top,
  left,
  right,
  bottom,
  color,
  fontSize,
  size,
  display,
  width,
  height,
  overflow,
  textAlign
} from 'styled-system';
import { Theme } from '..';
import { DEFAULT_SPACE_SCALE } from './constants';

const isNumber = (n: unknown): n is number => typeof n === 'number' && !isNaN(n);
// const isDefined = (n: unknown) => n !== undefined && n !== null;

// ---------------------------------------------------
// Library overrides
// ---------------------------------------------------

/**
 * NOTE: @types/styled-system typing is incorrect for `LowLevelStylefunctionArguments`
 */
interface StyleFunctionArguments {
  prop: string;
  cssProperty?: string;
  key?: string;
  getter?: () => any;
  transformValue?: (n: string | number, scale: (string | number)[]) => any;
  scale?: Array<string | number>;
  alias?: string;
}

type StyleFunction = (...args: any[]) => any;

export type StyleFunctionFactory = (args: StyleFunctionArguments) => StyleFunction;

const _toPixel = (value: number) => `${value}px`;

const getSpace: any = (n: any, scale: Theme['space']) => {
  if (!isNumber(n)) {
    return get(scale, n, n);
  }
  const isNegative = n < 0;
  const absolute = Math.abs(n);
  const value = get(scale, absolute, absolute);
  if (!isNumber(value)) {
    return isNegative ? '-' + value : value;
  }

  return _toPixel(value * (isNegative ? -1 : 1));
};

const getWidth = (n: any, scale: any) => (!isNumber(n) || n > 1 ? _toPixel(n) : n * 100 + '%');

// ---------------------------

/**
 * Returns a collection of CSS declarations that have been adjusted for responsive styling - if applicable.
 *
 * For example: margin={[1, 2, 3]} will yield a group of styles that map the value from `theme.space[index]` to
 * `theme.breakpoints[index]`.
 */
export const styleFunction = (style as any) as StyleFunctionFactory;

// ---------------------------------------------------
// Borders
// ---------------------------------------------------
export const getStyledBorders = borders;

// ---------------------------------------------------
// Color
// ---------------------------------------------------

/**
 * Apply `bg` styling declarations
 *
 * The JoyKit styling system will look at `theme.colors` for the appropriate color.
 *
 * @example
 * getStyledColor('primary1') => theme.colors.primary1;
 */
export const getStyledBGColor = styleFunction({
  prop: 'backgroundColor'
});

/**
 * Apply `color` and `bg` styling declarations.
 *
 * The JoyKit styling system will look at `theme.colors` for the appropriate color.
 *
 * @example
 * getStyledColor('primary1') => theme.colors.primary1;
 */
export const getStyledColor = color;

// ---------------------------------------------------
// Flexbox
// ---------------------------------------------------

/**
 * Apply `alignItems` styling declarations.
 */
export const getStyledAlignItems = styleFunction({ prop: 'alignItems' });

/**
 * Apply `alignContent` styling declarations.
 */
export const getStyledAlignContent = styleFunction({ prop: 'alignContent' });

/**
 * Apply `justifyItems` styling declarations.
 */
export const getStyledJustifyItems = styleFunction({ prop: 'justifyItems' });

/**
 * Apply `justifyContent` styling declarations.
 */
export const getStyledJustifyContent = styleFunction({ prop: 'justifyContent' });

/**
 * Apply `flexWrap` styling declarations.
 */
export const getStyledFlexWrap = styleFunction({ prop: 'flexWrap' });

/**
 * Apply `flexBasis` styling declarations.
 */
export const getStyledFlexBasis = styleFunction({ prop: 'flexBasis', transformValue: getWidth });

/**
 * Apply `flexDireciton` styling declarations.
 */
export const getStyledFlexDirection = styleFunction({ prop: 'flexDirection' });

/**
 * Apply `styledFlex` styling declarations.
 */
export const getStyledFlex = styleFunction({ prop: 'flex' });

/**
 * Apply `position` styling declarations.
 */
export const getStyledJustifySelf = styleFunction({ prop: 'justifySelf' });
export const getStyledAlignSelf = styleFunction({ prop: 'alignSelf' });
export const getStyledOrder = styleFunction({ prop: 'order' });

// ---------------------------------------------------
// Layout
// ---------------------------------------------------

/**
 * Apply `display` styling declarations.
 */
export const getStyledDisplay = display;

/**
 * Apply `width` and `height` styling declarations.
 */
export const getStyledSize = size;

export const getStyledWidth = width;
export const getStyledHeight = height;

// ---------------------------------------------------
// Position
// ---------------------------------------------------

/**
 * Apply `position` styling declarations.
 */
export const getStyledPosition = position;

/**
 * Apply `top` styling declarations.
 *
 * Typically used in conjuction with the `position` prop.
 */
export const getStyledTop = top;

/**
 * Apply `left` styling declarations.
 *
 * Typically used in conjuction with the `position` prop.
 */
export const getStyledLeft = left;

/**
 * Apply `right` styling declarations.
 *
 * Typically used in conjuction with the `position` prop.
 */
export const getStyledRight = right;

/**
 * Apply `bottom` styling declarations.
 *
 * Typically used in conjuction with the `position` prop.
 */
export const getStyledBottom = bottom;

export const getStyledTransform = styleFunction({
  prop: 'transform'
});

// ---------------------------------------------------
// Space
// ---------------------------------------------------

// External

const spaceConfig: Record<'margin' | 'padding', Config> = {
  margin: {
    margin: {
      property: 'margin',
      scale: 'space',
      transform: getSpace,
      defaultScale: DEFAULT_SPACE_SCALE
    },
    marginTop: {
      property: 'marginTop',
      scale: 'space',
      transform: getSpace,
      defaultScale: DEFAULT_SPACE_SCALE
    },
    marginBottom: {
      property: 'marginBottom',
      scale: 'space',
      transform: getSpace,
      defaultScale: DEFAULT_SPACE_SCALE
    },
    marginLeft: {
      property: 'marginLeft',
      scale: 'space',
      transform: getSpace,
      defaultScale: DEFAULT_SPACE_SCALE
    },
    marginRight: {
      property: 'marginRight',
      scale: 'space',
      transform: getSpace,
      defaultScale: DEFAULT_SPACE_SCALE
    },
    marginY: {
      properties: ['marginTop', 'marginBottom'],
      scale: 'space',
      transform: getSpace,
      defaultScale: DEFAULT_SPACE_SCALE
    },
    marginX: {
      properties: ['marginLeft', 'marginRight'],
      scale: 'space',
      transform: getSpace,
      defaultScale: DEFAULT_SPACE_SCALE
    }
  },
  padding: {
    padding: {
      property: 'padding',
      scale: 'space',
      transform: getSpace,
      defaultScale: DEFAULT_SPACE_SCALE
    },
    paddingTop: {
      property: 'paddingTop',
      scale: 'space',
      transform: getSpace,
      defaultScale: DEFAULT_SPACE_SCALE
    },
    paddingBottom: {
      property: 'paddingBottom',
      scale: 'space',
      transform: getSpace,
      defaultScale: DEFAULT_SPACE_SCALE
    },
    paddingLeft: {
      property: 'paddingLeft',
      scale: 'space',
      transform: getSpace,
      defaultScale: DEFAULT_SPACE_SCALE
    },
    paddingRight: {
      property: 'paddingRight',
      scale: 'space',
      transform: getSpace,
      defaultScale: DEFAULT_SPACE_SCALE
    },
    paddingY: {
      properties: ['paddingTop', 'paddingBottom'],
      scale: 'space',
      transform: getSpace,
      defaultScale: DEFAULT_SPACE_SCALE
    },
    paddingX: {
      properties: ['paddingRight', 'paddingLeft'],
      scale: 'space',
      transform: getSpace,
      defaultScale: DEFAULT_SPACE_SCALE
    }
  }
};

export const getStyledMargin = styleFunction({
  prop: 'margin',
  key: 'space',
  transformValue: getSpace,
  scale: DEFAULT_SPACE_SCALE
});

export const marginSystem = system(spaceConfig.margin);
export const paddingSystem = system(spaceConfig.padding);
export const spaceSystem = compose(marginSystem, paddingSystem);

export const borderSidesRadiusSystem = system({
  borderLeftRadius: {
    properties: ['borderTopLeftRadius', 'borderBottomLeftRadius'],
    scale: 'radii'
  },
  borderTopRadius: {
    properties: ['borderTopLeftRadius', 'borderTopRightRadius'],
    scale: 'radii'
  },
  borderRightRadius: {
    properties: ['borderTopRightRadius', 'borderBottomRightRadius'],
    scale: 'radii'
  },
  borderBottomRadius: {
    properties: ['borderBottomRightRadius', 'borderBottomLeftRadius'],
    scale: 'radii'
  }
});

/**
 * Used to handle spacing between components
 */
export const getStyledLayoutExternalSpacing = marginSystem;

/**
 * Used to handle spacing between components
 */
export const getStyledLayoutInternalSpacing = paddingSystem;

// ---------------------------------------------------
// Additional
// ---------------------------------------------------

export const getStyledOverflow = overflow;

// ---------------------------------------------------
// Typography
// ---------------------------------------------------

export const getStyledFontSize = fontSize;

export const getStyledTextAlign = textAlign;

// ---------------------------------------------------
// Util
// ---------------------------------------------------

/**
 * A composed function that will process CSS Declarations passed via `BaseComponentProps`
 */
export const getStyledBaseComponentProps = compose(getStyledLayoutExternalSpacing, getStyledFlex, getStyledAlignSelf);
