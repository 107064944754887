import React, { useEffect } from 'react';
import { useFeatureValue } from '@shared/core/featureFlags';
import { getLocalStorage } from '@shared/core';

const ASYNC_APOLLO_AUTH_FEATURE_FLAG_KEY = 'isAsyncApolloAuthEnabled';

const localStorage = getLocalStorage();

export const setAsyncApolloAuthEnabled = (enabled: boolean) => {
  localStorage.setItem(ASYNC_APOLLO_AUTH_FEATURE_FLAG_KEY, enabled ? 'true' : 'false');
};

export const isAsyncApolloAuthEnabled = (): boolean => {
  return localStorage.getItem(ASYNC_APOLLO_AUTH_FEATURE_FLAG_KEY) === 'true';
};

export const ApolloFeatureFlag: React.FC = ({ children }) => {
  const { value: asyncApolloAuthEnabled, loading } = useFeatureValue('asyncApolloAuthEnabled');
  useEffect(() => {
    if (loading) {
      return;
    }
    setAsyncApolloAuthEnabled(Boolean(asyncApolloAuthEnabled));
  }, [asyncApolloAuthEnabled, loading]);

  return <>{children}</>;
};
