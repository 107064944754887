import { TrackProps, PageProps } from '../context';
import { TrackFunctionMap, PageFunctionMap, TelemetryObjectArgs } from './types';

export const wrapActionsInAnalytics = <A extends TrackFunctionMap, C = undefined>(
  track: (payload: TrackProps) => void,
  actions: A,
  context?: C,
  enrichedExtraInfo?: Record<string, unknown>
) => {
  return Object.keys(actions).reduce((acc: A, curr: keyof A) => {
    return {
      ...acc,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      [curr]: (...args: any[]) => {
        const payload = actions[curr](...args);
        track({ ...payload, extraInfo: { ...payload.extraInfo, ...enrichedExtraInfo }, context });
      }
    };
  }, {} as A);
};

type PagesEnter<P extends PageFunctionMap> = Readonly<
  {
    [Key in keyof P]: Readonly<{
      enter: P[Key];
    }>;
  }
>;

export const wrapPagesInAnalytics = <P extends PageFunctionMap, C>(page: (payload: PageProps) => void, pages?: P, context?: C, enrichedExtraInfo?: Record<string, unknown>) => {
  if (pages) {
    return Object.keys(pages).reduce((acc: PagesEnter<P>, curr) => {
      return {
        ...acc,
        [curr]: {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          enter: (...args: any[]) => {
            const payload = pages[curr](...args);
            page({ ...payload, extraPageInfo: { ...payload.extraPageInfo, ...enrichedExtraInfo }, context, action: 'enter' });
          }
        }
      };
    }, {} as PagesEnter<P>);
  }
  return {} as PagesEnter<P>;
};

export const createTelemetryObject = <A extends TrackFunctionMap, P extends PageFunctionMap>(config: TelemetryObjectArgs<A, P>) => config;
