import { IconConfig } from '..';

/* tslint:disable:max-line-length */

export const add: IconConfig = {
  name: 'add',
  a11yLabel: 'Add',
  viewBox: 24,
  fill: '#333333',
  body: `
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M22.0833 11.0742H12.9167V1.91667C12.9167 1.41008 12.5066 1 12 1C11.4934 1 11.0833 1.41008 11.0833 1.91667V11.0742H1.91667C1.41008 11.0742 1 11.4934 1 12C1 12.5066 1.41008 12.9076 1.91667 12.9076H11.0833V22.0833C11.0833 22.5899 11.4934 23 12 23C12.5066 23 12.9167 22.5899 12.9167 22.0833V12.9076H22.0833C22.5899 12.9076 23 12.5066 23 12C23 11.4934 22.5899 11.0742 22.0833 11.0742Z"
  />
  `
};
