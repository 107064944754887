import React, { useEffect, useState } from 'react';
import { useGetEventByEventIdForEventRedirectQuery, useAuthenticateRegistryReservationMutation } from '@graphql/generated';
import { StaticRouterProps, RouteComponentProps } from '@react-router';
import { deriveQueryStringHelpersFromLocation } from '@shared/core/queryString';
import { JoyLogoLoader } from '../JoyLogoLoader';
import { withWindow } from '@shared/utils/withWindow';
import { Box, Flex } from '@withjoy/joykit';
import { ReactComponent as SurprisedCakeSvg } from '@assets/icons/spot-art-cake-surprised.svg';
import { useTranslation } from '@shared/core';
import { createTelemetryObject, createTelemetry } from '@shared/core/analytics';
import { ApolloError } from '@apollo/client';
import { config } from '@static/js/env.config';

type EventRedirectProps = Readonly<
  {
    eventId: string;
    staticContext: StaticRouterProps['context'];
    onNoMatch?: (args: { eventId: string }) => void;
  } & Pick<RouteComponentProps, 'location'>
>;

export const REGISTRY_PATH = '/registry';

const telemObject = createTelemetryObject({
  actions: {
    eventNotFoundError: (eventId: string, destination: string) => ({
      category: 'event.redirect',
      actionType: 'error',
      action: 'EventNotFound',
      label: eventId,
      extraInfo: {
        destination
      }
    }),
    findEventGqlError: (eventId: string, destination: string, error: ApolloError) => ({
      category: 'event.redirect',
      actionType: 'error',
      action: 'EventNotFound',
      label: eventId,
      extraInfo: {
        destination,
        error
      }
    }),
    authenticateReservationError: (eventId: string, authenticateUrl: string) => ({
      category: 'event.redirect',
      actionType: 'error',
      action: 'AuthenticateRegistryResultError',
      label: eventId,
      extraInfo: {
        authenticateUrl
      }
    })
  }
});

const { TelemetryProvider, useTelemetry } = createTelemetry(telemObject);

const ErrorScreen: React.FC<{ hed: string; dek: string }> = ({ hed, dek }) => {
  return (
    <Flex flexDirection="column" alignItems="center" marginTop="80px">
      <Flex flexDirection="column" width={320} maxWidth="100%" alignItems="center" textAlign="center">
        <SurprisedCakeSvg />
        <Box fontFamily="'Inter UI'" fontSize="32px" fontWeight="bold" lineHeight={1.3125} marginTop={8}>
          {hed}
        </Box>
        <Box fontFamily="'Inter UI'" fontSize="17px" lineHeight={1.588} marginTop={6} width="90%">
          {dek}
        </Box>
      </Flex>
    </Flex>
  );
};

const Page = (props: EventRedirectProps) => {
  const { eventId, staticContext, location, onNoMatch } = props;
  const [authenticateRegistryReservation, authenticateRegistryResult] = useAuthenticateRegistryReservationMutation();
  const { data, error } = useGetEventByEventIdForEventRedirectQuery({ variables: { id: eventId }, batchMode: 'fast' });
  const telemetry = useTelemetry();
  const { t2 } = useTranslation('eventSite');
  const { getValueString } = deriveQueryStringHelpersFromLocation(location);
  const destination = getValueString('destination') || '';
  const authenticateUrl = config.clientUri + `${location.pathname}${location.search}` || '';
  const shouldCallAuthenticateRegistry = destination.startsWith(REGISTRY_PATH) && location.search.includes('token=');
  const [isReadyToCheck, setReadyToCheck] = useState<boolean>(false);

  const nextPath = `/${data?.eventById?.website}${destination}`;
  const performRedirect = (path: string) => {
    if (staticContext) {
      staticContext.url = path;
      staticContext.statusCode = 302;
    } else {
      // Is client-side rendered.
      withWindow(global => {
        global.location.href = global.location.origin + nextPath;
      });
    }
  };
  if (!shouldCallAuthenticateRegistry && staticContext) {
    performRedirect(nextPath);
  }

  useEffect(() => {
    if (shouldCallAuthenticateRegistry) {
      authenticateRegistryReservation({
        variables: {
          payload: {
            url: authenticateUrl
          }
        }
      });
    } else {
      setReadyToCheck(true);
    }
  }, [authenticateRegistryReservation, authenticateUrl, shouldCallAuthenticateRegistry]);

  useEffect(() => {
    if (authenticateRegistryResult.error) {
      telemetry.authenticateReservationError(eventId, authenticateUrl);
      setReadyToCheck(true);
      // If there is an error in it, it returns nothing here so that the current flow is not broken.
    }
    if (authenticateRegistryResult.called && !authenticateRegistryResult.loading && !authenticateRegistryResult.error) {
      setReadyToCheck(true);
    }
  }, [authenticateRegistryResult, authenticateUrl, eventId, telemetry]);

  let content = <JoyLogoLoader loaderKey="event-redirect" />;
  if (isReadyToCheck) {
    if (data) {
      // Query was successful
      const eventHandle = data.eventById?.website;
      if (eventHandle) {
        performRedirect(nextPath);
      } else {
        // Event could not be found
        onNoMatch?.({ eventId });
        telemetry.eventNotFoundError(eventId, destination);
        const translations = t2('eventRedirect', 'eventNotFoundError');
        content = <ErrorScreen {...translations} />;
      }
    } else if (error) {
      // The default apollo error policy is if the GQL response includes errors, they are returned on
      // error.graphqlErrors and the response `data` is set to undefined even if the server returns `data`
      // in its response.
      telemetry.findEventGqlError(eventId, destination, error);
      const translations = t2('eventRedirect', 'graphqlError');
      content = <ErrorScreen {...translations} />;
    }
  }

  return content;
};

export const EventRedirect = (props: EventRedirectProps) => {
  return (
    <TelemetryProvider>
      <Page {...props} />
    </TelemetryProvider>
  );
};
