import React, { useEffect, useState } from 'react';
import { useIsomorphicLayoutEffect } from '@shared/utils/hooks/useIsomorphicLayoutEffect';

export interface NoSsrProps {
  children: React.ReactNode;

  /**
   * Whether rendering children should be deferred into a different screen frame.
   */
  defer?: boolean;

  /**
   * A fallback component to be rendered by the server.
   */
  fallback?: React.ReactElement<unknown>;
}

/**
 * NoSsr wraps components that should not be rendered by the server when Server Side Rendering.
 */
const NoSsr: React.FC<NoSsrProps> = ({ children, defer, fallback = null }) => {
  const [isMounted, setIsMounted] = useState<boolean>(false);

  useIsomorphicLayoutEffect(() => {
    if (!defer) {
      setIsMounted(true);
    }
  }, [defer]);

  useEffect(() => {
    if (defer) {
      setIsMounted(true);
    }
  }, [defer]);
  return <React.Fragment>{isMounted ? children : fallback}</React.Fragment>;
};

NoSsr.displayName = 'NoSsr';

export { NoSsr };
