import React from 'react';
import { getComponentDisplayName } from './getComponentDisplayName';

export const DISPLAYNAME_PREFIX = 'JoyKit';
/**
 * Generate a  `JoyKit` prefixed Component display name.
 *
 * This will add distinction to our UI kit when inspected and to reduce namespace collision.
 * For example, when inspected via React DevTools.
 * @param target The component to be inspected.
 *
 * @example
 * generateComponentDisplayName(Button) => `JoyKit.Button`
 * generateComponentDisplayName('Button') => `JoyKit.Button`
 */
export const generateComponentDisplayName = (target: React.ComponentType<any> | string) => {
  const displayName = typeof target === 'string' ? target : getComponentDisplayName(target);
  // if (process.env.TARGET === 'dist') {
  return `${DISPLAYNAME_PREFIX}.${displayName}`;
  // }
  return displayName;
};
