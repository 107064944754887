import { useContext, useRef } from 'react';
import { AnalyticsContext, SegmentGroupTraitsProps } from './context';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';
import { isEqual } from 'lodash-es';
import { useMount } from '@shared/utils/hooks/useMount';

/**
 * https://segment.com/docs/connections/spec/group/
 *
 * This hook returns a function that will make a segment `group` API call and it will do two things:
 * 1. Assign the event id as the group id
 * 2. Name the group `JoyEvent`
 */
export const useSegmentGroupCallForEvent = () => {
  const context = useContext(AnalyticsContext);

  useMount(() => {
    return () => {
      // Reset the group id when the component unmounts
      context.group({ groupId: null });
    };
  });

  const hasCalledGroupOnceRef = useRef(false);
  const prevEventIdRef = useRef<string | null>(null);
  const prevTraitsRef = useRef<SegmentGroupTraitsProps | null>(null);
  const executeSegmentGroupCallForEvent = useEventCallback((firebaseEventId: Maybe<string>, traits: SegmentGroupTraitsProps) => {
    if (firebaseEventId && (!hasCalledGroupOnceRef.current || prevEventIdRef.current !== firebaseEventId || !isEqual(prevTraitsRef.current, traits))) {
      context.group({ groupId: firebaseEventId, traits });
      hasCalledGroupOnceRef.current = true;
      prevEventIdRef.current = firebaseEventId;
      prevTraitsRef.current = traits;
    }
  });

  return {
    executeSegmentGroupCallForEvent
  };
};
