import { useContext, useEffect, useMemo, useRef } from 'react';

import { Experiment, ExperimentClient, ExperimentUser, Exposure } from '@amplitude/experiment-js-client';
import { getConfig } from '@config';
import { AnalyticsContext } from '@shared/core/analytics';

import { RemoteFeatureFlagService } from './remoteFeatureFlags.types';

const config = getConfig();

export function useAmplitudeExperiment(): RemoteFeatureFlagService {
  const exposureTrackFunction = useRef<(exposure: Exposure) => void>();

  const analytics = useContext(AnalyticsContext);

  useEffect(() => {
    exposureTrackFunction.current = exposure => {
      analytics.track({
        category: '$exposure',
        action: '$exposure',
        extraInfo: exposure
      });
    };
  }, [analytics]);

  const experimentSdk = useMemo<ExperimentClient>(() => {
    /**
     * For now, SSR is handled by fetching the experiments on the server so the app can render, but data is not handed off
     * to the client. This means that the first time the app loads on the client, it will fetch the experiments again.
     *
     * TODO: add the mechanism to hand off SSR data to the client, so we don't have to fetch again
     * https://www.docs.developers.amplitude.com/experiment/guides/server-side-rendering
     */
    let initializeExperimentClient: typeof Experiment.initialize;
    if (typeof window === 'undefined') {
      initializeExperimentClient = require('@amplitude/experiment-node-server').Experiment.initialize;
    } else {
      initializeExperimentClient = Experiment.initialize;
    }

    return initializeExperimentClient(config.amplitudeExperimentApiKey, {
      serverUrl: config.amplitudeExperimentServer,
      flagsServerUrl: config.amplitudeExperimentFlagsServer,
      exposureTrackingProvider: {
        track: exposure => {
          exposureTrackFunction.current?.(exposure);
        }
      },
      automaticExposureTracking: false,
      fetchOnStart: true,
      pollOnStart: false
    });
  }, []);

  const isFirstSdkLoad = useRef(true);

  const amplitudeExperimentService = useMemo<RemoteFeatureFlagService>(() => {
    return {
      fetchUser: async ({ userId, deviceId, eventFirebaseId, userProperties }) => {
        const experimentUser: ExperimentUser = {
          user_id: userId,
          device_id: deviceId,
          groups: {
            JoyEvent: eventFirebaseId ? [eventFirebaseId] : []
          },
          user_properties: userProperties
        };

        let loadPromise: Promise<unknown>;

        if (isFirstSdkLoad.current) {
          isFirstSdkLoad.current = false;
          loadPromise = experimentSdk.start.call(experimentSdk, experimentUser);
        } else {
          loadPromise = experimentSdk.fetch.call(experimentSdk, experimentUser);
        }

        await loadPromise;
      },
      getFeatureValue: (key: string) => {
        return experimentSdk.variant(key.toLowerCase());
      },
      trackExposure: (key: string) => {
        experimentSdk.exposure(key.toLowerCase());
      }
    };
  }, [experimentSdk]);

  return amplitudeExperimentService;
}
