import { IconConfig } from '..';

/* tslint:disable:max-line-length */
export const trash: IconConfig = {
  name: 'trash',
  a11yLabel: 'Trash',
  viewBox: 24,
  fill: '#333333',
  body: `
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20 6H4V19C4 20.6569 5.34315 22 7 22H17C18.6569 22 20 20.6569 20 19V6ZM5.25 19V7.25H18.75V19C18.75 19.9665 17.9665 20.75 17 20.75H7C6.0335 20.75 5.25 19.9665 5.25 19Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.5 1.37305C7.77301 1.37305 6.373 2.77305 6.373 4.50005V5.87305H2.5V7.12705H21.5V5.87305H17.627V4.50005C17.627 2.77305 16.227 1.37305 14.5 1.37305H9.5ZM16.373 5.87305H7.627V4.50005C7.627 3.46562 8.46557 2.62705 9.5 2.62705H14.5C15.5344 2.62705 16.373 3.46562 16.373 4.50005V5.87305Z"
    />
    <path
      d="M11.3737 9.52466C11.3737 9.17948 11.6536 8.89966 11.9987 8.89966C12.3439 8.89966 12.6237 9.17948 12.6237 9.52466V18.4365C12.6237 18.7817 12.3439 19.0615 11.9987 19.0615C11.6536 19.0615 11.3737 18.7817 11.3737 18.4365V9.52466Z"
    />
    <path
      d="M7.37375 9.52466C7.37375 9.17948 7.65357 8.89966 7.99875 8.89966C8.34393 8.89966 8.62375 9.17948 8.62375 9.52466V18.4365C8.62375 18.7817 8.34393 19.0615 7.99875 19.0615C7.65357 19.0615 7.37375 18.7817 7.37375 18.4365V9.52466Z"
    />
    <path
      d="M15.3737 9.52466C15.3737 9.17948 15.6536 8.89966 15.9987 8.89966C16.3439 8.89966 16.6237 9.17948 16.6237 9.52466V18.4365C16.6237 18.7817 16.3439 19.0615 15.9987 19.0615C15.6536 19.0615 15.3737 18.7817 15.3737 18.4365V9.52466Z"
    />
  `
};
