import React from 'react';
import { IconV2Props, IconSizeGrid, IconSize } from '@withjoy/joykit/components/IconV2/Icon.types';
import { IconV2 } from '@withjoy/joykit/components/IconV2/Icon';
import { SvgIconConfig } from './types';
import { iconScaleToSize } from '../components/IconV2/Icon.styles';

type GetCloseDefinitionReturn = {
  viewbox: string;
  path?: JSX.Element;
};

const SIZES: IconSizeGrid[] = Object.values({
  16: 16,
  24: 24,
  32: 32,
  48: 48,
  64: 64
});
const SIZES_DESC = SIZES.slice().reverse();
const DEFAULT_VIEWBOX = '0 0 24 24';

const _getBaseSizeIndex = (size: number) => {
  const index = SIZES_DESC.findIndex(value => size >= value);
  return SIZES.length - 1 - index;
};

const _getClosestDefinition = (size: number, definitions: SvgIconConfig['definitions']): GetCloseDefinitionReturn => {
  const baseSizeIndex = _getBaseSizeIndex(size);
  const result: GetCloseDefinitionReturn = {
    viewbox: DEFAULT_VIEWBOX
  };

  let step = 0;
  while (step < SIZES.length) {
    const nextIndex = baseSizeIndex - step;
    // Step down from base index, then step up from base index
    const iconSize = SIZES[nextIndex >= 0 ? nextIndex : baseSizeIndex - nextIndex];
    const def = definitions[iconSize];
    if (def) {
      result.path = def.path;
      result.viewbox = def.viewBox || `0 0 ${iconSize} ${iconSize}`;
      break;
    }
    step++;
  }
  return result;
};

const getSizeValue = (size: IconSize | number) => {
  return typeof size === 'number' ? size : iconScaleToSize[size];
};

const createSvgIcon = (config: SvgIconConfig) => {
  const Component: React.ForwardRefRenderFunction<SVGSVGElement, IconV2Props> = (props, ref) => {
    const { size = 'md', ...restProps } = props;
    const result = _getClosestDefinition(getSizeValue(size), config.definitions);
    return (
      <IconV2 viewBox={result.viewbox} ref={ref} size={size} title={config.title} {...restProps}>
        {result.path}
      </IconV2>
    );
  };

  return React.memo(React.forwardRef<SVGSVGElement, IconV2Props>(Component));
};

export default createSvgIcon;
