import { withWindow } from '@shared/utils/withWindow';
import React, { useMemo } from 'react';
import { IntercomContext } from './context';
import { IntercomService } from './service';

export const IntercomProvider: React.FC<{ intercom: IntercomService }> = ({ intercom, children }) => {
  const value = useMemo(() => {
    return {
      show: () => {
        intercom.show();
      },
      hide: () => {
        intercom.hide();
      },
      boot: () => {
        return new Promise<void>(res => {
          withWindow(window => {
            // eslint-disable-next-line compat/compat
            window.requestIdleCallback(() => {
              intercom.boot().then(res);
            });
          });
        });
      },
      shutdown: () => {
        intercom.shutdown();
      },
      showLauncher: () => {
        intercom.showLauncher();
      },
      hideLauncher: () => {
        intercom.hideLauncher();
      },
      startSurvey: (id: string) => {
        intercom.startSurvey(id);
      }
    };
  }, [intercom]);

  return <IntercomContext.Provider value={value}>{children}</IntercomContext.Provider>;
};
