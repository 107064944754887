class Anatomy<T extends string = string> {
  // private partKeys: Array<T> = [];

  private partSet: Set<T> = new Set<T>();

  constructor(readonly name: T) {}

  /**
   * Add the core parts of the components
   */
  public parts = <Part extends string>(...parts: Part[]) => {
    parts.forEach(part => {
      ((this.partSet as unknown) as Set<Part>).add(part);
    });

    return (this as unknown) as Omit<Anatomy<Part>, 'parts'>;
  };

  /**
   * Extend the component anatomy to includes new parts
   */
  public extend = <U extends string>(...parts: U[]) => {
    const partSet = this.partSet as Set<T | U>;

    parts.forEach(part => {
      if (!partSet.has(part)) {
        partSet.add(part);
      }
    });
    // for (const part of parts) {
    //   if (part in this.partKeys) continue;
    //   this.partKeys.push(part as any);
    // }
    return (this as unknown) as Omit<Anatomy<T | U>, 'parts'>;
  };

  get keys() {
    return Array.from(this.partSet.keys());
  }

  /**
   * Used to get the derived type of the anatomy
   */
  __type = {} as T;
}

export const anatomy = (name: string) => new Anatomy(name);
