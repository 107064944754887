import type { BreakpointWidthsEm, Breakpoints, BreakpointPx, BreakpointAliasKey, BreakpointAliases, BreakpointWidthsPx } from '@withjoy/joykit/theme';
import { pxToRelativeUnit } from './typography';

/**
 * For predictable cross-browser behavior, `JoyKit` use `em` in media queries. It avoids the problem
 * with `rem` in desktop Safarai, but otherwise they're interchangeable.
 *
 * **Note**: When `em`/`rem` bases are used in media queries, the browser scales the value in accordance
 * to the browser font setting.
 */
const pxToEm = (value: number): string => pxToRelativeUnit(value, 'em');

export const defaultBreakpointWidths: Readonly<BreakpointWidthsPx> = [480, 768, 1024, 1440, 1920];

// Material breakpoint definitions - https://material.io/resources/resizer/#device=window&width=1600
// Joy revised breakpoint definitions - https://www.figma.com/file/C99kEDHOlm5haFsKOZOMV5TO/%F0%9F%92%8D-JoyKit-Core-formerly-Admin?node-id=8418%3A6
export const breakpointAliasPx: ReadonlyRecord<BreakpointAliasKey, BreakpointPx> = {
  xxs: 375,
  xs: 480,
  sm: 640,
  sm2: 768,
  sm3: 840,
  sm4: 960,
  md: 1024,
  md2: 1152,
  md3: 1280,
  md4: 1366,
  lg: 1440,
  lg1: 1680,
  xl: 1920,
  xxl: 2560
};

export const breakpointAliasKeys = Object.keys(breakpointAliasPx) as ReadonlyArray<BreakpointAliasKey>;
export const breakpointAliases: BreakpointAliases = (Object.keys(breakpointAliasPx) as BreakpointAliasKey[]).reduce((acc, key) => {
  acc[key] = pxToEm(breakpointAliasPx[key]);
  return acc;
}, {} as UnsealedReadonly<BreakpointAliases>);

export const breakpointsPx: ReadonlyArray<BreakpointPx> = Object.values(breakpointAliasPx);

/**
 * There are components that utilize every breakpoint in addition to the ones used in media queries.
 *
 * For example, `<Columns />` uses each available breakpoint to establish "snap points" (or max-width).
 */
export const allBreakpoints = breakpointsPx.map(pxToEm);

/**
 * Create breakpoints relative to the root font size.
 *
 * Takes in a list of indexes, each number correlates to a value in `breakpointsPx`.
 */
export const createBreakpoints = (breakpointWidths: Readonly<BreakpointWidthsPx>): Breakpoints => {
  const selectedBreakpoints: BreakpointWidthsEm = breakpointWidths.map(value => pxToEm(value)) as Breakpoints;

  // https://material.io/design/layout/responsive-layout-grid.html#breakpoints
  return Object.assign([], selectedBreakpoints, breakpointAliases);
};
