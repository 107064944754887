import { IconConfig } from '..';

/* tslint:disable:max-line-length */
export const buy: IconConfig = {
  name: 'buy',
  a11yLabel: 'Buy',
  fill: '#94D0A7',
  viewBox: 24,
  body:
    '<path fill-rule="evenodd" clip-rule="evenodd" d="M1 12C1 5.93286 5.93286 1 12 1C18.0671 1 23 5.93286 23 12C23 18.0671 18.0671 23 12 23C5.93286 23 1 18.0671 1 12ZM14.0607 16.3078L14.1303 16.2381L14.1303 16.2381C14.7789 15.5895 15.1028 14.7328 15.0802 13.8744C15.0331 12.9942 14.5935 12.1837 13.8987 11.6744C13.265 11.1997 12.6312 11.0559 12.1274 10.9415C12.1156 10.9388 12.1039 10.9362 12.0922 10.9335C11.5594 10.8177 11.2817 10.7481 11.0266 10.4704C10.7724 10.1927 10.6096 9.89141 10.6331 9.6137C10.6331 9.33599 10.795 9.12702 10.957 8.9651C11.4898 8.52546 12.3926 8.52546 12.9254 8.98862L12.9323 8.99559C13.0223 9.08642 13.112 9.17696 13.157 9.28986L14.8015 9.03475C14.6622 8.54806 14.3845 8.10843 13.991 7.76106C13.5975 7.43722 13.157 7.18212 12.6477 7.06633V5.51493H11.0727V7.08985C10.6557 7.22917 10.2387 7.41462 9.91485 7.69233L9.89133 7.71585L9.86781 7.73937C9.33499 8.22513 9.03467 8.85113 9.01115 9.52234C8.98763 10.2397 9.28886 10.9805 9.86781 11.583C10.4703 12.1846 11.1415 12.3465 11.7204 12.4858C12.1601 12.5781 12.531 12.6713 12.9245 12.949C13.2492 13.2041 13.4338 13.5514 13.4573 13.9449C13.4808 14.361 13.318 14.7781 12.9941 15.0793C12.4613 15.566 11.535 15.566 10.956 15.1028C10.7245 14.9183 10.5861 14.6867 10.5625 14.4316L8.94149 14.6632C9.03468 15.3118 9.35852 15.9143 9.89134 16.3539C10.2387 16.6325 10.6331 16.8406 11.0728 16.9564V18.5078H12.6477V17.0025C13.1805 16.8867 13.6672 16.6551 14.0607 16.3078Z" />'
};
