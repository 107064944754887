/**
 * This file was automatically generated by `IconCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, follow the instructions at `src/shared/joykit/packages/core/icons/README.md`.
 */

import * as React from 'react';

import createSvgIcon from '../createSvgIcon';

import { SvgIconConfig } from '../types';

const config: SvgIconConfig = {
  title: 'Chevron Right Square',
  definitions: {
    '16': {
      viewBox: '0 0 16 16',
      path: (
        <>
          <path fillRule="evenodd" clipRule="evenodd" d="M12 8L5.89091 14L5 13.215L10.3455 8L5 2.78505L5.89091 2L12 8Z" fill="currentColor" />
        </>
      )
    },
    '24': {
      viewBox: '0 0 24 25',
      path: (
        <>
          <path fillRule="evenodd" clipRule="evenodd" d="M18 12.0518L8.4 22.0518L7 20.7433L15.4 12.0518L7 3.36017L8.4 2.05176L18 12.0518Z" fill="currentColor" />
        </>
      )
    }
  }
};

const icon = createSvgIcon(config);
export default icon;
