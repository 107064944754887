/**
 * https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/key/Key_Values#Whitespace_keys
 */
export const KEYBOARD_KEYS = {
  // Navigation Keys
  arrowUp: 'ArrowUp',
  arrowDown: 'ArrowDown',
  arrowLeft: 'ArrowLeft',
  arrowRight: 'ArrowRight',
  home: 'Home',
  end: 'End',
  pageUp: 'PageUp',
  pageDown: 'PageDown',

  // Whitespace keys
  enter: 'Enter',
  tab: 'Tab',
  space: ' ',

  // UI keys
  escape: 'Escape'
} as const;

export const isWhitespaceKeyClick = (key: string) => {
  return key === KEYBOARD_KEYS.enter || key === KEYBOARD_KEYS.space;
};

export const isEscapeKeyClick = (key: string) => {
  return key === KEYBOARD_KEYS.escape;
};

export const isVerticalArrowKeyClick = (key: string) => {
  return key === KEYBOARD_KEYS.arrowUp || key === KEYBOARD_KEYS.arrowDown;
};
