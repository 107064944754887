import { IconConfig } from '..';

/* tslint:disable:max-line-length */
export const creditCard: IconConfig = {
  name: 'creditCard',
  a11yLabel: 'Credit card',
  fill: '#333333',
  viewBox: 24,
  body: `
    <rect y="4" width="24" height="16" rx="2" />
    <rect x="3" y="15" width="3" height="2" fill="white" />
    <rect x="8" y="15" width="3" height="2" fill="white" />
    <rect x="13" y="15" width="3" height="2" fill="white" />
    <rect x="18" y="15" width="3" height="2" fill="white" />
    <rect x="3" y="7" width="6" height="5" fill="white" />  
  `
};
