import { EcardType } from '@graphql/generated';
import { LocationFormat, NameFormat } from '../SaveTheDate.types';

export const SAVE_THE_DATE_APP_NAME = 'savethedate';

export const SAVE_THE_DATE_PHOTO_ECARD_TYPE = EcardType.savethedatephoto;

export const LANDING_PAGE_PATH = 'ecard/savethedate';

const DEFAULT_CONTAINER_SIZE = { width: 500, height: 700 };
const getContainerSize = (width: number, height: number) => ({ width, height });

export interface PhotoEcardTheme {
  id: string;
  telemetryId: string;
  themeId: string;
  name: string;
  thumbnailUrl: string;
  assetUrl: string;
  assetTitleUrl: string;
  containerSize: { width: number; height: number };
}

export const SAVE_THE_DATE_PHOTO_THEMES: Array<PhotoEcardTheme> = [
  {
    id: 'goldBorderCursive',
    telemetryId: 'gold_border_cursive',
    themeId: 'gold_border_cursive',
    name: 'Gold Border Cursive',
    thumbnailUrl: 'https://withjoy.com/media/photo-ecard-design-thumbnails/Gold-Border-Cursive.jpg',
    assetUrl: 'https://withjoy.com/media/raw/photo-ecard-design/Gold-Border-Cursive-border.png',
    assetTitleUrl: 'https://withjoy.com/media/raw/photo-ecard-design/Gold-Border-Cursive-title.png',
    containerSize: DEFAULT_CONTAINER_SIZE
  },
  {
    id: 'sideCursive',
    telemetryId: 'side_cursive',
    themeId: 'side_cursive',
    name: 'Side Cursive',
    thumbnailUrl: 'https://withjoy.com/media/photo-ecard-design-thumbnails/Side-Cursive.jpg',
    assetUrl: 'https://withjoy.com/media/raw/photo-ecard-design/Side-Cursive.png',
    assetTitleUrl: '',
    containerSize: DEFAULT_CONTAINER_SIZE
  },
  {
    id: 'arch2',
    telemetryId: 'arch_2',
    themeId: 'arch_ii',
    name: 'Arch 2',
    thumbnailUrl: 'https://withjoy.com/media/photo-ecard-design-thumbnails/Arch-2.jpg',
    assetUrl: 'https://withjoy.com/media/raw/photo-ecard-design/Arch-2.png',
    assetTitleUrl: '',
    containerSize: DEFAULT_CONTAINER_SIZE
  },
  {
    id: 'arch1',
    telemetryId: 'arch_1',
    themeId: 'arch_i',
    name: 'Arch 1',
    thumbnailUrl: 'https://withjoy.com/media/photo-ecard-design-thumbnails/Arch-1.jpg',
    assetUrl: 'https://withjoy.com/media/raw/photo-ecard-design/Arch-1.png',
    assetTitleUrl: '',
    containerSize: getContainerSize(380, 415)
  },
  {
    id: 'goldWreath',
    telemetryId: 'gold_wreath',
    themeId: 'gold_wreath',
    name: 'Gold Wreath',
    thumbnailUrl: 'https://withjoy.com/media/photo-ecard-design-thumbnails/Gold-Wreath.jpg',
    assetUrl: 'https://withjoy.com/media/raw/photo-ecard-design/Gold-Wreath.png',
    assetTitleUrl: '',
    containerSize: getContainerSize(370, 370)
  },
  {
    id: 'ampersandTop',
    telemetryId: 'ampersand_top',
    themeId: 'ampersand_top',
    name: 'Ampersand Top',
    thumbnailUrl: 'https://withjoy.com/media/photo-ecard-design-thumbnails/Ampersand-Top.jpg',
    assetUrl: 'https://withjoy.com/media/raw/photo-ecard-design/Ampersand-Top.png',
    assetTitleUrl: '',
    containerSize: DEFAULT_CONTAINER_SIZE
  },
  {
    id: 'bigNames',
    telemetryId: 'big_names',
    themeId: 'big_names',
    name: 'Big Names',
    thumbnailUrl: 'https://withjoy.com/media/photo-ecard-design-thumbnails/Big-Names.jpg',
    assetUrl: 'https://withjoy.com/media/raw/photo-ecard-design/Big-Names.png',
    assetTitleUrl: '',
    containerSize: DEFAULT_CONTAINER_SIZE
  },
  {
    id: 'gildedCalligraphy',
    telemetryId: 'glided_calligraphy',
    themeId: 'gilded_calligraphy',
    name: 'Gilded Calligraphy',
    thumbnailUrl: 'https://withjoy.com/media/photo-ecard-design-thumbnails/Gilded-Calligraphy.jpg',
    assetUrl: 'https://withjoy.com/media/raw/photo-ecard-design/Gilded-Calligraphy.png',
    assetTitleUrl: '',
    containerSize: DEFAULT_CONTAINER_SIZE
  },
  {
    id: 'modularTexture',
    telemetryId: 'modular_texture',
    themeId: 'modular_texture',
    name: 'Modular Texture',
    thumbnailUrl: 'https://withjoy.com/media/photo-ecard-design-thumbnails/Modular-Texture.jpg',
    assetUrl: 'https://withjoy.com/media/raw/photo-ecard-design/Modular-Texture.png',
    assetTitleUrl: '',
    containerSize: getContainerSize(381, 461)
  },
  {
    id: 'simpleRetroDark',
    telemetryId: 'simple_retro_dark',
    themeId: 'simple_retro_dark',
    name: 'Simple Retro Dark',
    thumbnailUrl: 'https://withjoy.com/media/photo-ecard-design-thumbnails/Simple-Retro-Dark.jpg',
    assetUrl: 'https://withjoy.com/media/raw/photo-ecard-design/Simple-Retro-Dark.png',
    assetTitleUrl: '',
    containerSize: getContainerSize(321, 421)
  },
  {
    id: 'simplicity',
    telemetryId: 'simplicity',
    themeId: 'simplicity',
    name: 'Simplicity',
    thumbnailUrl: 'https://withjoy.com/media/raw/photo-ecard-design-thumbnails/Simplicity.png',
    assetUrl: 'https://withjoy.com/media/raw/photo-ecard-design/Simplicity%201.png',
    assetTitleUrl: '',
    containerSize: getContainerSize(321, 421)
  },
  {
    id: 'artDecoNavy',
    telemetryId: 'artDecoNavy',
    themeId: 'art_deco_navy',
    name: 'Art Deco Navy',
    thumbnailUrl: 'https://withjoy.com/media/raw/photo-ecard-design-thumbnails/Art%20Deco%20Navy.png',
    assetUrl: 'https://withjoy.com/media/raw/photo-ecard-design/art-deco-navy%201.png',
    assetTitleUrl: '',
    containerSize: getContainerSize(500, 321)
  },
  {
    id: 'artDecoWhite',
    telemetryId: 'artDecoWhite',
    themeId: 'art_deco_white',
    name: 'Art Deco White',
    thumbnailUrl: 'https://withjoy.com/media/raw/photo-ecard-design-thumbnails/Art%20Deco%20White.png',
    assetUrl: 'https://withjoy.com/media/raw/photo-ecard-design/art-deco-white.png',
    assetTitleUrl: '',
    containerSize: getContainerSize(401, 301)
  },
  {
    id: 'goldSwash',
    telemetryId: 'goldSwash',
    themeId: 'gold_swash',
    name: 'Gold Swash',
    thumbnailUrl: 'https://withjoy.com/media/raw/photo-ecard-design-thumbnails/Gold%20Swash.png',
    assetUrl: 'https://withjoy.com/media/raw/photo-ecard-design/gold-swash.png',
    assetTitleUrl: '',
    containerSize: getContainerSize(500, 401)
  },
  {
    id: 'picturePerfect',
    telemetryId: 'picturePerfect',
    themeId: 'picture_perfect',
    name: 'Picture Perfect',
    thumbnailUrl: 'https://withjoy.com/media/raw/photo-ecard-design-thumbnails/Picture%20Perfect.png',
    assetUrl: 'https://withjoy.com/media/raw/photo-ecard-design/captured-moment.png',
    assetTitleUrl: '',
    containerSize: getContainerSize(331, 376)
  },
  {
    id: 'brushedEdge',
    telemetryId: 'brushedEdge',
    themeId: 'brushed_edge',
    name: 'Brushed Edge',
    thumbnailUrl: 'https://withjoy.com/media/raw/photo-ecard-design-thumbnails/Brushed%20Edge.png',
    assetUrl: 'https://withjoy.com/media/raw/photo-ecard-design/captured-moment-1.png',
    assetTitleUrl: '',
    containerSize: getContainerSize(500, 451)
  },
  {
    id: 'scriptDark',
    telemetryId: 'scriptDark',
    themeId: 'script_dark',
    name: 'Script Dark',
    thumbnailUrl: 'https://withjoy.com/media/raw/photo-ecard-design-thumbnails/Script%20Dark.png',
    assetUrl: 'https://withjoy.com/media/raw/photo-ecard-design/Graphic.png',
    assetTitleUrl: '',
    containerSize: getContainerSize(500, 341)
  },
  {
    id: 'inkGold',
    telemetryId: 'inkGold',
    themeId: 'ink_and_gold',
    name: 'Ink & Gold',
    thumbnailUrl: 'https://withjoy.com/media/raw/photo-ecard-design-thumbnails/Ink%20&%20Gold.png',
    assetUrl: 'https://withjoy.com/media/raw/photo-ecard-design/Graphic-1.png',
    assetTitleUrl: '',
    containerSize: getContainerSize(291, 366)
  }
];

export const LOCATION_FORMATS = [LocationFormat.CITY_STATE_COUNTRY, LocationFormat.CITY_STATE, LocationFormat.CITY_COUNTRY];
export const COUPLE_NAME_FORMATS = [NameFormat.FIRST, NameFormat.FULL];
export const DISPLAY_DATE_FORMATS = ['dd MMMM yyyy', 'MMMM dd, yyyy', 'EEEE, dd MMMM yyyy'];

export const PHOTO_ECARD_DIMENSIONS: {
  width: number;
  height: number;
} = {
  width: 500,
  height: 700
};

export const PHOTO_ECARD_ASPECT_RATIO: number = PHOTO_ECARD_DIMENSIONS.width / PHOTO_ECARD_DIMENSIONS.height;
