interface RequestCaptchaEmitter {
  /**
   * Request a WAF captcha to be shown to the user.
   *
   * @returns Promise that resolves when captcha is completed or rejects if captcha fails.
   */
  requestCaptcha: () => Promise<void>;

  /**
   * Set the listener that will be called when a WAF captcha is requested.
   */
  setRequestCaptchaListener: (listener: (() => Promise<void>) | undefined) => void;
}

function createRequestCaptchaEmitter(): RequestCaptchaEmitter {
  let showCaptchaCallback: (() => Promise<void>) | undefined;

  return {
    setRequestCaptchaListener: (listener: (() => Promise<void>) | undefined) => {
      if (showCaptchaCallback) {
        console.error('WafCaptcha: listener already set. Ensure WafCaptcha is only mounted once for the app.');
        return;
      }

      showCaptchaCallback = listener;
    },
    requestCaptcha: () => {
      if (!showCaptchaCallback) {
        return Promise.reject('WafCaptcha: listener not set. Ensure WafCaptcha is mounted before calling emit.');
      }

      return showCaptchaCallback();
    }
  };
}

export const { setRequestCaptchaListener, requestCaptcha } = createRequestCaptchaEmitter();
