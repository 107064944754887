import React, { useCallback } from 'react';
import FocusLockLib from 'react-focus-lock';

export interface FocusableElement {
  focus(options?: FocusOptions): void;
}

export interface FocusLockProps
  extends Readonly<{
    isDisabled?: boolean;
    children: React.ReactNode;

    /**
     * `ref` of the element to return focus to when `FocusLock`
     * unmounts
     */
    finalFocusRef?: React.RefObject<FocusableElement>;
    /**
     * `ref` of the element to receive focus initially
     */
    initialFocusRef?: React.RefObject<FocusableElement>;

    /**
     * If `true`, the first focuable element within the `children`
     * will ne auto-focused once `FocusLock` mounts
     */
    enableAutoFocus?: boolean;

    /**
     * If `true`, focus will be restored to the element that
     * triggered the `FocusLock` once it unmounts
     */
    enableRestoreFocus?: boolean;
  }> {}

export const FocusLock = (props: FocusLockProps) => {
  const { children, enableAutoFocus, finalFocusRef, initialFocusRef, isDisabled, enableRestoreFocus } = props;

  const onActivation = useCallback(() => {
    if (initialFocusRef?.current) {
      initialFocusRef.current.focus();
    }
  }, [initialFocusRef]);

  const onDeactivation = useCallback(() => {
    finalFocusRef?.current?.focus();
  }, [finalFocusRef]);

  return (
    // eslint-disable-next-line jsx-a11y/no-autofocus
    <FocusLockLib disabled={isDisabled} autoFocus={enableAutoFocus} returnFocus={enableRestoreFocus} onActivation={onActivation} onDeactivation={onDeactivation}>
      {children}
    </FocusLockLib>
  );
};
