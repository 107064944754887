import { useRef, useCallback } from 'react';
import { useIsomorphicLayoutEffect } from './useIsomorphicLayoutEffect';

/**
 * https://github.com/facebook/react/issues/14099#issuecomment-440013892
 * @param fn Event callback
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useEventCallback = <T extends (...args: any[]) => unknown>(fn: Maybe<T>, deps: React.DependencyList = []): ((...args: Parameters<T>) => ReturnType<T>) => {
  // Initialize ref with callback
  const ref = useRef(fn);

  useIsomorphicLayoutEffect(() => {
    // The latest function is always copied into the ref
    ref.current = fn;
  });

  // A trampoline function that'll invoke the current callback
  return useCallback(
    (...args) =>
      // Ensure that the receiver (`this` value) of the function isn't ref,
      // that we are making function calls instead of method.
      //
      // The below is functionally equivalent to storing the function in a variable,
      // and then calling it - this is called dereferencing.
      (void 0, ref.current)?.(...args) as ReturnType<T>,

    // By not requiring a dependency list, we return a stable callback method.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    deps
  );
};
