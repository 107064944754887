import type { Typography, TextStyle, FontSizes, FontWeights, LetterSpacings, LineHeights, Theme } from '@withjoy/joykit/theme';
import type { Property } from 'csstype';
import marketingTextVariants from '@shared/utils/fonts/marketingFonts';

/**
 * Most browsers agreed on a default font size of 16px. JoyKit uses `rem` units to accomodate user's settings.
 *
 * This value is exposed two ways:
 *  1. For internal (JoyKit) usage, the variable is imported/exported directly from this file.
 *  2. For external (outside of `JoyKit`: `guest`, `admin`, `vendor`, etc.) usage, this variable can be accessed
 *     via the theme object - `theme.typography.htmlFontSize`.
 */
export const HTML_FONT_SIZE = 16;

export const toUnitless = (value: Maybe<string>) => (value ? Number.parseFloat(value) : 0);

// TODO: Maybe create a generic convert function
export const relativeUnitToPx = (value: Maybe<string>) => Number.parseFloat((toUnitless(value) * HTML_FONT_SIZE).toFixed(5));

/**
 * A utility method to convert an absolute `px` unit to a relative (`rem` or `em`) font unit.
 */
export const pxToRelativeUnit = (value: number, unit: 'rem' | 'em') => `${value / HTML_FONT_SIZE}${unit}`;

export const pxToRem = (value: number) => pxToRelativeUnit(value, 'rem');

interface FontToken
  extends Readonly<{
    primaryFontFamily: string;
  }> {}

export const defaultFontTokens: FontToken = {
  primaryFontFamily: '"Inter UI"'
};

type BuildVariantOptions = Partial<{
  fontFeatures: Property.FontFeatureSettings;
  casing: Property.TextTransform;
  ratio?: boolean;
}>;

export const createTextStyleVariantBuilder = (fontFamily: string) => {
  // Line-height should always represented as a unitless value
  // https://allthingssmitty.com/2017/01/30/nope-nope-nope-line-height-is-unitless/
  return (fontWeight: TextStyle['fontWeight'], size: number, lineHeightMultiplier: number, letterSpacing: number, options: BuildVariantOptions = {}): TextStyle => {
    return {
      fontFamily,
      fontWeight,
      fontSize: pxToRem(size),
      lineHeight: lineHeightMultiplier,
      textTransform: options.casing,
      letterSpacing: `${letterSpacing}em`,
      fontFeatureSettings: options.fontFeatures
    };
  };
};

export const createTypography = (fontTokens?: Partial<FontToken>): Pick<Theme, 'typography' | 'fontSizes' | 'fontWeights' | 'lineHeights' | 'letterSpacings'> => {
  const { primaryFontFamily } = Object.assign({}, defaultFontTokens, fontTokens);

  const { fontWeights, ...typographyTokens } = createTypographyTokens();

  const buildVariant = createTextStyleVariantBuilder(primaryFontFamily);

  const typography: Typography = {
    htmlFontSize: HTML_FONT_SIZE,
    pxToRem,
    primaryFontFamily,
    variants: {
      // https://www.figma.com/file/C6xJRqSjyUpvJNjCf0TErF/%F0%9F%85%B0%EF%B8%8FType-Kit-Admin?node-id=3697%3A7065

      body1: buildVariant(fontWeights.regular, 15, 1.5, -0.01), // AdminKit: body
      body2: buildVariant(fontWeights.regular, 16, 1.5, -0.01), // GuestKit: bodySM
      body3: buildVariant(fontWeights.regular, 17, 1.8, -0.0001), // GuestKit: bodyMD
      body4: buildVariant(fontWeights.regular, 17, 1.5, -0.0001), // GuestKit: bodyLG

      label1: buildVariant(fontWeights.regular, 12, 1.17, 0),
      label2: buildVariant(fontWeights.regular, 13, 1.4, -0.0025), // GuestKit: bodyXS; AdminKit: caption
      label3: buildVariant(fontWeights.semibold, 13, 1.4, -0.0025), // GuestKit: labelXS; AdminKit: subhead
      label4: buildVariant(fontWeights.bold, 13, 1.4, -0.0025), // GuestKit: labelSM
      label5: buildVariant(fontWeights.bold, 13, 1.4, -0.0025),
      label6: buildVariant(fontWeights.medium, 13, 1.4, -0.0025), // GuestKit: labelMD

      hed1: buildVariant(fontWeights.semibold, 15, 1.4, 0), // AdminKit: bodyTitle
      hed2: buildVariant(fontWeights.semibold, 17, 1.41, -0.016), // GuestKit: hedMD; AdminKit: head
      hed3: buildVariant(fontWeights.semibold, 19, 1.47, -0.024), // GuestKit: labelLG
      hed4: buildVariant(fontWeights.semibold, 20, 1.2, -0.016), // GuestKit: hedLG; AdminKit: title
      hed5: buildVariant(fontWeights.bold, 24, 1.2, -0.024),
      hed6: buildVariant(fontWeights.bold, 26, 1.2, -0.015), // AdminKit: page

      button1: buildVariant(fontWeights.semibold, 15, 1.2, -0.01), // GuestKit: button1; AdminKit: button
      button2: buildVariant(fontWeights.semibold, 16, 1.37, -0.01), // GuestKit: button3
      button3: buildVariant(fontWeights.regular, 16, 1.5, -0.016),
      button4: buildVariant(fontWeights.bold, 16, 1.5, 0.01),

      display1: buildVariant(fontWeights.bold, 32, 1.2, -0.015), // GuestKit: eventXS
      display2: buildVariant(fontWeights.bold, 36, 1.2, -0.015),
      display3: buildVariant(fontWeights.bold, 42, 1.2, -0.015), // GuestKit: eventSM
      display4: buildVariant(fontWeights.bold, 56, 1.2, -0.015), // GuestKit: eventMD
      display5: buildVariant(fontWeights.bold, 56, 1.2, -0.015),
      display6: buildVariant(fontWeights.bold, 64, 1.2, -0.024), // GuestKit: eventLG
      display7: buildVariant(fontWeights.medium, 72, 1.2, -0.024),
      display8: buildVariant(fontWeights.light, 96, 1.2, -0.032),
      display9: buildVariant(fontWeights.regular, 144, 1.2, -0.024),
      display10: buildVariant(fontWeights.extraLight, 288, 1.2, 0),
      display11: buildVariant(fontWeights.thin, 288, 1.2, -0.022),

      ...marketingTextVariants
    }
  };

  return {
    fontWeights,
    ...typographyTokens,
    typography
  };
};

////////////////////////////////////////////////////////////////////////

export const createTypographyTokens = (): Readonly<{ fontSizes: FontSizes; fontWeights: FontWeights; letterSpacings: LetterSpacings; lineHeights: LineHeights }> => {
  const fontSizes: FontSizes = {
    100: pxToRem(12), // 0.75rem
    200: pxToRem(13), // 0.8125rem
    300: pxToRem(14), // 0.875rem
    400: pxToRem(15), // 0.9375rem
    500: pxToRem(16), // 1rem
    600: pxToRem(17), // 1.0625rem
    700: pxToRem(19), // 1.1875rem
    800: pxToRem(20), // 1.25rem
    900: pxToRem(24), // 1.5rem
    1000: pxToRem(26), // 1.625rem
    1100: pxToRem(28), // 1.75rem
    1200: pxToRem(32), // 2rem
    1300: pxToRem(34), // 2.125rem
    1400: pxToRem(42), // 2.625rem
    1500: pxToRem(48), // 3rem
    1600: pxToRem(56), // 3.5rem
    1700: pxToRem(64), // 4rem
    1800: pxToRem(72), // 4.5rem
    1900: pxToRem(96) // 6re,
  };

  const fontWeights: FontWeights = {
    thin: 100,
    extraLight: 200,
    light: 300,
    regular: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
    extraBold: 800,
    black: 900
  };

  const lineHeights: LineHeights = {
    normal: 'normal',
    none: 1,
    shortest: 1.1,
    shorter: 1.2,
    short: 1.3,
    base: 1.4,
    tall: 1.5,
    taller: 1.6,
    tallest: 1.7
  };

  const letterSpacings: LetterSpacings = {
    tighter: '-0.05em',
    tight: '-0.025em',
    normal: '0',
    wide: '0.025em',
    wider: '0.05em',
    widest: '0.1em'
  };

  return { fontSizes, fontWeights, letterSpacings, lineHeights };
};
