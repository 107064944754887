/**
 * Run browser only code here.
 */

import { initNewRelic } from '@static/js/newrelic';
import { initSegment, initAuth0 } from './static/js/joy';
import { initBuildNum } from '@static/js/build';

initBuildNum();

// eslint-disable-next-line compat/compat
if (window.requestIdleCallback) {
  // eslint-disable-next-line compat/compat
  window.requestIdleCallback(() => {
    initSegment();
    initAuth0();
    initNewRelic();
  });
} else {
  initSegment();
  initAuth0();
  initNewRelic();
}

if (process.env.APP === 'main') {
  window.toggleFS = function (enable) {
    if (typeof enable === 'undefined') {
      console.warn('toggleFS() requires a boolean argument.');
      return;
    }
    if (window.FS) {
      // FS.shutdown() and FS.restart() are idempotent and will do nothing if called unnecessarily
      // EG calling shutdown when it's not recording will do nothing.
      if (enable) {
        // eslint-disable-next-line no-console
        console.log('Starting Fullstory.');
        window.FS.restart();
      } else {
        // eslint-disable-next-line no-console
        console.log('Shutting down Fullstory.');
        window.FS.shutdown();
      }
    }
  };
}
