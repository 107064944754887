import type { ResponsiveValue } from '@shared/joykit/packages/core/common/styledSystem/types';
import { variant } from '@shared/joykit/packages/core/common/styledSystem/core';
import { TextStyle } from '@withjoy/joykit/theme';

export type MarketingFontFamily = 'Austin News Headline' | 'Akkurat LL' | 'Austin News Deck Web';

export type MarketingFont =
  | 'HedSuperEmphasis120'
  | 'HedSuper120'
  | 'HedBreak96'
  | 'HedHero72'
  | 'Hed56'
  | 'Hed48'
  | 'Hed42'
  | 'Hed40'
  | 'Hed38'
  | 'Hed28'
  | 'HedMobile32'
  | 'CalloutHed40Bold'
  | 'CalloutHed40'
  | 'CalloutHed32Bold'
  | 'CalloutDek40'
  | 'CalloutDekMobile32'
  | 'SectionEyebrow24'
  | 'SectionEyebrow20'
  | 'Dek24'
  | 'Dek20'
  | 'FeatureHead17'
  | 'FeatureCopy17'
  | 'FeatureHead15'
  | 'FeatureCopy15'
  | 'Caption12'
  | 'Legal11'
  | 'Stat72';

export interface ResponsiveMarketingFont {
  marketingFontVariant?: ResponsiveValue<MarketingFont>;
}

const fonts: { [key in MarketingFont]: TextStyle } = {
  HedSuperEmphasis120: {
    fontFamily: "'Austin News Headline'",
    fontStyle: 'italic',
    fontWeight: 'bold',
    fontSize: '120px',
    lineHeight: 1.13, // '136px'
    letterSpacing: '-0.03em'
  },
  HedSuper120: {
    fontFamily: "'Austin News Headline'",
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '120px',
    lineHeight: 1.13, // '136px'
    letterSpacing: '-0.01em'
  },
  HedBreak96: {
    fontFamily: "'Austin News Headline'",
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '96px',
    lineHeight: 1.19, // '114px'
    letterSpacing: '-0.005em'
  },
  HedHero72: {
    fontFamily: "'Austin News Headline'",
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '72px',
    lineHeight: 1.22, // '88px'
    letterSpacing: '-1.5%'
  },
  Hed56: {
    fontFamily: "'Austin News Headline'",
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '56px',
    lineHeight: 1.21 // '68px'
  },
  Hed48: {
    fontFamily: "'Austin News Deck Web'",
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '48px',
    lineHeight: 1.2
  },
  Hed42: {
    fontFamily: "'Austin News Deck Web'",
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '42px',
    lineHeight: 1.2 // '48px'
  },
  Hed40: {
    fontFamily: "'Austin News Deck Web'",
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '40px',
    lineHeight: 1.2 // '48px'
  },
  Hed38: {
    fontFamily: "'Austin News Deck Web'",
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '38px',
    lineHeight: 1.1
  },
  Hed28: {
    fontFamily: "'Austin News Deck Web'",
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '28px',
    lineHeight: 1.21
  },
  HedMobile32: {
    fontFamily: "'Austin News Deck Web'",
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '32px',
    lineHeight: 1.19 // '38px'
  },
  CalloutHed40Bold: {
    fontFamily: "'Akkurat LL'",
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '40px',
    lineHeight: 1.35, // '54px'
    letterSpacing: '-0.015em'
  },
  CalloutHed40: {
    fontFamily: "'Akkurat LL'",
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '40px',
    lineHeight: 1.35, // '54px'
    letterSpacing: '-0.015em'
  },
  CalloutHed32Bold: {
    fontFamily: "'Akkurat LL'",
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '32px',
    lineHeight: 1.31 // '42px'
  },
  CalloutDek40: {
    fontFamily: "'Akkurat LL'",
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '40px',
    lineHeight: 1.38, // '55px'
    letterSpacing: '-0.02em'
  },
  CalloutDekMobile32: {
    fontFamily: "'Akkurat LL'",
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '32px',
    lineHeight: 1.31, // '42px'
    letterSpacing: '-0.01em'
  },
  SectionEyebrow24: {
    fontFamily: "'Akkurat LL'",
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '24px',
    lineHeight: 1.5, //'36px',
    letterSpacing: '0.005em'
  },
  SectionEyebrow20: {
    fontFamily: "'Akkurat LL'",
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '20px',
    lineHeight: 1.6, // '32px',
    letterSpacing: '0.005em'
  },
  Dek24: {
    fontFamily: "'Akkurat LL'",
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '24px',
    lineHeight: 1.5, // '36px',
    letterSpacing: '-0.005em'
  },
  Dek20: {
    fontFamily: "'Akkurat LL'",
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '20px',
    lineHeight: 1.6 //'32px'
  },
  FeatureHead17: {
    fontFamily: "'Akkurat LL'",
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '17px',
    lineHeight: 1.53, //'26px',
    letterSpacing: '0.01em'
  },
  FeatureCopy17: {
    fontFamily: "'Akkurat LL'",
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '17px',
    lineHeight: 1.59 //'27px'
  },
  FeatureHead15: {
    fontFamily: "'Akkurat LL'",
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '15px',
    lineHeight: 1.4 // '21px'
  },
  FeatureCopy15: {
    fontFamily: "'Akkurat LL'",
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '15px',
    lineHeight: 1.4 //'21px'
  },
  Caption12: {
    fontFamily: "'Akkurat LL'",
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: 1.42 // '17px'
  },
  Legal11: {
    fontFamily: "'Akkurat LL'",
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '11px',
    lineHeight: 1.17 // '14px'
  },
  Stat72: {
    fontFamily: "'Austin News Headline'",
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '72px',
    lineHeight: 1.5, // '108px',
    letterSpacing: '0.01em'
  }
};
export default fonts;

export const marketingFontVariant = variant({
  prop: 'marketingFontVariant',
  variants: fonts
});
