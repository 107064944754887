import { useReducer, useCallback, useRef, useEffect } from 'react';

export const useForceUpdate = () => {
  const unmountedRef = useRef(false);
  const [_, forceUpdate] = useReducer(x => x + 1, 0);

  useEffect(() => {
    return () => {
      unmountedRef.current = true;
    };
  }, []);

  return useCallback(() => {
    if (!unmountedRef.current) {
      forceUpdate();
    }
  }, []);
};
