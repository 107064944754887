import { getLocalStorage, getSessionStorage } from '@shared/core';
import qs from '@shared/core/queryString';
import { v4 as uuid } from 'uuid';

const BROWSER_ID_STORAGE_KEY = 'browserId';
const SESSION_ID_STORAGE_KEY = 'joySessionId';

/**
 * The `browserId` is unique to each browser and persisted to local storage.
 * It allows us to track a user’s journey across multiple tabs within the same browser.
 * Can be combined with `sessionId` and `instanceId` to understand user interactions.
 */
export const getBrowserId = () => {
  const storage = getLocalStorage();
  let browserId = storage.getItem(BROWSER_ID_STORAGE_KEY);

  if (!browserId) {
    // Ensure browserId exists + is persisted
    browserId = uuid();
    storage.setItem(BROWSER_ID_STORAGE_KEY, browserId);
  }

  return browserId;
};

let sessionId: string | null | undefined;

/**
 * The `sessionId` is unique to each browser tab and persisted to session storage.
 * This is useful to understanding actions a user took within a single tab.
 */
export const getSessionId = (): string => {
  if (sessionId) {
    return sessionId;
  }

  const sessionStorage = getSessionStorage();
  sessionId = sessionStorage.getItem(SESSION_ID_STORAGE_KEY);

  if (!sessionId) {
    sessionId = uuid();
    sessionStorage.setItem(SESSION_ID_STORAGE_KEY, sessionId);
  }

  return sessionId;
};

const _instanceId = qs.getValueString('instanceId') || uuid();

/**
 * The `instanceId` identifies the current load of joy-web.
 * This is different from sessionId because instanceId is regenerated on every reload, even
 * in the same tab, whereas sessionId is only regenerated when a new tab is opened.
 */
export const getInstanceId = () => {
  return _instanceId;
};
