import i18nLibrary from 'i18next';
import ReactPostprocessor from 'i18next-react-postprocessor';
import HttpApi from 'i18next-http-backend';

import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { getNodeEnv } from '@shared/utils/getEnvironmentVariables';
import { getInitOptions } from './utils';
import { manifest } from '@locales/generated';

const { isDevelopment } = getNodeEnv();

const getHost = () => {
  if (isDevelopment) {
    return `http://${process.env.HOST}:${process.env.PORT}`;
  }
  return '';
};

const RESOURCE_HOST = getHost();

const backendInitOptions: HttpApi['options'] = {
  // `allowMultiLoading` is false and so `languages` and `namespaces` arguments are arrays of length 1.
  loadPath: ([language], [namespace]) => {
    if (__STORYBOOK__) {
      return `locales/${language}/${namespace}.json`;
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const targetPath = (manifest as Record<string, any>)[language]?.[namespace];
    const fallbackPath = `/bliss-apps/joy-web/static/locales/${language}/${namespace}.json`;
    return `${RESOURCE_HOST}${targetPath || fallbackPath}`;
  }
};

// In browser, use http backend to load translations and browser lng detector
if (__BROWSER__) {
  i18nLibrary
    // // load translation using xhr
    // // learn more: https://github.com/i18next/i18next-xhr-backend
    .use(HttpApi)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next) // React post processor for inline react elements in translation
    .use(
      new ReactPostprocessor({
        prefix: '<',
        suffix: '/>'
      })
    );

  if (!i18nLibrary.isInitialized) {
    i18nLibrary.init(
      getInitOptions({
        debug: isDevelopment,
        backend: backendInitOptions
      })
    );
  }
}

export default i18nLibrary;
