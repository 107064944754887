import { useRef, useState, useCallback } from 'react';

import { callAllHandlers } from '@shared/utils/functions';
import { useIds, useShouldKeepOverlayOpen } from '@withjoy/joykit/hooks';
import { PropGetter, isEscapeKeyClick } from '@withjoy/joykit/utils';
import { AddAriaLabels, ModalProps } from './Modal.types';
import { mergeRefs } from '@shared/utils/hooks/setRef';
import { useModalManager, modalManager } from './modalManager';

export const useModal = (props: ModalProps) => {
  const { addAriaLabels, id: idProp, isOpen: isOpenProp, disableCloseOnEscapeKeyClick, disableCloseOnOutsideClick, isDraggable, onClose } = props;

  const [dialogId, headerId, bodyId] = useIds(idProp, 'joykit-dialog', 'joykit-dialog--header', 'joykit-dialog--body');
  const [headerMounted, setHeaderMounted] = useState(false);
  const [bodyMounted, setBodyMounted] = useState(false);

  const dialogContainerRef = useRef<HTMLDivElement>(null);
  const dialogRef = useRef<HTMLElement>(null);

  useModalManager(dialogRef, isOpenProp);

  const { shouldKeepOverlayOpen, setVisibilityForKey } = useShouldKeepOverlayOpen({
    refValues: { backdrop: false, content: false }
  });

  const getDialogRootProps: PropGetter = useCallback(
    (props = {}, ref) => {
      return {
        ...props,
        ref: mergeRefs(ref, dialogContainerRef),
        tabIndex: -1,
        onClick: callAllHandlers(props.onClick, e => {
          // Need to check if top modal
          if (!modalManager.isTopModal(dialogRef)) {
            return;
          }

          if (!disableCloseOnOutsideClick) {
            onClose?.({ closeSource: 'overlay', event: e });
          }
        }),
        onKeyDown: callAllHandlers(props.onKeyDown, e => {
          if (isEscapeKeyClick(e.key)) {
            // By stopping event propagation, previous modals won't receive the event -- no need to check if `Is Top Modal`
            e.stopPropagation();

            if (!disableCloseOnEscapeKeyClick) {
              onClose?.({ closeSource: 'escape', event: e });
            }
          }
        })
      };
    },
    [disableCloseOnEscapeKeyClick, disableCloseOnOutsideClick, onClose]
  );

  const getDialogProps: PropGetter = useCallback(
    (props = {}, ref) => {
      return {
        ...props,
        ref: mergeRefs(ref, dialogRef),
        tabIndex: -1,
        role: 'dialog',
        'aria-modal': true,
        'aria-labelledby': headerMounted && _shouldAddAriaLabelFor(addAriaLabels, 'header') ? headerId : undefined,
        'aria-describedby': bodyMounted && _shouldAddAriaLabelFor(addAriaLabels, 'body') ? bodyId : undefined,
        onClick: callAllHandlers(props.onClick, e => {
          // Stop propagation to dialog root component where outside click is handled
          e.stopPropagation();
        })
      };
    },
    [headerId, bodyId, addAriaLabels, headerMounted, bodyMounted]
  );

  const handleOnBackdropFrame = useCallback(
    (styles: { opacity: number }) => {
      setVisibilityForKey('backdrop', styles.opacity > 0.02);
    },
    [setVisibilityForKey]
  );

  return {
    bodyId,
    bodyMounted,
    dialogId,
    handleOnBackdropFrame,
    headerId,
    headerMounted,
    isDraggable,
    isOpen: isOpenProp,

    shouldKeepOverlayOpen,
    getDialogProps,
    getDialogRootProps,
    setHeaderMounted,
    setBodyMounted,
    setVisibilityForKey
  };
};

const _shouldAddAriaLabelFor = (input: Maybe<AddAriaLabels>, target: 'header' | 'body') => (typeof input === 'boolean' ? input : input?.[target] || false);
