import { styled } from '@withjoy/joykit';
import { Box, StyleSystemProps } from '../Box';

export const backdropStyles: StyleSystemProps = {
  height: '100vh',
  width: '100vw',
  top: 0,
  left: 0,
  position: 'fixed',
  backgroundColor: 'backdropFill',
  zIndex: 'modal'
};

export const StyledRoot = styled(Box)``;
