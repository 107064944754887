import { mappingStrategies, MappingStrategy } from './characterMappings';
import LRUCache from 'lru-cache';

export type Options = Readonly<
  Partial<{
    /** @default 'accented'' */
    strategy: MappingStrategy;

    /** @default false */
    log: boolean;
  }>
>;

type CharacterExpansionRange = 10 | 20 | 30 | 50 | 70 | 'over';

// Keeping vowel casing separate for if we want varying expansion
const VOWELS_LOWER = new Set(['a', 'e', 'i', 'o', 'u']);
const VOWELS_UPPER = new Set(['A', 'E', 'I', 'O', 'U']);
const isVowel = (char: string): boolean => VOWELS_LOWER.has(char) || VOWELS_UPPER.has(char);

// <Addtnl Required Space> / <# of characters range>
const SPREAD_DEFAULT = 0.3;
const SPREAD_SM = 9 / 19;
const SPREAD_MD = 20 / 19;
const SPREAD_LG = 20 / 9;
const SPREAD_XL = 10;

const percentToDecimal = (percent: number) => percent / 100;

const expansionFactorByRange: { [range in CharacterExpansionRange]: (length: number) => number } = {
  10: length => percentToDecimal(length * SPREAD_XL + 100),
  20: length => percentToDecimal((length % 11) * SPREAD_LG + 80),
  30: length => percentToDecimal((length % 21) * SPREAD_LG + 60),
  50: length => percentToDecimal((length % 31) * SPREAD_MD + 40),
  70: length => percentToDecimal((length % 51) * SPREAD_SM + 31),
  over: length => percentToDecimal(length * SPREAD_DEFAULT)
};

const expansionRatioByTextLength = (length: number): number => {
  const range: CharacterExpansionRange = length <= 10 ? 10 : length <= 20 ? 20 : length <= 30 ? 30 : length <= 50 ? 50 : length <= 70 ? 70 : 'over';
  return expansionFactorByRange[range](length);
};

const prettyPrint = (prefix: string, text: string, against?: number) => {
  const length = text.length;
  let lengthAffix: string | undefined;
  if (against !== undefined) {
    const lengthDiff = length - against;
    const isLonger = lengthDiff > 0;
    lengthAffix = `${isLonger ? '+' : '-'}${Math.round((lengthDiff / against) * 100)}% (from ${against})`;
  }
  // prettier-ignore
  // eslint-disable-next-line no-console
  console.log(
`${prefix}: {
  text: ${text},
  length: ${text.length} ${lengthAffix || ''},
}`.trim()
  );
};
const logChange = (source: string, output: string) => {
  // eslint-disable-next-line no-console
  console.log(
    `
START >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
  `.trim()
  );
  prettyPrint('Source', source);
  // eslint-disable-next-line no-console
  console.log('\n');
  prettyPrint('Output', output, source.length);
  // eslint-disable-next-line no-console
  console.log(
    `
END <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
  `.trim()
  );
};

const cache = new LRUCache<string, string>({ max: 500 });

/**
 * Transliterate text to emulate localization.
 *  1. Add start and end markers. Transformed strings are encapsulated in `[]`.
 *  2. Transform ASCII characters to extended character equivalents
 *  3. Expand words by:
 *    - Doubling all vowels
 *    - Add '=' padding
 *
 * @param text
 * @param options
 */
export const pseudoLocalizeString = (text: string, options: Options = {}) => {
  if (!text) {
    return '';
  }

  const cachedValue = cache.get(text);
  if (cachedValue) {
    return cachedValue;
  }

  const charMap: Record<string, string> = mappingStrategies[options.strategy || 'accented'];

  // Collect vowel count to adjust padding later on
  let vowelCount = 0;
  for (let i = 0; i < text.length; i++) {
    if (isVowel(text[i])) {
      vowelCount += 1;
    }
  }

  // Account for end markers, minus 2
  let additionalSpace = Math.ceil(expansionRatioByTextLength(text.length) * text.length - 2);

  // start/end padding (' ' and '=') requires 4 spaces
  if (additionalSpace - vowelCount <= 4) {
    additionalSpace += 4 - (additionalSpace - vowelCount);
  }

  // - Math.ceil to ensure a leading left padding
  // - Adjust for vowel duplication
  // - Non-inclusive last index
  const paddingLeftEnd = Math.ceil((additionalSpace - vowelCount) / 2) - 1;
  const paddingRightStart = paddingLeftEnd + text.length + vowelCount + 1;

  const newStringLength = text.length + additionalSpace;
  // Pre-allocate array
  const result: string[] = Array(newStringLength);

  let index = 0;
  // Keep track of original text position
  let textIndex = 0;

  while (index < newStringLength) {
    if (paddingLeftEnd < index && index < paddingRightStart) {
      // Index is within padding boundaries, add the transformed character

      const char = text[textIndex++];
      if (charMap[char]) {
        if (isVowel(char)) {
          // Emulate expansion by duplicating vowels
          result[index++] = charMap[char];
        }

        // Add mapped character
        result[index] = charMap[char];
      } else {
        // Unrecognized character, add as is
        result[index] = char;
      }
    } else if (index === paddingLeftEnd || index === paddingRightStart) {
      // Add space before/after original text
      result[index] = ' ';
    } else {
      // Emulate expansion by adding padding
      result[index] = '=';
    }

    index++;
  }

  const output = `[${result.join('')}]`;
  cache.set(text, output);

  if (options.log) {
    // eslint-disable-next-line no-console
    console.log('\n\n');
    logChange(text, output);
  }

  return output;
};
