import React from 'react';
import { Toaster as HotToaster, ToastBar } from 'react-hot-toast';
import { ToastContainerProps } from './Toast.types';
import { useLimit } from './useLimit';
import { useResponsive } from '@shared/utils/hooks/useResponsive';
import { Box } from '../Box';

export const ToastContainer: React.FC<ToastContainerProps> = ({ defaultPosition = 'top-center', defaultDuration = 3000, maxToastCount = 5 }) => {
  const [isMobile] = useResponsive<boolean>({ values: { mobile: true, tablet: false } });
  useLimit(maxToastCount);

  return (
    <HotToaster
      position={defaultPosition}
      toastOptions={{
        duration: defaultDuration
      }}
    >
      {t => (
        <ToastBar
          toast={t}
          style={{
            boxShadow: 'none',
            padding: 0,
            borderRadius: 12,
            overflow: 'hidden',
            backgroundColor: 'transparent',
            width: isMobile ? '100%' : 'auto',
            maxWidth: isMobile ? '100%' : 480
          }}
        >
          {() => (
            <Box
              width="100%"
              typographyVariant="body1"
              paddingY={5}
              paddingX={6}
              alignItems="center"
              display="inline-flex"
              columnGap={5}
              color="white"
              backgroundColor="mono14"
              textAlign={t.icon ? 'start' : 'center'}
              data-testid="toast-content"
            >
              {t.icon && (
                <Box display="flex" alignItems="center" justifyContent="center">
                  {t.icon}
                </Box>
              )}
              {t.message}
            </Box>
          )}
        </ToastBar>
      )}
    </HotToaster>
  );
};
