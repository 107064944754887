// prettier-ignore
export type BasicLatinAlphabet = 'a' | 'b' | 'c' | 'd' | 'e' | 'f' | 'g' | 'h' | 'i' | 'j' | 'k'
  | 'l' | 'm' | 'n' | 'o' | 'p' | 'q' | 'r' | 's' | 't' | 'u' | 'v' | 'w' | 'x'
  | 'y' | 'z' | 'A' | 'B' | 'C' | 'D' | 'E' | 'F' | 'G' | 'H' | 'I' | 'J' | 'K'
  | 'L' | 'M' | 'N' | 'O' | 'P' | 'Q' | 'R' | 'S' | 'T' | 'U' | 'V' | 'W' | 'X'
  | 'Y' | 'Z';

export type CharacterMap = Readonly<{ [character in BasicLatinAlphabet]: string }>;

const ACCENTED_LATIN_MAP: CharacterMap = {
  // Lowercase
  a: 'ȧ',
  b: 'ƀ',
  c: 'ƈ',
  d: 'ḓ',
  e: 'ḗ',
  f: 'ƒ',
  g: 'ɠ',
  h: 'ħ',
  i: 'ī',
  j: 'ĵ',
  k: 'ķ',
  l: 'ŀ',
  m: 'ḿ',
  n: 'ƞ',
  o: 'ǿ',
  p: 'ƥ',
  q: 'ɋ',
  r: 'ř',
  s: 'ş',
  t: 'ŧ',
  v: 'ṽ',
  u: 'ŭ',
  w: 'ẇ',
  x: 'ẋ',
  y: 'ẏ',
  z: 'ẑ',

  // Uppercase
  A: 'Ȧ',
  B: 'Ɓ',
  C: 'Ƈ',
  D: 'Ḓ',
  E: 'Ḗ',
  F: 'Ƒ',
  G: 'Ɠ',
  H: 'Ħ',
  I: 'Ī',
  J: 'Ĵ',
  K: 'Ķ',
  L: 'Ŀ',
  M: 'Ḿ',
  N: 'Ƞ',
  O: 'Ǿ',
  P: 'Ƥ',
  Q: 'Ɋ',
  R: 'Ř',
  S: 'Ş',
  T: 'Ŧ',
  V: 'Ṽ',
  U: 'Ŭ',
  W: 'Ẇ',
  X: 'Ẋ',
  Y: 'Ẏ',
  Z: 'Ẑ'
};

// Offer addtnl strategies in the future
export type MappingStrategy = 'accented';

export const mappingStrategies: Readonly<{ [strategy in MappingStrategy]: CharacterMap }> = {
  accented: ACCENTED_LATIN_MAP
};
