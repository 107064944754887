import { Logger, getActionTypeLogger } from '../logging';
import { createTelemetryObject } from '../analytics';
import { TrackFunctionMap } from '../analytics/telemetry/types';

type LoggerInitialization<A extends TrackFunctionMap> = (logger: Logger) => A;

export const createLoggerObject = <A extends TrackFunctionMap>(actions: A): LoggerInitialization<A> => (logger: Logger) => {
  const telemetryObject = createTelemetryObject({
    actions
  });

  const actionsMap = telemetryObject.actions;

  const wrappedActions = Object.keys(actionsMap).reduce((acc: A, curr: keyof A) => {
    return {
      ...acc,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      [curr]: (...args: any[]) => {
        const trackProps = actionsMap[curr](...args);
        const handler = getActionTypeLogger(logger, trackProps.actionType);

        handler({ ...trackProps });
      }
    };
  }, {} as A);

  return wrappedActions;
};
