import qs from 'query-string';
import axios from 'axios';
import type { WebAuth, Auth0Callback, Auth0Error, AuthOptions } from 'auth0-js';
import { config } from './env.config';
import { executeWithDeferredLibrary } from './utils';

const CLIENT_ID = config.auth0ClientId;
const REDIRECT_URI = `${config.clientUri}/account-callback`;
const IDENTITY_SERVER_URI = `${config.serverUri}/identity`;

/**
 * Currently we are using 2 domains for auth0. `withjoy.auth0.com` is the one provided by auth0 and
 * joy custom domain `accounts.withjoy.com`.
 * The reason we need both is because the legacy monorepo uses the one provided by auth0 and we are
 * very reluctant to modifying stuff there because of legacy code and apis being used.
 * The custom domain we need because our it's a necessity to have a tailored experience for our users
 * when they sign up so we must use a custom domain to avoid issues with 3rd party cookies Safari and
 * probably Chrome very soon.
 *
 * !!! So for now, unless it is sign up or login, any auth0 action should use `withjoy.auth0.com`
 */
const [resolveWebAuth, executeWithWebAuth] = executeWithDeferredLibrary<{ webAuth: WebAuth; customDomainWebAuth: WebAuth }>();

export const initAuth0 = () => {
  // auth0-js is a browser library and as such, is not instantiated on the server during SSR.
  // Normal imports at the top of the file would result in a error log.
  // https://github.com/auth0/auth0.js/issues/877#issuecomment-665743985
  import(
    /* webpackChunkName: "static/webpack/vendor/auth0-js" */
    'auth0-js'
  ).then(({ WebAuth }) => {
    const config: AuthOptions = {
      domain: 'accounts.withjoy.com',
      clientID: CLIENT_ID,
      redirectUri: REDIRECT_URI,
      audience: 'https://withjoy.auth0.com/userinfo',
      responseType: 'code',
      scope: 'openid offline_access'
    };
    resolveWebAuth({ customDomainWebAuth: new WebAuth(config), webAuth: new WebAuth({ ...config, domain: 'withjoy.auth0.com' }) });
  });
};

export type Auth0AuthorizeRedirect = {
  uri?: string;
  failUri?: string;
};

export type Auth0AuthorizeResult = {
  code: string;
  redirectJSON: string;
};

const socialLogin = (connection: string, redirect: Auth0AuthorizeRedirect) => {
  executeWithWebAuth(({ webAuth }) => {
    webAuth.authorize({ connection, redirectUri: REDIRECT_URI, state: JSON.stringify(redirect) });
  });
};

const authorize = (params?: Record<string, string | boolean | number>) => {
  executeWithWebAuth(({ webAuth }) => {
    webAuth.authorize({ redirectUri: REDIRECT_URI, ...params });
  });
};

const deriveAuthorizeResult = (urlSearch: string): Auth0AuthorizeResult => {
  const parsedUrl = qs.parse(urlSearch);
  const code = parsedUrl['code'] as string;
  const redirectJSON = parsedUrl['state'] as string;
  return { code, redirectJSON };
};

const hydrateAuthorizeRedirect = (state: string): Auth0AuthorizeRedirect => {
  // the caller must `try { } catch { }` as needed
  return JSON.parse(state);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const login = (email: string, password: string, cb: Auth0Callback<any, Auth0Error>) => {
  executeWithWebAuth(({ customDomainWebAuth }) => {
    customDomainWebAuth.login({ realm: 'Username-Password-Authentication', email, password }, cb);
  });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const signup = (email: string, password: string, cb: Auth0Callback<any, Auth0Error>) => {
  executeWithWebAuth(({ customDomainWebAuth }) => {
    customDomainWebAuth.signup({ connection: 'Username-Password-Authentication', email, password }, cb);
  });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const changePassword = (email: string, cb: Auth0Callback<any, Auth0Error>) => {
  executeWithWebAuth(({ webAuth }) => {
    webAuth.changePassword({ connection: 'Username-Password-Authentication', email }, cb);
  });
};

const signout = (returnToUrl?: string) => {
  executeWithWebAuth(({ webAuth }) => {
    webAuth.logout({ returnTo: returnToUrl });
  });
};

const refreshToken = (code: string, shouldSkipTokenDelegation = false) => {
  return axios.post(`${IDENTITY_SERVER_URI}/refreshToken`, { redirectUri: REDIRECT_URI, code, skipDelegate: shouldSkipTokenDelegation });
};

export const auth0 = {
  authorize,
  socialLogin,
  deriveAuthorizeResult,
  hydrateAuthorizeRedirect,
  login,
  signup,
  signout,
  changePassword,
  refreshToken
};

export type Auth0 = typeof auth0;
