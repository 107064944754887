import React, { useContext } from 'react';
import { MarkUserForMFAEnrollmentMutationFn, ResetUserMfaMutationOptions } from '@graphql/generated';

export type MfaContextValue = {
  loading: boolean;
  enableMfa: MarkUserForMFAEnrollmentMutationFn;
  disableMfa: (options?: ResetUserMfaMutationOptions) => void;
  isMfaEnabled: boolean;
  isAuth0Provider: boolean;
};

export const MfaContext = React.createContext<MfaContextValue | undefined>(undefined);

export const useMfaController = () => {
  const context = useContext(MfaContext);
  if (context === undefined) {
    throw new Error('useMfaController must be used within a MfaControllerProvider');
  }

  return context;
};
