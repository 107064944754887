import { IconConfig } from '..';

/* tslint:disable:max-line-length */
export const search: IconConfig = {
  name: 'search',
  a11yLabel: 'Search',
  viewBox: 24,
  fill: '#333333',
  body: `  
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4 10.7024C4 7.00841 7.00823 4.00017 10.7023 4.00017L10.7024 4C14.3965 4 17.4047 7.00823 17.4047 10.7023C17.4047 12.3162 16.8262 13.8001 15.871 14.9588L19.81 18.8911C20.0633 19.1445 20.0633 19.5566 19.81 19.81C19.5566 20.0633 19.1445 20.0633 18.8911 19.81L14.9588 15.871C13.8 16.8262 12.3162 17.4047 10.7023 17.4047C7.00823 17.4047 4 14.3965 4 10.7024ZM16.1073 10.7024C16.1073 7.70933 13.6954 5.29738 10.7023 5.29738C7.70916 5.29738 5.29721 7.70933 5.29721 10.7024C5.29721 13.6955 7.70916 16.1075 10.7023 16.1075C13.6954 16.1075 16.1073 13.6955 16.1073 10.7024Z"
    />  
  `
};
