import React, { useMemo, useCallback } from 'react';
import { AnalyticsContext, TrackProps, PageProps, IdentifyProp, ActionType, SegmentGroupProps } from './context';
import { v1 as uuid } from 'uuid';
import { SegmentService } from './segment/service';
import { getLocalStorage } from '../storage';
import { Logger, getActionTypeLogger } from '../logging';
import { useResponsive } from '@shared/utils/hooks/useResponsive';

enum MEDIA {
  Desktop = 'desktop',
  Mobile = 'mobile'
}

const getActionType = (actionType?: ActionType) => {
  switch (actionType) {
    case 'click':
      return 'Clicked';
    case 'select':
      return 'Selected';
    case 'error':
      return 'Error';
    default:
      return '';
  }
};

const pageFunc = (service: SegmentService, analyticsId: string, logger?: Logger, viewportSize?: Maybe<MEDIA>) => ({
  category,
  page,
  pagePrefix,
  action,
  time,
  extraPageInfo,
  referrerOverride,
  context
}: PageProps) => {
  const localStorage = getLocalStorage();
  const joyPlatform = localStorage.getItem('segmentPlatform');
  const joyPlatformData = localStorage.getItem('segmentPlatformData');
  let timeSpentInSeconds;
  if (time) {
    timeSpentInSeconds = (time.endTime - time.startTime) / 1000;
  }
  if (logger) {
    logger.info({
      analyticsId,
      page,
      action,
      category,
      pagePrefix,
      timeSpentInSeconds,
      extraPageInfo: {
        ...extraPageInfo,
        joyPlatformData,
        joyPlatform,
        viewportSize
      },
      referrerOverride,
      context
    });
  }
  service.sendPage({
    analyticsId,
    page,
    action,
    category,
    pagePrefix,
    timeSpentInSeconds,
    extraPageInfo: {
      ...extraPageInfo,
      joyPlatformData,
      joyPlatform,
      viewportSize
    },
    referrerOverride,
    context
  });
};

const trackFunc = (service: SegmentService, analyticsId: string, logger?: Logger, viewportSize?: Maybe<MEDIA>) => (props: TrackProps) => {
  const localStorage = getLocalStorage();
  const joyPlatform = localStorage.getItem('segmentPlatform');
  const joyPlatformData = localStorage.getItem('segmentPlatformData');
  const { category, page, pagePrefix, action, actionType, label, extraInfo, referrerOverride, context, callback } = props;
  const actionPlusType = action + getActionType(actionType);

  if (logger) {
    const handler = getActionTypeLogger(logger, actionType);
    handler({
      analyticsId,
      category,
      page,
      pagePrefix,
      action: actionPlusType,
      actionType,
      label,
      extraInfo: {
        ...extraInfo,
        joyPlatform,
        joyPlatformData,
        viewportSize
      },
      referrerOverride,
      context
    });
  }

  service.sendEvent({
    analyticsId,
    category,
    page,
    pagePrefix,
    action: actionPlusType,
    actionType,
    label,
    extraInfo: {
      ...extraInfo,
      joyPlatform,
      joyPlatformData,
      viewportSize
    },
    referrerOverride,
    context,
    callback
  });
};

const identifyFunc = (service: SegmentService, analyticsId: string) => (args: IdentifyProp) => {
  service.identify({ ...args, analyticsId });
};

export const AnalyticsProvider: React.FC<{ segment: SegmentService; logger?: Logger }> = ({ segment, logger, children }) => {
  const [isMobile] = useResponsive({ values: { mobile: true, tablet: false } }, false);
  const viewportSize = isMobile ? MEDIA.Mobile : MEDIA.Desktop;
  const storage = getLocalStorage();
  let analyticsId = storage.getItem('joy:analytics');
  if (!analyticsId) {
    analyticsId = uuid();
    storage.setItem('joy:analytics', analyticsId);
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const page = useCallback(pageFunc(segment, analyticsId, logger, viewportSize), [segment, analyticsId, logger, viewportSize]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const track = useCallback(trackFunc(segment, analyticsId, logger, viewportSize), [segment, analyticsId, logger, viewportSize]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const identify = useCallback(identifyFunc(segment, analyticsId), [segment, analyticsId]);

  const group = useCallback(
    (args: SegmentGroupProps) => {
      segment.group(args);
    },
    [segment]
  );

  const anonymousId = useMemo<string>(() => segment.anonymousId(), [segment]);

  const contextValue = useMemo(() => {
    return {
      track,
      page,
      identify,
      group,
      anonymousId
    };
  }, [track, page, identify, group, anonymousId]);

  return <AnalyticsContext.Provider value={contextValue}>{children}</AnalyticsContext.Provider>;
};
