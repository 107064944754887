import { useMount } from '../hooks/useMount';
import { useInternalEventInfoContext } from './internalEventInfoContext';

/**
 * Causes EvenInfoProvider to exit the loading state.
 *
 * Should be rendered after all other child components of EventInfoProvider which could call `useSetEventHandle()`.
 * See [eventInfo/README.md](./README.md) for more details.
 */
export const FinishLoadingEventInfo: React.FC = () => {
  const internalEventInfo = useInternalEventInfoContext();

  useMount(() => {
    internalEventInfo?.onFinishLoadingEventInfo();
  });

  return null;
};
