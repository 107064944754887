import { useEffect, useState, useCallback, useRef } from 'react';
import type { BreakpointAliasKey } from '@withjoy/joykit/theme';
import { breakpointsByScreen as joykitBreakpointsBySize, Screen as JoykitScreen } from '@withjoy/joykit';
import { breakpoints, ScreenBreakpoints } from '@shared/utils/style/breakpoints';
import { withWindow } from '../withWindow';

type ScreenBreakpoint = string | 0;
const screenBreakpoins: ScreenBreakpoints = breakpoints;
function breakpointsByScreen(screen: BreakpointAliasKey | JoykitScreen | string) {
  if (screen in screenBreakpoins) {
    return screenBreakpoins[screen as BreakpointAliasKey];
  }
  if (screen.endsWith('rem')) {
    return screen;
  }
  return joykitBreakpointsBySize(screen as JoykitScreen);
}

export interface UseMediaArgs<T> {
  values: Partial<Record<BreakpointAliasKey | JoykitScreen | string, T>>;
}

/**
 * Convers a rem value to an int for sorting
 * Ex: 24rem => 24
 * @param val
 */
const convertRemToInt = (val: ScreenBreakpoint): number => {
  if (typeof val === 'string') {
    return Number(val.slice(0, -3));
  }
  return 0;
};

interface MediaQueryObject<T> {
  media: string;
  value: T;
}

/**
 * Converts the list of values and screens into an ordered list of css media queries and values
 * @param values
 */
const orderRemValues = <T>(values: Partial<Record<BreakpointAliasKey | JoykitScreen, T>>): Array<MediaQueryObject<T>> => {
  return Object.entries(values)
    .map(entries => ({ value: entries[1] as T, size: breakpointsByScreen(entries[0]) }))
    .sort((a, b) => (convertRemToInt(a.size as ScreenBreakpoint) < convertRemToInt(b.size as ScreenBreakpoint) ? -1 : 1))
    .map(entry => ({ media: `(min-width: ${entry.size})`, value: entry.value }));
};

/**
 * Gets the value of the largest matching media query
 * @param media
 * @param orderedMediaList
 */
const getCalculatedValue = <T>(media: MediaQueryList[], orderedMediaList: Array<MediaQueryObject<T>>) => {
  return media.reduce((acc: T | undefined, curr: MediaQueryList) => {
    const val = orderedMediaList.find(val => val.media === curr.media);
    if (val && curr.matches) {
      return val.value;
    } else {
      return acc;
    }
  }, undefined);
};

/**
 * Returns a value based on the current size of the screen.
 * A value can be assigned to a screen size which will return if that screen size matches the current window size.
 * Not all screen sizes need to be used, it will default to the largest matching media query.
 *
 * @param args
 */
function useResponsive<T>(args: UseMediaArgs<T>): [T | undefined];
function useResponsive<T>(args: UseMediaArgs<T>, initialValue: T): [T];
function useResponsive<T>(args: UseMediaArgs<T>, initialValue?: T): [T | undefined] {
  const { values } = args;

  const orderedMediaListRef = useRef(orderRemValues(values));

  const getMatch = useCallback((targetWindow: Window, mediaQueryList: Array<MediaQueryObject<T>>) => {
    const media = mediaQueryList.map(item => targetWindow.matchMedia(item.media));
    return getCalculatedValue(media, mediaQueryList);
  }, []);

  const [currentValue, setCurrentValue] = useState<T | undefined>(initialValue);

  const updateCurrentValue = useCallback((value: T | undefined) => {
    if (typeof value !== 'undefined') {
      setCurrentValue(value);
    }
  }, []);

  useEffect(() => {
    return withWindow(
      global => {
        const orderedMediaList = orderedMediaListRef.current;
        updateCurrentValue(getMatch(global, orderedMediaList));

        const handleResize = () => {
          updateCurrentValue(getMatch(global, orderedMediaList));
        };

        global.addEventListener('resize', handleResize);
        return () => global.removeEventListener('resize', handleResize);
      },
      () => {}
    );
  }, [updateCurrentValue, getMatch]);

  return [currentValue];
}

export { useResponsive };
