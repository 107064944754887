import { IconConfig } from '..';
export const CLASS_ICON_BASE = 'joykit-icon__base';

/* tslint:disable:max-line-length */

export const iconBase: IconConfig = {
  name: 'iconBase',
  a11yLabel: 'Icon Base',
  viewBox: 40,
  fill: '#333333',
  body: `
  <path class="${CLASS_ICON_BASE}" opacity="0.48" fill-rule="evenodd" clip-rule="evenodd" d="M39.0009 9.82147C38.1556 5.28706 34.5961 1.8231 30.1483 0.97785C26.7688 0.312616 23.3427 0 20.0061 0C16.5799 0 13.1567 0.312616 9.77646 0.97785C5.23909 1.8231 1.81588 5.33299 0.968405 9.77776C-0.322802 16.5316 -0.322802 23.4217 0.968405 30.1326C1.81291 34.71 5.32651 38.1332 9.77646 38.9784C13.1567 39.6874 16.5829 40 20.0061 40C23.4293 40 26.8096 39.6874 30.1921 39.0221C34.7294 38.1332 38.1556 34.667 39.0009 30.1785C40.3343 23.4217 40.3343 16.5316 39.0009 9.82147Z" fill="#DBDBDB"/>
  `
};
