import { styled, StyleSystemProps, keyframes } from '@withjoy/joykit';
import { Box } from '../Box';
import type { ComponentColorTokens } from '@withjoy/joykit/theme';
import type { ButtonV2Props, ButtonVariant, ButtonSize, Intent, ButtonShape, ButtonDefaultProps } from './Button.types';
import type { IconSize } from '@withjoy/joykit/components/IconV2/Icon.types';
import { animationTransition } from '@shared/utils/animationTransition';
import { mergeWith } from 'lodash-es';
import { anatomy } from '../../common/utils/anatomy';
import { MultiPartStyleConfig, PartsStyleFunction } from '../../common/utils/styleConfig';

const spinAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const StyledSpinner = styled(Box)`
  animation: ${spinAnimation} 0.45s linear infinite;
`;

export const StyledContent = styled.span``;

export const StyledContainer = styled(Box)`
  appearance: none !important;
`;

export const StyledStartIcon = styled(Box)<{ $noMarginX: boolean }>`
  display: inline-flex;
  ${props => {
    return {
      [props.theme.direction === 'rtl' ? 'marginLeft' : 'marginRight']: props.$noMarginX ? undefined : props.theme.space[3]
    };
  }}
`;
export const StyledEndIcon = styled.span<{ $noMarginX: boolean }>`
  display: inline-flex;
  ${props => {
    return {
      [props.theme.direction === 'rtl' ? 'marginRight' : 'marginLeft']: props.$noMarginX ? undefined : props.theme.space[3]
    };
  }}
`;
// NOTE: This is a dynamically generated string, hard to search.
// TODO: find things like 'buttonPrimaryFill' and ensure it maps to corresponding value
// in global theme.
const getComponentColorTokens = (intent: Intent) => {
  const modifier = intent[0].toUpperCase() + intent.slice(1);
  return {
    text: `button${modifier}Text`,
    fill: `button${modifier}Fill`,
    hover: `button${modifier}Hover`,
    active: `button${modifier}Active`
  } as Record<'text' | 'fill' | 'hover' | 'active', keyof ComponentColorTokens>;
};

const colorTokensByIntent: Record<Intent, ReturnType<typeof getComponentColorTokens>> = {
  primary: getComponentColorTokens('primary'),
  productive: getComponentColorTokens('productive'),
  destructive: getComponentColorTokens('destructive'),
  neutral: getComponentColorTokens('neutral')
};

const baseStyles: StyleSystemProps = {
  display: 'inline-flex',
  appearance: 'none',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '4px',
  verticalAlign: 'middle',
  border: 0,
  textDecoration: 'none',
  position: 'relative',
  whiteSpace: 'nowrap',
  transition: animationTransition('background-color', 'color', 'border-color'),
  typographyVariant: 'button1',
  _disabled: {
    cursor: 'not-allowed',
    boxShadow: 'none',
    color: 'typeDisabledDark'
  },
  _hover: {
    _disabled: {
      color: 'typeDisabledDark',
      backgroundColor: 'initial'
    }
  }
};

const getSizeProps: Record<ButtonSize, (variant: ButtonVariant) => StyleSystemProps> = {
  sm: variant => ({
    height: 7,
    minWidth: 7,
    paddingX: 4,
    typographyVariant: variant === 'link' ? 'body1' : 'button1'
  }),
  md: variant => ({
    height: 8,
    minWidth: 8,
    paddingX: 5,
    typographyVariant: variant === 'link' ? 'body2' : 'button2'
  }),
  lg: variant => ({
    height: 9,
    minWidth: 9,
    paddingX: 6,
    typographyVariant: variant === 'link' ? 'body2' : 'button2'
  })
};

const shapeProps: Record<ButtonShape, StyleSystemProps> = {
  square: {
    borderRadius: 2
  },
  rounded: {
    borderRadius: 'full'
  }
};

const solidVariantProps = (intent: Intent): StyleSystemProps => {
  const tokens = colorTokensByIntent[intent];
  return {
    backgroundColor: tokens.fill,
    color: tokens.text,
    _hover: {
      backgroundColor: tokens.hover,
      _disabled: {
        backgroundColor: 'buttonDisabledFill'
      }
    },
    _active: {
      backgroundColor: tokens.active
    },
    _disabled: {
      backgroundColor: 'buttonDisabledFill',
      cursor: 'not-allowed'
    }
  };
};

const buttonOutlineColorTokens: Record<Intent, Record<'text' | 'textHover' | 'textPress' | 'fillHover' | 'fillPress', keyof ComponentColorTokens>> = {
  primary: {
    text: 'buttonOutlinePrimaryText',
    textHover: 'buttonOutlinePrimaryTextHover',
    textPress: 'buttonOutlinePrimaryTextPress',
    fillHover: 'buttonOutlinePrimaryFillHover',
    fillPress: 'buttonOutlinePrimaryFillPress'
  },
  productive: {
    text: 'buttonOutlineProductiveText',
    textHover: 'buttonOutlineProductiveTextHover',
    textPress: 'buttonOutlineProductiveTextPress',
    fillHover: 'buttonOutlineProductiveFillHover',
    fillPress: 'buttonOutlineProductiveFillPress'
  },
  destructive: {
    text: 'buttonOutlineDestructiveText',
    textHover: 'buttonOutlineDestructiveTextHover',
    textPress: 'buttonOutlineDestructiveTextPress',
    fillHover: 'buttonOutlineDestructiveFillHover',
    fillPress: 'buttonOutlineDestructiveFillPress'
  },
  neutral: {
    text: 'buttonOutlineNeutralText',
    textHover: 'buttonOutlineNeutralTextHover',
    textPress: 'buttonOutlineNeutralTextPress',
    fillHover: 'buttonOutlineNeutralFillHover',
    fillPress: 'buttonOutlineNeutralFillPress'
  }
};

const ghostVariantProps = (intent: Intent): StyleSystemProps => {
  const tokens = buttonOutlineColorTokens[intent];
  return {
    backgroundColor: 'transparent',
    color: tokens.text,
    _hover: {
      color: tokens.textHover,
      _disabled: {
        color: 'typeDisabledDark'
      }
    },
    _active: {
      color: tokens.textPress
    },
    _disabled: {
      color: 'typeDisabledDark'
    }
  };
};

const outlineVariantProps = (intent: Intent): StyleSystemProps => {
  const colorTokens = buttonOutlineColorTokens[intent];

  return mergeWith({}, ghostVariantProps(intent), {
    textTransform: undefined,
    borderColor: colorTokens.text,
    border: '2px solid',
    _hover: {
      borderColor: colorTokens.textHover,
      backgroundColor: colorTokens.fillHover,
      color: colorTokens.textHover,
      _disabled: {
        color: 'typeDisabledDark',
        backgroundColor: 'white',
        borderColor: 'mono3'
      }
    },
    _active: {
      backgroundColor: colorTokens.fillPress,
      borderColor: colorTokens.textPress,
      color: colorTokens.textPress
    },
    _disabled: {
      color: 'typeDisabledDark',
      backgroundColor: 'white',
      borderColor: 'mono3'
    }
  });
};

const linkVariantProps = (intent: Intent): StyleSystemProps => {
  const colorTokens = buttonOutlineColorTokens[intent];
  return {
    backgroundColor: 'transparent',
    height: 'auto',
    paddingX: 0,
    typographyVariant: 'body2',
    color: colorTokens.text,
    _hover: {
      textDecoration: 'underline',
      color: colorTokens.textHover,
      _disabled: {
        textDecoration: 'none',
        color: 'typeDisabledDark'
      }
    },
    _active: {
      color: colorTokens.textPress
    }
  };
};

const variantProps: Record<ButtonVariant, (intent: Intent) => StyleSystemProps> = {
  outline: intent => outlineVariantProps(intent),
  ghost: intent => ghostVariantProps(intent),
  link: intent => linkVariantProps(intent),
  solid: intent => solidVariantProps(intent)
};

export const buttonSizeToIconSize: ReadonlyRecord<ButtonSize, IconSize> = {
  sm: 'sm',
  md: 'md',
  lg: 'md'
};

const spinnerStyles: StyleSystemProps = {
  display: 'inline-block',
  borderTop: '2px solid currentcolor',
  borderRight: '2px solid currentcolor',
  borderBottomStyle: 'solid',
  borderLeftStyle: 'solid',
  borderRadius: 'full',
  borderBottomWidth: '2px',
  borderLeftWidth: '2px',
  borderBottomColor: 'transparent',
  borderLeftColor: 'transparent',
  size: '1em',
  color: 'currentColor'
};

const buttonBaseStyle: PartsStyleFunction<ButtonV2Props & ButtonDefaultProps, typeof buttonAnatomy> = props => {
  return {
    root: mergeWith(
      {},
      baseStyles,
      getSizeProps[props.size]?.(props.variant),
      shapeProps[props.shape],
      variantProps[props.variant]?.(props.intent),
      props.fullWidth ? { width: '100%' } : {}
    ),
    spinner: spinnerStyles
  };
};

const buttonAnatomy = anatomy('Button').parts('root', 'spinner');

export const buttonStyles: MultiPartStyleConfig<ButtonV2Props & ButtonDefaultProps, typeof buttonAnatomy> = {
  parts: buttonAnatomy.keys,
  baseStyle: buttonBaseStyle,
  defaultProps: {
    size: 'lg'
  }
};
