import { IconConfig } from '..';

/* tslint:disable:max-line-length */
export const starIcon: IconConfig = {
  name: 'star',
  a11yLabel: 'Star',
  viewBox: 24,
  fill: '#94D0A7',
  body: `
  <path d="M5.08334 22.8872C5.04466 22.8872 5.00599 22.8752 4.97313 22.8514C4.90732 22.8035 4.87985 22.7187 4.90512 22.6414L7.5327 14.6092L0.684024 9.654C0.618118 9.60629 0.590461 9.52153 0.615633 9.44415C0.640711 9.36689 0.712711 9.31454 0.793946 9.31454C0.794039 9.31454 0.79418 9.31454 0.794274 9.31454L9.24757 9.32972L11.8424 1.28663C11.8674 1.20924 11.9394 1.15674 12.0208 1.15674C12.1022 1.15674 12.1743 1.20919 12.1992 1.28663L14.7962 9.32977L23.2496 9.31458C23.2497 9.31458 23.2498 9.31458 23.2499 9.31458C23.3311 9.31458 23.4031 9.36689 23.4282 9.44419C23.4534 9.52153 23.4257 9.60633 23.3598 9.65405L16.5111 14.6092L19.1388 22.6414C19.1641 22.7188 19.1367 22.8036 19.0708 22.8514C19.005 22.8992 18.9158 22.8992 18.8501 22.8512L12.0209 17.8695L5.19387 22.8511C5.16096 22.8751 5.12215 22.8872 5.08334 22.8872Z"/>
  <path d="M5.08335 22.8872C5.04468 22.8872 5.00601 22.8752 4.97315 22.8514C4.90734 22.8035 4.87987 22.7187 4.90513 22.6414L7.53271 14.6092L0.684039 9.654C0.618133 9.60629 0.590477 9.52153 0.615648 9.44415C0.640727 9.36689 0.712727 9.31454 0.793961 9.31454C0.794055 9.31454 0.794195 9.31454 0.794289 9.31454L9.24759 9.32972L11.8424 1.28663C11.8674 1.20924 11.9394 1.15674 12.0208 1.15674C12.1022 1.15674 12.1743 1.20919 12.1993 1.28663L14.7962 9.32977L23.2496 9.31458C23.2497 9.31458 23.2498 9.31458 23.2499 9.31458C23.3311 9.31458 23.4031 9.36689 23.4283 9.44419C23.4534 9.52153 23.4257 9.60633 23.3598 9.65405L16.5112 14.6092L19.1388 22.6414C19.1641 22.7188 19.1367 22.8036 19.0709 22.8514C19.005 22.8992 18.9158 22.8992 18.8501 22.8512L12.0209 17.8695L5.19388 22.8511C5.16098 22.8751 5.12216 22.8872 5.08335 22.8872Z"/>
  
  `
};
