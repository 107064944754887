import type { Location as LocationHistory } from 'history';
import { deriveQueryStringHelpersFromLocation, qs } from '@shared/core/queryString';
import { FeatureFlags } from './featureFlags.constants';
import type { FeatureFlagsState, FeatureFlagsContextType, FeatureFlagKey } from './featureFlags.types';
import { Draft } from 'immer';

const _isSupportedValue = (supportedValues: ReadonlyArray<string> | ReadonlyArray<boolean> | boolean, value: Maybe<string | boolean>) => {
  return !!value && Array.isArray(supportedValues)
    ? supportedValues.includes(value)
    : // When `supportedValues` is  of type boolean, we accept all non-empty string values
      true;
};

export const updateStateDraftFromLocation = (config: FeatureFlags, stateDraft: Draft<FeatureFlagsState>, location: Maybe<Location | LocationHistory>): void => {
  const { getValueString } = deriveQueryStringHelpersFromLocation(location);
  Object.entries(config).forEach(([feature, { queryParam, supportedValues, type }]) => {
    const storedValue = stateDraft[feature as FeatureFlagKey];
    const rawValue = getValueString(queryParam);
    const maybeValue = type === 'boolean' ? rawValue === 'true' : rawValue;

    // Prefer new value if valid, otherwise default to the current (string | undefined)
    // @ts-ignore
    stateDraft[feature] = rawValue !== undefined && _isSupportedValue(supportedValues, maybeValue) ? maybeValue : storedValue;
  });
};

export const deriveFeatureContextFromState = (config: FeatureFlags, state: FeatureFlagsState) => {
  return Object.entries(config).reduce((acc, [feature, featureValue]) => {
    // @ts-ignore
    acc[feature] = {
      ...featureValue,
      // @ts-ignore
      value: state[feature]
    };
    return acc;
  }, {} as FeatureFlagsContextType['features']);
};

const _transformStateToQueryObject = (config: FeatureFlags, state: FeatureFlagsState) => {
  return Object.entries(config).reduce((acc, [feature, { queryParam }]) => {
    // @ts-ignore
    acc[queryParam] = state[feature];
    return acc;
  }, {} as Record<string, string>);
};

export const formatFeatureFlagsToUrl = (config: FeatureFlags, state: FeatureFlagsState, url: string = '') => {
  const { url: providedUrl, fragmentIdentifier, query } = qs.parseUrl(url, { parseFragmentIdentifier: true });
  const updatedQuery = { ..._transformStateToQueryObject(config, state), ...query };
  return qs.stringifyUrl({ url: providedUrl, query: updatedQuery, fragmentIdentifier });
};
