import { spaceScale } from './shared/space';
import { createBreakpoints, defaultBreakpointWidths } from './shared/breakpoints';
import { createMediaQueries } from './shared/mediaQuery';
import { createTypography } from './shared/typography';
import { createLayoutGrid } from './shared/grid';
import { createColors } from './shared/colors/createColors';
import { getSizes } from './shared/size';
import { extendTheme } from './';
import type { Theme, BreakpointWidthsPx } from './';

export type CreateThemeArgs = Readonly<
  Partial<{
    defaults: Readonly<
      Partial<{
        breakpointWidths: BreakpointWidthsPx;
      }>
    >;

    overrides: DeepPartial<Theme>;
  }>
>;

// TODO: Add overrides later
// Components are built on an 8px grid
// P1: overrides
// Tested by individual modules
/* istanbul ignore next */
export function createTheme(args: CreateThemeArgs = {}): Readonly<Theme> {
  const overrides = args.overrides || {};
  const breakpointWidths = args.defaults?.breakpointWidths || defaultBreakpointWidths;
  // const { overrides = {}, breakpointWidths } = args;
  // Per discussion with design, breakpoint values will be the same for both guest + admin contexts.
  // What we do allow is context designated media queries. We can choose a subset of the break point values
  // for a given layout, ecard, etc.
  //
  // EG. Brannan breakpoint targets could look like: [600, 840, 1280].
  const breakpoints = createBreakpoints(breakpointWidths);

  // A `MediaQuery` references a defined breakpoint.
  const mediaQueries = createMediaQueries(breakpoints);

  const layoutGrid = createLayoutGrid(breakpointWidths);
  const typeTokens = createTypography();

  const theme: Theme = {
    space: spaceScale as Theme['space'],
    sizes: getSizes(spaceScale),
    breakpoints,
    mediaQueries,
    grid: layoutGrid,
    colors: createColors(),
    ...typeTokens,
    radii: Object.assign([], [0, 2, 4, 8, 16, 32].map(typeTokens.typography.pxToRem), { full: '9999px' }),
    direction: 'ltr',
    zIndices: {
      hide: -1,
      auto: 'auto',
      base: 0,
      docked: 10,
      dropdown: 1000,
      sticky: 1100,
      banner: 1200,
      overlay: 1300,
      modal: 1400,
      popover: 1500,
      skipLink: 1600,
      toast: 1700,
      tooltip: 1800
    },
    global: {}
  };
  return extendTheme(overrides, theme);
}

export const defaultTheme = createTheme();
