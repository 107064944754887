import { useEffect, useRef } from 'react';

function usePreviousValue<T extends unknown>(value: T): T | undefined;
function usePreviousValue<T extends unknown>(value: T, initialValue: T): T;
function usePreviousValue<T extends unknown>(value: T, initialValue?: T): T | undefined {
  const ref = useRef<T | undefined>(initialValue);
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export { usePreviousValue };
