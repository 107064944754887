import { withWindow } from '@shared/utils/withWindow';
import { config } from '@static/js/env.config';

export const initNewRelic = () => {
  withWindow(window => {
    const { newrelic, buildNumber } = window;

    if (newrelic) {
      newrelic.setCustomAttribute('buildNumber', buildNumber);
      newrelic.setCustomAttribute('env', config.joyEnvironment);
      newrelic.addRelease('joy-web', buildNumber);
    }
  });
};
