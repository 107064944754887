import { IconConfig } from '..';

/* tslint:disable:max-line-length */
export const edit: IconConfig = {
  name: 'edit',
  a11yLabel: 'Edit',
  viewBox: 24,
  fill: '#333333',
  body: `
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M22.094 3.36194L20.6361 1.90401C19.4308 0.698665 17.468 0.698665 16.2626 1.90401L3.14427 15.0237C3.06222 15.1058 3.00119 15.2078 2.96932 15.3204L1.02653 22.1239C0.957705 22.3649 1.02518 22.622 1.20115 22.7996C1.33236 22.9305 1.50731 23.0007 1.68735 23.0007C1.75075 23.0007 1.8145 22.9929 1.87553 22.9742L8.679 21.0301C8.79157 20.9982 8.89362 20.9372 8.97703 20.8551L22.094 7.73541C22.6796 7.15122 23 6.37512 23 5.54884C23 4.72223 22.6796 3.94478 22.094 3.36194ZM8.12939 19.7586L2.68587 21.3122L4.24077 15.87L14.8057 5.30506L18.693 9.19233L8.12939 19.7586ZM21.1233 6.76334L19.6651 8.22128L15.7781 4.33435L17.2361 2.87608C17.9064 2.20576 18.9964 2.20712 19.6664 2.87608L21.1247 4.33435C21.4505 4.65883 21.6278 5.09044 21.6278 5.5502C21.6268 6.00691 21.4478 6.43886 21.1233 6.76334Z"
    />
  `
};
