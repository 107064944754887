import { Box, BoxProps } from '../Box';
import { styled } from '@withjoy/joykit';

export interface FlexProps extends BoxProps {}

const Flex = styled(Box).attrs<FlexProps>(props => ({ display: props.display || 'flex' }))``;

if (__DEV__) {
  Flex.displayName = 'Flex';
}

export { Flex };
