import { useTheme, StyleSystemProps } from '@withjoy/joykit';
import { useRef } from 'react';
import { StyleConfig, MultiPartStyleConfig, PartsStyleObject, MinimalAnatomy, StyleSystemFnReturn } from '.';
import { mergeWith, omit } from 'lodash-es';
import isEqual from 'react-fast-compare';
import { isFunction } from '@shared/utils/assertions';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ComponentProps = Record<string, any>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isMultiStyleConfig = (config: StyleConfig<any> | MultiPartStyleConfig<any, any>): config is MultiPartStyleConfig<any, any> => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return !!(config as any)?.parts;
};

export function useStyleConfig<Props extends ComponentProps = {}>(styleConfig: StyleConfig<Props>, props: Props): StyleSystemProps;
export function useStyleConfig<Props extends ComponentProps = {}, T extends MinimalAnatomy = MinimalAnatomy>(
  styleConfig: MultiPartStyleConfig<Props, T>,
  props: Props
): PartsStyleObject<T>;
export function useStyleConfig<Props extends ComponentProps = {}, T extends MinimalAnatomy = MinimalAnatomy>(
  styleConfig: StyleConfig<Props> | MultiPartStyleConfig<Props, T>,
  props: Props
) {
  const theme = useTheme();
  styleConfig = props.styleConfig || styleConfig;
  /**
   * Store the computed styles in a `ref` to avoid unneeded re-computation
   */
  type StylesRef = StyleSystemProps | Record<T['__type'], StyleSystemProps>;
  const stylesRef = useRef<StylesRef>({});

  let styles: StyleSystemFnReturn<Props>;
  if (isFunction(styleConfig.baseStyle)) {
    // Delay merge call to when `baseStyle` is a function.
    const mergedProps = mergeWith({ theme }, styleConfig.defaultProps || {}, omit(props, 'children'));
    styles = styleConfig.baseStyle(mergedProps);
  } else {
    styles = styleConfig.baseStyle;
  }

  if (isMultiStyleConfig(styleConfig)) {
    styleConfig.parts.forEach((part: string) => {
      (styles as Record<string, any>)[part] = (styles as Record<string, any>)[part] ?? {};
    });
  }

  if (!isEqual(stylesRef.current, styles)) {
    stylesRef.current = styles;
  }

  return stylesRef.current;
}
