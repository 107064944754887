import 'styled-components';
import { useContext } from 'react';
import { default as styled, ThemeContext, ThemedStyledProps, withTheme, ThemeProvider, css, keyframes, createGlobalStyle, CSSProp, StyledComponent } from 'styled-components';
import { Theme } from '../themes';

export type { FlattenInterpolation, FlattenSimpleInterpolation, CSSObject, StyledComponent, CSSProp } from 'styled-components';

// const {
//   default: styled,
//   css,
//   createGlobalStyle,
//   keyframes,
//   ThemeProvider,
//   withTheme: wt
// } = styledComponents as styledComponents.ThemedStyledComponentsModule<Theme>;

declare module 'styled-components' {
  interface DefaultTheme extends Theme {}
}

declare module 'react' {
  interface DOMAttributes<T> {
    css?: CSSProp;
  }
}
declare global {
  namespace JSX {
    interface IntrinsicAttributes {
      css?: CSSProp;
    }
  }
}

export type ThemeStyledProps<T> = ThemedStyledProps<T, Theme>;

/**
 * A HOC factory to get the current theme from a `JoyKitThemeProvider` and pass it to your
 * component as a `theme` prop.
 *
 * Note: Should be used within a `JoyKitThemeProvider` hierarchy.
 *
 * @example
 *
 * ```
 * class MyComponent extends React.Component {
 *    render() {
 *      console.log('Current theme: ', this.props.theme)
 *      // ...
 *    }
 *  }
 * ```
 *
 * export const MyComponentWithTheme = withTheme(MyComponent);
 */

const useTheme = (): Theme => {
  return useContext(ThemeContext);
};

type JoyKitStyledComponent<C extends keyof JSX.IntrinsicElements | React.ComponentType<any>, O extends object = {}, A extends keyof any = never> = StyledComponent<C, Theme, O, A>;

export { styled, css, ThemeProvider, withTheme, useTheme, keyframes, createGlobalStyle };
export type { JoyKitStyledComponent };
