import { OverlayProvider } from '../Overlay';
import React, { createContext, useContext, useEffect, useMemo } from 'react';
import { useImmer } from 'use-immer';

import { ThemeProvider, defaultTheme, Theme } from '@withjoy/joykit/theme';
import { createDisplayName } from '@withjoy/joykit/utils';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';
import { SsrSafeIdProvider } from '@withjoy/joykit/hooks';

type ChangeThemeHandler = (currentTheme: Readonly<Theme>) => Theme;

type JoyKitContextType = {
  changeTheme: (arg: Theme | ChangeThemeHandler) => void;
  theme: Theme;
};

const JoyKitContext = createContext<JoyKitContextType | undefined>(undefined);

export const useJoyKitContext = () => {
  const context = useContext(JoyKitContext);

  if (!context) {
    throw new Error(`useJoyKitContext() should be nested under a JoyKitThemeProvider`);
  }
  return context;
};

export interface JoyKitThemeProviderProps {
  children: React.ReactNode;
  theme?: Theme;
}

export const JoyKitThemeProvider: React.FC<JoyKitThemeProviderProps> = ({ children, theme = defaultTheme }) => {
  const [state, setState] = useImmer<{ theme: Theme }>({ theme });
  const handleChangeTheme = useEventCallback<JoyKitContextType['changeTheme']>(arg => {
    setState(draft => {
      if (typeof arg === 'function') {
        return {
          theme: arg((draft.theme as unknown) as Theme)
        };
      }

      return {
        theme: arg
      };
    });
  });

  const value = useMemo(() => {
    return {
      changeTheme: handleChangeTheme,
      theme
    };
  }, [handleChangeTheme]);

  useEffect(() => {
    setState(() => ({ theme }));
  }, [theme]);

  return (
    <SsrSafeIdProvider>
      <JoyKitContext.Provider value={value}>
        <ThemeProvider theme={state.theme}>
          <OverlayProvider>{children}</OverlayProvider>
        </ThemeProvider>
      </JoyKitContext.Provider>
    </SsrSafeIdProvider>
  );
};

JoyKitThemeProvider.displayName = createDisplayName('JoyKitThemeProvider');
