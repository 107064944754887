import React from 'react';
import { styled, keyframes } from '@shared/joykit/packages/core/common/styledComponents';

/**
 * TODO: Add in color states, full screen, size
 */
interface Props {
  className?: string;
}

export const spinAnimation = keyframes({
  from: { transform: 'rotate(0deg)' },
  to: { transform: 'rotate(360deg)' }
});

const StyledSpinner = styled.div`
  height: 20px;
  width: 20px;
  border: 2px solid black;
  border-top-color: white;
  border-radius: 50%;
  animation: ${spinAnimation} 1.5s ease infinite;
`;

const SpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
`;

export const Spinner: React.SFC<Props> = ({ className }) => {
  return (
    <SpinnerContainer className={className}>
      <StyledSpinner />
    </SpinnerContainer>
  );
};
