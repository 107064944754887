import * as React from 'react';
// eslint-disable-next-line no-restricted-imports
import type { StyledComponent } from 'styled-components';
import type { Theme } from '@withjoy/joykit';

//////////////////////////////////////////////////////////////////////////
// The following types help with automatic type inference based on the
// "as" prop

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type As<Props = any> = React.ElementType<Props>;

export type RightJoinProps<SourceProps extends object = {}, OverrideProps extends object = {}> = Omit<SourceProps, 'as' | 'color' | keyof OverrideProps> & OverrideProps;

export type MergeWithAs<ComponentProps extends object, AsProps extends object, AdditionalProps extends object = {}, AsComponent extends As = As> = RightJoinProps<
  ComponentProps,
  AdditionalProps
> &
  RightJoinProps<AsProps, AdditionalProps> & { as?: AsComponent };

export type PropsWithAs<T extends As, P> = P &
  Omit<React.ComponentPropsWithoutRef<T>, keyof P> & {
    as?: T | As;
  };

// Types taken from 'styled-components'
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type StyledComponentPropsWithAs<C extends keyof JSX.IntrinsicElements | React.ComponentType<any>, O extends object = {}, A extends keyof any = string> = StyledComponent<
  C,
  Theme,
  O,
  A
> & { as?: C | As };

export interface ComponentWithAs<Component extends As, Props extends object = {}> {
  // (props: MergeWithAs<React.ComponentPropsWithRef<Component>, {}, P, As> & { as?: never }, context?: any): React.ReactElement | null;
  /**
   * Inherited from React.FunctionComponent with modifications to support `as`
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  <AsComponent extends As = Component>(
    props: MergeWithAs<React.ComponentProps<Component>, React.ComponentProps<AsComponent>, Props, AsComponent>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    context?: any
  ): JSX.Element;

  /**
   * Inherited from React.FunctionComponent
   */
  displayName?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  propTypes?: React.WeakValidationMap<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  contextTypes?: React.ValidationMap<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  defaultProps?: Partial<any>;
}

export type FCWA<Component extends As, P extends object = {}> = ComponentWithAs<Component, P>;

export type ElementRef<Component> = Component extends keyof HTMLElementTagNameMap ? HTMLElementTagNameMap[Component] : Component;

export const forwardRef = <Component extends As, Props extends object, E = {}>(
  comp: (
    props: PropsWithAs<Component, RightJoinProps<React.ComponentPropsWithoutRef<Component> & { as?: As }, Props>>,
    ref: React.RefObject<ElementRef<Component>>
  ) => React.ReactNode
) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (React.forwardRef(comp as any) as unknown) as ComponentWithAs<Component, Props>;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ExtractComponentProps<Component = keyof JSX.IntrinsicElements | React.ComponentType<any>> = Component extends keyof JSX.IntrinsicElements
  ? Omit<JSX.IntrinsicElements[Component], 'size' | 'color'>
  : Component extends React.ComponentType<infer R>
  ? Omit<R, 'ref'>
  : never;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const forwardStyledComponentRef = <Component extends As, Props extends object, E extends keyof any = never>(
  comp: (
    props: PropsWithAs<Component, RightJoinProps<React.ComponentPropsWithoutRef<Component> & { as?: As }, Props>>,
    ref: React.RefObject<ElementRef<Component>>
  ) => React.ReactNode
) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (React.forwardRef(comp as any) as unknown) as StyledComponent<React.FC<Merge<ExtractComponentProps<Component>, Props>>, Theme, Props, E>;
};
