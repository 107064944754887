import globalWindow from '@shared/core/globals';

export interface Storage {
  getItem: (key: string) => string | null | undefined;
  setItem: (key: string, value?: string | null | undefined) => void;
}

const testKey = 'testKey';
const testValue = 'val' + Date.now();

import { MemoryStorage } from './memoryStorage';
import { CookieStorage, COOKIE_PREFIX_KEY } from './cookieStorage';
import { LocalStorage } from './localStorage';
import { SessionStorage } from './sessionStorage';

const isStorageEnabled = (constructorFn: new () => Storage & { removeItem?: (key: string) => void }) => {
  try {
    const storage = new constructorFn();
    if (!storage) {
      return false;
    }

    storage.setItem(testKey, testValue);
    if (storage.getItem(testKey) !== testValue) {
      return false;
    }

    storage.setItem(testKey, null);
    if (storage.getItem(testKey) !== null) {
      return false;
    }

    if (storage.removeItem) {
      storage.removeItem(testKey);
    }

    return true;
  } catch (err) {
    return false;
  }
};

const areCookiesEnabled = () => {
  if (!globalWindow.navigator || !globalWindow.navigator.cookieEnabled) {
    return false;
  }

  return isStorageEnabled(CookieStorage);
};

const createLocalStorage = (): Storage => {
  if (isStorageEnabled(LocalStorage)) {
    return new LocalStorage();
  } else if (areCookiesEnabled()) {
    return new CookieStorage();
  } else {
    return new MemoryStorage();
  }
};

let cookieStorage: CookieStorage;
const getCookieStorage = (): CookieStorage | undefined => {
  if (cookieStorage) {
    return cookieStorage;
  }
  if (areCookiesEnabled()) {
    cookieStorage = new CookieStorage();
    return getCookieStorage();
  }
  return;
};

let storage: Storage;
const getLocalStorage = (): Storage => {
  if (storage) {
    return storage;
  }

  storage = createLocalStorage();
  return getLocalStorage();
};

const createSessionStorage = (): Storage => {
  if (isStorageEnabled(SessionStorage)) {
    return new SessionStorage();
  } else {
    return new MemoryStorage();
  }
};

let sessionStorage: Storage;
const getSessionStorage = (): Storage => {
  if (sessionStorage) {
    return sessionStorage;
  }

  sessionStorage = createSessionStorage();
  return sessionStorage;
};

// TODO: Willwant to reconsider how we initalize this
getLocalStorage();

export { getLocalStorage, getSessionStorage, getCookieStorage, CookieStorage, COOKIE_PREFIX_KEY, isStorageEnabled };
