import React, { useState, useCallback, useMemo } from 'react';
import { OverlayRoot } from './';
import { OverlayContext, OverlayContextType, ExtendedOverlayableProps, OverlayComponentType } from './';
import { OVERLAY_PROVIDER_WARN_KEY_DOES_NOT_EXIST, OVERLAY_PROVIDER_WARN_REGISTER_CONTAINS_KEY, OVERLAY_PROVIDER_WARN_UNREGISTER_INVALID_KEY } from '../../../core/common/errors';
import { warningMessage } from '../../../core/utils';
import { NoSsr } from '@shared/components/NoSsr';

export interface OverlayComponentDescription {
  render: (props: any) => React.ReactElement<OverlayComponentType<any>>;
  isOpen: boolean;
  props: ExtendedOverlayableProps;
}

/**
 * A mapping between a string key with an `OverlayComponentDescription` element.
 */
export type OverlayDescriptionMap = Map<string, OverlayComponentDescription>;

/**
 * A mapping of overlay key to `OverlayDescription`
 */
const overlayDescriptionMap: OverlayDescriptionMap = new Map<string, OverlayComponentDescription>();

/**
 * Return a collection of overlay descriptions per the original insertion order of the keys.
 */
const getOverlayItemsAsArray = (overlayDescriptions: OverlayDescriptionMap): OverlayComponentDescription[] => {
  const items: OverlayComponentDescription[] = [];
  overlayDescriptions.forEach((value, key) => items.push(value));
  return items;
};

/**
 * Utility function to show/hide an overlay with a given key.
 */
function toggleOverlay(
  overlayDescriptions: OverlayDescriptionMap,
  dispatch: React.Dispatch<React.SetStateAction<OverlayComponentDescription[]>>,
  key: string,
  isOpen: boolean
): boolean {
  const overlayDescription = overlayDescriptions.get(key);
  warningMessage(overlayDescription, OVERLAY_PROVIDER_WARN_KEY_DOES_NOT_EXIST(key));
  if (overlayDescription) {
    if (overlayDescription.isOpen === isOpen) {
      return true;
    }
    // Description with a given key exists
    const updatedOverlayDescription: OverlayComponentDescription = {
      ...overlayDescription,
      // Update `isOpen` prop with the input
      // Note: We don't toggle it b/c that may be uninintentional
      isOpen
    };

    // Set/Update the map with the newly updated description
    // Note: Updating an element with a specified key will *not* affect insertion order.
    overlayDescriptions.set(key, updatedOverlayDescription);
    dispatch(() => {
      // Update the state with the newly updated overlay description
      return getOverlayItemsAsArray(overlayDescriptions);
    });
    return true;
  }
  return false;
}

export const OverlayProvider: React.SFC<{}> = ({ children }) => {
  const [overlayDescriptions, setOverlayDescriptions] = useState<OverlayComponentDescription[]>([]);

  // ==============================================================
  // Visibility handlers
  // ==============================================================

  const show = useCallback<OverlayContextType['show']>(key => {
    return toggleOverlay(overlayDescriptionMap, setOverlayDescriptions, key, true);
  }, []);

  const hide = useCallback<OverlayContextType['hide']>((key: string) => {
    return toggleOverlay(overlayDescriptionMap, setOverlayDescriptions, key, false);
  }, []);

  // ==============================================================
  // Registry handlers
  // ==============================================================

  const register = useCallback<OverlayContextType['register']>(({ overlayKey, render, props }) => {
    const previousOverlayDescription = overlayDescriptionMap.get(overlayKey);
    warningMessage(!previousOverlayDescription, OVERLAY_PROVIDER_WARN_REGISTER_CONTAINS_KEY(overlayKey));
    const isOpen = previousOverlayDescription ? previousOverlayDescription.isOpen : false;

    /**
     * New/Updated overlay description generated from the function arguments.
     *
     * 1. Shallow merge props
     * 2. Inject show/hide handlers
     */
    const overlayDescription: OverlayComponentDescription = {
      render,
      isOpen,
      props: {
        // Spread previous overlay description props.
        ...(previousOverlayDescription ? previousOverlayDescription.props : {}),
        // Set/Update with next overlay description props.
        ...props,
        overlayKey,

        // Inject visibility handlers.
        show,
        hide,
        hideSelf: () => hide(overlayKey)
      }
    };

    // Update overlay tracker with new/updated overlay description.
    overlayDescriptionMap.set(overlayKey, overlayDescription);

    // Update the local overlay collection state.
    setOverlayDescriptions(getOverlayItemsAsArray(overlayDescriptionMap));
  }, []);

  const unregister = useCallback<OverlayContextType['unregister']>(key => {
    // Delete the overlay description with the specified key.
    const success = overlayDescriptionMap.delete(key);
    warningMessage(success, OVERLAY_PROVIDER_WARN_UNREGISTER_INVALID_KEY(key));
    if (success) {
      // The specified key exists in the tracker, and so we should update
      // the local state with the modified tracker content.
      setOverlayDescriptions(getOverlayItemsAsArray(overlayDescriptionMap));
    }
    return success;
  }, []);

  // ==============================================================
  // Utility handlers
  // ==============================================================

  const isOverlayOpen = useCallback<OverlayContextType['isOverlayOpen']>((key: string) => {
    const overlayDescription = overlayDescriptionMap.get(key);
    return overlayDescription ? { registered: true, isOpen: overlayDescription.isOpen } : { registered: false, isOpen: false };
  }, []);

  /**
   * Memoize the context handlers as to not trigger needless
   */
  const contextValue = useMemo(() => ({ show, hide, register, unregister, isOverlayOpen }), []);
  return (
    <OverlayContext.Provider value={contextValue}>
      {children}
      <NoSsr defer={true}>
        <OverlayRoot overlays={overlayDescriptions} />
      </NoSsr>
    </OverlayContext.Provider>
  );
};
