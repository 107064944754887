const BODY_FONT_SIZE = 16; // Number.parseFloat(window.getComputedStyle(document.body).getPropertyValue('font-size'));

export type PixelToRemConversionValue = 'string' | 'number';

function pixelToRemConversion(pixel: number, as: 'string'): string;
function pixelToRemConversion(pixel: number, as: 'number'): number;
function pixelToRemConversion(pixel: number, as: PixelToRemConversionValue = 'number'): string | number {
  const conversionValue = pixel / BODY_FONT_SIZE;

  if (as === 'string') {
    return `${conversionValue}rem`;
  }

  return conversionValue;
}

export { pixelToRemConversion };
