import { useEffect } from 'react';
import { useToasterStore } from 'react-hot-toast';
import { useToast } from './useToast';

export const useLimit = (limit: number) => {
  const { toasts } = useToasterStore();
  const { dismiss } = useToast();

  useEffect(() => {
    toasts
      .filter(toast => toast.visible)
      .slice(limit)
      .forEach(toast => dismiss(toast.id));
  }, [toasts]);
};
