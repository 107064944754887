import { defaultTheme, useTheme } from '../common';

export type Screen = 'mobile' | 'tablet' | 'desktop' | 'widescreen';

/**
 * This function maps screen sizes to their respective sizes found at `breakpoints` under the nearest `theme`.
 *
 * Note: in order for this to tie into the theme config, this function must be used within the
 * context of the `JoyKitThemeProvider`.
 */
export const breakpointsByScreen = (screen: Screen) => {
  const nearestThemeContext = useTheme();
  const theme = nearestThemeContext ? nearestThemeContext : defaultTheme;
  switch (screen) {
    case 'mobile':
      return '0em';
    case 'tablet':
      return theme.breakpoints[1];
    case 'desktop':
      return theme.breakpoints[2];
    case 'widescreen':
      return theme.breakpoints[3];
    default:
      return 0;
  }
};
