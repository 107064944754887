import React, { useContext } from 'react';
import { OverlayableProps } from './';

export type OverlayComponentType<T> = { overlayKey: string } & OverlayableProps & Pick<OverlayContextType, 'hide' | 'show'> & T;

/**
 * ------------------------------------------
 * DEPRECATED -- use `DialogV2` directly
 * ------------------------------------------
 *
 *
 * @deprecated
 */
export interface ExtendedOverlayableProps extends OverlayableProps {
  overlayKey: string;
  show: OverlayContextType['show'];
  hide: OverlayContextType['hide'];
  hideSelf: () => void;
  [x: string]: any;
}

export interface OverlayContextRegisterFunction<T extends object> {
  /**
   * Unique identifier for the overlay element.
   */
  overlayKey: string;

  /**
   * An inline function for inline rendering. The render prop will receive all `OverlayableProps` as well as any additional
   * props provided via the `props` key.
   *
   * By having this be a function instead of a component, we will avoid unnecessary remounting of the
   * overlay element when updating.
   */
  render: (overlayProps: ExtendedOverlayableProps & T) => React.ReactElement<OverlayComponentType<T>>;

  /**
   *  Extends `OverlayableProps` with any additional props. The render method will receive this set of props.
   */
  props?: Omit<OverlayableProps, 'overlayKey'> & T;
}

export interface OverlayContextType {
  /**
   * Show the overlay with a specified key.
   *
   * @returns {boolean} Result of show attempt.
   */
  show: (key: string) => boolean;

  /**
   * Hide the overlay with a specified key.
   *
   * @returns {boolean} Result of hide attempt.
   */
  hide: (key: string) => boolean;

  /**
   * Register and track a given overlay element with the specified key.
   *
   * @param overlayKey Unique identifier for the overlay element.
   * @param render A function that will be called for inline rendering that will allow for the component to be updated once it's mounted - without having to remount.
   * @param props `OverlayableProps` as well as any additional props that will be spread to the component.
   */
  register: <T extends object>(registerArgs: OverlayContextRegisterFunction<T>) => void;

  /**
   * Remove the overlay element with the specified key from the internal overlay tracker
   */
  unregister: (key: string) => boolean;
  isOverlayOpen: (key: string) => { isOpen: boolean; registered: boolean };
}

export const OverlayContext = React.createContext<OverlayContextType>({
  show: () => {
    throw Error('Overlayed component must be set within an OverlayProvider');
  },
  hide: () => {
    throw Error('Overlayed component must be set within an OverlayProvider');
  },
  register: () => {
    throw Error('Overlayed component must be set within an OverlayProvider');
  },
  unregister: () => {
    throw Error('Overlayed component must be set within an OverlayProvider');
  },
  isOverlayOpen: () => {
    throw Error('Overlayed component must be set within an OverlayProvider');
  }
});

export const useOverlayContext = () => {
  return useContext(OverlayContext);
};
