import { notNullOrUndefined } from '@shared/utils/notNullOrUndefined';
import { withWindow } from '@shared/utils/withWindow';

export type Environment = 'dev' | 'stage' | 'prod';

function parseEnvironment(str: string): Environment {
  switch (str) {
    case 'stage':
      return 'stage';
    case 'prod':
      return 'prod';
    default:
      return 'dev';
  }
}

const searchStr = withWindow(window => window.location.search, '');
const searchParams = new URLSearchParams(searchStr.toLowerCase());

const currentEnvironment = parseEnvironment(searchParams.get('env') ?? (process.env.ENVIRONMENT as string));

export type ValuePerEnvironment<T> = {
  [K in Environment]: T;
};

export type ValuesPerEnvironment<TConfig> = {
  [K in keyof TConfig]: TConfig[K] | ValuePerEnvironment<TConfig[K]>;
};

function hasValuesPerEnvironment<TValue>(value: unknown): value is ValuePerEnvironment<TValue> {
  if (typeof value === 'object' || value !== null) {
    const maybeValuePerEnvironment = value as ValuePerEnvironment<unknown>;
    return notNullOrUndefined(maybeValuePerEnvironment.dev) && notNullOrUndefined(maybeValuePerEnvironment.stage) && notNullOrUndefined(maybeValuePerEnvironment.prod);
  }

  return false;
}

function extractConfigValueBasedOnEnvironment<TConfig, TKey extends keyof TConfig>(value: TConfig[TKey] | ValuePerEnvironment<TConfig[TKey]>): TConfig[TKey] {
  if (hasValuesPerEnvironment(value)) {
    return value[currentEnvironment];
  }

  return value;
}

export function extractConfigValuesBasedOnEnvironment<TConfig>(eventTypeConfig: ValuesPerEnvironment<TConfig>): TConfig {
  const newConfigEntries = Object.keys(eventTypeConfig).map(key => {
    return [key, extractConfigValueBasedOnEnvironment<TConfig, keyof TConfig>(eventTypeConfig[key as keyof TConfig])];
  });

  return Object.fromEntries(newConfigEntries);
}
