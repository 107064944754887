import React from 'react';
import { useFullPageLoader } from './FullPageLoaderProvider';

/**
 * Use JoyLogoLoader - To avoid mupltiple JoyLogoLoader on same page
 *
 */
export const JoyLogoLoader: React.FC<{ loaderKey: string }> = ({ loaderKey }) => {
  useFullPageLoader({ key: loaderKey, initialIsLoading: true });
  return <></>;
};

JoyLogoLoader.displayName = 'JoyLogoLoader';
