// implementation heavily influenced by react-aria

import { createContext } from '@shared/utils/createContext';
import React, { memo, useMemo } from 'react';

type SsrIdContextValue = {
  prefix: number;
  current: number;
};

const defaultIdContext: SsrIdContextValue = {
  prefix: Math.round(Math.random() * 10000000000),
  current: 0
};

const [Provider, useContext] = createContext({ name: 'SsrSafeId', initialValue: defaultIdContext, strict: false });

export const SsrSafeIdProvider = memo(({ children }) => {
  const currentContext = useContext();
  const isRootContext = currentContext === defaultIdContext;
  const context: SsrIdContextValue = useMemo(
    () => ({
      prefix: isRootContext ? 0 : ++currentContext.prefix,
      current: 0
    }),
    [isRootContext, currentContext]
  );
  return <Provider value={context}>{children}</Provider>;
});

export const useId = (idProp?: string, prefix?: string): string => {
  const context = useContext();
  return React.useMemo(
    () => {
      return idProp || [prefix, context.prefix, ++context.current].filter(Boolean).join('-');
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [idProp, prefix]
  );
};

/**
 * Hook to generate ids for use in compound components
 *
 * @example
 *
 * ```js
 * const [buttonId, menuId] = useIds("52", "button", "menu")
 *
 * // buttonId will be `button-52`
 * // menuId will be `menu-52`
 * ```
 */
export const useIds = (idProp?: string, ...prefixes: string[]) => {
  const id = useId(idProp);
  return React.useMemo(() => {
    return prefixes.map(prefix => `${prefix}-${id}`);
  }, [id, prefixes]);
};
