import { useContext } from 'react';
import deepmerge from 'deepmerge';
import deepequal from 'deep-equal';
import { AnalyticsContext, IdentifyProp } from './context';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';

export const useIdentify = () => {
  const analytics = useContext(AnalyticsContext);

  const setIdentify = (args?: IdentifyProp) => {
    // this code is making a change to a readonly object
    // probably should create a new object instead
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (analytics as any).identifiedUser = args;
  };

  const identify = useEventCallback((args: IdentifyProp, options?: deepmerge.Options) => {
    const mergedUser = deepmerge(analytics.identifiedUser || {}, args, options);
    if (!deepequal(analytics.identifiedUser, mergedUser)) {
      setIdentify(mergedUser);
      analytics.identify(mergedUser);
    }
  });

  const clearIdentifiedUser = useEventCallback(() => {
    setIdentify();
  });

  return { identify, clearIdentifiedUser };
};
