import { styled } from '@withjoy/joykit';
import { Box, BoxProps, StyleSystemProps } from '../Box';
import { ModalProps } from './Modal.types';
import { anatomy } from '@shared/joykit/packages/core/common/utils/anatomy';
import { createMultiPartStylesContext, MultiPartStyleConfig, PartsStyleFunction, StyleSystemFn } from '../../common/utils/styleConfig';
const INNER_PADDING_PROPS: Readonly<Pick<BoxProps, 'paddingX' | 'paddingY'>> = {
  paddingX: [6, 7],
  paddingY: ['20px', 6]
};

type ContainerPaddingProps = Pick<BoxProps, 'paddingTop' | 'paddingBottom' | 'paddingX'>;

const DEFAULT_CONTAINER_PAD_PROPS: ContainerPaddingProps = {
  paddingTop: [0, 8],
  paddingBottom: [0, '100px'],
  paddingX: [0, 6]
};
export const StyledRoot = styled(Box)``;
export const StyledHeader = styled(Box)``;
export const StyledBody = styled(Box)``;
export const StyledFooter = styled(Box)``;
export const StyledContentContainer = styled(Box)``;
export const StyledContent = styled(Box)``;

//////////////////////////////
// Modal.Header

const baseStyleHeader: StyleSystemFn<ModalProps> = props => ({
  _first: {
    borderTopLeftRadius: '12px',
    borderTopRightRadius: '12px'
  },
  ...(props.stickyBehavior === 'header' || props.stickyBehavior === 'header-footer'
    ? {
        position: 'sticky',
        top: 0,
        zIndex: 'sticky',
        backgroundColor: 'white'
      }
    : undefined),
  ...INNER_PADDING_PROPS
});

//////////////////////////////
// Dialog.CloseButton

const baseStyleCloseButton: StyleSystemProps = {
  position: 'absolute',
  top: { _: 6, sm4: 2 },
  right: 2,
  _focusVisible: {
    outline: 'none'
  }
};

//////////////////////////////
// Dialog.Body

const baseStyleBody: StyleSystemFn<ModalProps> = props => ({
  flex: '1 1 0%',
  ...INNER_PADDING_PROPS,

  ...(props.scrollBehavior === 'inside'
    ? {
        maxHeight: '100%',
        overflowY: 'scroll',
        paddingTop: 3
      }
    : null)
});

//////////////////////////////
// Dialog.Footer

const baseStyleFooter: StyleSystemFn<ModalProps> = props => {
  const { stickyBehavior } = props;
  return {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-end',
    ...(stickyBehavior === 'footer' || stickyBehavior === 'header-footer'
      ? {
          position: 'sticky',
          bottom: 0,
          zIndex: 'sticky',
          backgroundColor: 'white',
          borderBottomRightRadius: [0, 12],
          borderBottomLeftRadius: [0, 12]
        }
      : undefined),
    ...INNER_PADDING_PROPS
  };
};

//////////////////////////////
// Dialog

const baseStyleRoot: StyleSystemFn<ModalProps> = props => {
  return {
    overflow: props.scrollBehavior === 'inside' ? 'hidden' : 'auto',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: 'var(--full-screen-height)',
    zIndex: 'modal',
    backfaceVisibility: 'hidden'
  };
};

const baseStyleContentContainer: StyleSystemFn<ModalProps> = props => {
  return {
    display: 'flex',
    minHeight: '100%',
    pointerEvents: 'none',
    justifyContent: 'center',
    zIndex: 'modal',
    ...(props.size === 'full' || props.scrollBehavior === 'inside' ? undefined : DEFAULT_CONTAINER_PAD_PROPS),
    ...(props.scrollBehavior === 'inside'
      ? {
          maxHeight: '100%'
        }
      : null)
  };
};

const baseDialogContentStyle: StyleSystemFn<ModalProps> = props => {
  const { size } = props;
  return {
    backgroundColor: 'white',
    borderRadius: [0, size === 'full' ? null : 12],
    boxShadow: '0px 50px 100px rgba(44, 41, 37, 0.25), 0px 30px 60px rgba(0, 0, 0, 0.3)',
    display: 'flex',
    flexDirection: 'column',
    minHeight: ['100%', size === 'full' ? '100%' : 'auto'],
    maxWidth: '100%',
    outline: 'none',
    width: ['100%', size],
    backfaceVisibility: 'hidden',
    pointerEvents: 'auto',
    ...(props.enableDividers
      ? {
          [`${StyledHeader} ~ ${StyledBody}`]: {
            borderTop: `1px solid ${props.theme.colors.mono3}`,
            _notLastChild: {
              borderBottom: `1px solid ${props.theme.colors.mono3}`
            }
          }
        }
      : {
          [`${StyledHeader} ~ ${StyledBody}`]: {
            paddingTop: props.scrollBehavior === 'inside' ? 3 : 0
          }
        }),
    ...(props.scrollBehavior === 'inside'
      ? {
          maxHeight: 'calc(100% - 6rem)'
        }
      : undefined)
  };
};

//
export type ModalAnatomy = typeof modalAnatomy;
export const modalAnatomy = anatomy('Modal').parts('dialogRoot', 'contentContainer', 'content').extend('closeButton', 'body', 'header', 'footer');

const modalBaseStyle: PartsStyleFunction<ModalProps, typeof modalAnatomy> = props => {
  return {
    dialogRoot: baseStyleRoot(props),
    contentContainer: baseStyleContentContainer(props),
    content: baseDialogContentStyle(props),
    closeButton: baseStyleCloseButton,
    body: baseStyleBody(props),
    header: baseStyleHeader(props),
    footer: baseStyleFooter(props)
  };
};

const modalStyles: MultiPartStyleConfig<ModalProps, typeof modalAnatomy> = {
  parts: modalAnatomy.keys,
  baseStyle: modalBaseStyle,
  defaultProps: {
    size: 'xl'
  }
};

const [DialogStylesProvider, useModalStyles] = createMultiPartStylesContext(modalAnatomy);

export { DialogStylesProvider, useModalStyles, modalStyles };
