import { IconConfig } from '..';

/* tslint:disable:max-line-length */
export const forwardIcon: IconConfig = {
  name: 'forwardIcon',
  a11yLabel: 'Forward',
  viewBox: 24,
  fill: '#333333',
  body: `
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20.931 12.7948L13.4643 20.2583C13.1139 20.6119 13.1139 21.18 13.4643 21.5337C13.8172 21.8833 14.386 21.8833 14.7397 21.5337C14.7397 21.5337 21.5871 14.6823 23.7378 12.5356C24.0874 12.1819 24.0874 11.6131 23.7378 11.2602C21.6839 9.21032 15.0581 2.58058 14.7397 2.26533C14.386 1.91168 13.8172 1.91168 13.4643 2.26533C13.1139 2.61498 13.1139 3.18386 13.4643 3.53751L20.9246 10.9946L0.900127 10.9946C0.662495 10.9946 0.434462 11.0882 0.262438 11.257C0.0936146 11.4258 0 11.6571 0 11.8947C0 12.1323 0.0936146 12.3636 0.262438 12.5324C0.434462 12.7012 0.662495 12.7948 0.900127 12.7948L20.931 12.7948Z"
    />  
  `
};
