import React, { useCallback, useEffect, useMemo } from 'react';
import { MarkUserForMFAEnrollmentMutationFn, ResetUserMfaMutationOptions, useMarkUserForMFAEnrollmentMutation, useResetUserMfaMutation } from '@graphql/generated';
import { useTranslation } from '@shared/core';
import { useAuth } from '@shared/components/AuthProvider';
import { MfaContext } from './MfaControllerProvider.context';
import { useHistory } from '@react-router';
import { useToast } from '@withjoy/joykit';
import { IconV2 } from '@withjoy/joykit';
import { CheckFilled } from '@withjoy/joykit/icons';
import { getLocalStorage } from '@shared/core';
import { useResponsive } from '@shared/utils/hooks/useResponsive';

const MFA_GUARDED_ACTION_KEY = 'MFA_GUARDED_ACTION_KEY';
const MFA_ENROLLMENT_ACTION_STARTED = 'MFA_ENROLLMENT_ACTION_STARTED';

export const MfaProvider: React.FC = ({ children }) => {
  const { toast } = useToast();
  const { t } = useTranslation('account');
  const { loginManager, currentUser, identifyCurrentUser, isCurrentUserSettled } = useAuth();
  const localStorage = getLocalStorage();
  const [isMobile] = useResponsive({ values: { mobile: true, tablet: false } }, false);

  const [markForMfaMutation, { loading: markForMfaLoading }] = useMarkUserForMFAEnrollmentMutation({
    onCompleted: () => {
      localStorage.setItem(MFA_ENROLLMENT_ACTION_STARTED, 'true');
      loginManager.authService.authorize(window.location.href);
    },
    onError: () => {
      localStorage.setItem(MFA_ENROLLMENT_ACTION_STARTED, '');
      loginManager.authService.handleRefreshMfaStatusDone();
    }
  });

  const [resetMfaMutation, { loading: resetMfaLoading }] = useResetUserMfaMutation({
    onCompleted: () => {
      identifyCurrentUser();
      toast(isMobile ? t('MFASettings', 'successMFAResetedMobile')() : t('MFASettings', 'successMFAReseted')());
    }
  });

  const resetMfa = useCallback<(options?: ResetUserMfaMutationOptions) => void>(
    options => {
      // to check token first
      if (!loginManager.hasValidMfaClaim()) {
        const url = new URL(window.location.href);
        url.searchParams.set(MFA_GUARDED_ACTION_KEY, 'resetMfa');
        return loginManager.authService.authorize(url.href);
      }
      resetMfaMutation(options);
    },
    [loginManager, resetMfaMutation]
  );

  const markUserForMFAEnrollment = useCallback<MarkUserForMFAEnrollmentMutationFn>(
    options => {
      loginManager.authService.initiateRefreshMfaStatus();
      return markForMfaMutation(options);
    },
    [loginManager.authService, markForMfaMutation]
  );

  const guardedActionsMap = useMemo(() => {
    return {
      resetMfa
    };
  }, [resetMfa]);

  const history = useHistory();

  /**
   * Intercept any action that needed acquiring of new token with mfa claims
   * and execute it. Once done, remove the query parameter.
   */
  useEffect(() => {
    const url = new URL(window.location.href);
    const action = url.searchParams.get(MFA_GUARDED_ACTION_KEY) as keyof typeof guardedActionsMap;
    if (action && typeof guardedActionsMap[action] === 'function') {
      guardedActionsMap[action]();
      url.searchParams.delete(MFA_GUARDED_ACTION_KEY);
      history.replace({ search: url.searchParams.toString() });
    }
  }, [guardedActionsMap, history]);

  const isMfaEnabled = useMemo(() => {
    return Boolean(currentUser.profile?.activeAlias?.mfaEnabled);
  }, [currentUser.profile?.activeAlias?.mfaEnabled]);

  useEffect(() => {
    const isMFAEnrollmentProcessStarted = Boolean(localStorage.getItem(MFA_ENROLLMENT_ACTION_STARTED));
    if (isMFAEnrollmentProcessStarted && isMfaEnabled) {
      toast(isMobile ? t('MFASettings', 'successMFAMobile')() : t('MFASettings', 'successMFA')(), {
        icon: (
          <IconV2>
            <CheckFilled />
          </IconV2>
        )
      });
      localStorage.setItem(MFA_ENROLLMENT_ACTION_STARTED, '');
    }
  }, [isMfaEnabled, toast, localStorage, t, isMobile]);

  const memoizedValue = useMemo(() => {
    return {
      loading: resetMfaLoading || markForMfaLoading || !isCurrentUserSettled,
      enableMfa: markUserForMFAEnrollment,
      disableMfa: resetMfa,
      isMfaEnabled,
      isAuth0Provider: currentUser.profile?.activeAlias?.provider === 'auth0'
    };
  }, [currentUser.profile?.activeAlias?.provider, isCurrentUserSettled, isMfaEnabled, markForMfaLoading, markUserForMFAEnrollment, resetMfa, resetMfaLoading]);

  return <MfaContext.Provider value={memoizedValue}>{children}</MfaContext.Provider>;
};
