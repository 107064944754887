import { IconConfig } from '..';

/* tslint:disable:max-line-length */
export const toggle: IconConfig = {
  name: 'toggle',
  a11yLabel: 'Toggle',
  viewBox: 24,
  fill: '#333333',
  body: `
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M21.1316 11.9473C21.1316 9.46198 19.1169 7.44727 16.6316 7.44727C14.1463 7.44727 12.1316 9.46198 12.1316 11.9473C12.1316 14.4325 14.1463 16.4473 16.6316 16.4473C19.1169 16.4473 21.1316 14.4325 21.1316 11.9473ZM13.3316 11.9473C13.3316 10.1247 14.809 8.64727 16.6316 8.64727C18.4541 8.64727 19.9316 10.1247 19.9316 11.9473C19.9316 13.7698 18.4541 15.2473 16.6316 15.2473C14.809 15.2473 13.3316 13.7698 13.3316 11.9473Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.0526 5H6.94737C3.11044 5 0 8.11044 0 11.9474C0 15.7843 3.11044 18.8947 6.94737 18.8947H17.0526C20.8896 18.8947 24 15.7843 24 11.9474C24 8.11044 20.8896 5 17.0526 5ZM1.2 11.9474C1.2 8.77318 3.77318 6.2 6.94737 6.2H17.0526C20.2268 6.2 22.8 8.77318 22.8 11.9474C22.8 15.1216 20.2268 17.6947 17.0526 17.6947H6.94737C3.77318 17.6947 1.2 15.1216 1.2 11.9474Z"
    />  
  `
};
