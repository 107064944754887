import * as React from 'react';
import { isValidElement, ComponentType } from 'react';

function getComponentDisplayName(target: ComponentType<any>): string;
function getComponentDisplayName(target: React.ReactElement<any>): string;
function getComponentDisplayName(target: ComponentType<any> | React.ReactElement<any>): string {
  if (isValidElement(target)) {
    return (target.type as any).displayName;
  }

  target = target as ComponentType<any>;
  return (
    // (process.env.NODE_ENV !== 'production' ? typeof target === 'string' && target : false) ||
    target.displayName || target.name || 'Component'
  );
}

export { getComponentDisplayName };
