import type { Sizes, SizeTokens } from '@withjoy/joykit/theme';
import { pxToRem } from './typography';

const NAMED_SIZE_TOKENS: { [Key in keyof Pick<SizeTokens, 'full' | 'auto'>]: string } = {
  full: '100%',
  auto: 'auto'
};

const SIZE_TOKENS_PX: { [Key in keyof Omit<SizeTokens, 'full' | 'auto'>]: number } = {
  '2xs': 320,
  xs: 360,
  sm: 375,
  md: 390,
  lg: 480,
  xl: 640,
  '2xl': 768,
  '3xl': 840,
  '4xl': 1024,
  '5xl': 1152,
  '6xl': 1280,
  '7xl': 1368,
  '8xl': 1440,
  '9xl': 1680,
  '10xl': 1920,
  '11xl': 2560
};

const SIZE_TOKENS_REM = Object.keys(SIZE_TOKENS_PX).reduce(
  (acc, key) => {
    const keyId = key as keyof Omit<SizeTokens, 'full' | 'auto'>;
    acc[keyId] = pxToRem(SIZE_TOKENS_PX[keyId]);
    return acc;
  },
  {
    ...NAMED_SIZE_TOKENS
  } as SizeTokens
);

export const getSizes = (spaceScale: string[]): Sizes => {
  return Object.assign([...spaceScale], SIZE_TOKENS_REM);
};
