import { IconConfig } from '..';

export const optionSelect: IconConfig = {
  name: 'optionSelect',
  a11yLabel: 'Option select',
  viewBox: 24,
  fill: '#333333',
  body: `
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 16L6 9.89091L6.78505 9L12 14.3455L17.215 9L18 9.89091L12 16Z"
    />
  `
};
