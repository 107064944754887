/**
 * This file was automatically generated by `IconCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, follow the instructions at `src/shared/joykit/packages/core/icons/README.md`.
 */

import * as React from 'react';

import createSvgIcon from '../createSvgIcon';

import { SvgIconConfig } from '../types';

const config: SvgIconConfig = {
  title: 'Close',
  definitions: {
    '16': {
      viewBox: '0 0 16 16',
      path: (
        <>
          <path
            fillRule="evenodd"
            d="M11.56 3.662a.55.55 0 0 1 .778.778L8.778 8l3.56 3.56a.55.55 0 0 1-.778.778L8 8.778l-3.56 3.56a.55.55 0 0 1-.778-.778L7.222 8l-3.56-3.56a.55.55 0 0 1 .778-.778L8 7.222l3.56-3.56z"
            fill="currentColor"
          />
        </>
      )
    },
    '24': {
      viewBox: '0 0 24 24',
      path: (
        <>
          <path
            fillRule="evenodd"
            d="M17.476 5.674a.6.6 0 0 1 .849.848L12.848 12l5.476 5.477a.6.6 0 0 1-.849.848L12 12.849l-5.476 5.477a.6.6 0 0 1-.849-.848L11.152 12 5.676 6.523a.6.6 0 1 1 .849-.848L12 11.152l5.476-5.477z"
            fill="currentColor"
          />
        </>
      )
    },
    '32': {
      viewBox: '0 0 32 32',
      path: (
        <>
          <path
            fillRule="evenodd"
            d="M24.975 6.178a.6.6 0 0 1 .848.849L16.849 16l8.975 8.974a.6.6 0 1 1-.848.849L16 16.848l-8.975 8.974a.6.6 0 1 1-.848-.849L15.151 16 6.176 7.026a.6.6 0 1 1 .848-.849L16 15.151l8.975-8.974z"
            fill="currentColor"
          />
        </>
      )
    },
    '48': {
      viewBox: '0 0 48 48',
      path: (
        <>
          <path
            fillRule="evenodd"
            d="M37.684 9.434c.244-.244.64-.244.884 0s.244.64 0 .884L24.884 24l13.684 13.682c.244.244.244.64 0 .884s-.64.244-.884 0L24 24.884 10.315 38.566c-.244.244-.64.244-.884 0s-.244-.64 0-.884L23.116 24 9.432 10.318c-.244-.244-.244-.64 0-.884s.64-.244.884 0L24 23.116 37.684 9.434z"
            fill="currentColor"
          />
        </>
      )
    }
  }
};

const icon = createSvgIcon(config);
export default icon;
