import type { BreakpointAliasKey } from '@withjoy/joykit/theme';
import { breakpointAliasPx } from '@shared/joykit/packages/core/common/themes/shared/breakpoints';

export type ScreenBreakpoints = {
  [screen in BreakpointAliasKey]: string;
};
export type ScreenScalarBreakpoints = {
  [screen in BreakpointAliasKey]: number;
};

export const breakpoints = Object.keys(breakpointAliasPx).reduce((dict, key) => {
  dict[key as BreakpointAliasKey] = `${breakpointAliasPx[key as BreakpointAliasKey] / 16}rem`;
  return dict;
}, {} as ScreenBreakpoints);

function convertRemToInt(val: string): number {
  if (typeof val === 'string') {
    const num = Number.parseFloat(val);
    return isNaN(num) ? 0 : num;
  }
  return 0;
}

export const breakpointPixels = Object.keys(breakpoints).reduce((cur, bpKey) => {
  cur[bpKey as BreakpointAliasKey] = `${convertRemToInt(breakpoints[bpKey as BreakpointAliasKey]) * 16}px`;
  return cur;
}, {} as ScreenBreakpoints);
