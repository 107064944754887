import { useEffect, useRef } from 'react';

import { addAction } from '@shared/utils/logger';

export function logEvent(action: string, data: Record<string, unknown>): void {
  addAction('RemoteFeatureFlag', { ...data, action });
}

export function createPerfLogger(name: string): () => number {
  const startTime = Date.now();

  return () => {
    const endTime = Date.now();
    logEvent('Performance', { name, durationMs: endTime - startTime });
    return endTime - startTime;
  };
}

export function createOneTimePerfLogger(name: string): () => void {
  const logPerf = createPerfLogger(name);
  let hasRun = false;
  return () => {
    if (hasRun) {
      return;
    }
    hasRun = true;
    return logPerf();
  };
}

export function usePerfLogger({ name, isLoading }: { name: string; isLoading: boolean }): void {
  const perfLogger = useRef<() => number>(createPerfLogger(name));

  useEffect(() => {
    if (isLoading) {
      perfLogger.current = createPerfLogger(name);
      return;
    }

    perfLogger.current?.();
  }, [isLoading, name]);
}

export function parseError(error: unknown): { errorSignature: string; errorDetails?: unknown } {
  let errorSignature = 'Unknown error';
  let errorDetails: unknown;

  if (error instanceof Error) {
    errorSignature = error.message;
    errorDetails = {
      name: error.name,
      stack: error.stack
    };
  } else if (typeof error === 'string') {
    errorSignature = error;
  } else {
    errorDetails = error;
  }

  return {
    errorSignature,
    errorDetails
  };
}
