import React, { SVGProps } from 'react';
import { BundledIcon, JOYKIT_ICONS, IconConfig, generateViewBoxStringFromValue, IconViewBox, IconSVG24 } from '../../../icons';
import { generateComponentDisplayName, getPropKeyValues, animationTransition } from '../../utils';
import { PositionProps, TopProps, RightProps } from 'styled-system';
import { StyledIconContainer } from './StyledIconContainer';
import { BaseComponentProps } from '../../common/props';
import { iconBase, CLASS_ICON_BASE } from '../../../icons/iconDefinitions/iconBase';
import { styled } from '@shared/joykit/packages/core/common/styledComponents';
import { CSS_PROPS, removeNonHTMLProps } from '../../utils/removeNonHTMLProps';
import { BASE_COMPONENT_PROPS } from '../../common/styledSystem/types';

// TODO: handle custom SVG strings
export type IconSource = IconV1Props['source'];

export interface ComposedIconProps extends PositionProps, TopProps, RightProps, BaseComponentProps {}

/**
 * -----------------------------------------------
 * DEPRECATED -- Use JoyKit provided set of icons
 * -----------------------------------------------
 *
 * @deprecated
 *
 * ```typescript
 * import { AddToCalendar } from '@withjoy/joykit/icons';
 * ```
 *
 */
export interface IconV1Props extends ComposedIconProps {
  /**
   * Descriptive text to be read to screenreaders.
   * If not provided, the label will default to the one provided by
   * the Icon - if it exists.
   */
  a11yLabel?: string;

  /**
   * This component does not support custom children.
   */
  // children?: never;

  /**
   * To be used as the fill attribute on the `<svg />`, otherwise inherit from
   * surrounding context.
   *
   * @example
   *   <Button><Icon source="globe"/></Button>
   * If the button has `color: #FFF`, then the SVG will also be white.
   */
  color?: string;

  /**
   * String to appear on hover.
   * If not provided and icon exists, will default to the icon name.
   *
   */
  htmlTitle?: string;

  iconSize?: number;
  source: BundledIcon | undefined | React.ReactElement<any>;

  /**
   * Whether the target SVG should be stacked on a base container
   */
  useBackdrop?: boolean;

  // /**
  //  * HTML tag to use for the rendered element.
  //  * @default "span"
  //  */
  // tagName: keyof JSX.IntrinsicElements;
}

const SVG: React.FC<{ transform?: string } & SVGProps<any>> = ({ transform, ...rest }) => {
  return <svg {...removeNonHTMLProps(rest, BASE_COMPONENT_PROPS)} />;
};

const StyledSVG = styled(SVG)`
  display: block;
  & > *:not(.${CLASS_ICON_BASE}) {
    transform: ${props => props.transform};
  }
  path {
    transition: ${animationTransition('fill', 'color')};
  }
`;
/**
 * -----------------------------------------------
 * DEPRECATED -- Use JoyKit provided set of icons
 * -----------------------------------------------
 *
 * @deprecated
 *
 * ```typescript
 * import { AddToCalendar } from '@withjoy/joykit/icons';
 * ```
 *
 */
export class IconV1 extends React.PureComponent<IconV1Props & React.HTMLAttributes<any>> {
  static displayName = generateComponentDisplayName(IconV1);
  // ==============================================================
  // Render
  // ==============================================================

  render(): React.ReactNode {
    let { a11yLabel, color, children, htmlTitle, source, marginLeft, order, iconSize, useBackdrop, className, ...htmlProps } = this.props;

    if (!source) {
      return null;
    }

    let contentMarkup: React.ReactNode = undefined;
    if (React.isValidElement(source)) {
      contentMarkup = source;
    } else if (typeof source === 'string' && this.isBundledJoyKitIcon(source)) {
      const iconConfig = JOYKIT_ICONS[source] as IconConfig;

      a11yLabel = a11yLabel || iconConfig.a11yLabel;
      htmlTitle = htmlTitle || iconConfig.a11yLabel;

      let viewBox = iconConfig.viewBox;
      let transform;
      const paths = [iconConfig.body];
      if (useBackdrop) {
        viewBox = Math.max(iconConfig.viewBox, iconBase.viewBox) as IconViewBox;
        const transformBasis = (Math.max(iconConfig.viewBox, iconBase.viewBox) - iconConfig.viewBox) / 2;
        transform = `translate(${transformBasis}px, ${transformBasis}px)`;
        paths.unshift(iconBase.body);
      }

      contentMarkup = (
        <StyledSVG
          {...htmlProps}
          aria-hidden="true"
          focusable="false"
          height={iconSize || viewBox}
          width={iconSize || viewBox}
          fill={color}
          transform={transform}
          viewBox={generateViewBoxStringFromValue(viewBox)}
          dangerouslySetInnerHTML={{ __html: paths.join('') }}
        />
      );
    }

    const styledProps = getPropKeyValues(this.props, CSS_PROPS);

    return (
      <StyledIconContainer {...styledProps} className={className} aria-label={a11yLabel} title={htmlTitle}>
        {contentMarkup as any}
      </StyledIconContainer>
    );
  }

  // ==============================================================
  // Helpers
  // ==============================================================

  private isBundledJoyKitIcon = (key: string): boolean => {
    return key in JOYKIT_ICONS || key in IconSVG24;
  };
}
