// 24

import { add } from './add';
import { backArrow } from './backArrow';
import { buy } from './buy';
import { check } from './check';
import { close } from './close';
import { config } from './config';
import { creditCard } from './creditCard';
import { dropdown } from './dropdown';
import { edit } from './edit';
import { email } from './email';
import { eye } from './eye';
import { forwardIcon } from './forwardIcon';
import { friends } from './friends';
import { globe } from './globe';
import { label } from './label';
import { lock } from './lock';
import { more } from './more';
import { notification } from './notification';
import { optionSelect } from './optionSelect';
import { question } from './question';
import { refund } from './refund';
import { search } from './search';
import { toggle } from './toggle';
import { toggles } from './toggles';
import { trash } from './trash';
import { iconBase } from './iconBase';
import { chevronLeft, chevronLeft24 } from './chevronLeft';
import { chevronRight } from './chevronRight';
import { starIcon } from './star';
import { BundledIcon, IconConfig } from '..';

export const JOYKIT_ICONS: { [bundledIcon in BundledIcon]: IconConfig } = {
  add,
  backArrow,
  buy,
  check,
  chevronLeft,
  chevronRight,
  close,
  config,
  creditCard,
  dropdown,
  edit,
  email,
  eye,
  forwardIcon,
  friends,
  iconBase,
  globe,
  label,
  lock,
  more,
  notification,
  optionSelect,
  question,
  refund,
  search,
  star: starIcon,
  toggle,
  toggles,
  trash
};

export type IconSVG24Name = 'chevronLeft';

export const IconSVG24: { [iconName in IconSVG24Name]: IconConfig } = {
  chevronLeft: chevronLeft24
};
