import qs from 'query-string';
import * as H from 'history';
import { SAVE_THE_DATE_PHOTO_ECARD_TYPE } from './constants';
import { ECardDraft, ECardInputNoEventId, LocationFormat, NameFormat } from './SaveTheDate.types';
import { SAVE_THE_DATE_PHOTO_THEMES } from './constants/index';

export const getFormattedLocation = (args: {
  locationInfo?: { city?: string | null; state?: string | null; country?: string | null } | null;
  location?: string | null;
  format: string;
}) => {
  const { locationInfo, location, format } = args;
  const { city = '', state = '', country = '' } = locationInfo || {};

  if (!locationInfo || !locationInfo?.country) {
    return location || '';
  }

  if (format === LocationFormat.CITY_STATE_COUNTRY) {
    return `${city}, ${state}, ${country}`;
  }

  if (format === LocationFormat.CITY_STATE) {
    return `${city}, ${state}`;
  }

  if (format === LocationFormat.CITY_COUNTRY) {
    return `${city}, ${country}`;
  }

  return '';
};

export const getFormattedCoupleNames = (args: {
  ownerFirstName: string | null;
  fianceeFirstName: string | null;
  ownerFullName: string | null;
  fianceeFullName: string | null;
  format: string;
}) => {
  const { ownerFirstName, fianceeFirstName, ownerFullName, fianceeFullName, format } = args;

  if (format === NameFormat.FIRST) {
    return `${ownerFirstName} & ${fianceeFirstName}`;
  }
  if (format === NameFormat.FULL && ownerFullName !== ownerFirstName && fianceeFullName !== fianceeFirstName && ownerFullName && fianceeFullName) {
    return `${ownerFullName} & ${fianceeFullName}`;
  }

  return '';
};

export const getFormattedName = (args: { firstName: string | null; fullName: string | null; format: string }) => {
  const { firstName, fullName, format } = args;

  if (format === NameFormat.FIRST && firstName) {
    return firstName;
  }
  if (format === NameFormat.FULL && fullName) {
    return fullName;
  }

  return '';
};

export const getEcardType = (location: H.Location): string | undefined => {
  try {
    const { lookup = '' } = qs.parse(location.search);
    const { ecardType = '' } = JSON.parse(Buffer.from(lookup as string, 'base64').toString('ascii'));
    return ecardType;
  } catch {
    return undefined;
  }
};

export const getLocationAndDate = (lookup: string): { location: string; date: string; eventPassword: string } | undefined => {
  try {
    const { location = '', date = '', eventPassword = '' } = JSON.parse(Buffer.from(lookup, 'base64').toString('ascii'));
    return { location: decodeURI(location), date, eventPassword };
  } catch {
    return undefined;
  }
};

export const getEcardInputFromDraft = (draft: ECardDraft): ECardInputNoEventId => ({
  ecardId: draft.id,
  title: draft?.title || '',
  subTitle: draft?.subTitle,
  message: draft?.message,
  linkOptionPrimary: draft?.linkOptionPrimary,
  linkOptionSecondary: draft?.linkOptionSecondary,
  locationString: draft?.locationString,
  displayDateFormat: draft?.displayDateFormat,
  displayDateString: draft?.displayDateString,
  displayEventLink: draft?.displayEventLink,
  type: SAVE_THE_DATE_PHOTO_ECARD_TYPE,
  recipientIds: []
});

export const getThemeInfo = (themeSelected: string) => SAVE_THE_DATE_PHOTO_THEMES.find(({ id }) => id === themeSelected);
export const getThemeInfoByThemeId = (targetThemeId: string) => SAVE_THE_DATE_PHOTO_THEMES.find(({ themeId }) => themeId === targetThemeId);
