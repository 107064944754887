import { IconConfig } from '..';

/* tslint:disable:max-line-length */
// TODO: Michael bach should convert this to using fill
export const toggles: IconConfig = {
  name: 'toggles',
  a11yLabel: 'Toggles',
  viewBox: 24,
  fill: '#333333',
  body: `
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.7696 6.72089C19.7696 5.20645 18.5419 3.97876 17.0275 3.97876C15.513 3.97876 14.2853 5.20645 14.2853 6.72089C14.2853 8.23533 15.513 9.46303 17.0275 9.46303C18.5419 9.46303 19.7696 8.23533 19.7696 6.72089ZM15.4853 6.72089C15.4853 5.8692 16.1758 5.17876 17.0275 5.17876C17.8792 5.17876 18.5696 5.8692 18.5696 6.72089C18.5696 7.57259 17.8792 8.26303 17.0275 8.26303C16.1758 8.26303 15.4853 7.57259 15.4853 6.72089Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.3158 1.96338H6.74371C4.11625 1.96338 1.98627 4.09336 1.98627 6.72082C1.98627 9.34828 4.11625 11.4783 6.74371 11.4783H17.3158C19.9432 11.4783 22.0732 9.34828 22.0732 6.72082C22.0732 4.09336 19.9432 1.96338 17.3158 1.96338ZM3.18627 6.72082C3.18627 4.7561 4.77899 3.16338 6.74371 3.16338H17.3158C19.2805 3.16338 20.8732 4.7561 20.8732 6.72082C20.8732 8.68553 19.2805 10.2783 17.3158 10.2783H6.74371C4.77899 10.2783 3.18627 8.68553 3.18627 6.72082Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.76959 17.7209C9.76959 16.2065 8.5419 14.9788 7.02746 14.9788C5.51302 14.9788 4.28533 16.2065 4.28533 17.7209C4.28533 19.2353 5.51302 20.463 7.02746 20.463C8.5419 20.463 9.76959 19.2353 9.76959 17.7209ZM5.48533 17.7209C5.48533 16.8692 6.17576 16.1788 7.02746 16.1788C7.87916 16.1788 8.56959 16.8692 8.56959 17.7209C8.56959 18.5726 7.87916 19.263 7.02746 19.263C6.17576 19.263 5.48533 18.5726 5.48533 17.7209Z"
    />
    <rect x="2.58627" y="13.5634" width="18.887" height="8.31487" rx="4.15744" stroke="#333333" stroke-width="1.2" />  
  `
};
