import { createContext } from '../createContext';
import { EventInfo } from './EventInfo.types';

export interface InternalEventInfoContextType {
  setEventHandle: (eventHandle: string | undefined) => void;
  eventInfo: EventInfo;
  onFinishLoadingEventInfo: () => void;
}

export const [InternalEventInfoProvider, useInternalEventInfoContext] = createContext<InternalEventInfoContextType | undefined>({
  name: 'EventInfoProvider',
  strict: false
});
