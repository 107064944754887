import { IconConfig } from '..';

/* tslint:disable:max-line-length */
export const notification: IconConfig = {
  name: 'notification',
  a11yLabel: 'Notification',
  viewBox: 24,
  fill: '#333333',
  body: `
    <mask id="path-1-inside-1" fill="white">
      <path d="M4.84616 9.27493C4.84614 5.51362 7.1538 3.25684 11.7693 3.25684H12.2307C16.8462 3.25684 19.1539 5.51362 19.1538 9.27493C19.1538 13.1858 20.0409 16.9159 20.5852 18.8368C20.7731 19.4997 20.2788 20.1827 19.5897 20.1827H4.41027C3.7212 20.1827 3.22688 19.4997 3.41475 18.8368C3.9591 16.9159 4.84616 13.1858 4.84616 9.27493Z" />
    </mask>
    <path
      d="M4.84616 9.27493H6.09616L6.09616 9.27493L4.84616 9.27493ZM3.41475 18.8368L4.61739 19.1776L4.61739 19.1776L3.41475 18.8368ZM19.1538 9.27493L17.9038 9.27493V9.27493H19.1538ZM20.5852 18.8368L21.7879 18.4959V18.4959L20.5852 18.8368ZM11.7693 2.00684C9.27442 2.00684 7.20204 2.61626 5.74253 3.92463C4.26185 5.25198 3.59615 7.1324 3.59616 9.27494L6.09616 9.27493C6.09615 7.65616 6.58427 6.52753 7.41128 5.78615C8.25946 5.02581 9.64864 4.50684 11.7693 4.50684V2.00684ZM3.59616 9.27493C3.59616 13.0196 2.74289 16.623 2.21211 18.4959L4.61739 19.1776C5.17531 17.2089 6.09616 13.352 6.09616 9.27493H3.59616ZM4.41027 21.4327H19.5897V18.9327H4.41027V21.4327ZM12.2307 4.50684C14.3514 4.50684 15.7405 5.02581 16.5887 5.78615C17.4157 6.52753 17.9038 7.65616 17.9038 9.27493L20.4038 9.27494C20.4039 7.1324 19.7381 5.25198 18.2575 3.92463C16.798 2.61626 14.7256 2.00684 12.2307 2.00684V4.50684ZM17.9038 9.27493C17.9038 13.352 18.8247 17.2089 19.3826 19.1776L21.7879 18.4959C21.2571 16.623 20.4038 13.0196 20.4038 9.27493H17.9038ZM11.7693 4.50684H12.2307V2.00684H11.7693V4.50684ZM19.5897 21.4327C21.1465 21.4327 22.1913 19.9195 21.7879 18.4959L19.3826 19.1776C19.3644 19.1132 19.3825 19.0543 19.4088 19.0185C19.4369 18.9803 19.5019 18.9327 19.5897 18.9327V21.4327ZM2.21211 18.4959C1.80869 19.9195 2.85345 21.4327 4.41027 21.4327V18.9327C4.49811 18.9327 4.56311 18.9803 4.59121 19.0185C4.61749 19.0543 4.63564 19.1132 4.61739 19.1776L2.21211 18.4959Z"
      mask="url(#path-1-inside-1)"
    />
    <path
      d="M15.375 4.38518C15.375 2.5156 13.864 1 12 1C10.136 1 8.625 2.5156 8.625 4.38518"
      stroke-width="1.25"
      stroke-linejoin="round"
    />
    <path
      d="M15.759 19.2085C15.759 21.3024 14.0775 22.9999 12.0033 22.9999C9.92904 22.9999 8.24754 21.3024 8.24754 19.2085"
      stroke-width="1.25"
    />  
  `
};
