import { UserFragment, EventUserStatus, ActiveEventUserFragment, EventType } from '@graphql/generated';
import { useRouteMatch } from '@react-router';
import { usePreviousValue } from '@shared/utils/hooks/usePreviousValue';
import { useAuth } from './';
import { UserProfile } from './';

export type ExtendedEventUserStatus = EventUserStatus | 'superAdmin';

/**
 * The user's role in the event.
 * @default crasher
 */
export const getEventUserRole = (currentUser: UserProfile): ExtendedEventUserStatus => {
  const eventUser = currentUser.activeEventUser;
  return currentUser.profile?.superAdmin ? 'superAdmin' : eventUser?.status ?? EventUserStatus.crasher;
};

/**
 * Confirm that the EventUser profile belongs to the current User.
 *
 * @param user The current authenticated User
 * @param eventUser The active Event User profile for the current User.
 */
export function isValidActiveEventUser(user: Maybe<UserFragment>, eventUser: Maybe<ActiveEventUserFragment>) {
  return user?.id === eventUser?.user?.id;
}

// ===========================================================================
// useEventNameMatch
// ===========================================================================

export type UseEventNameMatchValue = Readonly<{
  /** The current event name, if it is an event route. */
  eventName: string | undefined;
  isEventRoute: boolean;
  /**
   * Whether the event name has changed or the route is changing to/from a non-event route. While the active EventUser profile
   * may be valid for the current User + current Event, we hint to any subscribers that revalidation will occur shortly.
   */
  shouldRevalidate: boolean;
}>;

export function useEventNameMatch(): UseEventNameMatchValue {
  const routeMatch = useRouteMatch<{ eventName: string }>('/:eventName');
  const currentEventName = routeMatch?.params.eventName;
  const prevEventName = usePreviousValue(currentEventName);

  // /kevinpluskevin -> /kkplusrt
  // The eventUser/user profile may not be authorized to view the next event.
  const hasEventNameChanged = currentEventName !== prevEventName;

  // TODO: Once we bring /designs, /createwedding, /preview, /login, etc into `joy-web`,
  // we'll want to consider those pathnames in determing isEventRoute.
  const isEventRoute = !!routeMatch;

  return {
    eventName: currentEventName,
    isEventRoute,
    shouldRevalidate: isEventRoute && hasEventNameChanged
  };
}

// ===========================================================================
// useEventUserRole
// ===========================================================================

export type UseEventRoleValue = Readonly<{
  hasIdentifiedUserOnce: boolean;
  isAdmin: boolean;
  /** Whether a User is currently logged in. */
  isLoggedIn: boolean;
  /** Whether the AuthProvider is identifying the current user. */
  fetching: boolean;
  /** The active Event User's role in the event */
  role: ExtendedEventUserStatus;
  /** The ID of the active Event User profile for the current User. */
  eventUserId: Maybe<string>;
  /** The ID of the current User */
  userId: Maybe<string>;
  /*  The account ID from auth0, important for CX segmenting in intercom */
  auth0Id: Maybe<string>;
}>;

/**
 * This will return the `active` EventUser profile for the `current` User - return the EventUser that matches the User Auth0 ID.
 */
export function useEventUserRole(): UseEventRoleValue {
  const { isLoggedIn, currentUser, willRefetch, hasInitializedOnce } = useAuth();
  const { activeEventUser } = currentUser || {};
  const role = getEventUserRole(currentUser);
  const isAdmin = role === 'superAdmin' || role === 'admin' || role === 'owner';
  return {
    hasIdentifiedUserOnce: hasInitializedOnce,
    isAdmin,
    isLoggedIn,
    eventUserId: activeEventUser?.id,
    userId: activeEventUser?.user.id,
    auth0Id: currentUser.profile?.activeAlias?.auth0Id,
    fetching: willRefetch || currentUser.requestStatus === 'loading',
    role
  };
}

interface JoyEventProps {
  userRole: 'owner' | 'admin';
  event: {
    id: string;
    firebaseId: string;
    website: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    firebaseCreateAt?: { timestamp: any } | null | undefined;
    info: {
      eventDisplayName?: string | null | undefined;
      ownerFirstName?: string | null | undefined;
      fianceeFirstName?: string | null | undefined;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      finalizedDate?: { timestamp: any } | null | undefined;
      eventType?: EventType;
    };
  };
}

export interface JoyEvent {
  website: string;
  postgresEventId: string;
  firebaseEventId: string;
  createdOn: string;
  eventDate: string;
  eventDisplayName: string;
  partnerOneFirstName: string;
  partnerTwoFirstName: string;
  userRole: 'owner' | 'admin';
  eventType: EventType;
}

export const getJoyEvent = (joyEvent: JoyEventProps): JoyEvent => {
  const { id: eventId, firebaseId, website, firebaseCreateAt, info } = joyEvent.event;
  return {
    website: website,
    postgresEventId: eventId,
    firebaseEventId: firebaseId,
    createdOn: firebaseCreateAt?.timestamp,
    eventDate: info.finalizedDate?.timestamp,
    eventDisplayName: info.eventDisplayName ?? '',
    partnerOneFirstName: info.ownerFirstName ?? '',
    partnerTwoFirstName: info.fianceeFirstName ?? '',
    userRole: joyEvent.userRole,
    eventType: info.eventType ?? EventType.general
  };
};
