import React, { useEffect, useState } from 'react';

import { getConfig } from '@config';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';
import { useMount } from '@shared/utils/hooks/useMount';
import { withWindow } from '@shared/utils/withWindow';
import { Box, DialogV2 } from '@withjoy/joykit';

import { WafCaptchaTelemetryProvider, useWafCaptchaTelemetry } from './WafCaptcha.telemetry';
import { setRequestCaptchaListener } from './requestCaptchaEmitter';

const awsWafCaptchaApiKey = getConfig().awsWafCaptchaApiKey;

enum CaptchaFailureReasons {
  cancelledByUser = 'CaptchaCancelledByUser',
  cancelledDueToNewRequest = 'CaptchaCancelledDueToNewRequest',
  captchaError = 'CaptchaError'
}

enum WafCaptchaStateType {
  hidden = 'hidden',
  shown = 'shown'
}

type WafCaptchaState =
  | {
      type: WafCaptchaStateType.hidden;
    }
  | {
      type: WafCaptchaStateType.shown;
      onSuccess: () => void;
      onError: (reason: string) => void;
    };

const WafCaptchaBase: React.FC = () => {
  const [captchaRenderContainer, setCaptchaRenderContainer] = useState<HTMLDivElement | null>(null);
  const [state, setState] = React.useState<WafCaptchaState>({ type: WafCaptchaStateType.hidden });
  const { captchaDialog, captchaFailure, captchaSuccess } = useWafCaptchaTelemetry();

  const cancelCaptcha = useEventCallback(() => {
    if (state.type === WafCaptchaStateType.shown) {
      state.onError(CaptchaFailureReasons.cancelledByUser);
    }

    setState({ type: WafCaptchaStateType.hidden });
  });

  const showCaptcha = useEventCallback(() => {
    if (state.type === WafCaptchaStateType.shown) {
      // reject any outstanding captcha request if a new captcha request comes in
      state.onError(CaptchaFailureReasons.cancelledDueToNewRequest);
    }

    captchaDialog.enter();

    return new Promise<void>((resolve, reject) => {
      setState({
        type: WafCaptchaStateType.shown,
        onSuccess: () => {
          captchaSuccess();
          resolve();
        },
        onError: reason => {
          captchaFailure(reason);
          reject(reason);
        }
      });
    });
  });

  useMount(() => {
    setRequestCaptchaListener(showCaptcha);

    return () => {
      setRequestCaptchaListener(undefined);
    };
  });

  useEffect(() => {
    if (state.type === WafCaptchaStateType.shown && captchaRenderContainer) {
      withWindow(global => {
        global.AwsWafCaptcha.renderCaptcha(captchaRenderContainer, {
          apiKey: awsWafCaptchaApiKey,
          onSuccess: () => {
            state.onSuccess();
            setState({ type: WafCaptchaStateType.hidden });
          },
          onError: ({ kind, statusCode }) => {
            state.onError(`${CaptchaFailureReasons.captchaError}: ${kind} ${statusCode !== undefined ? ' | ' + statusCode : ''}`);
            setState({ type: WafCaptchaStateType.hidden });
          }
        });
      });
    }
  }, [captchaRenderContainer, state]);

  return (
    <DialogV2 isOpen={state.type === WafCaptchaStateType.shown} onClose={cancelCaptcha} size="lg">
      <DialogV2.Body>
        <Box paddingY={5} ref={setCaptchaRenderContainer}></Box>
      </DialogV2.Body>
    </DialogV2>
  );
};

export const WafCaptcha: React.FC = () => {
  return (
    <WafCaptchaTelemetryProvider>
      <WafCaptchaBase />
    </WafCaptchaTelemetryProvider>
  );
};
