import React, { useEffect, useState } from 'react';
import { Redirect } from '@react-router';
import { EventType } from '@graphql/generated';
import { useAuth } from '@shared/components/AuthProvider';
import { JoyLogoLoader } from '../JoyLogoLoader';

export const AccountRedirect = () => {
  const authProvider = useAuth();
  const [loading, setLoading] = useState(true);
  const [pathRedirect, setPathRedirect] = useState<string>('/account/events');

  const { isCurrentUserSettled, isLoggedIn, currentUser } = authProvider;

  useEffect(() => {
    if (isCurrentUserSettled && !isLoggedIn) {
      return authProvider.loginManager.authService.authorize(window.location.href);
    }

    const event = currentUser.eventMemberships[0]?.event;
    if (currentUser.eventMemberships.length === 1 && event) {
      const isBabyRegistry = event.info.eventType === EventType.babyRegistry;
      setPathRedirect(`/${event.website}/edit${isBabyRegistry ? '/registry' : ''}`);
    }

    if (isCurrentUserSettled) {
      setLoading(false);
    }
  }, [isCurrentUserSettled, currentUser, authProvider.loginManager.authService, isLoggedIn]);

  return loading ? <JoyLogoLoader loaderKey="account-redirect" /> : <Redirect to={pathRedirect} />;
};
