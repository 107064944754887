import { addAction } from '@shared/utils/logger';

export interface GQLTelemetry {
  name: string;
  startTimeMs: number;
  status: 'Success' | 'NetworkError' | 'GraphQLError' | 'UnknownError';
  errors?: unknown[];
}

interface ErrorLike {
  message: string;
  name?: string;
  stack?: string;
}

function isErrorLike(value: unknown): value is ErrorLike {
  return Boolean(value && typeof value === 'object' && (value as ErrorLike).message);
}

export function logGQLTelemetry({ name, startTimeMs, status, errors }: GQLTelemetry): void {
  const errorsToLog: unknown[] = [];
  const stringsForErrorSignature: string[] = [];

  if (errors) {
    errors.forEach(error => {
      if (error instanceof Error || isErrorLike(error)) {
        errorsToLog.push({
          name: error.name,
          message: error.message,
          stack: error.stack
        });
        stringsForErrorSignature.push(error.message);
      } else {
        errorsToLog.push(error);
        stringsForErrorSignature.push('Unknown error');
      }
    });
  }

  addAction('GraphQLOperationResult', {
    name,
    durationMs: Math.round(performance.now() - startTimeMs),
    status,
    errors: errorsToLog.length ? errorsToLog : undefined,
    errorSignature: stringsForErrorSignature.length ? stringsForErrorSignature.join(' | ') : undefined
  });
}
