import { IconConfig } from '..';

/* tslint:disable:max-line-length */

export const chevronLeft: IconConfig = {
  name: 'chevronLeft',
  a11yLabel: 'Go back',
  viewBox: 16,
  fill: '#333333',
  body: `
    <path fill-rule="evenodd" clip-rule="evenodd" d="M4 8L10.1091 2L11 2.78505L5.65455 8L11 13.215L10.1091 14L4 8Z" />
  `
};

export const chevronLeft24: IconConfig = {
  name: 'chevronLeft',
  a11yLabel: 'Go forward',
  viewBox: 24,
  fill: '#333333',
  body: `
  <path fill-rule="evenodd" clip-rule="evenodd" d="M6 12L15.6 2L17 3.30841L8.6 12L17 20.6916L15.6 22L6 12Z"/>
  
  `
};
