import { Colors } from '@withjoy/joykit/theme';
import { primitiveColorTokens } from './primitiveTokens';
import { getPaletteColorTokens } from './paletteTokens';
import { getComponentColorTokens } from './componentTokens';

export const createColors = (): Colors => {
  const paletteTokens = getPaletteColorTokens(primitiveColorTokens);

  // Return primitives directly for now. This block will grow as we define component color tokens.
  return {
    ...primitiveColorTokens,
    ...paletteTokens,
    ...getComponentColorTokens(paletteTokens)
  };
};
