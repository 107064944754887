import React, { forwardRef } from 'react';

import type { ModalProps } from '@withjoy/joykit/components/Modal';
import { Modal, ModalContent, ModalHeader, ModalBody, ModalCloseButton, ModalFooter, ModalBackdrop } from '@withjoy/joykit/components/Modal/Modal';
import { AnimationInOutType } from '@withjoy/joykit/components/Modal';

export interface DialogV2Props extends ModalProps {
  useBackdrop?: boolean;
  contentTransition?: AnimationInOutType;
}

const DialogV2 = forwardRef<
  HTMLDivElement,
  DialogV2Props & {
    Header: typeof Modal.Header;
    Body: typeof Modal.Body;
    Footer: typeof Modal.Footer;
    CloseButton: typeof Modal.CloseButton;
    Backdrop: typeof Modal.Backdrop;
  }
>((props, ref) => {
  const { useBackdrop, contentTransition, children, ...restProps } = props;
  return (
    <Modal {...restProps}>
      {useBackdrop && <Modal.Backdrop />}
      <ModalContent ref={ref} transition={contentTransition}>
        {children}
      </ModalContent>
    </Modal>
  );
}) as React.ForwardRefExoticComponent<DialogV2Props & React.RefAttributes<HTMLDivElement>> & {
  Backdrop: typeof ModalBackdrop;
  CloseButton: typeof ModalCloseButton;
  Header: typeof ModalHeader;
  Body: typeof ModalBody;
  Footer: typeof ModalFooter;
};

DialogV2.defaultProps = {
  useBackdrop: true
};

DialogV2.Header = ModalHeader;
DialogV2.Body = ModalBody;
DialogV2.Footer = ModalFooter;
DialogV2.CloseButton = ModalCloseButton;
DialogV2.Backdrop = ModalBackdrop;

export { DialogV2 };
