import React, { useMemo, useState } from 'react';
import { createContext } from '@shared/utils/createContext';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';

import type { Player } from '@vimeo/player';
import loadable from '@loadable/component';

const VimeoLib = loadable.lib(
  () =>
    import(
      /* webpackChunkName: "static/webpack/vendor/vimeoplayer" */
      '@vimeo/player'
    ),
  { ssr: false }
);

const [Provider, useVimeoPlayerContext] = createContext<{ VimeoPlayer: typeof Player | null; prepareForUse: () => void }>({ name: 'VimeoPlayer' });

const VimeoPlayerProvider: React.FC = ({ children }) => {
  const [{ hasRequestedUsage, VimeoPlayer }, setState] = useState<{ hasRequestedUsage: boolean; VimeoPlayer: typeof Player | null }>({
    hasRequestedUsage: false,
    VimeoPlayer: null
  });
  const prepareForUse = useEventCallback(() => {
    if (!hasRequestedUsage) {
      setState(prev => ({ ...prev, hasRequestedUsage: true }));
    }
  });

  const handleLibraryLoaded = useEventCallback((instance: LoadableModule<typeof VimeoLib> | null) => {
    setState({ hasRequestedUsage: true, VimeoPlayer: instance?.default || null });
  });

  const ctx = useMemo(() => {
    return {
      VimeoPlayer,
      prepareForUse
    };
  }, [VimeoPlayer, prepareForUse]);
  return (
    <Provider value={ctx}>
      {children}
      {hasRequestedUsage && <VimeoLib ref={handleLibraryLoaded} />}
    </Provider>
  );
};

export { VimeoPlayerProvider, useVimeoPlayerContext };
