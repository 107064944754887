import { IconConfig } from '..';

/* tslint:disable:max-line-length */

export const backArrow: IconConfig = {
  name: 'backArrow',
  a11yLabel: 'Back arrow',
  fill: '#333333',
  viewBox: 24,
  body:
    '<path fill-rule="evenodd" clip-rule="evenodd" d="M3.06903 11.0011L10.5357 3.53762C10.8861 3.18397 10.8861 2.61589 10.5357 2.26224C10.1828 1.91259 9.61396 1.91259 9.26031 2.26224C9.26031 2.26224 2.41294 9.11361 0.262237 11.2603C-0.0874124 11.614 -0.0874124 12.1828 0.262237 12.5357C2.31613 14.5856 8.94187 21.2153 9.26031 21.5306C9.61396 21.8842 10.1828 21.8842 10.5357 21.5306C10.8861 21.1809 10.8861 20.612 10.5357 20.2584L3.07544 12.8013H23.0999C23.3375 12.8013 23.5655 12.7077 23.7376 12.5389C23.9064 12.3701 24 12.1388 24 11.9012C24 11.6636 23.9064 11.4323 23.7376 11.2635C23.5655 11.0947 23.3375 11.0011 23.0999 11.0011H3.06903Z" />'
};
