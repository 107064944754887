import React from 'react';
import { ApolloClient, ApolloProvider, NormalizedCacheObject } from '@apollo/client';
import { useComponentWillMount } from '@shared/utils/hooks/useComponentWillMount';
import { useEffect } from 'react';

/**
 * there's odd behavior in apollo that merits disabling network fetch on initial load
 * this is a generally accepted strategy to avoid having to use ssrForceFetchDelay
 * https://github.com/apollographql/apollo-client/issues/4814
 */
export const WrappedApolloProvider: React.FC<{ client: ApolloClient<NormalizedCacheObject> }> = ({ children, client }) => {
  useComponentWillMount(() => {
    client.disableNetworkFetches = true;
  });

  useEffect(() => {
    client.disableNetworkFetches = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
