import { getConfig } from '@config';
import React from 'react';
import { Helmet } from 'react-helmet-async';

const SCRIPT_BODY = `(function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
    })(window, document, "clarity", "script", "hvn8o07eqd");`;

/**
 * https://clarity.microsoft.com/
 * Free heatmaps & session recordings
 *
 */
export const ClarityService = () => {
  if (!getConfig().microsoftClarityEnabled) {
    return null;
  }

  return (
    <Helmet>
      <script type="text/javascript">{SCRIPT_BODY}</script>
    </Helmet>
  );
};
