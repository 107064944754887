import { styled, TextV2, Box, LinkV1 } from '@withjoy/joykit';

export const StyledTitle = styled(TextV2)`
  text-align: center;
`;

export const StyledSubtitle = styled(TextV2)`
  text-align: center;
`;

export const StyledBox = styled(Box)<{ height?: string; width?: string }>`
  width: ${({ width }) => width || '311px'};
  height: ${({ height }) => height || '292px'};
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
`;

export const StyledLink = styled(LinkV1)`
  color: ${props => props.theme.colors.linkText};
  &:hover {
    color: ${props => props.theme.colors.linkHover};
  }
  &:active {
    color: ${props => props.theme.colors.linkActive};
  }
`;
