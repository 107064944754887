import React, { useMemo } from 'react';
import { useTranslation } from '@shared/core';
import { Flex, ButtonV2 } from '@withjoy/joykit';
import { ReactComponent as JoyLogo } from '@assets/joy-logo.svg';
import {
  PageNotFoundContainer,
  JoyLogoContainer,
  PageNotFoundContentContainer,
  TextEyebrow,
  TextTitle,
  TextInfo,
  PageNotFoundButtonContainer,
  PageNotFoundCtaOverrides,
  ButtonWrapper,
  StyledChevronRightSquare,
  ImageWrapper
} from './PageNotFound.styles';
import { useNotFoundTelemetry } from './PageNotFound.telemetry';
import { withWindow } from '@shared/utils/withWindow';
import { useMount } from '@shared/utils/hooks/useMount';
import { addAction } from '@shared/utils/logger';

const PageNotFound: React.FC = () => {
  const { notFound } = useNotFoundTelemetry();
  const { t2 } = useTranslation('pageNotFound');
  const { textEyebrow, textTitle, textInfoOne, textInfoTwo, findBtn, registryBtn, shopBtn, getStartedBtn, helpBtn, homeBtn } = t2('pageNotFound');

  useMount(() => {
    let notFoundUrl = '';
    withWindow(global => (notFoundUrl = global.location.href));
    notFound.enter(notFoundUrl);
    addAction('NotFoundPageLoaded', { notFoundUrl });
  });

  const generatedUrl = useMemo(() => {
    return withWindow(
      () => {
        const url = new URL(window.location.origin);
        return { find: url + 'find', registry: url + 'registry', shop: url + 'shop', home: url + '' };
      },
      { find: '', registry: '', shop: '', home: '' }
    );
  }, []);

  return (
    <PageNotFoundContainer>
      <Flex flexDirection="column">
        <PageNotFoundContentContainer>
          <JoyLogoContainer>
            <JoyLogo />
          </JoyLogoContainer>
          <TextEyebrow typographyVariant={'SectionEyebrow20'} color={'brandWarmGray6'}>
            {textEyebrow}
          </TextEyebrow>
          <TextTitle typographyVariant={'Hed40'} fontFamily={'Austin News Deck Web'}>
            {textTitle}
          </TextTitle>
          <TextInfo typographyVariant={'Dek20'} color={'brandWarmGray6'} fontFamily={'Inter UI'}>
            {textInfoOne}
          </TextInfo>
          <TextInfo typographyVariant={'Dek20'} color={'brandWarmGray6'} fontFamily={'Inter UI'}>
            {textInfoTwo}
          </TextInfo>
        </PageNotFoundContentContainer>
        <PageNotFoundButtonContainer>
          <ButtonWrapper>
            <ButtonV2 as="a" href={generatedUrl.find} shape="rounded" variant="solid" marginBottom={5} overrides={PageNotFoundCtaOverrides} endIcon={<StyledChevronRightSquare />}>
              {findBtn}
            </ButtonV2>
          </ButtonWrapper>
          <ButtonWrapper>
            <ButtonV2
              as="a"
              href={generatedUrl.registry}
              shape="rounded"
              variant="solid"
              marginBottom={5}
              overrides={PageNotFoundCtaOverrides}
              endIcon={<StyledChevronRightSquare />}
            >
              {registryBtn}
            </ButtonV2>
          </ButtonWrapper>
          <ButtonWrapper>
            <ButtonV2 as="a" href={generatedUrl.shop} shape="rounded" variant="solid" marginBottom={5} overrides={PageNotFoundCtaOverrides} endIcon={<StyledChevronRightSquare />}>
              {shopBtn}
            </ButtonV2>
          </ButtonWrapper>
          <ButtonWrapper>
            <ButtonV2 as="a" href={generatedUrl.home} shape="rounded" variant="solid" marginBottom={5} overrides={PageNotFoundCtaOverrides} endIcon={<StyledChevronRightSquare />}>
              {getStartedBtn}
            </ButtonV2>
          </ButtonWrapper>
          <ButtonWrapper>
            <ButtonV2
              href={'https://help.withjoy.com/'}
              as="a"
              shape="rounded"
              variant="solid"
              marginBottom={5}
              overrides={PageNotFoundCtaOverrides}
              endIcon={<StyledChevronRightSquare />}
            >
              {helpBtn}
            </ButtonV2>
          </ButtonWrapper>
          <ButtonWrapper>
            <ButtonV2 as="a" href={generatedUrl.home} shape="rounded" variant="solid" overrides={PageNotFoundCtaOverrides} endIcon={<StyledChevronRightSquare />}>
              {homeBtn}
            </ButtonV2>
          </ButtonWrapper>
        </PageNotFoundButtonContainer>
      </Flex>
      <ImageWrapper>
        <img src="https://withjoy.com/assets/public/page-not-found.png" alt="page not found" />
      </ImageWrapper>
    </PageNotFoundContainer>
  );
};

export default PageNotFound;
