import { ComposedIconProps } from '.';
import { styled } from '@shared/joykit/packages/core/common/styledComponents';
import { getStyledPosition, getStyledLeft, getStyledRight, getStyledBottom, getStyledTop, getStyledBaseComponentProps } from '../../common/styledSystem/styledFunctions';

export const StyledIconContainer = styled('span')<ComposedIconProps>`
  flex: 0 0 auto;
  vertical-align: text-bottom;
  margin: 0;
  display: inline-block;
  vertical-align: text-bottom;

  ${getStyledPosition}
  ${getStyledLeft}
  ${getStyledRight}
  ${getStyledBottom}
  ${getStyledTop}
  ${getStyledBaseComponentProps}

  > svg:not([fill]) {
    fill: currentColor;
  }
`;
