import { IconConfig } from '..';

/* tslint:disable:max-line-length */
export const refund: IconConfig = {
  name: 'refund',
  a11yLabel: 'Refund',
  viewBox: 24,
  fill: '#94D0A7',
  body: `
  <path
    d="M14.1303 16.2381L14.0607 16.3077C13.6672 16.6551 13.1805 16.8867 12.6477 17.0025V18.5077H11.0728V16.9563C10.6331 16.8406 10.2387 16.6325 9.89134 16.3539C9.35853 15.9142 9.03469 15.3118 8.9415 14.6632L10.5626 14.4316C10.5861 14.6867 10.7245 14.9183 10.956 15.1028C11.535 15.5659 12.4613 15.5659 12.9941 15.0793C13.318 14.778 13.4808 14.361 13.4573 13.9449C13.4338 13.5514 13.2492 13.204 12.9245 12.9489C12.531 12.6712 12.1601 12.578 11.7204 12.4858C11.1415 12.3465 10.4703 12.1845 9.86781 11.583C9.28887 10.9805 8.98763 10.2396 9.01116 9.5223C9.03468 8.85109 9.335 8.22509 9.86781 7.73933L9.89133 7.71581L9.91485 7.69229C10.2387 7.41458 10.6557 7.22913 11.0727 7.08981V5.51489H12.6477V7.06629C13.157 7.18208 13.5975 7.43718 13.991 7.76102C14.3845 8.10839 14.6622 8.54802 14.8015 9.03471L13.157 9.28981C13.1108 9.17403 13.0176 9.08175 12.9254 8.98858C12.3926 8.52542 11.4898 8.52542 10.957 8.96506C10.795 9.12698 10.6331 9.33595 10.6331 9.61366C10.6096 9.89137 10.7724 10.1926 11.0266 10.4703C11.2817 10.748 11.5594 10.8177 12.0922 10.9335C12.6015 11.0493 13.2501 11.1886 13.8988 11.6743C14.5935 12.1836 15.0331 12.9942 15.0802 13.8743C15.1028 14.7328 14.7789 15.5895 14.1303 16.2381L14.1303 16.2381Z"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M12.4452 1C18.2687 1 23 5.93085 23 12C23 18.0691 18.2687 23 12.4452 23C10.6027 23 8.87419 22.5035 7.36694 21.6339L7.84365 20.7716C9.20944 21.5522 10.7757 21.9997 12.4452 21.9997C17.7501 21.9997 22.0415 17.5273 22.0415 11.9986C22.0415 6.46995 17.7501 1.99889 12.4452 1.99889C7.94054 1.99889 4.18481 5.22929 3.14891 9.58716H4.48318L2.74159 12.7302L1 9.58995H2.16262C3.22065 4.68076 7.4179 1.0014 12.4439 1.0014L12.4452 1Z"
  />  
  `
};
