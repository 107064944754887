import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import type { PickerOptions, PickerResponse, Client } from 'filestack-js';
import type { PickerFileCallback, PickerFileErrorCallback, PickerInstance, PickerTransformationOptions } from 'filestack-js/build/main/lib/picker';
import loadable, { LoadableLibrary } from '@loadable/component';

import { v1 as uuid } from 'uuid';
import { useFilestackLazyQuery } from '@graphql/generated';
import { createContext } from '../createContext';
import { useImmer } from 'use-immer';
import { executeWithDeferredLibrary, ExecuteWithLibrary } from '../executeWithDeferredLibrary';

type LoadableModule<Module> = Module extends LoadableLibrary<infer R> ? R : never;

const FilestackLib = loadable.lib(
  () =>
    import(
      /* webpackChunkName: "static/webpack/vendor/filestack-js" */
      'filestack-js'
    ),
  {
    ssr: false
  }
);

type LoadableFilestack = LoadableModule<typeof FilestackLib>;

type ContextType = {
  withClient: ExecuteWithLibrary<Client>;
  prepareForUse: () => void;
  loading: boolean;
};
const [Provider, useContext] = createContext<ContextType>({ name: 'Filestack' });

export const FilestackProvider: React.FC<{}> = ({ children }) => {
  const [{ hasInitializedClient, hasRequestedUsage, hasLoadedLibrary }, setState] = useImmer<{
    hasInitializedClient: boolean;
    hasLoadedLibrary: boolean;
    hasRequestedUsage: boolean;
  }>(() => ({
    hasInitializedClient: false,
    hasLoadedLibrary: false,
    hasRequestedUsage: false
  }));
  const filestackRef = useRef<{ filestack: LoadableModule<typeof FilestackLib> | null; client: Client | undefined }>({ filestack: null, client: undefined });
  const [requestFilestackData, { data, loading }] = useFilestackLazyQuery();
  const deferredRef = useRef(executeWithDeferredLibrary<Client>());

  const handleLibraryLoaded = useCallback<(instance: LoadableFilestack | null) => void>(
    instance => {
      filestackRef.current.filestack = instance;
      setState(draft => {
        draft.hasLoadedLibrary = !!instance;
      });
    },
    [setState]
  );

  const prepareForUse = useCallback(() => {
    if (!hasRequestedUsage) {
      setState(draft => {
        draft.hasRequestedUsage = true;
      });
      requestFilestackData();
    }
  }, [hasRequestedUsage, requestFilestackData, setState]);

  const initClient = useCallback(() => {
    const filestack = filestackRef.current.filestack;
    if (data && filestack) {
      const client = filestack.init(data.filestack.apiKey, {
        security: {
          policy: data.filestack.policy,
          signature: data.filestack.signature
        }
      });

      filestackRef.current.client = client;
      const [resolveClient] = deferredRef.current;
      resolveClient(client);

      setState(draft => {
        draft.hasInitializedClient = true;
      });
    }
  }, [data, setState]);

  const withClient = useCallback<ContextType['withClient']>(
    cb => {
      if (!hasRequestedUsage) {
        prepareForUse();
      }
      const executeWithClient = deferredRef.current[1];
      return executeWithClient(cb);
    },
    [hasRequestedUsage, prepareForUse]
  );

  // Init the client when ready
  const shouldInitClient = !hasInitializedClient && hasLoadedLibrary && !!data;
  useEffect(() => {
    if (shouldInitClient) {
      initClient();
    }
  }, [shouldInitClient, initClient]);

  const value = useMemo(() => {
    return { loading, withClient, prepareForUse };
  }, [loading, withClient, prepareForUse]);

  return (
    <>
      <Provider value={value}>{children}</Provider>
      {hasRequestedUsage && <FilestackLib ref={handleLibraryLoaded} />}
    </>
  );
};

interface Props
  extends Readonly<{
    accept?: string | string[];
    containerId: string;
    onFileSelected?: PickerFileCallback;
    onUploadDone: (res: PickerResponse) => void;
    onFileUploadFailed?: PickerFileErrorCallback;
    maxFiles?: number;
    transformations?: PickerTransformationOptions;
    pickerContainer?: string | Node;
    rootId?: string;
    onOpen?: (handle: PickerInstance) => void;
    onClose?: () => void;
  }> {}

interface PreviewProps {
  url: string;
}
// When requesting assets from google drive, parenthese get encoded in the filename sent to filestack, we need to encode as well
const encodeForGoogleDrive = (filename: string) => encodeURIComponent(filename).replace(/\(/g, '%28').replace(/\)/g, '%29');

const defaultOnFileSelected: PickerFileCallback = async res => {
  return { ...res, filename: `${uuid()}-` + (res.source === 'googledrive' ? encodeForGoogleDrive(res.filename) : res.filename) };
};

export const useFilestack = (props: Props) => {
  const { containerId, onFileSelected, onUploadDone, onFileUploadFailed, onClose, onOpen, maxFiles = 50, pickerContainer, rootId, transformations = {}, accept } = props;
  const { withClient, prepareForUse } = useContext();

  useEffect(() => {
    prepareForUse();
  }, [prepareForUse]);

  const options: PickerOptions = {
    rootId,
    // Chose to not allow "imagesearch" because results vary in quality.
    // Instead, we'll leverage unsplash as the primary search source
    fromSources: ['local_file_system', 'url', 'unsplash', 'facebook', 'instagram', 'googledrive', 'dropbox'],
    container: pickerContainer,
    maxFiles: Math.max(1, maxFiles),
    uploadInBackground: false,
    disableStorageKey: true,
    transformations,
    storeTo: {
      location: 'azure',
      container: containerId
    },
    onFileSelected: onFileSelected ?? defaultOnFileSelected,
    onUploadDone,
    onFileUploadFailed,
    onOpen,
    onClose,
    accept
  };
  const open = () => {
    withClient(client => {
      // Open operation is idempotent.
      client.picker(options).open();
    });
  };

  const getPhotoPreviewUrl = ({ url }: PreviewProps): Promise<string> => {
    return withClient(client => {
      return `${url}?policy=${client?.session.policy}&signature=${client?.session.signature}`;
    });
  };

  return { open, getPhotoPreviewUrl };
};
