import React, { useState, useMemo, useCallback } from 'react';
import { TelemetryContextProps } from './types';

export const createProvider = <A, C>(TelemetryContext: React.Context<TelemetryContextProps<A, C>>, actions: A, initialContext?: C) => {
  const TelemetryProvider: React.FC<{ context?: C }> = ({ context, children }) => {
    const [contextState, setContextState] = useState<C | undefined>(context ?? initialContext);

    const updateContext = useCallback<TelemetryContextProps<A, C>['updateContext']>(input => {
      if (typeof input === 'function') {
        setContextState(prevState => input(prevState as C));
      } else {
        setContextState(prevContext => ({ ...(prevContext || ({} as C)), ...input }));
      }
    }, []);

    const value = useMemo(() => {
      return { context: contextState, actions, updateContext };
    }, [contextState, updateContext]);

    return <TelemetryContext.Provider value={value}>{children}</TelemetryContext.Provider>;
  };
  return TelemetryProvider;
};
