import { styled } from '@withjoy/joykit';

export const StyledMessage = styled.div`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  justify-content: center;
  color: inherit;
  flex: 1 1 auto;
  white-space: pre-line;
`;
