import { currencyDecimalPlaces } from './constants';
import { NumberFormatOptions, CurrencyFormatOptions, CurrencyArgs, MissingCurrencyCodeError, CurrencyError } from './types';
import { memoizedNumberFormatter, getCurrencySymbol } from './utils';

interface CurrencyFormatterArgs extends CurrencyArgs {
  onError?(error: CurrencyError): void;
}

export class CurrencyFormatter {
  readonly locale: string;
  readonly defaultCurrency?: string;
  readonly onError: NonNullable<CurrencyFormatterArgs['onError']>;

  constructor({ locale, defaultCurrency, onError }: CurrencyFormatterArgs) {
    this.locale = locale!;
    this.defaultCurrency = defaultCurrency!;
    this.onError = onError || this.defaultOnError;
  }

  formatNumber(amount: number, { as, precision, ...options }: NumberFormatOptions = {}) {
    const { locale, defaultCurrency: currency } = this;

    if (as === 'currency' && currency == null && options.currency == null) {
      this.onError(new MissingCurrencyCodeError(`formatNumber(amount, {as: 'currency'}) cannot be called without a currency code.`));

      return '';
    }

    return memoizedNumberFormatter(locale, {
      style: as,
      maximumFractionDigits: precision,
      currency,
      ...options
    }).format(amount);
  }

  formatCurrency(amount: number, { form, ...options }: CurrencyFormatOptions = {}) {
    switch (form) {
      case 'auto':
        return this.formatCurrencyAuto(amount, options);
      case 'explicit':
        return this.formatCurrencyExplicit(amount, options);
      case 'short':
        return this.formatCurrencyShort(amount, options);
      case 'none':
        return this.formatCurrencyNone(amount, options);
    }

    return this.formatNumber(amount, { as: 'currency', ...options });
  }

  getCurrencySymbol = (currencyCode?: string) => {
    const currency = currencyCode || this.defaultCurrency;
    if (currency == null) {
      throw new MissingCurrencyCodeError('formatCurrency cannot be called without a currency code.');
    }
    return this.getCurrencySymbolLocalized(this.locale, currency);
  };

  getCurrencySymbolLocalized(locale: string, currency: string) {
    return getCurrencySymbol(locale, { currency });
  }

  private formatCurrencyAuto(amount: number, options: Intl.NumberFormatOptions = {}): string {
    // use the short format if we can't determine a currency match, or if the
    // currencies match, use explicit when the currencies definitively do not
    // match.
    const formatShort = options.currency == null || this.defaultCurrency == null || options.currency === this.defaultCurrency;

    return formatShort ? this.formatCurrencyShort(amount, options) : this.formatCurrencyExplicit(amount, options);
  }

  private formatCurrencyExplicit(amount: number, options: Intl.NumberFormatOptions = {}): string {
    const value = this.formatCurrencyShort(amount, options);
    const isoCode = options.currency || this.defaultCurrency || '';
    if (value.includes(isoCode)) {
      return value;
    }
    return `${value} ${isoCode}`;
  }

  private formatCurrencyShort(amount: number, options: NumberFormatOptions = {}): string {
    const formattedAmount = this.formatCurrencyNone(amount, options);
    const shortSymbol = this.getShortCurrencySymbol(options.currency);

    const formattedWithSymbol = shortSymbol.prefixed ? `${shortSymbol.symbol}${formattedAmount}` : `${formattedAmount}${shortSymbol.symbol}`;

    return amount < 0 ? `-${formattedWithSymbol.replace(/[-−]/, '')}` : formattedWithSymbol;
  }

  private formatCurrencyNone(amount: number, options: NumberFormatOptions = {}): string {
    const { locale } = this;
    let adjustedPrecision = options.precision;
    if (adjustedPrecision === undefined) {
      const currency = options.currency || this.defaultCurrency || '';
      adjustedPrecision = currencyDecimalPlaces.get(currency.toUpperCase());
    }

    return memoizedNumberFormatter(locale, {
      style: 'decimal',
      minimumFractionDigits: adjustedPrecision,
      maximumFractionDigits: adjustedPrecision,
      ...options
    }).format(amount);
  }

  // Intl.NumberFormat sometimes annotates the "currency symbol" with a country code.
  // For example, in locale 'fr-FR', 'USD' is given the "symbol" of " $US".
  // This method strips out the country-code annotation, if there is one.
  // (So, for 'fr-FR' and 'USD', the return value would be " $").
  //
  // For other currencies, e.g. CHF and OMR, the "symbol" is the ISO currency code.
  // In those cases, we return the full currency code without stripping the country.
  private getShortCurrencySymbol(currencyCode = this.defaultCurrency || '') {
    const currency = currencyCode || this.defaultCurrency || '';
    const regionCode = currency.substring(0, 2);
    const info = this.getCurrencySymbol(currency);
    const shortSymbol = info.symbol.replace(regionCode, '');
    const alphabeticCharacters = /[A-Za-zÀ-ÖØ-öø-ÿĀ-ɏḂ-ỳ]/;

    return alphabeticCharacters.exec(shortSymbol) ? info : { symbol: shortSymbol, prefixed: info.prefixed };
  }

  private defaultOnError(error: CurrencyError) {
    throw error;
  }
}
