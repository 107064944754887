import * as React from 'react';
import { StyledSvg } from './Icon.styles';
import { IconV2Props } from './Icon.types';

export const IconV2 = React.forwardRef<SVGSVGElement, IconV2Props>((props, ref) => {
  const { size = 'md', children, color, title, ...restProps } = props;
  return (
    <StyledSvg as="svg" ref={ref} color={color} fill={color} iconSize={size} {...restProps}>
      {title && <title>{title}</title>}
      {children}
    </StyledSvg>
  );
});
