/**
 * This file was automatically generated by `IconCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, follow the instructions at `src/shared/joykit/packages/core/icons/README.md`.
 */

import * as React from 'react';

import createSvgIcon from '../createSvgIcon';

import { SvgIconConfig } from '../types';

const config: SvgIconConfig = {
  title: 'Check Filled',
  definitions: {
    '16': {
      viewBox: '0 0 16 16',
      path: (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8ZM11.3622 6.91392C11.5908 6.71389 11.6139 6.36642 11.4139 6.13782C11.2139 5.90922 10.8664 5.88606 10.6378 6.08608L7.02506 9.24725L5.38891 7.61109C5.17412 7.3963 4.82588 7.3963 4.61109 7.61109C4.3963 7.82588 4.3963 8.17412 4.61109 8.38891L6.61109 10.3889C6.81584 10.5937 7.14427 10.6046 7.36218 10.4139L11.3622 6.91392Z"
            fill="currentColor"
          />
        </>
      )
    },
    '48': {
      viewBox: '0 0 48 48',
      path: (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M48 24C48 37.2548 37.2548 48 24 48C10.7452 48 0 37.2548 0 24C0 10.7452 10.7452 0 24 0C37.2548 0 48 10.7452 48 24ZM36.5268 18.6021C36.8593 18.3111 36.893 17.8057 36.6021 17.4732C36.3111 17.1407 35.8057 17.107 35.4732 17.3979L20.0365 30.9051L12.5657 23.4343C12.2533 23.1219 11.7467 23.1219 11.4343 23.4343C11.1219 23.7467 11.1219 24.2533 11.4343 24.5657L19.4343 32.5657C19.7321 32.8635 20.2098 32.8794 20.5268 32.6021L36.5268 18.6021Z"
            fill="currentColor"
          />
        </>
      )
    }
  }
};

const icon = createSvgIcon(config);
export default icon;
