import type { Theme, MediaQueryHelpers, Breakpoints, ViewportSize, MediaQueryInputTarget, ViewportMediaQuery, MediaQuery } from '@withjoy/joykit/theme';
import { getMediaQuery } from '../../utils/responsiveHelpers';
import { relativeUnitToPx, HTML_FONT_SIZE, pxToRelativeUnit } from './typography';
import { breakpointAliasKeys, breakpointAliases } from './breakpoints';

const STEP = 0.05;

export const viewportSizeKeys = ['xs', 'sm', 'md', 'lg', 'xl'] as ReadonlyArray<ViewportSize>;

export const createMediaQueries = (breakpoints: Breakpoints): Theme['mediaQueries'] => {
  // BreakpointWidthsEm -> [xs: string, sm: string, md: string, lg: string, xl: string]
  // NOTE: `Breakpoints` has aliases defined, so we index the associated position
  const viewportSizes = {
    xs: breakpoints[0],
    sm: breakpoints[1],
    md: breakpoints[2],
    lg: breakpoints[3],
    xl: breakpoints[4]
  };

  const _isViewportInput = (input: MediaQueryInputTarget): input is ViewportMediaQuery => !!(input as ViewportMediaQuery).viewport;

  const _breakpointValueFromInput = (input: MediaQueryInputTarget) => {
    return _isViewportInput(input)
      ? // When viewport input is provided, refer to the theme media queries
        viewportSizes[input.viewport]
      : // Else, access the aliases defined on the theme breakpoints object
        breakpoints[input.breakpointAlias];
  };

  const _breakpointDataFromInput = (input: MediaQueryInputTarget) => {
    return _isViewportInput(input)
      ? { key: input.viewport, keys: viewportSizeKeys, breakpointMap: breakpoints }
      : { key: input.breakpointAlias, keys: breakpointAliasKeys, breakpointMap: breakpointAliases };
  };

  const _viewportSizesToMediaQueries = () => {
    return Object.keys(viewportSizes).reduce((acc, size) => {
      const sizeKey = size as ViewportSize;
      acc[sizeKey] = getMediaQuery(viewportSizes[sizeKey]);
      return acc;
    }, {} as MediaQuery);
  };

  //////////////////////////////////////////

  const up: MediaQueryHelpers['up'] = input => {
    const value = typeof input === 'number' ? pxToRelativeUnit(input, 'em') : _breakpointValueFromInput(input);
    return `@media screen and (min-width: ${value})`;
  };

  const down: MediaQueryHelpers['down'] = input => {
    let upperBoundValue: number;
    if (typeof input === 'number') {
      // Use specified screen width
      upperBoundValue = input;
    } else {
      // Use an existing `ViewportSize`

      const { key, keys, breakpointMap } = _breakpointDataFromInput(input);

      // We'll leverage static type checking of `key`
      const endIndex = keys.indexOf(key) + 1; // + 1 because this media query is inclusive of the key.
      if (endIndex === keys.length) {
        // xl applies to all sizes
        return up(0);
      }

      const upperBound: Maybe<string> = breakpointMap[keys[endIndex]];
      upperBoundValue = relativeUnitToPx(upperBound);
    }

    return `@media screen and (max-width: ${Math.max(upperBoundValue - STEP, 0) / HTML_FONT_SIZE}em)`;
  };

  const between: MediaQueryHelpers['between'] = (start, end) => {
    const upperBound: number = typeof end === 'number' ? end : relativeUnitToPx(_breakpointValueFromInput(end));
    return `${up(start)} and (max-width: ${Math.max(upperBound - STEP, 0) / HTML_FONT_SIZE}em)`;
  };

  return {
    ..._viewportSizesToMediaQueries(),
    up,
    down,
    between
  };
};
