import { filterUnique } from './array';

export const SPACE_LAYOUT_PROPS: string[] = ['margin', 'marginTop', 'marginRight', 'marginBottom', 'marginLeft', 'marginX', 'marginY'];
export const SPACE_INTERNAL_PROPS: string[] = ['padding', 'paddingTop', 'paddingRight', 'paddingBottom', 'paddingLeft', 'paddingX', 'paddingY'];
export const SPACE_STYLE_PROPS: string[] = [...SPACE_LAYOUT_PROPS, ...SPACE_INTERNAL_PROPS];

/** CSS properties that are used across Components. */
export const CSS_PROPS: string[] = [
  'alignItems',
  'backgroundColor',
  'border',
  'borderBottom',
  'borderColor',
  'borderRadius',
  'color',
  'display',
  'height',
  'justifyContent',
  'position',
  'left',
  'top',
  'bottom',
  'right',
  'textAlign',

  ...SPACE_STYLE_PROPS
];

/** A collection of curated prop keys used across our Components which are not valid HTMLElement props. */
export const BLOCKLIST_COMPONENT_PROPS: string[] = [
  'alignIndicator',
  'color',
  'component',
  'error',
  'fill',
  'ghost',
  'icon',
  'iconRight',
  'inputRef',
  'intent',
  'labelInfo',
  'loading',
  'margin',
  'minimal',
  'padding',
  'rotate',
  'rounded',
  'spacing',
  'styles',
  'transitionProperties',
  'truncate',
  'typeVariant',
  'underline',
  'variant',
  'vertical'
];

export const INVALID_PROPS_AND_STYLED_PROPS = filterUnique([...BLOCKLIST_COMPONENT_PROPS, ...CSS_PROPS]).sort();

/**
 * Typically applied to HTMLElements to filter out blocklisted props. When applied to a Component,
 * can filter props from being passed down to the children. Can also filter by a combined list of
 * supplied prop keys and the blocklist (only appropriate for HTMLElements).
 * @param props The original props object to filter down.
 * @param {string[]} invalidProps If supplied, overwrites the default blocklist.
 * @param {boolean} shouldMerge If true, will merge supplied invalidProps and blocklist together.
 */
export function removeNonHTMLProps(props: { [key: string]: any }, invalidProps: string[] = INVALID_PROPS_AND_STYLED_PROPS, shouldMerge: boolean = false): { [key: string]: any } {
  if (shouldMerge) {
    invalidProps = invalidProps.concat(BLOCKLIST_COMPONENT_PROPS);
  }

  return invalidProps.reduce(
    (prev, curr) => {
      if (prev.hasOwnProperty(curr)) {
        delete (prev as any)[curr];
      }
      return prev;
    },
    { ...props }
  );
}
export function extractProps(props: { [key: string]: any }, targetProps: string[]): { [key: string]: any } {
  return targetProps.reduce<{ [key: string]: any }>((prev, curr) => {
    if (props.hasOwnProperty(curr)) {
      prev[curr] = curr;
    }
    return prev;
  }, {});
}
