import { pixelToRemConversion } from '../../utils';
import { Theme } from '../themes';

export const DEFAULT_BREAKPOINTS = [
  pixelToRemConversion(0, 'string'),
  pixelToRemConversion(769, 'string'),
  pixelToRemConversion(1024, 'string'),
  pixelToRemConversion(1216, 'string'),
  pixelToRemConversion(1408, 'string')
] as Theme['breakpoints'];

/**
 * To have consistent spacing throughout our system, we will be adhering to a
 * 8pt soft grid system - increments between individual elements. In doing so, we can achieve `vertical rythmn` by applying
 * multiples/factors of the base value to determine margin, padding, etc.
 *
 *
 * - Most popular screen sizes are divisible by `8` on at least one axis - usually both.
 * - To ensure distinct (vs minor) changes, scale options are determined via geometric progression - doubling each step.
 * - Note: `12` is an option because some UI will require an exception.
 */
export const DEFAULT_SPACE_SCALE: number[] = [8, 12, 16, 24, 32, 40, 48, 64];
