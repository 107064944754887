export function getNodeEnv() {
  return {
    isTest: process.env.NODE_ENV === 'test',
    isProduction: process.env.NODE_ENV === 'production',
    isDevelopment: process.env.NODE_ENV === 'development',
    isPullRequest: process.env.BRANCH_NUM === '0',
    // we need to determine isCI at run time => we use a function
    isCI: () => process.env.CI === 'true'
  };
}
