type SharedGlobalObjectApi = keyof Window & keyof NodeJS.Global;
type GuaranteedWindowObject = Pick<Window, SharedGlobalObjectApi>;
type NonGuaranteedWindowObject = Partial<Omit<Window, SharedGlobalObjectApi>>;
export type GlobalWindow = GuaranteedWindowObject & NonGuaranteedWindowObject;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const NOOP: any = () => {};

const ssrWindowObject: GuaranteedWindowObject = {
  setInterval: NOOP,
  setTimeout: NOOP,
  clearInterval: NOOP,
  clearTimeout: NOOP,
  queueMicrotask: NOOP
};

const getGlobalWindow = (): GlobalWindow => {
  if (typeof self !== 'undefined') {
    return self;
  }
  if (typeof window !== 'undefined') {
    return window;
  }

  return ssrWindowObject;
};

/**
 * global self, window.
 *
 * This `globalWindow` object doesn't implement the DOM, it simply ensures that it does not throw errors during SSR.
 *
 * In a web browser, any code which the script doesn't specifically start up as a background task has a Window as its global object.
 * This is the vast majority of JavaScript code on the Web.
 */
const globalWindow = getGlobalWindow();

export default globalWindow;
