import { notNullOrUndefined } from '@shared/utils/notNullOrUndefined';
import deepmerge from 'deepmerge';
import produce from 'immer';
import type { Theme } from './';

const _removeFalsyProperties = (obj: Record<string, any>) => {
  if (notNullOrUndefined(obj)) {
    Object.keys(obj).forEach(key => {
      if (!obj[key]) {
        delete obj[key];
      } else if (typeof obj[key] === 'object' && notNullOrUndefined(obj[key])) {
        _removeFalsyProperties(obj[key]);
      }
    });
  }

  return obj;
};

export const extendTheme = <T extends DeepPartial<Theme>>(overrides: T, source: Theme): Theme & T => {
  const sanitizedOverrides = produce(overrides, draft => {
    _removeFalsyProperties(draft);
  });
  return deepmerge<Theme, T>(source, sanitizedOverrides, {
    arrayMerge: (target, source) => source
  });
};
