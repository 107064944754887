import { useRef, useState, useCallback } from 'react';

type UseShouldKeepOverlayOpenArgs<VisRefs> = Readonly<{ refValues: VisRefs; beforeStateChange?: (nextShouldKeepOverlayOpen: boolean) => void }>;
export const useShouldKeepOverlayOpen = <VisRefs extends { [key: string]: boolean }>(args: UseShouldKeepOverlayOpenArgs<VisRefs>) => {
  const { refValues, beforeStateChange } = args;
  const [shouldKeepOverlayOpen, setShouldKeepOverlayOpen] = useState(false);
  const visibilityRefs = useRef<VisRefs>(refValues);

  const getNextShouldKeepOverlayOpen = useCallback(() => {
    return Object.values(visibilityRefs.current).some(x => x);
  }, []);

  const setVisibilityForKey = useCallback(
    (key: keyof VisRefs, isVisible: boolean) => {
      visibilityRefs.current[key] = isVisible as VisRefs[keyof VisRefs];
      const nextShouldKeepOverlayOpen = getNextShouldKeepOverlayOpen();
      if (shouldKeepOverlayOpen !== nextShouldKeepOverlayOpen) {
        beforeStateChange?.(nextShouldKeepOverlayOpen);
        setShouldKeepOverlayOpen(nextShouldKeepOverlayOpen);
      }
    },
    [beforeStateChange, shouldKeepOverlayOpen, getNextShouldKeepOverlayOpen]
  );

  return { setVisibilityForKey, shouldKeepOverlayOpen };
};
