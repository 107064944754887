/* eslint-disable no-restricted-imports */
import { styled } from '@shared/joykit/packages/core/common/styledComponents';
// eslint-disable-next-line no-restricted-imports
import type { StyledComponent } from 'styled-components';
import { generateComponentDisplayName } from '../../utils';

import type { StyledConfig } from 'styled-components';
import { BaseBoxProps } from './';
import { shouldForwardProp, styleSystemPropsParser } from './Box.system';
import { HTMLProps } from '@withjoy/joykit/utils';
import { Theme } from '@withjoy/joykit';

/**
 * List of props that should be forwarded to the wrapped component.
 */
const forwardedProps = new Set(['isActive', 'exact']);

/**
 * A simple component that supports most system properties.
 *
 * @example
 *
 * ```
 * <Box
 *  marginBottom={{
 *    xs: 0
 *   }}
 * >
 *   I'm inside a box
 * </Box>
 *
 * // To enhance a box beyond it's API,
 * // use styled as a method and wrap the Box component - as such:
 *
 * styled(Box)'
 *  ...
 * ';
 * ```
 *
 */
const Box: StyledComponent<React.FC<HTMLProps<HTMLDivElement>>, Theme, BaseBoxProps> = styled.div.withConfig<BaseBoxProps>({
  shouldForwardProp: prop => {
    return forwardedProps.has(prop) || shouldForwardProp(prop);
  },
  componentId: 'joykit-box'
} as StyledConfig<{}>)(styleSystemPropsParser);
// );
Box.displayName = generateComponentDisplayName('Box');

export { Box };
