import React from 'react';

import { PageNotFoundWrapper } from '@apps/pageNotFound/PageNotFoundWrapper';
import { useSetEventHandle } from '@shared/utils/eventInfo';

export interface EventRouteProps {
  /**
   * The event handle for the route.
   */
  eventHandle: string;

  /**
   * Optional component to render which will automatically be passed the eventHandle prop.
   *
   * Defaults to rendering `children` if not provided.
   */
  ComponentToRender?: React.ComponentType<{ eventHandle: string }>;
}

export const EventRoute: React.FC<EventRouteProps> = ({ eventHandle, ComponentToRender, children }) => {
  useSetEventHandle(eventHandle);

  return <PageNotFoundWrapper eventHandle={eventHandle}>{ComponentToRender ? <ComponentToRender eventHandle={eventHandle} /> : children}</PageNotFoundWrapper>;
};
