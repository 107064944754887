import React from 'react';
import { animated, AnimatedValue, useSpring } from 'react-spring';
import type { BoxProps } from '../Box';
import { backdropStyles, StyledRoot } from './Backdrop.styles';
import { forwardRef } from '@shared/utils/forwardRef';

export interface BackdropProps
  extends Readonly<{
      isOpen: boolean;
      onFrame?: (styles: { opacity: number }) => void;
      delay?: number;
      duration?: number;
    }>,
    BoxProps {}

export const Backdrop = forwardRef<'div', BackdropProps>((props, ref) => {
  const { delay, duration, isOpen, onFrame, ...restProps } = props;
  const backdropSprings = useSpring({
    from: {
      opacity: 0
    },
    to: {
      opacity: isOpen ? 1 : 0
    },
    onFrame,
    delay,
    config: {
      duration
    }
  }) as AnimatedValue<{ opacity: number }>;

  return <StyledRoot data-testid="backdrop" as={animated.div} ref={ref} style={{ opacity: backdropSprings.opacity.interpolate(x => x) }} {...restProps} __css={backdropStyles} />;
});

Backdrop.defaultProps = {
  duration: 200
};
Backdrop.displayName = 'Backdrop';
