import { IconConfig } from '..';

/* tslint:disable:max-line-length */
export const question: IconConfig = {
  name: 'question',
  a11yLabel: 'Question',
  viewBox: 24,
  fill: '#5BA4E5',
  body:
    '<path d="M12 1C5.93286 1 1 5.93286 1 12C1 18.0671 5.93286 23 12 23C18.0671 23 23 18.0671 23 12C23 5.93286 18.0671 1 12 1Z"/> <path fill-rule="evenodd" clip-rule="evenodd" d="M12 5C14.1997 5 15.9953 6.72584 15.9999 8.84293V8.85152C16.0111 10.5043 14.9865 11.5327 14.116 12.3247C13.6796 12.7212 13.2745 13.078 13 13.4423C12.7254 13.8066 12.5714 14.157 12.5714 14.6287V14.9038C12.5736 15.051 12.5145 15.1929 12.4074 15.2982C12.2991 15.4024 12.1529 15.4615 12 15.4615C11.8471 15.4615 11.7009 15.4024 11.5926 15.2982C11.4855 15.1929 11.4263 15.051 11.4286 14.9038V14.6287C11.4286 13.9023 11.7087 13.2897 12.0803 12.7976C12.452 12.3054 12.9062 11.9121 13.3303 11.5252C14.1796 10.7525 14.8682 10.0873 14.8571 8.85152C14.8571 7.32555 13.5848 6.10048 12 6.10048C10.4152 6.10048 9.1429 7.32555 9.1429 8.85152C9.14514 8.99875 9.08599 9.1406 8.97885 9.24591C8.87059 9.35015 8.72439 9.40925 8.57148 9.40925C8.41858 9.40925 8.27237 9.35015 8.16412 9.24591C8.05698 9.1406 7.99783 8.99875 8.00006 8.85152C8.00006 6.7313 9.79802 5.00007 12 5.00007L12 5Z" fill="white" stroke="white" stroke-width="0.25"/> <path fill-rule="evenodd" clip-rule="evenodd" d="M12 17.6924C12.5096 17.6924 12.9231 18.1059 12.9231 18.6155C12.9231 19.1251 12.5096 19.5385 12 19.5385C11.4904 19.5385 11.0769 19.1251 11.0769 18.6155C11.0769 18.1059 11.4904 17.6924 12 17.6924Z" fill="white" stroke="white" stroke-width="0.25"/>'
};
