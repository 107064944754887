import { createTelemetry, createTelemetryObject } from '@shared/core/analytics/telemetry';

const category = 'wafcaptcha';

const telemetryObject = createTelemetryObject({
  pages: {
    captchaDialog: () => ({
      category,
      pagePrefix: 'admin',
      page: 'captchaDialog'
    })
  },
  actions: {
    captchaSuccess: () => ({
      category,
      action: 'ButtonInteracted',
      extraInfo: {
        actionType: 'click',
        name: 'captchaSuccess'
      }
    }),
    captchaFailure: (reason: string) => ({
      category,
      action: 'ButtonInteracted',
      extraInfo: {
        actionType: 'click',
        name: 'captchaFailure',
        reason
      }
    })
  }
});

export const { TelemetryProvider: WafCaptchaTelemetryProvider, useTelemetry: useWafCaptchaTelemetry } = createTelemetry(telemetryObject);
