import { DecodedToken } from './token.service';
import { getLocalStorage, Storage } from '../storage';
import { History } from 'history';
import { TokenService } from './token.service';
import { AxiosResponse } from 'axios';
import safeUrlToRedirect from '@shared/utils/safeUrlToRedirect';
import { withWindow } from '@shared/utils/withWindow';
import { config } from '@static/js/joy';
const auth0: import('../../../../static/js/joy').Auth0 = require('../../../../static/js/joy').auth0;
type Auth0AuthorizeResult = import('../../../../static/js/joy').Auth0AuthorizeResult;
type Auth0AuthorizeRedirect = import('../../../../static/js/joy').Auth0AuthorizeRedirect;

const REDIRECT_STORAGE_KEY = 'joy:auth:redirect';
const SKIP_TOKEN_DELEGATION_KEY = 'joy:auth:skipDelegation';
const REFRESH_MFA_STATUS_KEY = 'joy:auth:refreshMfaStatus';

interface TokenData {
  token_type: string;
  expires_in: number;
  id_token: string;
  refresh_token: string;
  serverTime: number;
}

export class AuthService {
  private clientId: string = config.auth0ClientId;
  private localStorage: Storage;
  private tokenService: TokenService;

  constructor(tokenService: TokenService) {
    this.tokenService = tokenService;
    this.localStorage = getLocalStorage();
  }

  public deriveAuthorizeResult(history: History): Auth0AuthorizeResult {
    // provided via URL querystring
    return auth0.deriveAuthorizeResult(history.location.search);
  }

  public async consumeAuthenticationToken(result: Auth0AuthorizeResult): Promise<boolean> {
    // we (should have) received a one-use code to retrieve our Token
    //   they are single-use codes;  try to use it twice?  Error!
    const { code } = result;
    if (code) {
      try {
        const tokenRes = await this.getToken(code);
        this.tokenService.setToken(
          {
            bearer_type: tokenRes.data.token_type,
            expires_in: tokenRes.data.expires_in,
            id_token: tokenRes.data.id_token
          },
          this.clientId,
          tokenRes.data.refresh_token,
          tokenRes.data.serverTime
        );
        return true;
      } catch (err) {
        console.error(err);
        return false;
      }
    } else {
      return false;
    }
  }

  public redirectAfterAuthentication(result: Auth0AuthorizeResult, history: History, success: boolean): void {
    // we passed data to our future self from the prior #authorize call
    const { redirectJSON } = result;
    let redirect: Auth0AuthorizeRedirect | null = null;
    try {
      redirect = auth0.hydrateAuthorizeRedirect(redirectJSON);
    } catch {
      try {
        const storedValue = this.localStorage.getItem(REDIRECT_STORAGE_KEY);
        if (storedValue) {
          redirect = auth0.hydrateAuthorizeRedirect(storedValue);
          this.localStorage.setItem(REDIRECT_STORAGE_KEY, null);
        }
      } catch (err) {}
    }
    if (success) {
      // safeUrlToRedirect will share the current origin
      if (redirect?.uri && safeUrlToRedirect(redirect.uri)) {
        // here we're allowing full URL location set for external app routing
        // e.g. dev.withjoy.com/me
        withWindow(({ location }) => {
          if (redirect?.uri) {
            location.href = redirect.uri;
          }
        });
      } else {
        // e.g. /eq/edit/design
        // treat redirectUri as a path (/asdf) for internal app routing
        history.push(redirect?.uri || '/');
        return;
      }
    }
    history.push(redirect?.failUri || '/');
  }

  public isAdminOrOwner = async (aclString?: string): Promise<boolean> => {
    // NOTE: aclString is only an argument for the time being - it'll eventually become an internal property
    try {
      const token = await this.tokenService.getTokenAsync();
      if (token) {
        const decodedToken: DecodedToken = JSON.parse(atob(token.split('.')[1]));
        const uniqueId = decodedToken.sub;
        const isAuthorizedUser = !!aclString && (aclString === 'owner' || aclString === 'admin');
        const isJoyUser = uniqueId.startsWith('google-apps') && uniqueId.endsWith('@withjoy.com');
        const isAdminOrOwner = isAuthorizedUser || isJoyUser;
        return isAdminOrOwner;
      }
    } catch (e) {
      console.error('Error verifying credentials. ');
      console.error(e);
    }

    return false;
  };

  public initiateRefreshMfaStatus = () => {
    this.localStorage.setItem(REFRESH_MFA_STATUS_KEY, 'true');
  };

  public shouldRefreshMfaStatus = () => {
    return Boolean(this.localStorage.getItem(REFRESH_MFA_STATUS_KEY));
  };

  public handleRefreshMfaStatusDone = () => {
    this.localStorage.setItem(REFRESH_MFA_STATUS_KEY, '');
  };

  public clearToken = (): void => {
    this.tokenService.deleteSecrets();
  };

  public signout = (returnToUrl?: string): void => {
    this.clearToken();
    auth0.signout(returnToUrl);
  };

  public loginWithFacebook = (redirectUri: string = '/', redirectUriOnFail: string = '/'): void => {
    auth0.socialLogin('facebook', { uri: redirectUri, failUri: redirectUriOnFail });
  };

  public loginWithGoogle = (redirectUri: string = '/', redirectUriOnFail: string = '/'): void => {
    auth0.socialLogin('google-oauth2', { uri: redirectUri, failUri: redirectUriOnFail });
  };

  public loginWithUsernameAndPassword = (email: string, password: string, redirectUrl: string = '/', redirectUriOnFail: string = '/'): Promise<void> => {
    const redirect = { uri: redirectUrl, failUri: redirectUriOnFail };
    this.localStorage.setItem(REDIRECT_STORAGE_KEY, JSON.stringify(redirect));
    return new Promise((resolve, reject) => {
      auth0.login(email, password, (err, response) => {
        if (err) {
          reject(err);
        }
      });
    });
  };

  public signupWithUsernameAndPassword = (email: string, password: string, redirectUri: string = '/', redirectUriOnFail: string = '/'): Promise<void> => {
    const redirect = { uri: redirectUri, failUri: redirectUriOnFail };
    this.localStorage.setItem(REDIRECT_STORAGE_KEY, JSON.stringify(redirect));
    return new Promise((resolve, reject) => {
      auth0.signup(email, password, (err, response) => {
        if (err) {
          reject(err);
        } else {
          this.loginWithUsernameAndPassword(email, password, redirectUri, redirectUriOnFail);
          resolve(response);
        }
      });
    });
  };

  public changePassword = (email: string): Promise<unknown> => {
    return new Promise((resolve, reject) => {
      auth0.changePassword(email, (err, res) => {
        if (err) {
          reject(err);
        } else {
          resolve(res);
        }
      });
    });
  };

  private getToken = async (code: string): Promise<AxiosResponse<TokenData>> => {
    const shouldSkipTokenDelegation = !!localStorage.getItem(SKIP_TOKEN_DELEGATION_KEY);

    this.localStorage.setItem(SKIP_TOKEN_DELEGATION_KEY, '');
    try {
      const res = await auth0.refreshToken(code, shouldSkipTokenDelegation);
      return res;
    } catch (err: unknown) {
      throw Error(typeof err === 'string' ? err : `Failed to get token`);
    }
  };

  public authorize = (redirectUri = '/', params?: Record<string, string | boolean | number>) => {
    const redirect = { uri: redirectUri };

    this.localStorage.setItem(REDIRECT_STORAGE_KEY, JSON.stringify(redirect));
    this.localStorage.setItem(SKIP_TOKEN_DELEGATION_KEY, 'true');

    auth0.authorize(params);
  };
}
