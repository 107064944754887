import React from 'react';
import { useCheckIfPageNotFoundQuery } from '@graphql/generated';
import PageNotFound from './PageNotFound';

export interface PageNotFoundWrapperProps {
  eventHandle: string;
}

export const PageNotFoundWrapper: React.FC<PageNotFoundWrapperProps> = props => {
  const { data, loading } = useCheckIfPageNotFoundQuery({ batchMode: 'fast', variables: { eventHandle: props.eventHandle } });

  if (!loading && !data?.eventByName?.id) {
    return <PageNotFound />;
  }

  return <>{props.children}</>;
};
