import type { BreakpointAliases, Theme, TypographyVariant, ZIndices, FontWeightToken } from '../themes';
import type { Property } from 'csstype';
import type { BaseComponentProps } from '../';

import type { ThemeValue } from 'styled-system';

// ==============================================================
// Base types/interfaces
// ==============================================================

/**
 * Modified `ResponsiveValue` type from `styled-system`.
 */
export type ResponsiveValue<T> = T | null | Array<T | null> | { [key in keyof BreakpointAliases | (string & {})]?: T };
type Length = string | number;

type ThemedColor = ThemeValue<'colors', Theme> | (string & {});

export interface ResponsiveSpacingProps {
  /**
   * @default 2
   */
  spacing?: ResponsiveValue<ThemeValue<'space', Theme> | (string & {})>;
}

export interface ResponsiveMarginProps<TLength = string | number> {
  /** Margin on top, left, bottom and right */
  margin?: ResponsiveValue<Property.Margin<TLength>>;
  /** Margin for the top */
  marginTop?: ResponsiveValue<Property.MarginTop<TLength>>;
  /** Margin for the right */
  marginRight?: ResponsiveValue<Property.MarginRight<TLength>>;
  /** Margin for the bottom */
  marginBottom?: ResponsiveValue<Property.MarginBottom<TLength>>;
  /** Margin for the left */
  marginLeft?: ResponsiveValue<Property.MarginLeft<TLength>>;
  /** Margin for the left and right */
  marginX?: ResponsiveValue<Property.Padding<TLength>>;
  /** Margin for the top and bottom */
  marginY?: ResponsiveValue<Property.Padding<TLength>>;
}

export interface ResponsivePaddingProps<TLength = string | number> {
  /** Padding on top, left, bottom and right */
  padding?: ResponsiveValue<Property.Padding<TLength>>;
  /** Padding for the top */
  paddingTop?: ResponsiveValue<Property.PaddingTop<TLength>>;
  /** Padding for the right */
  paddingRight?: ResponsiveValue<Property.PaddingRight<TLength>>;
  /** Padding for the bottom */
  paddingBottom?: ResponsiveValue<Property.PaddingBottom<TLength>>;
  /** Padding for the left */
  paddingLeft?: ResponsiveValue<Property.PaddingLeft<TLength>>;
  /** Padding for the left and right */
  paddingX?: ResponsiveValue<Property.Padding<TLength>>;
  /** Padding for the top and bottom */
  paddingY?: ResponsiveValue<Property.Padding<TLength>>;
}

export interface SpaceProps<TLength = string | number> extends ResponsiveMarginProps<TLength>, ResponsivePaddingProps<TLength> {}

// ==============================================================
// System Props
// ==============================================================

/**
 * Modified `SpaceProps`  interface from `styled-system`.
 */
export interface ResponsiveSpaceProps extends SpaceProps {}

export interface ResponsiveAlignSelfProps {
  /**
   * The align-self CSS aligns flex items of the current flex line overriding the align-items value.
   *
   * If any of the item's cross-axis margin is set to auto, then align-self is ignored. In Grid layout align-self
   * aligns the item inside the grid area.
   *
   * [MDN reference](https://developer.mozilla.org/en-US/docs/Web/CSS/align-self)
   */
  alignSelf?: ResponsiveValue<Property.AlignSelf>;
}

export interface ResponsiveTextColorProps {
  /**
   * The color utility parses a component's `color` and `backgroundColor` props and converts them into CSS declarations.
   * By default the raw value of the prop is returned.
   *
   * Color palettes can be configured with the ThemeProvider to use keys as prop values, with support for dot notation.
   * Array values are converted into responsive values.
   *
   * [MDN reference](https://developer.mozilla.org/en-US/docs/Web/CSS/color)
   */
  color?: ResponsiveValue<ThemeValue<'colors', Theme> | (string & {})>;
}

export interface ResponsiveColorProps extends ResponsiveTextColorProps, ResponsiveBackgroundColorProps, ResponsiveOpacityProps {}

export interface ResponsiveBackgroundColorProps {
  backgroundColor?: ResponsiveValue<ThemeValue<'colors', Theme> | (string & {})>;
}

export interface ResponsiveWidthProps<TLength = Length> {
  /**
   *   The width utility parses a component's `width` prop and converts it into a CSS width declaration.
   *
   *   - Numbers from 0-1 are converted to percentage widths.
   *   - Numbers greater than 1 are converted to pixel values.
   *   - String values are passed as raw CSS values.
   *   - And arrays are converted to responsive width styles.
   */
  width?: ResponsiveValue<Property.Width<TLength>>;
}

/**
 * Font Size
 */

export interface ResponsiveFontSizeProps<TLength = Length> {
  /**
   * The fontSize utility parses a component's `fontSize` prop and converts it into a CSS font-size declaration.
   *
   * - Numbers from 0-8 (or `theme.fontSizes.length`) are converted to values on the [font size scale](#default-theme).
   * - Numbers greater than `theme.fontSizes.length` are converted to raw pixel values.
   * - String values are passed as raw CSS values.
   * - And array values are converted into responsive values.
   *
   */
  fontSize?: ResponsiveValue<Property.FontSize<TLength>>;
}

/**
 * Color
 */

export interface ResponsiveBgColorProps<TLength = Length> {
  /**
   * The color utility parses a component's `color` and `bg` props and converts them into CSS declarations.
   * By default the raw value of the prop is returned.
   *
   * Color palettes can be configured with the ThemeProvider to use keys as prop values, with support for dot notation.
   * Array values are converted into responsive values.
   *
   * [MDN Reference](https://developer.mozilla.org/en-US/docs/Web/CSS/background-color)
   */
  bg?: ResponsiveValue<Property.Background<TLength>>;
}

export interface ResponsiveColorsProps<TLength = Length> extends ResponsiveTextColorProps, ResponsiveBackgroundColorProps, ResponsiveBorderColorProps {}

/**
 * Typography
 */

export interface JoykitTypographyProps {
  typographyVariant?: ResponsiveValue<TypographyVariant>;

  /**
   * @deprecated use `typographyVariant`
   */
  typeVariant?: ResponsiveValue<TypographyVariant>;
}

export interface ResponsiveFontFamilyProps {
  fontFamily?: ResponsiveValue<Property.FontFamily>;
}

export interface ResponsiveTextAlignProps {
  /**
   * The text-align CSS specifies the horizontal alignment of an inline or table-cell box.
   *
   * [MDN reference](https://developer.mozilla.org/en-US/docs/Web/CSS/text-align)
   */
  textAlign?: ResponsiveValue<Property.TextAlign>;
}

export interface ResponsiveLineHeightProps<TLength = Length> {
  /**
   * The line-height CSS sets the amount of space used for lines, such as in text. On block-level elements,
   * it specifies the minimum height of line boxes within the element.
   *
   * On non-replaced inline elements, it specifies the height that is used to calculate line box height.
   *
   * [MDN reference](https://developer.mozilla.org/en-US/docs/Web/CSS/line-height)
   */
  lineHeight?: ResponsiveValue<Property.LineHeight<TLength>>;
}

export interface ResponsiveFontWeightProps {
  /**
   * The font-weight CSS specifies the weight (or boldness) of the font.
   *
   * The font weights available to you will depend on the font-family you are using. Some fonts are only available in normal and bold.
   *
   * [MDN reference](https://developer.mozilla.org/en-US/docs/Web/CSS/font-weight)
   */
  fontWeight?: ResponsiveValue<FontWeightToken | Property.FontWeight | number>;
}

export interface ResponsiveFontStyleProps {
  /**
   * The font-style CSS specifies whether a font should be styled with a normal, italic,
   * or oblique face from its font-family.
   *
   * [MDN reference](https://developer.mozilla.org/en-US/docs/Web/CSS/font-style)
   */
  fontStyle?: ResponsiveValue<Property.FontStyle>;
}

export interface ResponsiveLetterSpacingProps<TLength = Length> {
  /**
   * The letter-spacing CSS sets the spacing behavior between text characters.
   *
   * [MDN reference](https://developer.mozilla.org/en-US/docs/Web/CSS/letter-spacing)
   */
  letterSpacing?: ResponsiveValue<Property.LetterSpacing<TLength>>;
}

/**
 * A convenience style group containing props related to typography such as fontFamily, fontSize, fontWeight, etc.
 *
 * - String values are passed as raw CSS values.
 * - Array values are converted into responsive values.
 */
export interface ResponsiveTypographyProps
  extends ResponsiveFontFamilyProps,
    ResponsiveFontSizeProps,
    ResponsiveFontWeightProps,
    ResponsiveLineHeightProps,
    ResponsiveLetterSpacingProps,
    ResponsiveFontStyleProps,
    ResponsiveTextAlignProps,
    JoykitTypographyProps {}

/**
 * Layout
 */

export interface ResponsiveDisplayProps {
  /**
   * The display CSS defines the display type of an element, which consists of the two basic qualities
   * of how an element generates boxes — the outer display type defining how the box participates in flow layout,
   * and the inner display type defining how the children of the box are laid out.
   *
   * [MDN reference](https://developer.mozilla.org/en-US/docs/Web/CSS/display)
   */
  display?: ResponsiveValue<Property.Display>;
}

export interface ResponsiveMaxWidthProps<TLength = Length> {
  /**
   * The max-width CSS sets the maximum width of an element.
   * It prevents the used value of the width  from becoming larger than the value specified by max-width.
   *
   * [MDN reference](https://developer.mozilla.org/en-US/docs/Web/CSS/max-width)
   */
  maxWidth?: ResponsiveValue<Property.MaxWidth<TLength>>;
}

export interface ResponsiveMinWidthProps<TLength = Length> {
  /**
   * The min-width CSS sets the minimum width of an element.
   * It prevents the used value of the width  from becoming smaller than the value specified for min-width.
   *
   * [MDN reference](https://developer.mozilla.org/en-US/docs/Web/CSS/min-width)
   */
  minWidth?: ResponsiveValue<Property.MinWidth<TLength>>;
}

export interface ResponsiveMaxHeightProps<TLength = Length> {
  /**
   * The max-height CSS sets the maximum height of an element. It prevents the used value of the height
   *  from becoming larger than the value specified for max-height.
   *
   * [MDN reference](https://developer.mozilla.org/en-US/docs/Web/CSS/max-height)
   */
  maxHeight?: ResponsiveValue<Property.MaxHeight<TLength>>;
}

export interface ResponsiveMinHeightProps<TLength = Length> {
  /**
   * The min-height CSS sets the minimum height of an element. It prevents the used value of the height
   *  from becoming smaller than the value specified for min-height.
   *
   * [MDN reference](https://developer.mozilla.org/en-US/docs/Web/CSS/display)
   */
  minHeight?: ResponsiveValue<Property.MinHeight<TLength>>;
}

export interface ResponsiveHeightProps<TLength = Length> {
  /**
   * The height CSS specifies the height of an element. By default, the  defines the height of the
   * content area. If box-sizing is set to border-box, however, it instead determines the height of the border area.
   *
   * [MDN reference](https://developer.mozilla.org/en-US/docs/Web/CSS/height)
   */
  height?: ResponsiveValue<Property.Height<TLength>>;
}

// TODO: Document, I couldn't find any info on these two properties...

export interface ResponsiveSizeWidthProps<TLength = Length> {
  size?: ResponsiveValue<Property.Width<TLength>>;
}

export interface ResponsiveSizeHeightProps<TLength = Length> {
  size?: ResponsiveValue<Property.Height<TLength>>;
}

export type ResponsiveSizeProps<TLength = Length> = ResponsiveSizeHeightProps<TLength> & ResponsiveSizeWidthProps<TLength>;

/**
 * A convenience style group containing props related to layout such as width, height, and display.
 *
 * - For length props, Numbers from 0-4 (or the length of theme.sizes) are converted to values on the spacing scale.
 * - For length props, Numbers greater than the length of the theme.sizes array are converted to raw pixel values.
 * - String values are passed as raw CSS values.
 * - Array values are converted into responsive values.
 */
export interface ResponsiveLayoutProps
  extends ResponsiveWidthProps,
    ResponsiveHeightProps,
    ResponsiveMinWidthProps,
    ResponsiveMinHeightProps,
    ResponsiveMaxWidthProps,
    ResponsiveMaxHeightProps,
    ResponsiveDisplayProps,
    ResponsiveVerticalAlignProps,
    ResponsiveSizeProps,
    ResponsiveOverflowProps {}

export interface ResponsiveRatioPaddingProps<TLength = Length> {
  ratio?: ResponsiveValue<number>;
}

export interface ResponsiveRatioProps {
  /**
   * The ration is height: 0 & paddingBottom
   */
  ratio?: ResponsiveValue<number>;
}

export interface ResponsiveVerticalAlignProps<TLength = Length> {
  /**
   * The vertical-align CSS specifies sets vertical alignment of an inline or table-cell box.
   *
   * [MDN reference](https://developer.mozilla.org/en-US/docs/Web/CSS/vertical-align)
   */
  verticalAlign?: ResponsiveValue<Property.VerticalAlign<TLength>>;
}

/**
 * Flexbox
 */

export interface ResponsiveAlignItemsProps {
  /**
   * The CSS align-items  sets the align-self value on all direct children as a group. The align-self
   *  sets the alignment of an item within its containing block.
   *
   * In Flexbox it controls the alignment of items on the Cross Axis, in Grid Layout it controls the alignment
   * of items on the Block Axis within their grid area.
   *
   * [MDN reference](https://developer.mozilla.org/en-US/docs/Web/CSS/align-items)
   */
  alignItems?: ResponsiveValue<Property.AlignItems>;
}

export interface ResponsiveAlignContentProps {
  /**
   * The CSS align-content  sets how the browser distributes space between and around content items
   * along the cross-axis of a flexbox container, and the main-axis of a grid container.
   *
   * [MDN reference](https://developer.mozilla.org/en-US/docs/Web/CSS/align-content)
   */
  alignContent?: ResponsiveValue<Property.AlignContent>;
}

export interface ResponsiveJustifyItemsProps {
  /**
   * The CSS justify-items  defines the default justify-self for all items of the box, giving them all
   * a default way of justifying each box along the appropriate axis.
   *
   * [MDN reference](https://developer.mozilla.org/en-US/docs/Web/CSS/justify-items)
   */
  justifyItems?: ResponsiveValue<Property.JustifyItems>;
}

export interface ResponsiveJustifyContentProps {
  /**
   * The CSS justify-content  defines how the browser distributes space between and around content items
   * along the main-axis of a flex container, and the inline axis of a grid container.
   *
   * [MDN reference](https://developer.mozilla.org/en-US/docs/Web/CSS/justify-content)
   */
  justifyContent?: ResponsiveValue<Property.JustifyContent>;
}

export interface ResponsiveFlexWrapProps {
  /**
   * The flex-wrap CSS sets whether flex items are forced onto one line or can wrap onto multiple lines.
   * If wrapping is allowed, it sets the direction that lines are stacked.
   *
   * [MDN reference](https://developer.mozilla.org/en-US/docs/Web/CSS/flex-wrap)
   */
  flexWrap?: ResponsiveValue<Property.FlexWrap>;
}

export interface ResponsiveFlexBasisProps<TLength = Length> {
  // TODO: The FlexBasisValue currently really only exists for documentation
  //       purposes, because flex-basis also accepts `Nem` and `Npx` strings.
  //       Not sure there’s a way to still have the union values show up as
  //       auto-completion results.
  flexBasis?: ResponsiveValue<Property.FlexBasis<TLength>>;
}

export interface ResponsiveFlexDirectionProps {
  /**
   * The flex-direction CSS specifies how flex items are placed in the flex container defining the main
   * axis and the direction (normal or reversed).
   *
   * [MDN reference](https://developer.mozilla.org/en-US/docs/Web/CSS/flex-direction)
   */
  flexDirection?: ResponsiveValue<Property.FlexDirection>;
}

export interface ResponsiveFlexProps<TLength = Length> {
  /**
   * The flex CSS specifies how a flex item will grow or shrink so as to fit the space available in
   * its flex container. This is a shorthand  that sets flex-grow, flex-shrink, and flex-basis.
   *
   * [MDN reference](https://developer.mozilla.org/en-US/docs/Web/CSS/flex)
   */
  flex?: ResponsiveValue<Property.Flex<TLength>>;
}

export interface ResponsiveFlexGrowProps {
  /**
   * The flex-grow CSS property sets the flex grow factor of a flex item main size. It specifies how much of the
   * remaining space in the flex container should be assigned to the item (the flex grow factor).
   *
   * [MDN reference](https://developer.mozilla.org/en-US/docs/Web/CSS/flex-grow)
   */
  flexGrow?: ResponsiveValue<Property.FlexGrow>;
}

export interface ResponsiveFlexShrinkProps {
  /**
   * The flex-shrink CSS property sets the flex shrink factor of a flex item. If the size of all flex items is larger
   * than the flex container, items shrink to fit according to flex-shrink.
   *
   * [MDN reference](https://developer.mozilla.org/en-US/docs/Web/CSS/flex-shrink)
   */
  flexShrink?: ResponsiveValue<Property.FlexShrink>;
}
export interface ResponsiveJustifySelfProps {
  /**
   * The CSS justify-self  set the way a box is justified inside its alignment container along
   * the appropriate axis.
   *
   * [MDN reference](https://developer.mozilla.org/en-US/docs/Web/CSS/justify-self)
   */
  justifySelf?: ResponsiveValue<Property.JustifySelf>;
}

export interface ResponsiveAlignSelfProps {
  /**
   * The align-self CSS aligns flex items of the current flex line overriding the align-items value.
   *
   * If any of the item's cross-axis margin is set to auto, then align-self is ignored. In Grid layout align-self
   * aligns the item inside the grid area.
   *
   * [MDN reference](https://developer.mozilla.org/en-US/docs/Web/CSS/align-self)
   */
  alignSelf?: ResponsiveValue<Property.AlignSelf>;
}

export interface ResponsiveOrderProps {
  /**
   * The order CSS sets the order to lay out an item in a flex or grid container. Items in a container
   * are sorted by ascending order value and then by their source code order.
   *
   * [MDN reference](https://developer.mozilla.org/en-US/docs/Web/CSS/order)
   */
  order?: ResponsiveValue<number | string>;
}

export interface ResponsiveFlexBoxProps
  extends ResponsiveAlignItemsProps,
    ResponsiveAlignContentProps,
    ResponsiveJustifyContentProps,
    ResponsiveJustifyItemsProps,
    ResponsiveFlexWrapProps,
    ResponsiveFlexDirectionProps,
    ResponsiveFlexProps,
    ResponsiveFlexGrowProps,
    ResponsiveFlexShrinkProps,
    ResponsiveFlexBasisProps,
    ResponsiveAlignSelfProps,
    ResponsiveJustifySelfProps,
    ResponsiveOrderProps {}

/**
 * Grid Layout
 */

export interface ResponsiveGridGapProps<TLength = Length> {
  /**
   * The gap CSS sets the gaps (gutters) between rows and columns. It is a shorthand for row-gap
   * and column-gap.
   *
   * @deprecated use gap
   *
   * [MDN reference](https://developer.mozilla.org/en-US/docs/Web/CSS/gap)
   */
  gridGap?: ResponsiveValue<Property.GridGap<TLength>>;
}

export interface ResponsiveGridColumnGapProps<TLength = Length> {
  /**
   * The column-gap CSS sets the size of the gap (gutter) between an element's columns.
   *
   * @deprecated use column-gap
   *
   * [MDN reference](https://developer.mozilla.org/en-US/docs/Web/CSS/column-gap)
   */
  gridColumnGap?: ResponsiveValue<Property.GridColumnGap<TLength>>;
  gap?: ResponsiveValue<Property.Gap<TLength>>;
  columnGap?: ResponsiveValue<Property.GridRowGap<TLength>>;
}

export interface ResponsiveGridRowGapProps<TLength = Length> {
  /**
   * The row-gap CSS sets the size of the gap (gutter) between an element's rows.
   *
   * @deprecated use row-gap
   *
   * [MDN reference](https://developer.mozilla.org/en-US/docs/Web/CSS/row-gap)
   */
  gridRowGap?: ResponsiveValue<Property.GridRowGap<TLength>>;
  rowGap?: ResponsiveValue<Property.GridRowGap<TLength>>;
}

export interface ResponsiveGridColumnProps {
  /**
   * The grid-column CSS is a shorthand  for grid-column-start and grid-column-end specifying
   * a grid item's size and location within the grid column by contributing a line, a span, or nothing (automatic)
   * to its grid placement, thereby specifying the inline-start and inline-end edge of its grid area.
   *
   * [MDN reference](https://developer.mozilla.org/en-US/docs/Web/CSS/grid-column)
   */
  gridColumn?: ResponsiveValue<Property.GridColumn>;
}

export interface ResponsiveGridRowProps {
  /**
   * The grid-row CSS is a shorthand  for grid-row-start and grid-row-end specifying a grid item’s
   * size and location within the grid row by contributing a line, a span, or nothing (automatic) to its grid
   * placement, thereby specifying the inline-start and inline-end edge of its grid area.
   *
   * [MDN reference](https://developer.mozilla.org/en-US/docs/Web/CSS/grid-row)
   */
  gridRow?: ResponsiveValue<Property.GridRow>;
}

export interface ResponsiveGridAutoFlowProps {
  /**
   * The grid-auto-flow CSS controls how the auto-placement algorithm works, specifying exactly
   * how auto-placed items get flowed into the grid.
   *
   * [MDN reference](https://developer.mozilla.org/en-US/docs/Web/CSS/grid-auto-flow)
   */
  gridAutoFlow?: ResponsiveValue<Property.GridAutoFlow>;
}

export interface ResponsiveGridAutoColumnsProps<TLength = Length> {
  /**
   * The grid-auto-columns CSS specifies the size of an implicitly-created grid column track.
   *
   * [MDN reference](https://developer.mozilla.org/en-US/docs/Web/CSS/grid-auto-columns)
   */
  gridAutoColumns?: ResponsiveValue<Property.GridAutoColumns<TLength>>;
}

export interface ResponsiveGridAutoRowsProps<TLength = Length> {
  /**
   * The grid-auto-rows CSS specifies the size of an implicitly-created grid row track.
   *
   * [MDN reference](https://developer.mozilla.org/en-US/docs/Web/CSS/grid-auto-rows)
   */
  gridAutoRows?: ResponsiveValue<Property.GridAutoRows<TLength>>;
}

export interface ResponsiveGridTemplatesColumnsProps<TLength = Length> {
  /**
   * The grid-template-columns CSS defines the line names and track sizing functions of the grid columns.
   *
   * [MDN reference](https://developer.mozilla.org/en-US/docs/Web/CSS/grid-template-columns)
   */
  gridTemplateColumns?: ResponsiveValue<Property.GridTemplateColumns<TLength>>;
}

export interface ResponsiveGridTemplatesRowsProps<TLength = Length> {
  /**
   * The grid-template-rows CSS defines the line names and track sizing functions of the grid rows.
   *
   * [MDN reference](https://developer.mozilla.org/en-US/docs/Web/CSS/row-template-rows)
   */
  gridTemplateRows?: ResponsiveValue<Property.GridTemplateRows<TLength>>;
}

export interface ResponsiveGridTemplatesAreasProps {
  /**
   * The grid-template-areas CSS specifies named grid areas.
   *
   * [MDN reference](https://developer.mozilla.org/en-US/docs/Web/CSS/grid-template-areas)
   */
  gridTemplateAreas?: ResponsiveValue<Property.GridTemplateAreas>;
}

export interface ResponsiveGridAreaProps {
  /**
   * The grid-area CSS is a shorthand  for grid-row-start, grid-column-start, grid-row-end
   * and grid-column-end, specifying a grid item’s size and location within the grid row by contributing a line,
   * a span, or nothing (automatic) to its grid placement, thereby specifying the edges of its grid area.
   *
   * [MDN reference](https://developer.mozilla.org/en-US/docs/Web/CSS/grid-area)
   */
  gridArea?: ResponsiveValue<Property.GridArea>;
}

export interface ResponsiveGridProps
  extends ResponsiveGridGapProps,
    ResponsiveGridColumnGapProps,
    ResponsiveGridRowGapProps,
    ResponsiveGridColumnProps,
    ResponsiveGridRowProps,
    ResponsiveGridAutoFlowProps,
    ResponsiveGridAutoColumnsProps,
    ResponsiveGridAutoRowsProps,
    ResponsiveGridTemplatesColumnsProps,
    ResponsiveGridTemplatesRowsProps,
    ResponsiveGridTemplatesAreasProps,
    ResponsiveGridAreaProps {}

/**
 * Borders
 */

export interface ResponsiveBorderProps<TLength = Length> {
  /**
   * The border CSS sets an element's border. It's a shorthand for border-width, border-style,
   * and border-color.
   *
   * [MDN reference](https://developer.mozilla.org/en-US/docs/Web/CSS/border)
   */
  border?: ResponsiveValue<Property.Border<TLength>>;
}

export interface ResponsiveBorderTopProps<TLength = Length> {
  /**
   * The border-top CSS is a shorthand that sets the values of border-top-width, border-top-style,
   * and border-top-color. These properties describe an element's top border.
   *
   * [MDN reference](https://developer.mozilla.org/en-US/docs/Web/CSS/border-top)
   */
  borderTop?: ResponsiveValue<Property.BorderTop<TLength>>;
}

export interface ResponsiveBorderRightProps<TLength = Length> {
  /**
   * The border-right CSS is a shorthand that sets border-right-width, border-right-style,
   * and border-right-color. These properties set an element's right border.
   *
   * [MDN reference](https://developer.mozilla.org/en-US/docs/Web/CSS/border-right)
   */
  borderRight?: ResponsiveValue<Property.BorderRight<TLength>>;
}

export interface ResponsiveBorderBottomProps<TLength = Length> {
  /**
   * The border-bottom CSS sets an element's bottom border. It's a shorthand for
   * border-bottom-width, border-bottom-style and border-bottom-color.
   *
   * [MDN reference](https://developer.mozilla.org/en-US/docs/Web/CSS/border-bottom)
   */
  borderBottom?: ResponsiveValue<Property.BorderBottom<TLength>>;
}

export interface ResponsiveBorderLeftProps<TLength = Length> {
  /**
   * The border-left CSS is a shorthand that sets the values of border-left-width,
   * border-left-style, and border-left-color. These properties describe an element's left border.
   *
   * [MDN reference](https://developer.mozilla.org/en-US/docs/Web/CSS/border-left)
   */
  borderLeft?: ResponsiveValue<Property.BorderLeft<TLength>>;
}

export interface ResponsiveBordersProps<TLength = Length>
  extends ResponsiveBorderTopProps<TLength>,
    ResponsiveBorderRightProps<TLength>,
    ResponsiveBorderBottomProps<TLength>,
    ResponsiveBorderLeftProps<TLength>,
    ResponsiveBorderWidthProps,
    ResponsiveBorderProps,
    ResponsiveBorderStyleProps,
    ResponsiveBorderColorProps {}

export interface ResponsiveBorderStyleProps {
  /**
   * The border-style shorthand CSS property sets the style of all sides of an element's border.
   *
   * [MDN * reference](https://developer.mozilla.org/en-US/docs/Web/CSS/border-style)
   */
  borderStyle?: ResponsiveValue<Property.BorderStyle>;
  /**
   * The border-top-style CSS property sets the line style of an element's top border.
   *
   * [MDN * reference](https://developer.mozilla.org/en-US/docs/Web/CSS/border-top-style)
   */
  borderTopStyle?: ResponsiveValue<Property.BorderTopStyle>;
  /**
   * The border-bottom-style CSS property sets the line style of an element's bottom border.
   *
   * [MDN * reference](https://developer.mozilla.org/en-US/docs/Web/CSS/border-bottom-style)
   */
  borderBottomStyle?: ResponsiveValue<Property.BorderBottomStyle>;
  /**
   * The border-left-style CSS property sets the line style of an element's left border.
   *
   * [MDN * reference](https://developer.mozilla.org/en-US/docs/Web/CSS/border-left-style)
   */
  borderLeftStyle?: ResponsiveValue<Property.BorderLeftStyle>;
  /**
   * The border-right-style CSS property sets the line style of an element's right border.
   *
   * [MDN * reference](https://developer.mozilla.org/en-US/docs/Web/CSS/border-right-style)
   */
  borderRightStyle?: ResponsiveValue<Property.BorderRightStyle>;
}

export interface ResponsiveBorderColorProps<TVal = ThemedColor> {
  /**
   * The border-color shorthand CSS property sets the color of all sides of an element's border.
   *
   * [MDN reference](https://developer.mozilla.org/en-US/docs/Web/CSS/border-color)
   */
  borderColor?: ResponsiveValue<TVal>;
  /**
   * The border-top-color CSS property sets the color of an element's top border. It can also be set with the shorthand CSS properties border-color or border-top.
   *
   * [MDN reference](https://developer.mozilla.org/en-US/docs/Web/CSS/border-top-color)
   */
  borderTopColor?: ResponsiveValue<TVal>;
  /**
   * The border-bottom-color CSS property sets the color of an element's bottom border. It can also be set with the shorthand CSS properties border-color or border-bottom.
   *
   * [MDN reference](https://developer.mozilla.org/en-US/docs/Web/CSS/border-bottom-color)
   */
  borderBottomColor?: ResponsiveValue<TVal>;
  /**
   * The border-left-color CSS property sets the color of an element's left border. It can also be set with the shorthand CSS properties border-color or border-left.
   *
   * [MDN reference](https://developer.mozilla.org/en-US/docs/Web/CSS/border-left-color)
   */
  borderLeftColor?: ResponsiveValue<TVal>;
  /**
   * The border-right-color CSS property sets the color of an element's right border. It can also be set with the shorthand CSS properties border-color or border-right.
   *
   * [MDN reference](https://developer.mozilla.org/en-US/docs/Web/CSS/border-right-color)
   */
  borderRightColor?: ResponsiveValue<TVal>;
}

export interface ResponsiveBorderRadiusProps<TLength = 'full' | number> {
  /**
   * The border-radius CSS rounds the corners of an element's outer border edge. You can set a single
   * radius to make circular corners, or two radii to make elliptical corners.
   *
   * [MDN reference](https://developer.mozilla.org/en-US/docs/Web/CSS/border-radius)
   */
  borderRadius?: ResponsiveValue<Property.BorderRadius<TLength>>;
  /**
   * The border-top-left-radius CSS property rounds the top-left corner of an element.
   *
   * [MDN reference](https://developer.mozilla.org/en-US/docs/Web/CSS/border-top-left-radius)
   */
  borderTopLeftRadius?: ResponsiveValue<Property.BorderTopLeftRadius<TLength>>;
  /**
   * The border-top-right-radius CSS property rounds the top-right corner of an element.
   *
   * [MDN reference](https://developer.mozilla.org/en-US/docs/Web/CSS/border-top-right-radius)
   */
  borderTopRightRadius?: ResponsiveValue<Property.BorderTopRightRadius<TLength>>;
  /**
   * The border-bottom-left-radius CSS property rounds the bottom-left corner of an element.
   *
   * [MDN reference](https://developer.mozilla.org/en-US/docs/Web/CSS/border-bottom-left-radius)
   */
  borderBottomLeftRadius?: ResponsiveValue<Property.BorderBottomLeftRadius<TLength>>;
  /**
   * The border-bottom-right-radius CSS property rounds the bottom-right corner of an element.
   *
   * [MDN reference](https://developer.mozilla.org/en-US/docs/Web/CSS/border-bottom-right-radius)
   */
  borderBottomRightRadius?: ResponsiveValue<Property.BorderBottomRightRadius<TLength>>;
}

export interface ResponsiveBorderWidthProps {
  /**
   * The border-width shorthand CSS property sets the width of all sides of an element's border.
   *
   * [MDN * reference](https://developer.mozilla.org/en-US/docs/Web/CSS/border-width)
   */
  borderWidth?: ResponsiveValue<Property.BorderWidth>;
  /**
   * The border-top-width CSS property sets the width of the top border of an element.
   *
   * [MDN * reference](https://developer.mozilla.org/en-US/docs/Web/CSS/border-top-width)
   */
  borderTopWidth?: ResponsiveValue<Property.BorderTopWidth>;
  /**
   * The border-bottom-width CSS property sets the width of the bottom border of an element.
   *
   * [MDN * reference](https://developer.mozilla.org/en-US/docs/Web/CSS/border-bottom-width)
   */
  borderBottomWidth?: ResponsiveValue<Property.BorderBottomWidth>;
  /**
   * The border-left-width CSS property sets the width of the left border of an element.
   *
   * [MDN * reference](https://developer.mozilla.org/en-US/docs/Web/CSS/border-left-width)
   */
  borderLeftWidth?: ResponsiveValue<Property.BorderLeftWidth>;
  /**
   * The border-right-width CSS property sets the width of the right border of an element.
   *
   * [MDN * reference](https://developer.mozilla.org/en-US/docs/Web/CSS/border-right-width)
   */
  borderRightWidth?: ResponsiveValue<Property.BorderRightWidth>;
}

export interface ResponsiveBoxShadowProps {
  /**
   * The box-shadow CSS adds shadow effects around an element's frame. You can set multiple effects
   * separated by commas. A box shadow is described by X and Y offsets relative to the element, blur and spread
   * radii, and color.
   *
   * [MDN reference](https://developer.mozilla.org/en-US/docs/Web/CSS/box-shadow)
   */
  boxShadow?: ResponsiveValue<Property.BoxShadow | number>;
}

export interface ResponsiveOpacityProps {
  /**
   * The opacity CSS sets the transparency of an element or the degree to which content
   * behind an element is visible.
   *
   * [MDN reference](https://developer.mozilla.org/en-US/docs/Web/CSS/opacity)
   */
  opacity?: ResponsiveValue<number | string>;
}

export interface ResponsiveOverflowProps {
  /**
   * The overflow CSS sets what to do when an element's content is too big to fit in its block
   * formatting context. It is a shorthand for overflow-x and overflow-y.
   *
   * [MDN reference](https://developer.mozilla.org/en-US/docs/Web/CSS/overflow)
   */
  overflow?: ResponsiveValue<Property.Overflow>;
  overflowY?: ResponsiveValue<Property.OverflowY>;
  overflowX?: ResponsiveValue<Property.OverflowX>;
}

/**
 * Background
 */

export interface ResponsiveBackgroundProps<TLength = Length>
  extends ResponsiveBackgroundImageProps,
    ResponsiveBackgroundSizeProps,
    ResponsiveBackgroundPositionProps,
    ResponsiveBackgroundRepeatProps {
  /**
   * The background shorthand CSS sets all background style properties at once,
   * such as color, image, origin and size, repeat method, and others.
   *
   * [MDN reference](https://developer.mozilla.org/en-US/docs/Web/CSS/background)
   */
  background?: ResponsiveValue<Property.Background<TLength>>;
}

export interface ResponsiveBackgroundImageProps {
  /**
   * The background-image CSS sets one or more background images on an element.
   *
   * [MDN reference](https://developer.mozilla.org/en-US/docs/Web/CSS/background-image)
   */
  backgroundImage?: ResponsiveValue<Property.BackgroundImage>;
}

export interface ResponsiveBackgroundSizeProps<TLength = Length> {
  /**
   * The background-size CSS sets the size of the element's background image. The
   * image can be left to its natural size, stretched, or constrained to fit the available space.
   *
   * [MDN reference](https://developer.mozilla.org/en-US/docs/Web/Property/background-size)
   */
  backgroundSize?: ResponsiveValue<Property.BackgroundSize<TLength>>;
}

export interface ResponsiveBackgroundPositionProps<TLength = Length> {
  /**
   * The background-position CSS sets the initial position for each background image. The
   * position is relative to the position layer set by background-origin.
   *
   * [MDN reference](https://developer.mozilla.org/en-US/docs/Web/CSS/background-position)
   */
  backgroundPosition?: ResponsiveValue<Property.BackgroundPosition<TLength>>;
}

export interface ResponsiveBackgroundRepeatProps {
  /**
   * The background-repeat CSS sets how background images are repeated. A background
   * image can be repeated along the horizontal and vertical axes, or not repeated at all.
   *
   * [MDN reference](https://developer.mozilla.org/en-US/docs/Web/CSS/background-repeat)
   */
  backgroundRepeat?: ResponsiveValue<Property.BackgroundRepeat>;
}

/**
 * Position
 */

export interface ResponsivePositionProps {
  /**
   * The position CSS specifies how an element is positioned in a document.
   * The top, right, bottom, and left properties determine the final location of positioned elements.
   *
   * [MDN reference](https://developer.mozilla.org/en-US/docs/Web/CSS/position)
   */
  position?: ResponsiveValue<Property.Position>;
}

export interface ResponsiveZIndexProps {
  /**
   * The z-index CSS sets the z-order of a positioned element and its descendants or
   * flex items. Overlapping elements with a larger z-index cover those with a smaller one.
   *
   * [MDN reference](https://developer.mozilla.org/en-US/docs/Web/CSS/z-index)
   */
  zIndex?: ResponsiveValue<keyof ZIndices | (number & {})>;
}

export interface ResponsiveTopProps<TLength = Length> {
  /**
   * The top CSS participates in specifying the vertical position of a
   * positioned element. It has no effect on non-positioned elements.
   *
   * [MDN reference](https://developer.mozilla.org/en-US/docs/Web/CSS/top)
   */
  top?: ResponsiveValue<Property.Top<TLength>>;
}

export interface ResponsiveRightProps<TLength = Length> {
  /**
   * The right CSS participates in specifying the horizontal position of a
   * positioned element. It has no effect on non-positioned elements.
   *
   * [MDN reference](https://developer.mozilla.org/en-US/docs/Web/CSS/right)
   */
  right?: ResponsiveValue<Property.Right<TLength>>;
}

export interface ResponsiveBottomProps<TLength = Length> {
  /**
   * The bottom CSS participates in specifying the vertical position of a
   * positioned element. It has no effect on non-positioned elements.
   *
   * [MDN reference](https://developer.mozilla.org/en-US/docs/Web/CSS/top)
   */
  bottom?: ResponsiveValue<Property.Bottom<TLength>>;
}

export interface ResponsiveLeftProps<TLength = Length> {
  /**
   * The left CSS participates in specifying the horizontal position
   * of a positioned element. It has no effect on non-positioned elements.
   *
   * [MDN reference](https://developer.mozilla.org/en-US/docs/Web/CSS/left)
   */
  left?: ResponsiveValue<Property.Left<TLength>>;
}

export interface ResponsiveInsetProps<TLength = Length> {
  /**
   * The inset CSS property is a shorthand that corresponds to the top, right, bottom,
   * and/or left properties. It has the same multi-value syntax of the margin shorthand.
   *
   * [MDN reference](https://developer.mozilla.org/en-US/docs/Web/CSS/inset)
   */
  inset?: ResponsiveValue<Property.Inset<TLength>>;
}

export interface ResponsivePositionProps
  extends ResponsiveZIndexProps,
    ResponsiveTopProps,
    ResponsiveRightProps,
    ResponsiveBottomProps,
    ResponsiveLeftProps,
    ResponsiveInsetProps {}

export interface ExtraSystemConfig
  extends Readonly<{
    appearance?: ResponsiveValue<Property.Appearance>;
    aspectRatio?: ResponsiveValue<Property.AspectRatio>;
    backdropFilter?: ResponsiveValue<Property.BackdropFilter>;
    backfaceVisibility?: ResponsiveValue<Property.BackfaceVisibility>;
    boxSizing?: ResponsiveValue<Property.BoxSizing>;
    clipPath?: ResponsiveValue<Property.ClipPath>;
    content?: ResponsiveValue<Property.Content>;
    cursor?: ResponsiveValue<Property.Cursor>;
    fill?: ResponsiveValue<Property.Fill>;
    filter?: ResponsiveValue<Property.Filter>;
    listStyle?: ResponsiveValue<Property.ListStyle>;
    wordBreak?: ResponsiveValue<Property.WordBreak>;
    mixBlendMode?: ResponsiveValue<Property.MixBlendMode>;
    objectFit?: ResponsiveValue<Property.ObjectFit>;
    objectPosition?: ResponsiveValue<Property.ObjectPosition>;
    outline?: ResponsiveValue<Property.Outline<number>>;
    pointerEvents?: ResponsiveValue<Property.PointerEvents>;
    stroke?: ResponsiveValue<Property.Stroke>;
    textDecoration?: ResponsiveValue<Property.TextDecoration<string>>;
    textDecorationThickness?: ResponsiveValue<Property.TextDecorationThickness>;
    textDecorationColor?: ResponsiveValue<Property.TextDecorationColor>;
    textDecorationStyle?: ResponsiveValue<Property.TextDecorationStyle>;
    textDecorationLine?: ResponsiveValue<Property.TextDecorationLine>;
    textOverflow?: ResponsiveValue<Property.TextOverflow>;
    textTransform?: ResponsiveValue<Property.TextTransform>;
    touchAction?: ResponsiveValue<Property.TouchAction>;
    transition?: ResponsiveValue<Property.Transition>;
    transform?: ResponsiveValue<Property.Transform>;
    transformOrigin?: ResponsiveValue<Property.TransformOrigin<string>>;
    transitionDelay?: ResponsiveValue<Property.TransitionDelay<string>>;
    transitionProperty?: ResponsiveValue<Property.TransitionProperty>;
    transitionDuration?: ResponsiveValue<Property.TransitionDuration>;
    transitionTimingFunction?: ResponsiveValue<Property.TransitionTimingFunction>;
    userSelect?: ResponsiveValue<Property.UserSelect>;
    visibility?: ResponsiveValue<Property.Visibility>;
    whiteSpace?: ResponsiveValue<Property.WhiteSpace>;
    willChange?: ResponsiveValue<Property.WillChange>;
  }> {} // CSS properties

export const BASE_COMPONENT_PROPS: (keyof BaseComponentProps)[] = [
  'alignSelf',
  'margin',
  'marginTop',
  'flex',
  'order',
  'marginRight',
  'marginBottom',
  'marginLeft',
  'marginX',
  'marginY'
];

export type JoyKitResponsiveStyleKeys =
  | 'space'
  | 'layout'
  // space
  | keyof ResponsiveSpaceProps
  // size
  | keyof ResponsiveHeightProps
  | keyof ResponsiveWidthProps
  // flex
  | keyof ResponsiveFlexProps
  | keyof ResponsiveFlexWrapProps
  | keyof ResponsiveFlexDirectionProps
  | keyof ResponsiveJustifyContentProps
  | keyof ResponsiveAlignItemsProps
  | keyof ResponsiveAlignSelfProps

  // position
  | keyof ResponsivePositionProps
  | keyof ResponsiveTopProps
  | keyof ResponsiveRightProps
  | keyof ResponsiveBottomProps
  | keyof ResponsiveLeftProps
  | keyof ResponsiveInsetProps

  // Typography
  | keyof ResponsiveFontSizeProps

  // misc
  | keyof ResponsiveDisplayProps
  | keyof ResponsiveOverflowProps;
