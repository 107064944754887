import { config } from '@static/js/joy';
import { SegmentService } from '../analytics/segment/service';
import { Logger } from '../logging';
import { createApolloClient } from '../graphql/client';
import { LoginManager } from '../auth/loginManager';
import type { NormalizedCacheObject } from 'apollo-cache-inmemory';
import { ApolloClient } from '@apollo/client';

export type SetupOverrides = Partial<{
  logger: Logger;
  apolloClient?: ApolloClient<NormalizedCacheObject>;
}>;

export const setup = (overrides: SetupOverrides = {}) => {
  // Create segment service
  const segment = new SegmentService();

  // Create logger
  const logger = overrides.logger || new Logger();

  // Create login manager
  const loginManager = new LoginManager(logger);

  // Create Graphql Client
  const apolloClient = overrides.apolloClient || createApolloClient({ loginManager, graphQLUrl: config.graphQLUrl });

  // Return all objects needed for <SetupComponent />
  return { logger, segment, apolloClient, config, loginManager };
};
