import { PaletteColorTokens, PrimitiveColorTokens } from '@withjoy/joykit/theme';

export const getPaletteColorTokens = (primitives: PrimitiveColorTokens): PaletteColorTokens => {
  return {
    transparent: primitives.transparent,
    white: primitives.white, // #FFFFFF
    black: primitives.black, // #333333

    mono1: primitives.gray1, // #FBFBFB
    mono2: primitives.gray2, // #F7F7F7
    mono3: primitives.gray3, // #EBEBEB
    mono4: primitives.gray4, // #E0E0E0
    mono5: primitives.gray5, // #D9D9D9
    mono6: primitives.gray6, // #BEBEBE
    mono7: primitives.gray7, // #A6A6A6
    mono8: primitives.gray8, // #949494
    mono9: primitives.gray9, // #808080
    mono10: primitives.gray10, // #767676
    mono11: primitives.gray11, // #666666
    mono12: primitives.gray12, // #595959
    mono13: primitives.gray13, // #4D4D4D
    mono14: primitives.gray14, // #333333 // black

    primary1: primitives.purple1, // rgba(250, 247, 255, 1)
    primary2: primitives.purple2, // rgba(238, 235, 255, 1)
    primary3: primitives.purple3, // rgba(217, 217, 255, 1)
    primary4: primitives.purple4, // rgba(208, 204, 255, 1)
    primary5: primitives.purple5, // rgba(191, 178, 255, 1)
    primary6: primitives.purple6, // rgba(113, 57, 191, 1) // #7139BF
    primary7: primitives.purple7, // rgba(95, 38, 155, 1) // #5F2698
    primary8: primitives.purple8, // rgba(80, 32, 128, 1) // #502080 // default
    primary9: primitives.purple9, // rgba(68, 0, 102, 1) // #440066

    // Positive palette
    positive1: primitives.green1, // rgba(247, 255, 253, 1)
    positive2: primitives.green2, // rgba(232, 255, 248, 1)
    positive3: primitives.green3, // rgba(163, 245, 220, 1)
    positive4: primitives.green4, // rgba(108, 224, 204, 1)
    positive5: primitives.green5, // rgba(0, 199, 186, 1)
    positive6: primitives.green6, // rgba(24, 146, 137, 1) // default
    positive7: primitives.green7, // rgba(23, 110, 104, 1)
    positive8: primitives.green8, // rgba(6, 64, 57, 1)
    positive9: primitives.green9, // rgba(0, 39, 27, 1)

    // Negative palette
    negative1: primitives.red1, // rgba(255, 247, 248, 1)
    negative2: primitives.red2, // rgba(255, 229, 234, 1)
    negative3: primitives.red3, // rgba(245, 163, 177, 1)
    negative4: primitives.red4, // rgba(224, 108, 127, 1)
    negative5: primitives.red5, // rgba(215, 66, 90, 1)
    negative6: primitives.red6, // rgba(197, 0, 32, 1) // default
    negative7: primitives.red7, // rgba(153, 0, 25, 1)
    negative8: primitives.red8, // rgba(77, 0, 12, 1)
    negative9: primitives.red9, // rgba(51, 0, 8, 1)

    // Warning palette
    warning1: primitives.orange1, // rgba(255, 251, 247, 1)
    warning2: primitives.orange2, // rgba(255, 243, 229, 1)
    warning3: primitives.orange3, // rgba(255, 207, 151, 1)
    warning4: primitives.orange4, // rgba(255, 185, 102, 1)
    warning5: primitives.orange5, // rgba(255, 161, 51, 1)
    warning6: primitives.orange6, // rgba(255, 138, 0, 1) // default?
    warning7: primitives.orange7, // rgba(178, 96, 0, 1)
    warning8: primitives.orange8, // rgba(77, 41, 0, 1)
    warning9: primitives.orange9, // rgba(58, 28, 0, 1)

    //  Info palette
    info1: primitives.humana1, // rgba(255, 249, 245, 1)
    info2: primitives.humana2, // rgba(255, 242, 232, 1)
    info3: primitives.humana3, // rgba(255, 216, 187, 1)
    info4: primitives.humana4, // rgba(249, 182, 134, 1) // default
    info5: primitives.humana5, // rgba(224, 148, 109, 1)
    info6: primitives.humana6, // rgba(182, 94, 56, 1)
    info7: primitives.humana7, // rgba(107, 46, 46, 1)
    info8: primitives.humana8, // rgba(77, 0, 0, 1)
    info9: primitives.humana9 // rgba(51, 0, 0, 1)
  };
};
