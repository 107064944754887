import { styled } from '@withjoy/joykit';
import { Box } from '../Box';
import { TextV2Props } from './Text';

export const StyledContainer = styled(Box)<TextV2Props>`
  ${props => {
    return props.truncate
      ? {
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap'
        }
      : {};
  }}
`;
