import { GetMeLazyQueryHookResult } from '@graphql/generated';
import { createReducerAction } from '@shared/utils/createReducerAction';

export const Action = {
  HANDLE_MISSING_TOKEN: 'HANDLE_MISSING_TOKEN',
  IDENTIFY_USER: 'IDENTIFY_USER',
  IDENTIFY_USER_INITIATE: 'IDENTIFY_USER_INITIATE'
} as const;

export const initiateIdentifyUserAction = createReducerAction(Action.IDENTIFY_USER_INITIATE);
export const identifyUserAction = (result: Partial<Pick<GetMeLazyQueryHookResult[1], 'data' | 'error'>>) => createReducerAction(Action.IDENTIFY_USER, result);
export const handleMissingTokenAction = createReducerAction(Action.HANDLE_MISSING_TOKEN);

// TODO: investigate how to automate type inferrence
export type ReducerActions = ReturnType<typeof identifyUserAction> | typeof handleMissingTokenAction | typeof initiateIdentifyUserAction;
