import { styled, Flex, TextV2 } from '@withjoy/joykit';
import { ButtonOverrides } from '@withjoy/joykit/components/ButtonV2/Button.types';
import { ChevronRightSquare } from '@withjoy/joykit/icons';

export const PageNotFoundCtaOverrides: ButtonOverrides = {
  Root: {
    props: {
      color: 'mono14',
      backgroundColor: 'mono2',
      height: '2.5rem',
      _hover: {
        color: 'mono12',
        backgroundColor: 'mono3'
      },
      _active: {
        color: 'mono14',
        backgroundColor: 'mono4'
      },
      _pressed: {
        color: 'mono14',
        backgroundColor: 'mono4'
      }
    }
  }
};

export const PageNotFoundContainer = styled(Flex)`
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  flex-direction: column-reverse;
  margin: 0px;
  padding: 2rem;

  @media (min-width: 769px) {
    flex-direction: row;
    margin: auto;
    padding: 4rem;
  }
`;

export const PageNotFoundRoot = styled(Flex)`
  overflow: hidden;
`;

export const JoyLogoContainer = styled(Flex)`
  padding-top: 1.5rem;
  @media (min-width: 769px) {
    padding-top: 0rem;
  }
`;

export const PageNotFoundContentContainer = styled(Flex)`
  flex-direction: column;
  align-items: center;
  @media (min-width: 769px) {
    align-items: flex-start;
  }
`;

export const TextEyebrow = styled(TextV2)`
  padding-top: 2rem;
  padding-bottom: 1rem;
  font-size: 17px;

  @media (min-width: 501px) {
    font-size: 20px;
  }
`;

export const TextTitle = styled(TextV2)`
  padding-bottom: 1rem;
  font-size: 32px;
  text-align: center;

  @media (min-width: 501px) {
    font-size: 40px;
  }
  @media (min-width: 769px) {
    text-align: left;
  }
`;

export const TextInfo = styled(TextV2)`
  font-size: 17px;
  text-align: center;
  @media (min-width: 501px) {
    font-size: 20px;
  }
  @media (min-width: 769px) {
    text-align: left;
  }
`;

export const PageNotFoundButtonContainer = styled(Flex)`
  flex-direction: column;
  padding-top: 3rem;
  align-items: center;
  @media (min-width: 769px) {
    align-items: flex-start;
  }
`;

export const StyledChevronRightSquare = styled(ChevronRightSquare)`
  width: 0.875rem;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  gap: ${props => props.theme.space[6]};

  ${props => props.theme.mediaQueries.up({ viewport: 'md' })} {
    flex-direction: row;
  }
`;

export const ImageWrapper = styled(Flex)`
  padding-bottom: 1rem;
  margin-left: 2rem;
  margin-right: 2rem;
  width: 340px;
  > img {
    width: 100%;
  }

  @media (min-width: 769px) {
    margin-left: 96px;
    margin-right: 0;
    > img {
      width: 365px;
    }
  }

  @media (min-width: 1061px) {
    margin-left: 96px;
    > img {
      width: 416px;
    }
  }
`;
