import React, { useMemo } from 'react';
import { createContext } from '@shared/utils/createContext';
import { DEFAULT_CURRENCY } from './constants';
import { useBrowserLocale } from './useLocale';
import { CurrencyFormatter } from './currency.formatter';
import { CurrencyArgs } from './types';

const [Provider, useCurrencyContext] = createContext<CurrencyFormatter>({ name: 'Currency' });

interface CurrencyProps extends Partial<CurrencyArgs> {
  children: React.ReactNode;
}

const CurrencyProvider = (props: CurrencyProps) => {
  const { children, locale, defaultCurrency = DEFAULT_CURRENCY } = props;

  const { locales } = useBrowserLocale({});
  const preferredLocale = locale ?? locales[0];

  const value = useMemo(() => {
    return new CurrencyFormatter({ locale: preferredLocale, defaultCurrency });
  }, [preferredLocale, defaultCurrency]);

  return <Provider value={value}>{children}</Provider>;
};

CurrencyProvider.displayName = 'CurrencyProvider';

export { CurrencyProvider, useCurrencyContext };
