import React, { useCallback, useEffect, useRef } from 'react';
import { useImmer } from 'use-immer';
import { useSpring, animated, useChain, ReactSpringHook, AnimatedValue, useTransition } from 'react-spring';
import { Box, BoxProps, css, keyframes, styled } from '@withjoy/joykit';
import globalWindow from '@shared/core/globals';

const rootStyles: BoxProps = {
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  left: '0px',
  pointerEvents: 'none',
  position: 'fixed',
  top: '0px',
  zIndex: 1402,
  transform: [null, null, 'translate3d(0px, 0px, 0px)']
};

const visibilityKeyframes = keyframes`
  to {
    visibility: visible;
  }
`;

const delayedVisibilityRules = css`
  visibility: hidden;
  animation: 0s linear 0.5s forwards ${visibilityKeyframes};
`;

const StyledRoot = styled(Box)<{ $enableDelayedVisibility: boolean }>`
  ${props => {
    return props.$enableDelayedVisibility ? delayedVisibilityRules : {};
  }}
`;

// const hasOpacitySpringCrossedThreshold = (opacity: number) => opacity <= 0.48;

type SketchedJoyLogoProps = Readonly<
  {
    enableDelayedVisibility?: boolean;
    key?: string;
    /** @default '#FFFFFF' */
    containerBgColor?: BoxProps['backgroundColor'];
    /** @default '100%'' */
    containerHeight?: BoxProps['height'];
    /** @default '100%'' */
    containerWidth?: BoxProps['width'];
    /** @default '#322E53' */
    logoFillTo?: string;
    /** @default '#FFFFFF' */
    logoFillFrom?: string;
    /** @default true */
    shouldAnimate?: boolean;
    onAnimateStop?: () => void;
  } & Omit<BoxProps, 'backgroundColor' | 'width' | 'height'>
>;

type AnimationStep = 'animateForward' | 'animateBackward' | 'preparingToStop' | 'stopped' | 'unmounted';

/**
 * This should generally be used as a loader for a large surface area.
 *
 * TODO:
 * - Support size prop
 */
export const JoyLogoLoaderBase: React.FC<SketchedJoyLogoProps> = props => {
  const {
    containerBgColor = '#ffffff',
    containerHeight = '100%',
    containerWidth = '100%',
    enableDelayedVisibility,
    logoFillFrom = '#ffffff',
    logoFillTo = '#322E53',
    shouldAnimate = true,
    onAnimateStop,
    ...restProps
  } = props;
  const [{ animationStep }, setState] = useImmer<{
    animationStep: AnimationStep;
  }>(() => ({ animationStep: shouldAnimate ? 'animateForward' : 'stopped' }));

  const svgStrokeSpringRef = useRef<ReactSpringHook>(null);
  const svgFillSpringRef = useRef<ReactSpringHook>(null);
  const animateStepRef = useRef<{ hasFinishedAnimating: boolean }>({ hasFinishedAnimating: !shouldAnimate });
  const timeoutRefs = useRef<{ startTimeoutId: number | undefined; restTimeoutId: number | undefined; unmountTimeoutId: number | undefined }>({
    startTimeoutId: undefined,
    restTimeoutId: undefined,
    unmountTimeoutId: undefined
  });

  const isAnimatingForward = animationStep === 'animateForward';
  const isAnimatingBackward = animationStep === 'animateBackward';
  const isAnimationStopped = animationStep === 'stopped';
  const shouldContainerBeMounted = shouldAnimate || isAnimatingForward;

  const setAnimationDirection = useCallback(
    (direction: 'forward' | 'backward') => {
      animateStepRef.current.hasFinishedAnimating = false;
      timeoutRefs.current.startTimeoutId = globalWindow.setTimeout(() => {
        setState(draft => {
          draft.animationStep = direction === 'forward' ? 'animateForward' : 'animateBackward';
        });
      }, 200);
    },
    [setState]
  );

  const stopAnimationLoop = useCallback(() => {
    if (!animateStepRef.current.hasFinishedAnimating) {
      animateStepRef.current.hasFinishedAnimating = true;
      timeoutRefs.current.restTimeoutId = globalWindow.setTimeout(() => {
        setState(draft => {
          draft.animationStep = 'stopped';
        });
      }, 0);
    }
  }, [setState]);
  // ==============================
  // Springs
  // ==============================

  const svgStrokeSpring = useSpring({
    from: { strokeInner: 98, strokeOuter: 246 },
    to: isAnimatingForward ? { strokeInner: 0, strokeOuter: 0 } : { strokeInner: 98, strokeOuter: 246 },
    ref: svgStrokeSpringRef,
    onRest: () => {
      if (isAnimatingBackward) {
        if (shouldAnimate) {
          setAnimationDirection('forward');
        } else {
          stopAnimationLoop();
        }
      }
    }
  }) as AnimatedValue<{ strokeInner: number; strokeOuter: number }>;

  const svgFillSpring = useSpring({
    from: { color: logoFillFrom },
    to: isAnimatingForward ? { color: logoFillTo } : { color: logoFillFrom },
    ref: svgFillSpringRef,
    onRest: () => {
      if (isAnimatingForward) {
        setAnimationDirection('backward');
      }
    }
  }) as AnimatedValue<{ color: string }>;

  const containerTransitions = useTransition(shouldContainerBeMounted, null, {
    from: { opacity: 1 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    unique: true,
    reset: true,
    trail: shouldContainerBeMounted ? 0 : 1000,
    onDestroyed: isDestroyed => {
      if (isDestroyed) {
        requestAnimationFrame(() => {
          requestAnimationFrame(() => {
            onAnimateStop?.();
          });
        });
      }
    }
  });

  useChain(isAnimatingForward ? [svgStrokeSpringRef, svgFillSpringRef] : [svgFillSpringRef, svgStrokeSpringRef], [0, 0.2]);

  // ==============================
  // Effects
  // ==============================

  useEffect(() => {
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      const timoutTracker = timeoutRefs.current;
      Object.keys(timoutTracker).forEach(key => {
        globalWindow.clearTimeout(timoutTracker[key as keyof typeof timoutTracker]);
      });
    };
  }, []);

  useEffect(() => {
    if (shouldAnimate && isAnimationStopped) {
      setAnimationDirection('forward');
    }
  }, [isAnimationStopped, shouldAnimate, setAnimationDirection, setState]);

  return (
    <>
      {containerTransitions.map(
        ({ item, key, props }) =>
          item && (
            <StyledRoot
              as={animated.div}
              key={key}
              $enableDelayedVisibility={enableDelayedVisibility}
              {...rootStyles}
              backgroundColor={containerBgColor}
              height={containerHeight}
              width={containerWidth}
              {...restProps}
              style={props}
            >
              <animated.svg style={{ transform: 'translate3d(0,0,0)' }} width={81} height={50} viewBox="0 0 81 50" fill={svgFillSpring.color}>
                <animated.g clipPath="url(#A)">
                  <animated.path
                    fillRule="evenodd"
                    d="M10.091 38.312c.046-.088.123-.156.216-.191 2.591-1.231 4.797-4.974 7.082-14.628l.997-4.358 2.637-11.462c2.098-9.269 9.308-7.91 11.574-7.073.062.023.117.061.161.11s.075.109.09.173.015.131-.001.195-.048.123-.093.172c-.758.857-1.655 2.994-2.876 8.087l-3.871 15.977c-2.492 10.353-10.392 13.209-15.504 13.584-.098.014-.198-.01-.281-.065s-.141-.139-.166-.236-.012-.198.034-.286zm67.168-27.565c3.142 1.005 3.103 4.354 2.64 6.511-.813 3.385-2.114 6.633-3.861 9.643-.047.084-.122.148-.212.182s-.189.033-.28 0-.166-.098-.213-.181-.063-.182-.044-.276a15.01 15.01 0 0 0-1.97-10.077c-2.502-4.098 1.704-6.521 3.94-5.802zM55.737 13.79c2.384-2.344 10.412-6.826 13.318 5.231 1.151 4.538 1.092 9.299-.169 13.808s-3.682 8.609-7.021 11.891c-.065.058-.147.094-.233.102s-.174-.01-.249-.054-.135-.11-.17-.189-.045-.168-.027-.253c2.325-8.737.877-29.649-5.201-29.846-.076-.007-.149-.036-.21-.083s-.107-.111-.133-.183-.031-.151-.013-.226.055-.143.11-.197zm1.852 33.56c.093-.02.19-.006.274.039s.149.118.184.206.038.186.009.276-.09.167-.171.217a9.79 9.79 0 0 1-6.255 1.901c-5.91-.266-6.156-4.925-4.423-7.112.933-1.084 2.184-1.846 3.576-2.177.066-.007.133.002.194.026s.116.064.159.114.073.111.087.176.012.132-.007.196c-.778 2.591 1.221 7.053 6.373 6.137zM33.298 15.445c3.242-2.797 7.371-4.354 11.653-4.393 6.688 0 11.486 4.275 11.486 10.835a17.73 17.73 0 0 1-6.028 12.717c-3.159 2.78-7.219 4.32-11.426 4.334-6.915 0-11.663-4.334-11.663-10.953a17.89 17.89 0 0 1 5.979-12.539zm6.954 20.942c3.645-1.921 6.107-11.259 6.511-12.805.522-1.793 2.088-12.579-3.132-10.038-3.511 1.751-5.904 10.541-6.462 12.589v.001l-.059.215c-.66 2.601-2.128 12.756 3.142 10.037zm-38.84-.857c.048.066.115.117.192.144s.161.031.24.009.15-.066.203-.129.086-.14.094-.221c.099-1.675 1.901-3.694 4.984-3.467 2.177.167 3.29-3.29 1.487-5.112-2.226-2.256-5.536-1.369-7.201.985-2.305 3.26-1.409 6.038 0 7.791z"
                    stroke={logoFillTo}
                    strokeWidth=".6"
                    strokeDasharray={98}
                    strokeDashoffset={svgStrokeSpring.strokeInner}
                  />
                </animated.g>
                <defs>
                  <clipPath id="A">
                    <animated.path
                      fill={svgFillSpring.color}
                      transform="translate(.004)"
                      d="M0 0h80.122v50H0z"
                      stroke={logoFillTo}
                      strokeWidth=".6"
                      strokeDasharray={246}
                      strokeDashoffset={svgStrokeSpring.strokeOuter}
                    />
                  </clipPath>
                </defs>
              </animated.svg>
            </StyledRoot>
          )
      )}
    </>
  );
};

JoyLogoLoaderBase.displayName = 'JoyLogoLoader';
