import { Storage } from '../index';
import globalWindow from '@shared/core/globals';

const sessionStorage = (() => {
  try {
    // sometimes just accessing session storage can throw (like incognito Safari/Chrome)
    return globalWindow.sessionStorage;
  } catch {
    return null;
  }
})();

export class SessionStorage implements Storage {
  public getItem(key: string) {
    return sessionStorage?.getItem(key);
  }

  public setItem(key: string, value: string | null | undefined) {
    if (value) {
      sessionStorage?.setItem(key, value);
    } else {
      sessionStorage?.removeItem(key);
    }
  }

  public removeItem(key: string) {
    sessionStorage?.removeItem(key);
  }
}
