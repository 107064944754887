import { createTelemetry, createTelemetryObject } from '@shared/core/analytics';

const category = 'notFound';

export type PagesForTelemetry = 'notFound';

const telemetryObject = createTelemetryObject({
  pages: {
    notFound: (url: string) => ({
      category,
      name: 'notFound',
      pagePrefix: 'guest',
      page: 'notFound',
      extraPageInfo: { url }
    })
  },
  actions: {
    notFoundClicked: () => ({
      category,
      action: 'notFoundPageClicked',
      extraInfo: {
        actionType: 'click',
        name: 'notFoundPageClicked'
      }
    })
  }
});

export const { useTelemetry: useNotFoundTelemetry } = createTelemetry(telemetryObject);
