// https://www.apollographql.com/docs/react/local-state/reactive-variables
// NOTE: This is a first pass at using reactive variables
//       A more structured approach will come at a later time.

import { makeVar, ReactiveVar, TypePolicies } from '@apollo/client';
import { relayStylePagination } from '@apollo/client/utilities';
import { ProductCatalogAttributeSelectionType, ProductCatalogSearchQueryOrderBy } from '@graphql/generated';

export type ActiveFilters = Record<string, boolean | string>;
// Identification code for the "Sort By" refinement facet in the filters list
export const SORT_BY_CODE = 'sortBy';

/**
 * This reactive var will change depending on how API plans to expose `AttributeValue.groupCode`
 */
export const activeFiltersVar = makeVar<ActiveFilters>({});

export const catalogCachePolicies: TypePolicies = {
  ProductCatalogSearchResultContainer: {
    fields: {
      pagedResults: relayStylePagination(),
      orderedBy: {
        read: () => {
          const activeFilters = activeFiltersVar();
          return activeFilters[SORT_BY_CODE] || ProductCatalogSearchQueryOrderBy.featured;
        }
      }
    }
  },
  ProductUniversalSearchResultContainer: {
    fields: {
      pagedResults: relayStylePagination(),
      orderedBy: {
        read: () => {
          const activeFilters = activeFiltersVar();
          return activeFilters[SORT_BY_CODE] || ProductCatalogSearchQueryOrderBy.featured;
        }
      }
    }
  },
  RatingReviewSearchResultContainer: {
    fields: {
      pagedResults: relayStylePagination(['orderBy'])
    }
  },
  ProductAttributeGroup: {
    // A unique Attribute Group code.
    keyFields: ['code']
  },
  ProductAttributeValue: {
    // A unique Attribute Group code.
    keyFields: ['code']
  },
  ProductSearchFilterAttribute: {
    // A unique composite of the respective Attribute Group + Value codes.
    keyFields: ['compositeCode'],
    fields: {
      isActive: {
        read: (existing, opts) => {
          const code: string | undefined = opts.readField<string>('compositeCode');
          if (!code) {
            return false;
          }
          const activeFilters = activeFiltersVar();
          if (!(code in activeFilters)) {
            activeFilters[code] = false;
          }
          return activeFilters[code];
        }
      }
    }
  },
  ProductCatalogPageSection: {
    fields: {
      paginatedCatalogEntities: relayStylePagination()
    }
  },
  ProductCatalogSearchFilterGroup: {
    fields: {
      // A local-only attribute to track the actual price range user selection
      selectedPriceRange: {
        read: () => {
          const activeFilters = activeFiltersVar();
          const priceRange = activeFilters[ProductCatalogAttributeSelectionType.priceRange];
          const [min, max] = typeof priceRange === 'string' ? priceRange?.split('-') : [];
          return {
            // A local-only attribute to track the min price range user selection
            minValueInMinorUnits: !isNaN(parseInt(min)) ? parseInt(min) : null,
            // A local-only attribute to track the max price range user selection
            maxValueInMinorUnits: !isNaN(parseInt(max)) ? parseInt(max) : null
          };
        }
      }
    }
  }
};

// ----------------------------------------

const getActiveFilterCountByGroupCode = (filterVar: ReactiveVar<ActiveFilters>) => (groupCode: string) => {
  const activeFilters = filterVar();
  /**
   * Returns the number of active filters for the group.
   */
  return Object.keys(activeFilters).reduce((acc, compositeCode) => {
    if (compositeCode.startsWith(`${groupCode}:`) && activeFilters[compositeCode]) {
      acc++;
    }
    return acc;
  }, 0);
};

const getTotalActiveFilterCount = (filterVar: ReactiveVar<ActiveFilters>) => () => {
  const activeFilters = filterVar();
  return Object.keys(activeFilters).reduce((acc, compositeCode) => {
    /**
     * Skip the "Sort By" and "Price Range" filters, and sum the rest including PriceBucket attributes.
     */
    if (activeFilters[compositeCode] && compositeCode !== SORT_BY_CODE && compositeCode !== ProductCatalogAttributeSelectionType.priceRange) {
      acc++;
    }
    return acc;
  }, 0);
};

const toggleCatalogSearchFilter = (filterVar: ReactiveVar<ActiveFilters>) => (attributeCompositeCode: string) => {
  const prev = filterVar();
  return filterVar({ ...prev, [attributeCompositeCode]: !prev[attributeCompositeCode] })[attributeCompositeCode];
};

const clearAllCatalogSearchFilters = (filterVar: ReactiveVar<ActiveFilters>) => () => {
  return filterVar({});
};

export const catalogCacheApi = {
  getActiveFilterCountByGroupCode: getActiveFilterCountByGroupCode(activeFiltersVar),
  getTotalActiveFilterCount: getTotalActiveFilterCount(activeFiltersVar),
  toggleSearchFilter: toggleCatalogSearchFilter(activeFiltersVar),
  clearAllSearchFilters: clearAllCatalogSearchFilters(activeFiltersVar)
};
