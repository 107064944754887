import { isEqual } from 'lodash-es';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';

import { useAuth } from '@shared/components/AuthProvider';
import { AnalyticsContext } from '@shared/core/analytics';
import { createContext } from '@shared/utils/createContext';
import { useEventInfo } from '@shared/utils/eventInfo';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';

import { FeatureFlags } from '../featureFlags.constants';
import { useFeatureFlagsContext } from '../featureFlags.provider';
import { RemoteFeatureFlagKeys, RemoteFeatureFlagOptions } from '../featureFlags.types';
import { createOneTimePerfLogger, createPerfLogger, logEvent, parseError, usePerfLogger } from './remoteFeatureFlags.logger';
import { RemoteFeatureFlag, RemoteFeatureFlagService, UserInfo, UserProperties } from './remoteFeatureFlags.types';
import { useAmplitudeExperiment } from './useAmplitudeExperiment';
import { getLocalStorage } from '@shared/core/storage';

export interface InternalRemoteFeatureFlagsContextType {
  loading: boolean;
  getFeatureFlagValue: <TFeatureName extends RemoteFeatureFlagKeys>(key: TFeatureName) => { value?: FeatureFlags[TFeatureName]['supportedValues'][number]; payload?: unknown };
}

const [InternalRemoteFeatureFlagsProvider, useInternalRemoteFeatureFlagsContext, InternalRemoteFeatureFlagsContext] = createContext<InternalRemoteFeatureFlagsContextType>({
  name: 'RemoteFeatureFlagProvider'
});

enum RemoteFeatureFlagProviderState {
  Loading,
  Ready
}

const logFirstLoadPerf = createOneTimePerfLogger('FirstLoad');

function isSupportedFeatureValue<TFeatureName extends RemoteFeatureFlagKeys>(
  value: string,
  supportedValues: FeatureFlags[TFeatureName]['supportedValues']
): value is FeatureFlags[TFeatureName]['supportedValues'][number] {
  return Array.isArray(supportedValues) && (supportedValues as string[]).includes(value.toLowerCase());
}

const getForceHotelBlockEnabled = () => {
  const localStorage = getLocalStorage();
  const ajsUserTraits = localStorage.getItem('ajs_user_traits') || '';
  let isForceHotelBlockEnabled = false;
  if (ajsUserTraits) {
    try {
      const ajsUserTraitsObject = JSON.parse(ajsUserTraits);
      if (ajsUserTraitsObject?.forceHotelBlockEnabled) {
        isForceHotelBlockEnabled = ajsUserTraitsObject.forceHotelBlockEnabled === true;
        logEvent('GetForceHotelBlockEnabled', { status: 'success' });
      }
    } catch (err) {
      logEvent('GetForceHotelBlockEnabled', { status: 'error', error: parseError(err) });
    }
  }
  return isForceHotelBlockEnabled;
};

export interface RemoteFeatureFlagProviderParams {
  remoteFeatureFlagService?: RemoteFeatureFlagService;
}

export const RemoteFeatureFlagsProvider: React.FC<RemoteFeatureFlagProviderParams> = ({ children, remoteFeatureFlagService }) => {
  const amplitudeService = useAmplitudeExperiment();
  const featureFlagService = remoteFeatureFlagService ?? amplitudeService;

  const [state, setState] = useState<RemoteFeatureFlagProviderState>(RemoteFeatureFlagProviderState.Loading);

  const auth = useAuth();
  const authUserId = auth.currentUser.profile?.activeAlias?.auth0Id;
  const authHasInitialized = auth.hasInitializedOnce;
  usePerfLogger({ name: 'Dependency_Auth', isLoading: !authHasInitialized });

  const { anonymousId } = useContext(AnalyticsContext);
  usePerfLogger({ name: 'Dependency_SegmentUser', isLoading: !anonymousId });

  const { loading: eventInfoLoading, eventInfo } = useEventInfo();
  usePerfLogger({ name: 'Dependency_EventInfo', isLoading: eventInfoLoading });

  const lastFetchedUserProfile = useRef<UserInfo>({});

  useEffect(() => {
    if (!anonymousId || !authHasInitialized || eventInfoLoading) {
      setState(RemoteFeatureFlagProviderState.Loading);
      return;
    }

    const fetchRemoteFeatureFlags = async () => {
      const forceHotelBlockEnabled = getForceHotelBlockEnabled();

      const userProperties: UserProperties = {
        forceHotelBlockEnabled
      };

      if (eventInfo?.eventFirebaseId) {
        userProperties.lastFirebaseEventId = eventInfo?.eventFirebaseId;
      }

      const userInfoToFetch: UserInfo = { userId: authUserId, deviceId: anonymousId, eventFirebaseId: eventInfo?.eventFirebaseId, userProperties: userProperties };
      if (!isEqual(lastFetchedUserProfile.current, userInfoToFetch)) {
        // Fetch feature flags for the new user
        lastFetchedUserProfile.current = userInfoToFetch;
        setState(RemoteFeatureFlagProviderState.Loading);

        const trackFetchUserTime = createPerfLogger('FetchUser');
        try {
          await featureFlagService.fetchUser(userInfoToFetch);
          logEvent('FetchUser', { status: 'success', durationMs: trackFetchUserTime(), user: userInfoToFetch });
        } catch (err) {
          logEvent('FetchUser', { status: 'error', durationMs: trackFetchUserTime(), user: userInfoToFetch, error: parseError(err) });
        }

        logFirstLoadPerf();
      }
      setState(RemoteFeatureFlagProviderState.Ready);
    };

    fetchRemoteFeatureFlags();
  }, [authUserId, authHasInitialized, featureFlagService, anonymousId, eventInfoLoading, eventInfo]);

  const { features } = useFeatureFlagsContext();

  const getFeatureFlagValue = useEventCallback((featureName: RemoteFeatureFlagKeys) => {
    const { defaultValue, value: overriddenFeatureValue, supportedValues, key: featureKey } = features[featureName];
    if (overriddenFeatureValue) {
      return { value: overriddenFeatureValue };
    }

    const { payload, value } = featureFlagService.getFeatureValue(featureKey);
    if (value && isSupportedFeatureValue(value, supportedValues)) {
      featureFlagService.trackExposure(featureKey);
      return { payload, value };
    }

    if (value && !isSupportedFeatureValue(value, supportedValues)) {
      logEvent('UnsupportedFeatureValue', { featureName, value, supportedValues, hasPayload: !!payload, payload });
    }

    return { payload, value: defaultValue };
  });

  return (
    <InternalRemoteFeatureFlagsProvider
      value={{
        loading: state === RemoteFeatureFlagProviderState.Loading,
        getFeatureFlagValue
      }}
    >
      {children}
    </InternalRemoteFeatureFlagsProvider>
  );
};

export function useRemoteFeatureFlag<TFeatureName extends RemoteFeatureFlagKeys, TSupportedValues extends FeatureFlags[TFeatureName]['supportedValues'][number]>(
  featureName?: TFeatureName,
  options?: RemoteFeatureFlagOptions
): RemoteFeatureFlag<TFeatureName> {
  const { loading, getFeatureFlagValue } = useInternalRemoteFeatureFlagsContext();

  const { features } = useFeatureFlagsContext();
  const defaultFeatureValue = featureName && features[featureName].defaultValue;

  const remoteFeatureFlag = useMemo((): RemoteFeatureFlag<TFeatureName> => {
    if (options?.skip) {
      return {
        loading: false,
        value: defaultFeatureValue
      };
    }

    if (loading || !featureName) {
      return {
        loading: true,
        value: defaultFeatureValue
      };
    }

    return {
      loading: false,
      ...getFeatureFlagValue(featureName)
    };
  }, [loading, featureName, getFeatureFlagValue, defaultFeatureValue, options]);

  return remoteFeatureFlag;
}

export { InternalRemoteFeatureFlagsContext };
