import { IconConfig } from '..';

/* tslint:disable:max-line-length */
export const check: IconConfig = {
  name: 'check',
  a11yLabel: 'Check',
  viewBox: 24,
  fill: '#94D0A7',
  body: `
    <path
      d="M1.5 12C1.5 6.20901 6.20901 1.5 12 1.5C17.791 1.5 22.5 6.20901 22.5 12C22.5 17.791 17.791 22.5 12 22.5C6.20901 22.5 1.5 17.791 1.5 12Z"
      stroke="#DBDBDB"
    />
    <path
      d="M12 1C5.93286 1 1 5.93286 1 12C1 18.0671 5.93286 23 12 23C18.0671 23 23 18.0671 23 12C23 5.93286 18.0671 1 12 1Z"
    />
    <path
      d="M6.68394 12.2205C6.28103 11.8427 5.64819 11.8631 5.27046 12.2661C4.89273 12.669 4.91315 13.3018 5.31606 13.6795L6.68394 12.2205ZM10.32 17L9.63606 17.7295C9.83546 17.9165 10.1027 18.0136 10.3756 17.9985C10.6485 17.9833 10.9033 17.857 11.0807 17.6491L10.32 17ZM18.7607 8.64912C19.1192 8.229 19.0692 7.59781 18.6491 7.23931C18.229 6.88081 17.5978 6.93077 17.2393 7.35088L18.7607 8.64912ZM5.31606 13.6795L9.63606 17.7295L11.0039 16.2705L6.68394 12.2205L5.31606 13.6795ZM11.0807 17.6491L18.7607 8.64912L17.2393 7.35088L9.55931 16.3509L11.0807 17.6491Z"
      fill="white"
    />
  `
};
