import type { ColumnCount, LayoutGrid, BreakpointPx, BreakpointWidthsPx } from '@withjoy/joykit/theme';
import { pxToRem } from './typography';
import { breakpointsPx } from './breakpoints';

type GutterPx = 16 | 24;
type MarginPx = 24 | 40 | 88;

type LayoutGridByBreakpointIndex = {
  widthPx: BreakpointPx;
  columns: ColumnCount;
  gutterPx: GutterPx;
  maxWidthPx: number;
};

////////////

const COLUMNS: ReadonlyRecord<'sm' | 'md' | 'lg', ColumnCount> = {
  sm: 4,
  md: 8,
  lg: 12
};
export const COLUMN_BREAKPOINTS: ReadonlyRecord<'sm' | 'md', number> = {
  sm: 600,
  md: 840
};

////////////

const MARGINS: ReadonlyRecord<'sm' | 'md' | 'lg', MarginPx> = {
  sm: 24,
  md: 40,
  lg: 88
};
const MARGIN_BREAKPOINTS: ReadonlyRecord<'sm' | 'md', number> = {
  sm: 768,
  md: 1152
};

////////////

const GUTTERS: ReadonlyRecord<'sm' | 'md', GutterPx> = {
  sm: 16,
  md: 24
};
export const GUTTER_BREAKPOINTS: ReadonlyRecord<'sm', number> = {
  sm: 768
};

////////////

const _getBreakpointConfig = (breakpoint: BreakpointPx): LayoutGridByBreakpointIndex => {
  const columnCount = breakpoint < COLUMN_BREAKPOINTS.sm ? COLUMNS.sm : breakpoint < COLUMN_BREAKPOINTS.md ? COLUMNS.md : COLUMNS.lg;
  const margin = breakpoint < MARGIN_BREAKPOINTS.sm ? MARGINS.sm : breakpoint < MARGIN_BREAKPOINTS.md ? MARGINS.md : MARGINS.lg;
  const gutter = breakpoint < GUTTER_BREAKPOINTS.sm ? GUTTERS.sm : GUTTERS.md;

  return {
    widthPx: breakpoint,
    columns: columnCount,
    gutterPx: gutter,
    maxWidthPx: breakpoint - margin * 2
  };
};

export const configByBreakpoint = breakpointsPx.reduce((acc, breakpoint) => {
  acc[breakpoint] = _getBreakpointConfig(breakpoint);
  return acc;
}, {} as Record<BreakpointPx, LayoutGridByBreakpointIndex>);

export const createLayoutGrid = (breakpointWidths: Readonly<BreakpointWidthsPx | Array<BreakpointPx>>): LayoutGrid => {
  return breakpointWidths.reduce(
    (acc, cur) => {
      const config = configByBreakpoint[cur];
      // acc
      acc.columns.push(config.columns);
      acc.gutters.push(pxToRem(config.gutterPx));
      acc.maxWidths.push(pxToRem(config.maxWidthPx));
      return acc;
    },
    {
      columns: [],
      gutters: [],
      maxWidths: []
    } as LayoutGrid
  );
};

export const allGridRecommendations = createLayoutGrid(breakpointsPx);
